import { v4 as uuidv4 } from 'uuid';

export default {
  /**
   *
   * @param {Function} commit
   */
  initNewNft({ commit }) {
    commit('reset');
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} attribute
   */
  addAttribute({ commit }, attribute) {
    attribute.id = uuidv4();
    commit('setAttribute', attribute);
  },
  /**
   *
   * @param {Function} commit
   * @param {String} id
   */
  removeAttribute({ commit }, id) {
    commit('deleteAttribute', id);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} contract
   */
  updateContract({ commit }, contract) {
    commit('setContract', contract);
  },
};
