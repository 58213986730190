import { eidApi, productsApi, stripeApi } from '@/apis';
import { ROUTES, SETTINGS } from '@/constants';
import { xLocale } from '@/locale';
import { loadStripe } from '@stripe/stripe-js';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} productsCategory
   */
  async initPurchaseDashboard({ commit, dispatch }, productsCategory) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchProducts', productsCategory)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} category
   */
  async fetchProducts({ commit, rootGetters }, category) {
    const token = rootGetters['auth/token'];
    try {
      const response = await productsApi.requestList({
        token,
        locale: xLocale,
        category,
      });
      commit('setProducts', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Array.<Object>} contracts
   * @param {Object} data
   * @param {String} productId
   */
  async buyProduct({ commit, dispatch }, { contracts, data, productId }) {
    commit('setLoading', true);
    const requests = [
      dispatch('acceptAgreements', contracts),
      dispatch('updateIdentity', data),
    ];
    await Promise.all(requests);
    await dispatch('payByStripe', productId);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async updateIdentity({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    try {
      await eidApi.requestUpdate({
        locale: xLocale,
        token,
        data,
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Array.<Object>} contracts
   */
  async acceptAgreements({ commit, rootGetters }, contracts) {
    if (!contracts.length) return;
    const token = rootGetters['auth/token'];
    try {
      await eidApi.requestUpdateContracts({
        locale: xLocale,
        token,
        data: { contracts },
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} dispatch
   * @param {String} membershipId
   */
  async payByStripe({ dispatch }, membershipId) {
    const data = {
      cancel_url: `${SETTINGS.HOST}${ROUTES.PATH.OUTPUT_CANCEL}`,
      membership_id: membershipId,
      success_url: `${SETTINGS.HOST}${ROUTES.PATH.OUTPUT_WAITING}`,
    };
    const sessionId = await dispatch('getStripeSessionId', data);
    await dispatch('redirectToCheckout', sessionId);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async getStripeSessionId({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stripeApi.requestCreateSession({
        locale: xLocale,
        token,
        data,
      });
      return response.data.session_id;
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {String} sessionId
   */
  async redirectToCheckout({ commit }, sessionId) {
    if (!process.env.VUE_APP_STRIPE_PUBLIC_KEY) return;
    try {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId: sessionId });
    } catch (error) {
      commit('setError', error);
    }
  },
};
