export const intro = {
  buttonRegister: 'Register Now',
  closeText: 'Of course, you can switch from a free plan to a business plan at any time',
  closeTitle: 'Register for Free',
  faq01Answer: 'Companies can increase consumer trust by recording events throughout the entire product lifecycle, from production to recycling or disposal. This allows consumers to have a transparent view of the authenticity and condition of the product they are purchasing, thus increasing buyer confidence and maximizing customer loyalty.',
  faq01Question: 'How can companies increase consumer trust in their products?',
  faq02Answer: 'Digital passports can verify the sustainability of a product by validating and protecting sustainability attributes and claims made by companies on an immutable blockchain network. This allows businesses to optimize their circular strategy and become leaders in sustainability, responding to customers\' growing demands for more eco-friendly practices.',
  faq02Question: 'How can digital passports verify the sustainability of a product?',
  faq03Answer: 'Companies can protect consumers by ensuring that raw materials and production processes meet predefined standards. This safeguards customers from illegitimate, dangerous, and low-quality materials and final products, also protecting the brand from negative public relations issues such as product recalls and incidents caused by defects.',
  faq03Question: 'How can companies protect consumers from low-quality materials and products?',
  faq04Answer: 'Digital passports increase buyer confidence by allowing them to make more informed purchasing decisions regarding the sustainability performance of brands. As consumers increasingly prefer brands that support their eco-friendly claims, digital passports are an ideal tool.',
  faq04Question: 'How do digital passports increase buyer confidence?',
  faq05Answer: 'Digital passports can help consumers recognize the carbon footprint of products by providing accurate data on energy consumption and the environmental impact associated with the products. This is particularly useful for consumers looking to improve their sustainable performance and reduce their carbon footprint.',
  faq05Question: 'How can digital passports help consumers recognize the carbon footprint of products?',
  faqTitle: 'Frequently Asked Questions',
  featureSlim01Description: 'Accurately assess the sustainability and recyclability of products along with their environmental impact, sharing verifiable claims with customers and regulators.',
  featureSlim01Title: 'Demonstrable Sustainability',
  featureSlim02Description: 'Record key events along the product lifecycle, including repairs, maintenance, and upgrades, building a completely transparent product history accessible by multiple stakeholders.',
  featureSlim02Title: 'Lifecycle Tracking',
  featureSlim03Description: 'Verify compliance with the European Ecodesign for Sustainable Products Regulation (ESPR) and demonstrate that your organization is achieving circularity goals.',
  featureSlim03Title: 'Regulatory Compliance',
  featureSlim04Description: 'Allow customers to trace the origin and source of raw materials and resources used in the production process, enhancing consumer trust.',
  featureSlim04Title: 'Track Provenance',
  featureSlim05Description: 'NFT warranty certificates tied to a physical product allow customers to verify the authenticity of their product through the associated DPP, combating illegal counterfeits.',
  featureSlim05Title: 'Combat Counterfeits',
  featureSlim06Description: 'Offer warranty extensions in a fully automated and proactive manner, significantly improving warranty management and increasing post-sales revenue.',
  featureSlim06Title: 'Post-Sales Revenue',
  featureWide01Description: 'Digital passports facilitate the improvement of product recyclability and end-of-life processes.<br>Demonstrate compliance with EU sustainability guidelines and new ESPR legislation.<br>Capture and record product sustainability data to verify environmental impact.<br>Monitor and record significant events throughout the lifecycle to build an accurate product history.',
  featureWide01Subtitle: 'Product recyclability and end-of-life processes.',
  featureWide01Title: 'Product Sustainability and Circularity',
  featureWide02Description: 'Improve data accuracy and increase transparency throughout the entire supply chain.<br>Trace the origin of raw materials and resources to accurately illustrate proof of origin.<br>Easily verify product authenticity and effectively combat the counterfeit market.',
  featureWide02Subtitle: 'More efficient, accurate, and transparent supply chain.',
  featureWide02Title: 'Supply Chain Traceability',
  heroDescription: 'The Digital Product Passport, thanks to blockchain and the issuance of an NFT certificate, allows companies to meet EU standards on product sustainability, improve supply chain traceability, and enhance post-sales revenue with warranty extensions.',
  heroTitle: 'Create Your Product\'s Digital Passport',
  witness01City: 'Padua',
  witness01Name: 'Giovanni',
  witness01Text: 'The Digital Passport has increased the transparency and traceability of our supply chain.',
  witness02City: 'Rome',
  witness02Name: 'Paola',
  witness02Text: 'Using Digital Product Passports has revolutionized our approach to sustainability.',
  witness03City: 'Trieste',
  witness03Name: 'Marco',
  witness03Text: 'Digital Product Passports have transformed our ability to engage customers.',
  witnessTitle: 'User Testimonials',
}
