import { LOCALES } from '@/constants';
import termsEnConstant from './terms-en-constant';
import termsItConstant from './terms-it-constant';

const TERMS = (lang) => {
  switch (lang) {
    case LOCALES.IT:
      return termsItConstant;
    default:
      return termsEnConstant;
  }
};

export default TERMS;
