import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en/index.js';
import it from './it/index.js';
import { LOCALES } from '@/constants';

export const xLocale = navigator.language || navigator.userLanguage || LOCALES.EN;

Vue.use(VueI18n);

export default new VueI18n({
  locale: xLocale,
  fallbackLocale: LOCALES.EN,
  messages: { en, it },
  availableLocales: [LOCALES.EN, LOCALES.IT],
  silentFallbackWarn: true,
});
