import quickDeliveryHelper from './helpers/quickDeliveryHelper';
import { vaultApi } from '@/apis';

export default {
  async initQuickDelivery({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchContacts')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   */
  async fetchContacts({ commit, dispatch, rootGetters }) {
    commit('contacts/reset', null, { root: true });
    do {
      await dispatch(
        'contacts/fetchContactsList',
        { cursor: rootGetters['contacts/cursor'] },
        { root: true },
      );
    } while (rootGetters['contacts/cursor']);
  },
  /**
   *
   * @param {Function} commit
   * @param {File} file
   */
  saveFile({ commit }, file) {
    commit('setFile', file);
  },
  /**
   *
   * @param {Function} commit
   * @param {String} address
   */
  saveRecipient({ commit }, address) {
    commit('setRecipient', address);
  },
  async notarize({ commit, dispatch, getters }) {
    commit('setLoading', true);
    const file = getters['file'];
    const data = await dispatch('deliverNewVault', file);
    if (data) {
      const fileName = file.name;
      const recipient = getters['recipient'];
      await dispatch('deliverNewSharedoc', {
        fileName,
        recipient,
        vaultId: data.document_id,
      });
    }
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {File} file
   * @returns {Promise}
   */
  async deliverNewVault({ commit, rootGetters }, file) {
    const token = rootGetters['auth/token'];
    const body = quickDeliveryHelper.buildNewVault(file);
    try {
      const response = await vaultApi.requestCreate(token, body);
      return response.data;
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   * @returns {Promise}
   */
  async deliverNewSharedoc({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    const { fileName, recipient, vaultId } = data;
    try {
      const body = await quickDeliveryHelper.buildShareVault(
        fileName,
        recipient,
      );
      const response = await vaultApi.requestShare({
        token,
        vaultId,
        data: body,
      });
      commit('setProcessId', response.data.process_id);
    } catch (error) {
      commit('setError', error);
    }
  },
};
