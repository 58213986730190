import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { v4 as uuidv4 } from 'uuid';
import { LISTINGS } from '@/constants';

dayjs.extend(objectSupport);

const mockVault = (id = uuidv4()) => {
  const item = {
    document_uuid: id,
    name: `File ${id}`,
    description:
      'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit',
    created_at: dayjs().format(),
  };
  return item;
};

const mockVaults = (vaults = 5) => {
  const list = new Array(vaults).fill(null).map(() => mockVault());
  return list;
};

const mockVaultFull = (status = LISTINGS.TX.MESSAGE_STATUS.SUCCESS) => {
  const item = {
    chain_id: 'commercio-testnet10k2',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    document_id: '87df0ab9-58b2-4e41-9b81-ebb41b54c768',
    dossier: {
      color: '#FF0000',
      created_at: '1970-01-01T01:00:00+01:00',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      id: '87df0ab9-58b2-4e41-9b81-ebb41b54c768',
      name: 'My dossier',
      updated_at: '1970-01-01T01:00:00+01:00',
    },
    dossier_id: '6ae6e4c4-bcd2-4a58-a535-35ecfd68da17',
    error: 'bastion could not sign the messages',
    hash: '2cf24dba5fb0a30e26e83b2ac5b9e29e1b161e5c1fa7425e73043362938b9824',
    hash_alg: 'md5',
    metadata: {
      content_uri:
        '55fa8b74d91bc8443f46b9dc7a179bd3f709bb803f9dccda467310f0fb656a7f',
      schema: {
        uri: 'http://example.com/schema.xml',
        version: '1.0.0',
      },
    },
    name: 'document.pdf',
    process_id: '87df0ab9-58b2-4e41-9b81-ebb41b54c768',
    recipients: ['did:com:1svkc84z3dxzrdtddwt58ycme69jtlm5yyvemgw'],
    sender: 'did:com:1svkc84z3dxzrdtddwt58ycme69jtlm5yyvemgw',
    status,
    storage_type: 'http',
    storage_uri:
      '55fa8b74d91bc8443f46b9dc7a179bd3f709bb803f9dccda467310f0fb656a7f',
    tx_hash: 'FA81C0F03B49FEA2D3E671DC1504004DEDE7F1642C341DC0CCB03E9CE3BC3966',
    tx_timestamp: '2017-07-21T17:32:28Z',
    tx_type: 'send',
  };
  return item;
};

export { mockVault, mockVaults, mockVaultFull };
