import { mintApi } from '@/apis';
import { featureActivator } from '@/utils';
import { v4 as uuidv4 } from 'uuid';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} id
   */
  async initMintWizard({ commit, dispatch }, id) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchContractDetail', id),
      dispatch('saveHasFunds'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchContractDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    try {
      const response = await mintApi.requestDetail(token, id);
      commit('setContract', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {number} txsAmount
   */
  saveHasFunds({ commit, rootGetters }, txsAmount = 1) {
    const identity = rootGetters['account/identity'];
    const surcharge = rootGetters['application/surcharge'];
    const hasFunds = featureActivator.hasFunds({
      identity,
      surcharge,
      txsAmount,
    });
    commit('setHasFunds', hasFunds);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} data
   */
  saveBasicData({ commit }, data) {
    commit('setBasicData', data);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} data
   */
  saveMetadata({ commit }, data) {
    commit('setMetadata', data);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} attribute
   */
  saveAttribute({ commit }, attribute) {
    if (!attribute.id) {
      attribute.id = uuidv4();
    }
    commit('setAttribute', attribute);
  },
  /**
   *
   * @param {Function} commit
   * @param {String} id
   */
  removeAttribute({ commit }, id) {
    commit('deleteAttribute', id);
  },
  mint() {
    console.log('MINT');
  },
};
