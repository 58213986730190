<template>
  <v-btn
    :color="color"
    :depressed="hasLabel"
    :disabled="disabled"
    :icon="!hasLabel"
    :loading="isLoading"
    @click.stop="onClick"
  >
    <span v-if="hasLabel" class="text-caption" v-text="$t('labels.download')" />
    <v-icon :right="hasLabel">{{ mdiDownload }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiDownload } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BaseBtnDownloadFromVault',
  props: {
    documentName: {
      type: String,
      required: true,
      note: 'The document name',
    },
    documentUUID: {
      type: String,
      required: true,
      note: 'The document ID',
    },
    color: {
      type: String,
      default: 'info',
    },
    disabled: {
      type: Boolean,
      dafult: false,
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mdiDownload,
    };
  },
  computed: {
    ...mapGetters('download', ['isLoading', 'url']),
  },
  methods: {
    ...mapActions('download', ['fetchFileFromVault']),
    async onClick() {
      await this.fetchFileFromVault(this.documentUUID);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', this.url);
      link.setAttribute('download', this.documentName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
