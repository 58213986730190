import { initState } from './index';
import { COIN } from '@/constants';
import { settingsHandler } from '@/utils';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setHandling(state, payload) {
    state.isHandling = payload;
  },
  setSettings(state, payload) {
    const config = structuredClone(payload);
    const { surcharge, theme } = { ...config };
    delete surcharge.totals;
    surcharge.parameters = settingsHandler.convertSurchargeFromBase(
      surcharge.parameters,
      COIN.STABLE.EXPONENT,
    );
    state.settings = { surcharge, theme };
  },
  setValidSettings(state, payload) {
    state.areValidSettings = payload;
  },
  setTheme(state, payload) {
    state.settings.theme = payload;
  },
  setParameters(state, payload) {
    state.settings.surcharge.parameters = payload;
  },
  setVisibility(state, payload) {
    state.settings.surcharge.visibility = payload;
  },
};
