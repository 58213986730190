const PROGRESS = Object.freeze({
  CONFIRM: 'confirm',
  HOW: 'how',
  WHAT: 'what',
  WIZARD: 'wizard',
});

const EARCHIVE = Object.freeze({
  PROGRESS,
});

export default EARCHIVE;
