export default Object.freeze({
  TITLE: `Agreement for the purchase of technology goods and services ("Agreement")<br />
  General terms and conditions`,
  P1: `
  <h2>1. Scope of Application</h2>
  <p>
    The following provisions of the general terms and conditions regulate the
    relationship between Commercio.network SpA (hereinafter referred to as
    ‘COMMERCIO.NETWORK’), with registered office in 36015- Schio (VI), Via Luigi
    dalla Via n. 3/b, cf/piva 04136930247, in the person of its legal
    representative pro tempore and the subject who adheres to these general terms
    and conditions for the use of Membership services (hereinafter referred to as
    ‘MEMBER’)<br />
    The terms, conditions and provisions contained in the hyperlinks in the
    following general terms and conditions in the version in force at the time of
    the performance of each individual activity of the Member also form an
    integral part of the Agreement.<br />
    This Agreement constitutes the entire agreement between the Parties with the
    same subject matter and supersedes any previous agreement with the same
    subject matter and also supersedes any general terms and conditions of the
    Member, which shall be ineffective.
  </p>
  `,
  P2: `
  <h2>2. Definitions</h2>
  <p>The terms indicated below have the meanings specified here:</p>
  <ul>
    <li>
      Blockchain Commercio.Network or Commercio.Network: The blockchain network
      consisting of the set of Nodes that enables the creation of decentralised
      identities, as well as the signing and sending of documents.
    </li>
    <li>
      User: An individual or legal entity that has completed the registration
      process on Commercio.App.
    </li>
    <li>
      Member: An individual or legal entity that has agreed to this Agreement and
      purchased one of the various types of Memberships, namely GREEN, BRONZE,
      SILVER, or GOLD.
    </li>
    <li>
      Green Member: An individual who acts for purposes unrelated to
      entrepreneurial, commercial, artisanal, or professional activities as per
      Article 3, Paragraph 1, Letter a) of Legislative Decree no. 206/2005 (the
      Consumer Code) and who has agreed to this Agreement by completing the
      procedure provided on Commercio.App and purchasing a Green Membership.
    </li>
    <li>
      Bronze Member, Silver Member, and Gold Member: Respectively, individuals or
      legal entities with a VAT number who act in the exercise of their
      professional, entrepreneurial, commercial, or artisanal activities and who
      are not the subjects indicated in Article 3, Paragraph 1, Letters a-b, of
      Legislative Decree no. 206/2005 (the Consumer Code), and who have purchased
      a Bronze Membership, Silver Membership, or Gold Membership.
    </li>
    <li>
      Membership: The relationship between the interested party (provided they are
      already a User) and COMMERCIO.NETWORK, established by adhering to this
      Agreement, which allows them to benefit from the package of services
      (Membership Benefits) provided to Members by COMMERCIO.NETWORK through the
      Commercio.Network Blockchain.
    </li>
    <li>
      Membership Benefits: The set of services provided to Members by
      COMMERCIO.NETWORK (through the Commercio.Network Blockchain), obtainable by
      purchasing a Membership.
    </li>
    <li>
      Affiliate Programs: The set of all current and future affiliate programs
      that Bronze Members, Silver Members, or Gold Members can join.
    </li>
    <li>
      Validator: A legal entity with a VAT number that acts in the exercise of its
      professional, entrepreneurial, commercial, or artisanal activities and is
      not one of the subjects indicated in Article 3, Paragraph 1, Letters a-b, of
      Legislative Decree no. 206/2005 (the Consumer Code), which operates a
      Validator Node of Commercio.Network.
    </li>
    <li>
      Validator Program: The affiliate program that establishes the rights and
      duties of Validator Nodes.
    </li>
    <li>
      Distributor: An individual or legal entity with a VAT number that acts in
      the exercise of its professional, entrepreneurial, commercial, or artisanal
      activities and is not one of the subjects indicated in Article 3, Paragraph
      1, Letters a-b, of Legislative Decree no. 206/2005 (the Consumer Code),
      which promotes the Membership services of COMMERCIO.NETWORK.
    </li>
    <li>
      Distributor Program: The affiliate program that establishes the rights and
      duties of Distributors.
    </li>
    <li>
      Developer: An individual or legal entity with a VAT number that acts in the
      exercise of its professional, entrepreneurial, commercial, or artisanal
      activities and is not one of the subjects indicated in Article 3, Paragraph
      1, Letters a-b, of Legislative Decree no. 206/2005 (the Consumer Code),
      which develops Apps.
    </li>
    <li>
      Developer Program: The affiliate program that establishes the rights and
      duties of Developers.
    </li>
    <li>
      Ambassador: An individual or legal entity with a VAT number that acts in the
      exercise of its professional, entrepreneurial, commercial, or artisanal
      activities and is not one of the subjects indicated in Article 3, Paragraph
      1, Letters a-b, of Legislative Decree no. 206/2005 (the Consumer Code),
      which promotes the Membership services of COMMERCIO.NETWORK.
    </li>
    <li>
      Ambassador Program: The affiliate program that establishes the rights and
      duties of Ambassadors.
    </li>
    <li>
      Commercio token (symbol COM): Utility tokens generated by Commercio.Network
      in a predetermined amount of 60 million, with a value determined by the
      market. Their purchase (via digital platform or third parties) is subject to
      the standard VAT rate as per Presidential Decree no. 633/1972, created to
      secure the Blockchain through the staking mechanism.
    </li>
    <li>
      Commercio Cash Credit (symbol CCC): Utility tokens generated by
      Commercio.Network in an undetermined number, currently sold at EUR 1.00 each
      or at a different price indicated during the purchase procedure at the time
      of the interested party's registration, plus the standard VAT rate as per
      Presidential Decree no. 633/1972, created for transactions on the
      Commercio.Network Blockchain.
    </li>
    <li>Utility Token: COM and CCC collectively.</li>
    <li>
      VBR: The compensation in COM that Members receive, under certain conditions,
      when they perform the staking activity of COM, either by themselves (as
      Nodes) or by delegating to third parties who are Validator Nodes.
    </li>
    <li>
      ABR: The compensation in COM that Members receive, under certain conditions,
      when they promote the purchase of GREEN, BRONZE, SILVER, or GOLD Memberships
      to Clients, according to the terms and conditions of the Agreement.
    </li>
    <li>
      Staking: The specific activity of staking according to the protocol of the
      Proof of Stake system, i.e., creating a bond as a guarantee of the
      correctness of the activity performed as a Validator Node of
      Commercio.Network according to the system's rules.
    </li>
    <li>
      Validator Nodes: Validator nodes, i.e., servers interconnected to create a
      distributed and decentralized infrastructure to perform the validation of
      transactions occurring on the Commercio.Network Blockchain.
    </li>
    <li>
      Protocol: The consensus protocol of the Commercio.Network Blockchain that
      uses the Proof of Stake (POS) method and is based on the Tendermint
      algorithm.
    </li>
    <li>
      Project: The development project of the Commercio.Network Blockchain,
      available at https://commercio.network/it/progetto.
    </li>
    <li>
      Software: The software developed by COMMERCIO.NETWORK necessary for the use
      of the Commercio.Network Blockchain.
    </li>
    <li>
      Validation: The validation mechanism of the Commercio.Network Blockchain,
      which is based on a consensus system using the Proof of Stake (POS) method
      and algorithm.
    </li>
    <li>
      eID (or Commercio eID): A managed (hosted) electronic wallet that allows
      Members to create and manage a sovereign SSI (Self Sovereign Identity) and
      to check the balance of the eID wallet in credits and tokens.
    </li>
    <li>
      Credits Wallet: A managed (hosted) electronic wallet that allows Members to
      purchase, store, receive, track, transfer, and manage Commercio Cash Credit
      (symbol CCC) balances.
    </li>
    <li>
      Token Wallet: A managed (hosted) electronic wallet that allows Members to
      exchange, store, receive, track, transfer, and manage token balances on
      Commercio.Network (e.g., COM symbol).
    </li>
    <li>
      DID:COM: The unique address on the blockchain of the wallet and the
      decentralized eID identity.
    </li>
    <li>
      CommercioID: The IT protocol that allows for the creation of a decentralized
      identity.
    </li>
    <li>
      CommercioSign: The IT protocol that allows for the creation of an electronic
      signature.
    </li>
    <li>
      CommercioDocs: The IT protocol that allows for the exchange of a document,
      certifying its integrity.
    </li>
    <li>
      Commercio.App: The electronic system where a user (User) can register and
      subsequently purchase a Membership that allows access to: eID, App, Wallet,
      Token Wallet, and Affiliate Programs.
    </li>
    <li>
      App or APP: A software application based on the protocols of
      Commercio.Network and created by a Developer to be used by Members, subject
      to registration and possible purchase.
    </li>
    <li>
      Apps: The electronic system where a Member can find an App and register.
    </li>
    <li>
      API (Application Programming Interface): The programming interface provided
      by COMMERCIO.NETWORK that allows Members to integrate their application
      software with the available protocols of Commercio.Network.
    </li>
  </ul>
  `,
  P3: `
  <h2>3. Object of the Agreement</h2>
  <h3>3.1. Membership</h3>
  <p>
    COMMERCIO.NETWORK has launched the Commercio.Network Blockchain. This
    Agreement governs the terms and conditions for the purchase of a Membership,
    through which the Member can acquire services based on Commercio.Network (the
    Membership Benefits). To use or purchase any product or service within
    Commercio.Network, it is necessary to use the utility token called Commercio
    Cash Credit (CCC).<br />
    A Member, by accessing the dedicated area of Commercio.App, can proceed with
    one or more of the following purchase options:
  </p>
  <ul>
    <li>
      GREEN: For individuals acting for purposes unrelated to entrepreneurial,
      commercial, artisanal, or professional activities as per Article 3,
      Paragraph 1, Letter a) of Legislative Decree no. 206/2005 (the Consumer
      Code).
    </li>
    <li>
      BRONZE, SILVER, and GOLD: For individuals or legal entities with a VAT
      number who act in the exercise of their professional, entrepreneurial,
      commercial, or artisanal activities and who are not the subjects indicated
      in Article 3, Paragraph 1, Letters a-b, of Legislative Decree no. 206/2005
      (the Consumer Code).
    </li>
  </ul>
  <p>
    All Memberships (GREEN, BRONZE, SILVER, and GOLD) receive an eID to use for
    all contractual relationships within the entire Commercio.Network ecosystem
    and the Application Programming Interface (API).<br />
    Additionally, holding a BRONZE, SILVER, or GOLD Membership allows the Member
    to purchase Commercio Cash Credit (CCC).<br />
    By joining Commercio.Network, each Member commits to COMMERCIO.NETWORK and to
    each other Member as follows:
  </p>
  <ol type="a">
    <li>
      To accept being identified through the KYC (know your client) system
      provided for in this Agreement.
    </li>
    <li>To recognize and not contest the eID of each Member.</li>
    <li>
      To recognize and not contest the eSignature applied to documents by another
      Member through the use of dedicated Apps and/or by COMMERCIO.NETWORK on
      Commercio.App.
    </li>
    <li>
      To recognize and not contest the eDelivery of documents carried out by
      another Member through the use of dedicated Apps and/or by COMMERCIO.NETWORK
      on Commercio.App.
    </li>
    <li>
      To use Utility Tokens as the unit of account and payment for the Membership
      Benefits used through the use of dedicated Apps and/or by COMMERCIO.NETWORK
      on Commercio.App.
    </li>
  </ol>
  <h3>3.2. Extra Benefits</h3>
  <p>
    According to the terms and conditions of this Agreement, during the Membership
    period, COMMERCIO.NETWORK may offer Members a series of additional and
    distinct Extra Benefits, either free of charge or for a fee. The Extra
    Benefits are additional services compared to the Membership Benefits provided
    by COMMERCIO.NETWORK to its Members, which from time to time meet the
    requirements specified and indicated by COMMERCIO.NETWORK, such as eligibility
    criteria for each individual and distinct Extra Benefit.<br />
    COMMERCIO.NETWORK reserves the right to define and modify the eligibility
    criteria for the attribution of each Extra Benefit, to grant or deny each
    Extra Benefit to Members, and to change the content of each Extra Benefit at
    any time and for any reason, without entitling the Member to any indemnity
    and/or compensation for economic and non-economic damages of any kind.
    Currently, COMMERCIO.NETWORK offers Free Commercio Cash Credits. Access to
    this experimental service is granted for a temporary period and can be removed
    at any time. The service consists of a one-time variable gratuitous allocation
    of Commercio Cash Credits (CCC) for promotional purposes, which can only be
    used to test the services.<br />
    Currently, COMMERCIO.NETWORK offers Free Commercio Cash Credits. Access to
    this experimental service is granted for a temporary period and can be removed
    at any time. The service consists of a one-time variable gratuitous allocation
    of Commercio Cash Credits (CCC) for promotional purposes, which can only be
    used to test the services.<br />
    The list detailing the content of any additional Extra Benefits is provided at
    the following link: https://commercio.app/membership/extrabenefits on
    Commercio.App, and it will be updated from time to time by COMMERCIO.NETWORK.
    COMMERCIO.NETWORK reserves the right, at its sole discretion, to verify the
    eligibility requirements of each Member for the attribution of each Extra
    Benefit. The attribution of each Extra Benefit will be communicated by
    COMMERCIO.NETWORK to the Member via email to the address provided during
    Member registration and/or via a notification that will appear when the Member
    accesses their Account.
  </p>
  <h3>3.3. Purchase of Utility Tokens from Commercio.Network</h3>
  <p>
    To use or purchase any product or service within Commercio.Network, it is
    necessary to use the utility token called Commercio Cash Credit (CCC).
  </p>
  `,
  P4: `
  <h2>4. Contractual basis</h2>
  <p>
    The subscription to this Agreement by an interested party is possible provided
    that the party has already registered as a User, adhering to the related
    agreement with COMMERCIO.NETWORK.<br />
    The User interested in subscribing to this Agreement must correctly complete
    the registration procedure as a Member and proceed with the purchase and
    payment of one of the types of Membership (GREEN, BRONZE, SILVER, and GOLD).
    Only once the party has registered on Commercio.App and subsequently received
    confirmation of their registration from COMMERCIO.NETWORK, will they become,
    and be considered in all respects, a Member of Commercio.Network.
  </p>
  `,
  P5: `
  <h2>5.Types of Membership</h2>
  <p>
    The Member who has purchased one of the types of Membership will be able to
    enjoy the types of Member Benefits provided for that specific type of
    Membership. If a Member wishes to use services provided in a different type of
    Membership, they must purchase that different type of Membership, as the
    purchase of individual additional Member Benefits is not provided for in this
    Agreement.<br />
    COMMERCIO.NETWORK commits, for the duration and under the terms and conditions
    of this Agreement, to provide the individual Member Benefits indicated below
    and further detailed in the area Commercio.app/membership/ to the individual
    Member.
  </p>
  <h3>GREEN MEMBERSHIP</h3>
  <p>This type entitles you to the following Member Benefits:</p>
  <ul>
    <li>eID</li>
  </ul>
  <h3>BRONZE MEMBERSHIP (AMBASSADOR)</h3>
  <p>This type entitles you to the following Member Benefits:</p>
  <ul>
    <li>eID</li>
    <li>Programming Interface (API);</li>
    <li>the Member may purchase or hold CCC Commercio Cash Credits;</li>
    <li>
      the Member may participate in Ambassador affiliate programmes offered by
      COMMERCIO.NETWORK when they are activated, subject to signing the relevant
      contract.
    </li>
  </ul>
  <h3>SILVER MEMBERSHIP (DEVELOPER)</h3>
  <p>This type entitles you to the following Member Benefits:</p>
  <ul>
    <li>eID</li>
    <li>Programming Interface (API);</li>
    <li>the Member may purchase or hold CCC Commercio Cash Credits;</li>
    <li>
      the Member may participate in Developer affiliate programmes offered by
      COMMERCIO.NETWORK when they are activated, subject to signing the relevant
      contract.
    </li>
  </ul>
  <h3>GOLD MEMBERSHIP (DISTRIBUTOR)</h3>
  <p>This type entitles you to the following Member Benefits:</p>
  <ul>
    <li>eID</li>
    <li>Programming Interface (API);</li>
    <li>the Member may purchase or hold CCC Commercio Cash Credits;</li>
    <li>
      the Member may participate in Distributor affiliate programmes offered by
      COMMERCIO.NETWORK when they are activated, subject to signing the relevant
      contract.
    </li>
  </ul>
  <p>
    For GREEN, BRONZE, SILVER and GOLD Memberships COMMERCIO.NETWORK will create
    and make available to the Member on Commercio.App a wallet with a unique
    public non-transferable identification address (hereinafter referred to as
    ‘DID:COM’).
  </p>
  `,
  P6: `
  <h2>6. Member registration procedure</h2>
  <p>
    The contract between COMMERCIO.NETWORK and the potential Member is executed
    only after registration as a Member and the completion of the formalities
    indicated below. Registration can be carried out in the following ways:
  </p>
  <ol type="a">
    <li>Through Commercio.App;</li>
    <li>
      Through a Distributor, by registering via the link assigned by
      COMMERCIO.NETWORK to the Distributor according to the terms and conditions
      of the contract concluded with the Distributor;
    </li>
    <li>
      Through a Developer, by registering via the link assigned by
      COMMERCIO.NETWORK to the Developer according to the terms and conditions of
      the contract concluded with the Developer;
    </li>
    <li>
      Through another Member participating in the Ambassador Program, by
      registering via the link assigned by COMMERCIO.NETWORK to the Ambassador.
    </li>
  </ol>
  <p>
    Once the registration process has been successfully completed, the interested
    party can purchase a GREEN Membership or one of the other three available
    Memberships: BRONZE, SILVER, and GOLD. Membership is reserved for those who
    have already successfully registered as a User.<br />
    For the purchase of Membership, the interested party must correctly complete
    the identification phase by providing the following required data, which are
    mandatory:
  </p>
  <ul>
    <li>
      Individuals: Name, Surname, email, Mobile phone number, Tax Code, Country of
      Tax Residence, and VAT number (if not a consumer as per Article 3, Paragraph
      1, Letter a) of Legislative Decree no. 206/2005);
    </li>
    <li>
      Legal Entities: Company Name, Name and Surname of the Legal Representative,
      Legal Representative’s email, Legal Representative’s mobile phone number,
      Legal Representative’s Tax Code, VAT number, and Country of Tax Residence.
    </li>
  </ul>
  <p>
    The interested party undertakes to provide all the data requested at the time
    of registration. In particular, the interested party undertakes to provide
    truthful, accurate, and complete information during registration and use of
    the Commercio.App, and to ensure its periodic updating.<br />
    The interested party is aware that failure to provide all the requested data
    will result in COMMERCIO.NETWORK's inability to accept their request to join
    this Agreement.<br />
    Upon successful completion of the registration procedure, the Member will
    become the holder of a personal account (the "Account") with an email address
    and creation of a personal password indicated.<br />
    Access to Commercio.App will take place through login, indicating the email
    and password. COMMERCIO.NETWORK, following the purchase of the Membership,
    will allow the Member to activate a Strong Customer Authentication (SCA)
    mobile app that will allow the user to generate a One Time Password (OTP,
    which constitutes the second factor of security authentication). The Member
    undertakes not to disclose any information about the Account to any party.<br />
    For security reasons, under no circumstances should Account details and/or
    passwords, OTPs necessary to access them, be shared with third parties.
    Additionally, the Member undertakes to adopt every suitable, reasonable, and
    appropriate measure to protect and safeguard their Account. The Member
    acknowledges that they are solely responsible for maintaining the
    confidentiality and security of the Account and all activities carried out on
    or through it.<br />
    Any breach of Account security must be reported to COMMERCIO.NETWORK
    immediately, and in any case without delay; COMMERCIO.NETWORK will not be
    liable for damages, costs, or expenses resulting from or arising out of the
    failure to report violations promptly.<br />
    The Member is aware of and agrees that:
  </p>
  <ol type="I">
    <li>
      Before communicating their personal data to COMMERCIO.NETWORK, the
      interested party declares to have read and understood the privacy policy of
      Commercio.App (available at the following link
      https://commercio.app/privacy/) and that once registered, they undertake to
      review each subsequent update or modification;
    </li>
    <li>
      damages resulting from inaccuracies and/or incompleteness of the information
      provided cannot be attributed to COMMERCIO.NETWORK, and the interested party
      undertakes to indemnify and hold harmless COMMERCIO.NETWORK from any
      liability connected to willfully provided false statements;
    </li>
    <li>
      furthermore, any damages resulting from unauthorized access to the Account
      by third parties, following the Member's violation of the security measures
      provided and/or recommended or advisable to be implemented according to the
      rules of prudence, cannot in any case be attributed to COMMERCIO.NETWORK;
    </li>
    <li>
      furthermore, any violations of the above provisions may result in the
      suspension and/or deletion of the Account pursuant to articles 11.5 and
      18.2.
    </li>
  </ol>
  <p>
    COMMERCIO.NETWORK reserves the right to request, at any time and at its sole
    discretion, further information regarding the Member to allow for the proper
    operation and provision of services. In case of refusal, COMMERCIO.NETWORK may
    suspend the operation of the Account or proceed with its deletion.<br />
    The Member undertakes to promptly inform COMMERCIO.NETWORK of any changes to
    their personal data provided at the time of registration (in particular,
    address, email address, bank details, phone number, etc.).<br />
    Only one Account is allowed per natural or legal person holding a VAT number
    interested in becoming a Member. Therefore, a natural person User intending to
    become a Member may activate at most one Account as a natural person acting
    for purposes unrelated to entrepreneurial, commercial, artisanal, or
    professional activities possibly carried out pursuant to Article 3 paragraph 1
    letter a) of legislative decree no. 206/2005 (Consumer Code), and another
    Account as the holder of a VAT number, acting in the exercise of their own
    professional, entrepreneurial, commercial, or artisanal activity and different
    from the subjects indicated by Article 3, paragraph 1, letters a-b, of
    legislative decree no. 206/2005 (Consumer Code). It remains understood that in
    such cases, individual Accounts must be associated with distinct Devices, as
    it is prohibited to use an Account on a Device different from the one to which
    it was associated at the time of registration.<br />
    In the event that a User makes multiple registrations, COMMERCIO.NETWORK shall
    have the right to terminate the Agreement automatically pursuant to Article
    18.1 or suspend the service pursuant to Article 11.5. In case of multiple
    registrations, Accounts registered after the first one will be deleted, with
    immediate loss of all Membership Benefits obtained exclusively based on the
    multiple registration.<br />
    The acceptance of the Agreement by the interested party must take place,
    following the completion of the registration procedure, through a Point &
    Click system to be acquired in advance through a confirmation flag by the
    interested party. The contractual relationship governed by the general
    conditions of this Agreement is perfected when the interested party has
    received the acceptance confirmation email sent by COMMERCIO.NETWORK.<br />
    The Member must correctly complete the identification phase
    (Know-your-client-KYC) by providing all the required data, as mandatory under
    the registration procedure, and also providing valid identity documents.
  </p>
  `,
  P7: `
  <h2>7. Legal relationship</h2>
  <p>
    Between COMMERCIO.NETWORK and the Member, no type of employment, service, or
    corporate relationship is established (in particular, no associative
    relationship). The Member's participation in the various Programs: Validator
    program, Distributor program, Developer program, and Ambassador program occurs
    exclusively within the scope of autonomous and legally independent activity
    from COMMERCIO.NETWORK under their own responsibility.<br />
    The Member shall be entitled only to what is provided and regulated in this
    Agreement, namely the Membership Benefits. The Member is not entitled to any
    additional compensation for their activity. The Member is not entitled to any
    expense reimbursement. In the event that the Member invites other members
    through the Validator Program, or the Distributor Program, or the Developer
    Program, or the Ambassador Program, they shall be entitled to what is
    established in the individual agreements. The Member is not authorized to
    represent COMMERCIO.NETWORK, in particular, to provide or receive statements
    on behalf of other Members within any Affiliate Program.<br />
    The Member is not authorized to receive cash, nor to cash sums on behalf of
    COMMERCIO.NETWORK. A willful violation of the provisions of this article
    entitles COMMERCIO.NETWORK to automatically terminate the Agreement pursuant
    to Article 18.1. <br />
    Without prior express written authorization from COMMERCIO.NETWORK or in the
    absence of a specific contractual relationship (Validator Program -
    Distributor Program - Developer Program - Ambassador Program) with
    COMMERCIO.NETWORK, the Member is not authorized to:
  </p>
  <ol type="a">
    <li>
      to use logos, writings, trademarks, slogans, domains, other signs, and
      similar material of COMMERCIO.NETWORK, or of Commercio.Network, or logos and
      trademarks of the Developers;
    </li>
    <li>
      to create, in written or electronic form, disseminate or make available to
      the public in another form business cards, presentations, videos, audio
      files, screenshots, web content, multimedia content, flyers, brochures,
      websites, advertising documents, printed materials, mailings, homepages, or
      similar regarding COMMERCIO.NETWORK, or Commercio.Network, expressly
      mentioning the latter or the Membership Benefits Program (for example, on
      Internet pages such as YouTube or Facebook) to express political,
      ideological, or religious orientations or using tones and terms that are
      offensive or that otherwise exceed the limit of objective and civil
      criticism;
    </li>
    <li>
      to organize events such as informational events, workshops, seminars, etc.,
      regarding COMMERCIO.NETWORK or Commercio.Network.
    </li>
    <li>
      to promote towards retailers, wholesalers, and other resellers, as well as
      towards other companies or services intended for end consumers, including
      gas stations, franchisees, and department stores;
    </li>
    <li>
      to act on behalf and/or for the account of COMMERCIO.NETWORK and to assume
      obligations on behalf and/or for the account of COMMERCIO.NETWORK.
    </li>
  </ol>
  `,
  P8: `
  <h2>8. Purchasing a Membership</h2>
  <p>
    The purchase of one of the Memberships is possible through one of the channels
    described above in Article 6 and following correct registration.<br />
    The Member declares to have understood that Commercio.Network is not a system
    of payment in traditional FIAT currency "eMoney," but rather a decentralized,
    distributed, and immutable blockchain where data and document transactions are
    recorded and paid through Utility Tokens among network members. These are
    documentary transactions with legal validity according to eIDAS principles,
    which can only be made using CCC and COM tokens. These Utility Tokens are
    units of account and value on a voluntary basis available only on the
    Commercio.Network Blockchain. The purchase of a Membership allows access to
    Apps that are made available in the Apps area by the Developers. The Member
    can register or use these Apps.
  </p>
  `,
  P9: `
  <h2>9. Membership Benefits resulting from the purchase of Membership</h2>
  <p>
    The GREEN, BRONZE, SILVER, and GOLD Memberships entitle the Member to an eID
    that will allow them to obtain a Self-Sovereign Identity (SSI). Access is
    valid for 1 year. Only and exclusively BRONZE, SILVER, and GOLD Memberships
    will also entitle the Member to the following Benefits:
  </p>
  <ul>
    <li>Programming Interface (API);</li>
    <li>
      Credits Wallet (access for 1 year). This wallet will allow the purchase of
      Utility Tokens called Commercio Cash Credit (symbol CCC).
    </li>
  </ul>
  `,
  P10: `
  <h2>10. Creation of eID COMMERCIO</h2>
  <p>
    Commercio.App has a section called eID, which is a Self-Sovereign Identity
    (SSI), through which the Member can demonstrate ownership of their Documents.
    Since the Wallet address and that of the eID correspond, the user can also
    view the balance of their Commercio Cash Credits and Commercio Tokens, without
    however being able to perform any purchase, exchange, transfer, or management
    of the same Tokens.<br />
    No other service is owed by COMMERCIO.NETWORK and no other activity and/or
    service will be carried out by COMMERCIO.NETWORK.<br />
    In particular, the Member declares to be aware of and accept the following:
  </p>
  <ol type="a">
    <li>
      The Wallet that controls the Commercio eID does not constitute a service
      through which COMMERCIO.NETWORK acts as a financial intermediary or
      custodian and/or administrator of digital currencies but can only contain
      tokens usable for the services described in Article 9;
    </li>
    <li>
      COMMERCIO.NETWORK does not guarantee in any way the functionality, security,
      and confidentiality of Commercio eID;
    </li>
    <li>
      the use of Commercio eID is under the sole responsibility of the Member, at
      their complete discretion and in compliance with all applicable laws;
    </li>
    <li>
      the Member is solely responsible for the custody and security of the
      Account, password, SCA-OTP, and, in general, any access code to the
      Commercio eID (hereinafter also "Credentials"). In particular, the Member
      undertakes to adopt every suitable, reasonable, and appropriate measure to
      protect their Credentials; not to disclose their Credentials to third
      parties, not to share the access screen to Commercio.App; not to allow
      remote access to Commercio.App by third parties. The Member is aware that
      the loss, theft, misuse, and/or any other loss of control of the Credentials
      could result in the loss, theft, and/or unavailability of the Tokens
      contained in their Commercio eID in the future. The Member is aware of and
      agrees that in such cases COMMERCIO.NETWORK will be exempt from any
      liability for what happened.
    </li>
    <li>
      In the event that the Member loses, has their Credentials stolen and/or
      loses control of their Commercio eID in any way, it will not be possible for
      COMMERCIO.NETWORK to recover it in any way, and COMMERCIO.NETWORK itself
      cannot be held responsible for any resulting loss or damage.
    </li>
    <li>
      The Member is aware of and agrees that in case of suspected misuse and/or
      any hypothesis of violation of the security of their Credentials and/or any
      hypothesis of fraud attempt and/or unauthorized access and/or violation of
      the security of the Credentials ("Violations"), the Member must promptly and
      without delay inform COMMERCIO.NETWORK of what happened by sending an email
      to the Commercio.App support service at the address info@commercio.network
      containing accurate, detailed, and updated information about what happened.
      At the same time, the Member undertakes to adopt all reasonable and useful
      measures to reduce and manage security Violations. In the event of failure
      to promptly notify COMMERCIO.NETWORK by the Member, COMMERCIO.NETWORK cannot
      be held responsible for the adequacy or timeliness of its response, nor for
      any damages that may result to the Member.
    </li>
  </ol>
  `,
  P11: `
  <h2>11. Programming interface (API)</h2>
  <p>
    COMMERCIO.NETWORK will provide BRONZE SILVER and GOLD Members with the
    Programming Interface (API) that allows Members to integrate their application
    software with the available Commerce.Network protocols.<br />
    The use of the API allows transactions to be sent and stored on the
    Commerce.Network blockchain. The contents of the transactions sent, exchanged
    and stored by each Member on the Commerce.Network Blockchain constitute the
    contents of that Member (the ‘Contents’). As the Blockchain is an immutable
    ledger, there is no possibility to delete any data.<br />
    The Member is aware of and agrees to the following:
  </p>
  <h3>11.1. Contents.</h3>
  <p>
    The Member must ensure that the Content and its use do not violate any of the
    provisions listed in Appendix A ("Prohibited Uses") or any applicable law. The
    Member is solely responsible for the development, content, operation,
    maintenance, and use of their own Content. The Member will assume the full
    risk of loss and damage to their Content. The Member acknowledges and agrees
    to be solely responsible for all acts, omissions, use, expenses incurred with
    reference to any Content displayed, linked, transmitted, or stored through the
    APIs on Commercio.Network. The Member is solely responsible for taking
    measures to (i) prevent any loss or damage to the Member's Content; (ii)
    maintain independent backup copies of the Member's Content; and (iii) ensure
    the security, confidentiality, and integrity of the Member's Content
    transmitted through the APIs. API services are not intended to be used for
    data backup or storage. COMMERCIO.NETWORK is not liable to the Member or any
    other person for the loss, damage, or destruction of any Member Content.
  </p>
  <h3>11.2. Access credentials and API account keys.</h3>
  <p>
    Insofar as COMMERCIO.NETWORK will provide the API access credentials and API
    authentication generated by the Services, such access credentials and API
    authentication are solely for the internal use of the Member and the Member
    shall not under any circumstances: sell, transfer or sublicense to any other
    entity or person, with the exception of the communication of the private key
    by the Member to its employees under its own responsibility.
  </p>
  <h3>11.3. Safety Risks.</h3>
  <p>
    The Member is aware that storing their Content on or using the protocols of
    Commercio.Network may entail risks, including, for example, the loss of the
    Content itself. The Member is aware that COMMERCIO.NETWORK will not be in any
    way responsible for the Member's Content stored on Commercio.Network;
    furthermore, they are aware and accept that COMMERCIO.NETWORK does not
    exercise any control over the Content and/or the subject matter of the
    information passing through the network. The Member must evaluate in advance,
    and in any case before transferring their Content to the Blockchain, the
    legality and lawfulness of their conduct. The importance of the assessment of
    legality and lawfulness is primarily due to the fact that every action of the
    Member must be based on respect for the regulations and the rights of third
    parties, as well as the fact that due to the characteristics of the
    Commercio.Network Blockchain, it is not possible to delete any data once
    inserted into the system. By sending and storing their Content on the
    blockchain, the Member assumes responsibility for any unlawful and/or
    illegitimate conduct and/or violation of the rights of third parties that they
    may have committed through the use of the Commercio.Network blockchain. In the
    event that the Member's conduct is found to be unlawful, illegitimate, and/or
    in violation of the rights of third parties, the Member must indemnify and
    hold harmless COMMERCIO.NETWORK from any costs, expenses (including any costs
    and expenses for the defense of COMMERCIO.NETWORK in judicial and non-judicial
    disputes), loss, pecuniary and non-pecuniary damage (including, for example,
    damage to reputation and the costs necessary to remedy it) that
    COMMERCIO.NETWORK may suffer in relation to the Member's conduct.
  </p>
  <h3>11.4. Data privacy</h3>
  <p>
    COMMERCIO.NETWORK does not carry out any processing of the personal data
    contained in the Content, which is located on the decentralised network of
    Commerce.Network and not at COMMERCIO.NETWORK. COMMERCIO.NETWORK will not,
    however, access or use the Content.
  </p>
  <h3>11.5. Temporary Suspension of Membership.</h3>
  <p>
    Under certain conditions, COMMERCIO.NETWORK may suspend the Member's right to
    access or use the membership and therefore all APIs. The suspension of the
    Membership will occur following communication from COMMERCIO.NETWORK, by
    registered mail or other means providing proof of receipt, with a notice
    period of 48 hours thereafter (unless otherwise ordered by judicial
    authorities or other public authorities or required by law), if the Member's
    use of the APIs (i) poses a security risk to Commercio.App, Comercio.Network,
    or third parties, (ii) may have a negative impact on the computer systems or
    services of COMMERCIO.NETWORK, or on the computer systems or Content of any
    other Member, (iii) may expose COMMERCIO.NETWORK, its affiliates, or third
    parties to liability, or (iv) may be fraudulent; (v) is carried out in
    violation of the provisions of Appendix 1. The suspension shall remain in
    effect until the complete elimination of the causes of suspension listed
    above, of which the Member has given written notice to COMMERCIO.NETWORK,
    which reserves the right to verify. The suspension does not release the Member
    from the obligation to pay the annual fees and expenses incurred during the
    suspension period, provided that the Member will not be entitled to any
    service credit for any suspension period.
  </p>
  `,
  P12: `
  <h2>12. Portafoglio crediti</h2>
  <p>
    COMMERCIO.NETWORK will provide the BRONZE SILVER and GOLD Member with a wallet
    (Wallet) enabled to purchase, receive, hold, track, transfer and manage a
    utility token called Commerce Cash Credit. COMMERCIO.NETWORK will not provide
    the service of exchanging cryptocurrencies for other cryptocurrencies or the
    service of selling or buying cryptocurrencies.
  </p>
  <h3>12.1. Purchase and delivery of CCC</h3>
  <p>
    The Member can purchase and receive Commercio Cash Credits from
    COMMERCIO.NETWORK through operations on their Credits Wallet. In order to
    purchase CCC Utility Tokens or carry out any operation on their Wallet, each
    Member must follow the instructions provided on Commercio.App. Each operation
    will be processed by the Commercio.App system no later than one working day
    following the request.<br />
    With the purchase order, the Member authorizes COMMERCIO.NETWORK to charge the
    costs of the requested operation. Once the purchase request is received by
    Commercio.App, it cannot be revoked, even if the purchase has not yet been
    completed and/or registered on Commercio.Network.<br />
    If the purchase of Utility Tokens fails due to insufficient funds,
    Commercio.App will promptly notify the Member of the negative outcome of the
    requested operation.
  </p>
  <h3>12.2. Unauthorised or incorrect transactions.</h3>
  <p>
    If the purchase of Utility Tokens is made using the Member's Credentials,
    COMMERCIO.NETWORK will consider it an authorized transaction, unless
    COMMERCIO.NETWORK receives a notification to the contrary (e.g., the Member
    reports unauthorized transaction) before the completion of such operation
    and/or registration of the operation in Commercio.Network.<br />
    If a Member believes that a Wallet transaction has been made without
    authorization, or if a Member has reason to believe that a Wallet transaction
    has been executed incorrectly or incompletely by COMMERCIO.NETWORK, the Member
    agrees to immediately, and without delay, contact COMMERCIO.NETWORK.<br />
    Each Member agrees to regularly check the outcomes of each transaction to
    identify and track any unauthorized and/or incorrect and/or incomplete
    transaction and agrees to report such occurrence to COMMERCIO.NETWORK without
    delay and in any case within 2 days from the discovery.<br />
    Each Member is aware and accepts that the delayed reporting of unauthorized
    and/or incorrect and/or incomplete operations beyond the aforementioned terms
    will make it impossible for COMMERCIO.NETWORK to address the issue.<br />
    In the event that the transaction reported within the aforementioned terms is
    found to be the result of fraud and/or error on the part of COMMERCIO.NETWORK,
    the Member may receive a refund of the stolen and/or lost Utility Tokens
    within the limits of the maximum capital guaranteed as provided in the
    WalletShield.
  </p>
  <h3>12.3. Withdrawal of Wallet contents.</h3>
  <p>
    The Member may redeem the contents of his/her Wallet at any time, by selecting
    the relative request in Commercio.App following the instructions provided
    therein. COMMERCIO.NETWORK will credit the utility tokens contained in the
    Wallet at the moment of the request to another digital wallet indicated by the
    Member within 15 working days from the request and after having carried out
    the verifications required by law. If the Member does not provide a digital
    wallet the utility Tokens contained in the Wallet at the time of the request
    cannot be recovered.
  </p>
  <h3>12.4. Exclusions of liability of COMMERCIO.NETWORK.</h3>
  <p>
    COMMERCIO.NETWORK cannot be held responsible for any damage or failure to
    execute transactions and/or their suspension caused by fraudulent and/or
    negligent and/or negligent conduct of the Member and/or caused by the
    violation of the terms and conditions provided in this Agreement. In
    particular, by way of example and not exhaustively:
  </p>
  <ol type="a">
    <li>
      COMMERCIO.NETWORK cannot in any case be held responsible for any
      unauthorized and/or incorrect and/or incomplete transaction that has been
      communicated to COMMERCIO.NETWORK beyond the terms and/or in a manner
      different from those provided for in this Agreement. COMMERCIO.NETWORK
      cannot be held responsible for any claim, action, request, compensation,
      damages in relation to unauthorized and/or incorrect and/or incomplete
      transactions communicated to COMMERCIO.NETWORK belatedly;
    </li>
    <li>
      transactions executed on the Wallet via Commercio.App are executed "as is"
      and "as possible", without any warranty from COMMERCIO.NETWORK in terms of
      gain, profit and/or result;
    </li>
    <li>
      the Member is aware and accepts that access to Membership Benefits may be
      compromised or unavailable during periods of significant volatility or data
      traffic volume. This may result in the inability to buy or sell for periods
      of time and may also lead to delays in Commercio.App response times;
    </li>
    <li>
      The Member is also aware and accepts that, although COMMERCIO.NETWORK
      strives to provide excellent service, it does not guarantee that its
      Commercio.App platform or other services, including the website, will be
      available without interruptions and does not guarantee that any Member's
      command/order/request will be executed, accepted, recorded, or remain open.
      COMMERCIO.NETWORK specifies that response times for its customer service may
      be delayed, even during periods of significant volatility or data traffic
      volume.
    </li>
  </ol>
  <p>
    COMMERCIO.NETWORK cannot be held liable in such cases for any losses, damages,
    expenses, or any prejudice arising from transaction delays.<br />
    COMMERCIO.NETWORK is committed to providing accurate and timely information on
    its website and Commercio.App. However, the content of these platforms may not
    always be entirely accurate, complete, or up-to-date and may also include
    technical inaccuracies or typographical errors.<br />
    In an effort to continue providing Members with as complete and accurate
    information as possible, information may, to the extent permitted by
    applicable law, be changed or updated from time to time without notice,
    including, without limitation, information concerning COMMERCIO.NETWORK's
    policies, products, and services. Consequently, the Member is aware and
    accepts that they must verify all information before relying on it, and all
    decisions based on the information contained on the website or Commercio.App
    are the sole responsibility of the Member, and COMMERCIO.NETWORK cannot be
    held liable for such decisions.<br />
    The Member is aware and accepts that any links to third-party materials
    (including, without limitation, websites or apps) may be provided for
    convenience but are not pre-checked by COMMERCIO.NETWORK. Consequently, the
    Member acknowledges and agrees that COMMERCIO.NETWORK cannot be held liable in
    any way for any aspect of the information, content, or services contained in
    such third-party materials accessible or linked to the COMMERCIO.NETWORK
    website or Commercio.App.
  </p>
  <h3>12.5. Suspension, termination and cancellation of Wallet transactions</h3>
  <p>
    The Member acknowledges and agrees that COMMERCIO.NETWORK may (a) refuse to
    complete, block, or cancel a transaction authorized by the Member (even after
    funds have been debited from the Commercio.App Wallet), or (b) suspend, limit,
    or terminate access to one or all Membership Benefits, and/or (c) deactivate
    or cancel the Member's Account immediately, for the following reasons:
  </p>
  <ol>
    <li>
      it reasonably believes it is necessary to protect the reputation of
      COMMERCIO.NETWORK;
    </li>
    <li>
      COMMERCIO.NETWORK, in its reasonable opinion, is required to do so by law,
      regulations, or any court or other authority to which it is subject in any
      jurisdiction;
    </li>
    <li>
      COMMERCIO.NETWORK has reasonable suspicion that the Member is acting in
      violation of this Agreement;
    </li>
    <li>
      COMMERCIO.NETWORK has reasonable suspicion that the Member has violated the
      provisions of Appendix 1 ("Prohibited Uses");
    </li>
    <li>
      COMMERCIO.NETWORK has reasonable suspicion that a transaction is incorrect;
    </li>
    <li>
      COMMERCIO.NETWORK has reasonable suspicion that the security of the Account
      has been compromised, or that Membership Benefits are being used
      fraudulently or unauthorizedly;
    </li>
    <li>
      COMMERCIO.NETWORK suspects money laundering, terrorist financing, fraud, or
      any other financial crime;
    </li>
    <li>
      COMMERCIO.NETWORK has received notice that the use of the Account is subject
      to any ongoing dispute, investigation, or governmental proceeding, and/or
      COMMERCIO.NETWORK has been notified of a high risk of legal or regulatory
      non-compliance associated with the Account's activity;
    </li>
    <li>
      COMMERCIO.NETWORK has been notified that the Member is taking any action
      that may circumvent its controls, such as opening multiple accounts or
      abusing promotions offered from time to time.
    </li>
  </ol>
  <p>
    In the event that COMMERCIO.NETWORK refuses to complete a transaction and/or
    suspends, limits, or closes the Member's Account, and/or interrupts the use of
    Membership Benefits, it will provide (unless illegal) notice of the actions
    taken and the reasons for the refusal, suspension, or closure, and, if
    applicable, the procedure for correcting any factual errors that led to the
    refusal, suspension, or closure.<br />
    In the event that COMMERCIO.NETWORK refuses to complete a transaction and/or
    suspends the Member's Account, it will revoke the suspension or complete the
    transaction as soon as reasonably possible and once the reasons for the
    refusal and/or suspension no longer exist. However, COMMERCIO.NETWORK is not
    obligated to allow the Member to reinstate a transaction at the same price or
    under the same conditions as the suspended, reversed, or canceled
    transaction.<br />
    Under no circumstances will COMMERCIO.NETWORK be held liable for the
    suspension, termination, or cancellation of the transaction if it has
    correctly applied the provisions of this Agreement.
  </p>
  `,
  P13: `
  <h2>13. Duration of Membership</h2>
  <p>
    The purchase of the Membership has an annual duration, starting from the
    declaration of adherence with completion of the procedure and signing of this
    Agreement.<br />
    As the annual expiration date approaches, at least 30 days before the
    expiration, COMMERCIO.NETWORK may send the Member a communication about the
    approaching expiration, along with instructions for renewing the Membership.
    This notice can be sent to the email address provided at registration or
    through a notification in the Member's Account..<br />
    The Member activates, at the time of signing this Agreement, the automatic
    renewal function via Direct Debit or Credit Card charge. In this case, the
    Membership is automatically renewed upon expiration by debiting the respective
    cost according to the chosen payment method. COMMERCIO.NETWORK may send the
    Member communication regarding the success or failure of the automatic
    renewal.<br />
    If the Member renews by paying the new annual fee, all Membership Benefits
    will be available to the Member again.<br />
    However, if the renewal is not completed, the Membership expires, and it will
    no longer be possible to enjoy the Membership Benefits. The consequences of
    the termination of the Membership relationship will be as governed in Article
    17.1.
  </p>
  `,
  P14: `
  <h2>14. Malfunctioning of Developers' Services</h2>
  <p>
    The services provided by COMMERCIO.NETWORK are limited to the Membership
    Benefits as described in this Agreement and its subsequent amendments.<br />
    Purchases of services made by the Member within the Apps are concluded solely
    between the Member and the Developer, without COMMERCIO.NETWORK being a party
    thereto. In fact, COMMERCIO.NETWORK does not assume any responsibility or
    grant any guarantee for the obligations to provide products or services of the
    Developer, and in particular, COMMERCIO.NETWORK shall not be liable, even
    indirectly, for any breach of contract or non-contractual liability on the
    part of the Developer.
  </p>
  `,
  P15: `
  <h2>15. Liability</h2>
  <p>
    COMMERCIO.NETWORK shall be liable only and to a limited extent for its own
    breach of its obligations under this Agreement. COMMERCIO.NETWORK shall not be
    held liable for the negligent conduct and/or breach of obligations of third
    parties, such as, but not limited to, Developers, Distributors, Validation
    Nodes or internet providers, etc.<br />
    By way of example but not limited to COMMERCIO.NETWORK shall not be
    responsible for:
  </p>
  <ol type="a">
    <li>Interruptions in internet access availability for the Member;</li>
    <li>
      other technical and electronic failures (i) during data transmission via the
      internet, as well as (ii) during the use of COMMERCIO.NETWORK sites or apps,
      the COMMERCIO.NETWORK SMS service, and the COMMERCIO.NETWORK applications
      for mobile devices, to the extent that such failures are not within the
      responsibility of COMMERCIO.NETWORK;
    </li>
    <li>
      technical and electronic failures that prevent the registration of purchases
      made in the COMMERCIO.NETWORK Membership Benefits not attributable to
      COMMERCIO.NETWORK (in particular, any tracking anomalies and consequent data
      losses);
    </li>
    <li>unavailability of mobile phone networks or terminals;</li>
    <li>malfunction of the Member's mobile devices;</li>
    <li>
      damage and/or service interruptions of any kind resulting from computer
      viruses or other malicious code that may affect the Member's computer or
      other equipment, including any phishing, spoofing, or other attacks. To
      prevent such occurrences, the Member is aware that COMMERCIO.NETWORK
      recommends the use of appropriately updated and reliable antivirus and
      malware prevention software. The Member is also informed that SMS activities
      (e.g., OTP) could be subject to spoofing, phishing, or other malicious
      activities, and thus commits to paying appropriate and adequate attention to
      every message, email, and communication that appears to come from
      COMMERCIO.NETWORK.
    </li>
  </ol>
  <p>
    Insofar as the responsibility of COMMERCIO.NETWORK is limited or excluded, the
    limitations or exclusions also apply to the personal liability of the
    employees, legal representatives and vicarious agents of COMMERCIO.NETWORK.<br />
    The limitations of liability and exclusions of liability set out herein do not
    affect the liability of COMMERCIO.NETWORK, if any, in accordance with the
    legal provisions applicable to the parties.
  </p>
  `,
  P16: `
  <h2>16. Costs</h2>
  <ul style="list-style-type: none">
    <li>
      16.1. Subscription to the Memberships requires payment, with varying costs 
      depending on the type of Membership. The annual costs of the Memberships are 
      available on the page https://commerc.io
    </li>
  </ul>
  `,
  P17: `
  <h2>17. Termination of contractual relationship by the Member</h2>
  <p>
    The Member has the right to withdraw from this Agreement with
    COMMERCIO.NETWORK at any time by means of the ‘Cancel Membership’ button on
    the Commercio.App.<br />
    On termination of the contractual relationship, the Member is only entitled to
    receive CCC credits if the Member transfers the content of their Wallet to a
    valid alternative digital Wallet within 15 days of the expiry of their
    Membership, otherwise all content of their Wallet will be automatically lost
    and will not be recoverable in any way.
  </p>
  <h3>17.1. Consequences of Termination of Membership.</h3>
  <p>
    Once the Member has opted to terminate their relationship with
    COMMERCIO.NETWORK or in the event that the Member has not renewed the
    Membership by the expiration date as provided in Article 13, the Member will
    revert to being classified as a mere User and COMMERCIO.NETWORK may proceed
    with closing the Member's Account according to the following provisions:
  </p>
  <ol type="a">
    <li>
      COMMERCIO.NETWORK will allow the User (as a former Member) access to their
      expired Account for a maximum of 15 days from the expiration date for the
      purpose of transferring any Utility Tokens contained in the User's Wallet;
    </li>
    <li>
      The User is aware and accepts that, in the event of transferring Utility
      Tokens after the Account expiration, COMMERCIO.NETWORK may legitimately
      require the User to undergo a new identification process, including the
      relevant KYC verification procedure;
    </li>
    <li>
      The User is aware and accepts that from the date of the Account expiration,
      they will no longer be authorized to use Commercio.App or any related and/or
      connected services in any manner.
    </li>
    <li>
      COMMERCIO.NETWORK will no longer manage the Wallet. The content of the
      Wallet must be transferred at the User's expense and care to another wallet
      owned by the User and which will be managed entirely by the User. The
      transfer of the Wallet content must be completed by the User no later than
      15 days from the Membership expiration. If the Member does not transfer the
      contents of their Wallet to an alternative valid digital Wallet within 15
      days of the Membership expiration, all contents of their Wallet will be
      automatically lost and will not be recoverable in any way. All the content
      of the Wallet will be lost.
    </li>
  </ol>
  `,
  P18: `
  <h2>18. Termination of the contractual relationship by COMMERCIO.NETWORK</h2>
  <ul style="list-style-type: none">
    <li>
      18.1. COMMERCIO.NETWORK has the right to terminate the Contract by law,
      pursuant to Article 1456 of the Italian Civil Code, if the Member violates
      any of the provisions of this Agreement specified in the following articles:
      Article 6, paragraph 13; Article 7, paragraphs 3 and 4, letters a-d-e;
      Article 11.2; Attachment 1, sub. 1 and 2. The Agreement is dissolved at the
      moment the Member receives the communication from COMMERCIO.NETWORK (via
      certified email, registered mail with return receipt, or any other method
      that provides proof of receipt) declaring its intention to invoke the
      express termination clause. Compensation for any damages remains unaffected.
    </li>
    <li>
      18.2. Outside of the cases provided for in Article 18.1, COMMERCIO.NETWORK
      has the right to terminate this Agreement for serious breach of the
      Agreement by the Member pursuant to Article 1453 of the Italian Civil Code,
      subject to prior notification of the breach by certified email, registered
      mail with return receipt, or any other method that provides proof of
      receipt, which the Member does not remedy within the following 15 days.
      Compensation for any damages to the economic interests or reputation of
      COMMERCIO.NETWORK or the Validators, Distributors, Developers, or
      Ambassadors remains unaffected.
    </li>
    <li>
      18.3. The Member agrees to indemnify and hold COMMERCIO.NETWORK harmless in
      relation to liabilities and/or damages connected to their culpable violation
      of contractual provisions. This also applies to defense costs to be borne in
      the event of claims by third parties. Furthermore, COMMERCIO.NETWORK has the
      right to claim any damages resulting from the Member's violations of
      obligations, including legal expenses.
    </li>
    <li>
      18.4. Upon the conclusion of the contractual relationship, the provisions of
      Article 17.1 shall apply.
    </li>
  </ul>
  `,
  P19: `
  <h2>19. General Rules</h2>
  <h3>19.1. Communications</h3>
  <p>
    The Member expressly consents to receive all communications (including those
    of an accounting nature or related to the termination or suspension of the
    relationship) in electronic format, including email, unless otherwise provided
    by law or this Agreement.<br />
    All communications related to this Agreement will be sent to the address
    provided by the Member during the registration process, or to a different
    address communicated by the Member.<br />
    The Member consents to all communications being made through remote
    communication techniques ("RCT"), such as email, messaging services, and
    notifications via Commercio.App.<br />
    Periodic communications provided for in this Agreement, or those that
    COMMERCIO.NETWORK intends to send to the Member, will be addressed solely to
    the Member and it will be considered that the Member has received them on the
    day of the notification on Commercio.App, informing that the communication is
    available in the appropriate section.
  </p>
  <h3>19.2. Unilateral Changes</h3>
  <p>
    Any modifications to this Agreement, communicated to the Member even through
    RCT, will be deemed accepted by the Member if the Member does not object in
    writing (via email) within 30 days from the receipt of the relevant
    modification notice.<br />
    COMMERCIO.NETWORK will draw the Member's attention to the start of this
    period, specifically indicating that their consent to the communicated
    modifications of the general conditions of this Agreement will be deemed
    accepted if they do not object, in writing (via email to
    info@commercio.network), to their validity within the specified period.<br />
    The changes in question will be deemed accepted by the Member only if this
    notice has actually been given.
  </p>
  <ul style="list-style-type: none">
    <li>
      19.3. Where specific expressions referring to gender are used in the
      contract, they equally refer to male and female subjects and legal entities.
    </li>
    <li>
      19.4. If certain provisions of this Agreement prove to be entirely or
      partially ineffective or unenforceable, the remaining provisions will remain
      effective.
    </li>
    <li>
      19.5. Italian law applies to the contractual relationship. In the event that
      the Member (Green) is considered a "consumer" under current legislation, the
      mandatory provisions of the law will prevail over the provisions of this
      Agreement.
    </li>
    <li>
      19.6. Registration and purchase of Memberships are allowed for individuals
      starting from the age of 18.
    </li>
    <li>
      19.7. The Member agrees to bear all expenses, rights, taxes, etc., arising
      from obtaining Memberships of Utility Tokens and any other product or
      service purchased.
    </li>
    <li>
      19.8. Personal data protection information is available at the address
      COMMERCIO.NETWORK/privacy.
    </li>
  </ul>
  `,
  P20: `
  <h2>20. Disputes</h2>
  <ul style="list-style-type: none">
    <li>
      20.1. The parties to this Agreement (the Member and COMMERCIO.NETWORK) will
      submit disputes arising from this document to the conciliation attempt
      provided by the Conciliation Service of the Arbitration Chamber of Milan
      established at the Chamber of Commerce of Milan, Monza Brianza, and Lodi. If
      the conciliation attempt fails, disputes, including those of a
      non-contractual nature, arising from, related to, or connected with this
      document, will be resolved through arbitration in accordance with the Rules
      of the Arbitration Chamber of Milan, Monza Brianza, and Lodi, by a sole
      arbitrator appointed in accordance with these Rules.
    </li>
    <li>
      20.2. A Green Member who qualifies as a consumer pursuant to Article 3(a) of
      Legislative Decree No. 206/2005 shall have any disputes that may arise
      regarding the interpretation, execution, and/or validity of this Agreement
      decided by the legally competent Court.
    </li>
  </ul>
  `,
  P_APPENDIX: `
  <h2>Appendix 1: Forbidden Uses</h2>
  <h3>1) Forbidden Uses</h3>
  <p>
    The Member must not use their COMMERCIO.NETWORK Account and Membership to
    engage in the following categories of activities ("Prohibited Uses"). By
    opening an Account with COMMERCIO.NETWORK, you confirm that you will not use
    your Account and/or COMMERCIO.NETWORK services to do the following:
  </p>
  <ul style="list-style-type: none">
    <li>
      Illegal Activities: Activities that violate, or contribute to the violation
      of, any law, statute, ordinance, regulation, or administrative sanction
      program in the countries where COMMERCIO.NETWORK operates, or that involve
      the proceeds of illegal activities; publishing, distributing, or
      disseminating illegal material or information.
    </li>
    <li>
      Abusive Activities: Actions that impose an unreasonable or disproportionate
      load on COMMERCIO.NETWORK's infrastructure, or that interfere, intercept, or
      expropriate any system, data, or information; transmitting or uploading any
      material to the Site that contains viruses, trojans, worms, or any other
      harmful or deleterious programs; attempting to gain unauthorized access to
      COMMERCIO.NETWORK's site, other COMMERCIO.NETWORK accounts, computer
      systems, or networks connected to the COMMERCIO.NETWORK site, through
      password mining or any other means; using another party's Account
      information to access or use the COMMERCIO.NETWORK site, except in the case
      of specific merchants and/or applications specifically authorized by a user
      to access and use that user's Account information; or transferring access to
      your account or account rights to a third party, except by law or with the
      express authorization of COMMERCIO.NETWORK.
    </li>
    <li>
      Abuse of Other Users: Interfering with another individual or entity's access
      to or use of any COMMERCIO.NETWORK service; defaming, abusing, extorting,
      harassing, stalking, threatening, or otherwise violating or infringing the
      legal rights (such as, but not limited to, rights of privacy, publicity, and
      intellectual property) of others; inciting, threatening, facilitating,
      promoting, or encouraging hatred, racial intolerance, or violent acts
      against others; harvesting or otherwise collecting information from the
      COMMERCIO.NETWORK site about others, including, without limitation, email
      addresses, without proper consent.
    </li>
    <li>
      Fraud: Activities that operate to defraud COMMERCIO.NETWORK,
      COMMERCIO.NETWORK users, or any other person; providing COMMERCIO.NETWORK
      with false, inaccurate, or misleading information.
    </li>
    <li>
      Gambling: Using the COMMERCIO.NETWORK services to operate lotteries;
      paid-to-enter auctions; sports forecasting or odds-making; fantasy sports
      leagues with cash prizes; internet gaming; contests; sweepstakes; gambling.
    </li>
    <li>
      Intellectual Property Infringement: Engaging in transactions involving items
      that infringe on copyright, trademark, publicity, privacy, or any
      proprietary rights under the law, including, but not limited to, the sale,
      distribution, or access to counterfeit music, movies, software, or other
      licensed materials without the appropriate authorization from the rights
      holder; using COMMERCIO.NETWORK's intellectual property, name, or logo,
      including the use of COMMERCIO.NETWORK's trademarks or service marks,
      without the express consent of COMMERCIO.NETWORK or in a manner that damages
      COMMERCIO.NETWORK or the COMMERCIO.NETWORK brand; any action that implies a
      false endorsement by or affiliation with COMMERCIO.NETWORK.
    </li>
  </ul>
  <h3>2) Prohibited Companies</h3>
  <p>
    By purchasing a Membership, the Member agrees not to use COMMERCIO.NETWORK
    services in connection with any of the following activities, practices, or
    items ("Prohibited Activities"):
  </p>
  <ul style="list-style-type: none">
    <li>
      Investment and Credit Services: securities brokers; mortgage advice or debt
      reduction services; credit counseling or repair; real estate opportunities;
      investment plans;
    </li>
    <li>
      Restricted Financial Services: check cashing, bail bonds, collection
      agencies;
    </li>
    <li>
      Intellectual Property or Property Rights Infringement: sale, distribution,
      or access to counterfeit music, movies, software, or other licensed
      materials without appropriate authorization from the rights holder;
    </li>
    <li>
      Counterfeit or Unauthorized Products: unauthorized sale or resale of branded
      or designer products or services; sale of illegally imported or exported or
      stolen products or services;
    </li>
    <li>
      Regulated Products and Services: sale of tobacco, electronic cigarettes, and
      e-liquids; online pharmaceutical or prescription services; age-restricted
      goods or services; weapons and ammunition; gunpowder and other explosives;
      fireworks and related goods; toxic, flammable, and radioactive materials;
    </li>
    <li>
      Drugs and Drug Paraphernalia: sale of narcotics, controlled substances, and
      any equipment designed for the production or use of drugs, such as bongs,
      vaporizers, and hookahs;
    </li>
    <li>
      Pseudo-Pharmaceuticals: pharmaceutical products and other products with
      health claims not approved or verified by the relevant local and/or national
      regulatory authority;
    </li>
    <li>
      Substances Designed to Mimic Illegal Drugs: sale of a legal substance that
      provides the same effect as an illegal drug;
    </li>
    <li>
      Adult Content and Services: pornography and other obscene materials
      (including literature, images, and other media); sites offering sex-related
      services such as prostitution, escort services, pay-per-view, adult live
      chat services;
    </li>
    <li>
      Multi-Level Marketing: pyramid schemes, network marketing, and referral
      marketing programs;
    </li>
    <li>
      Unfair, Predatory, or Deceptive Practices: investment opportunities or other
      services promising high rewards; sale or resale of a service without
      additional benefits to the buyer; resale of government offers without
      authorization or added value; sites that we determine at our sole discretion
      to be unfair, deceptive, or predatory to consumers;
    </li>
    <li>
      High-Risk Activities: any activity that we deem to involve a high financial
      risk, legal liability, or violate credit card network or bank policies;
    </li>
    <li>
      Money Services: money transfer, digital currency transfer; currency or
      digital currency exchange; gift cards; prepaid cards; sale of in-game
      currency unless the merchant is the operator of the virtual world; acting as
      a payment intermediary or aggregator;
    </li>
    <li>
      Skill-Based Games: Games not defined as gambling under this agreement or by
      law but that require an entry fee and award a prize;
    </li>
    <li>
      Religious/Spiritual Organizations: Operating a for-profit religious or
      spiritual organization.
    </li>
  </ul>
  `,
  TITLE_ADDITIONALS: `Additional Terms`,
  P_ADDITIONALS: `
  <p>
    The Member hereby specifically accepts, in accordance with articles 1341 -
    1342 of the Italian Civil Code, the following provisions: limits of liability
    of COMMERCIO.NETWORK (art. 10 lett. b-d-e-f-, art. 11.1, art. 11.3, art. 12.4,
    art. 14, art. 15 paragraph 1), unilateral withdrawal faculty (art. 18 .1),
    express termination clause (Art. 18.1), unilateral amendments to
    COMMERCIO.NETWORK (Art. 19.2), arbitration clause (Art. 20.1)
  </p>
  `,
});
