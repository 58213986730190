import { AUTH, ROUTES, SUBSCRIPTION } from '@/constants';
import store from '@/store';

export default {
  async setupAuthorization() {
    if (!store.getters['auth/isAuthenticated']) {
      await store.dispatch('auth/initAuth');
    }
    return store.getters['auth/isAuthenticated']
      ? AUTH.USER_ROLE.AUTHORIZED
      : AUTH.USER_ROLE.NOT_AUTHORIZED;
  },
  async setupApp() {
    if (!store.getters['application/hasSettings']) {
      await store.dispatch('application/initApp');
    }
  },
  async setupAccount() {
    if (!store.getters['account/hasIdentity']) {
      await store.dispatch('account/initAccount');
    }
  },
  redirectToLandingPage(next, introPath) {
    const config = store.getters['application/config'];
    if (config && config.landing_page.outside) {
      window.location.replace(config.landing_page.address);
    } else {
      next(introPath);
    }
  },
  getHasContractsToSubscribe() {
    return store.getters['account/hasAgreementsToSubscribe'];
  },
  getSubscriptionStatus() {
    return store.getters['account/subscriptionStatus'];
  },
  setupDefaultRoute(status) {
    switch (status) {
      case SUBSCRIPTION.STATUS.ACTIVE:
        return ROUTES.PATH.NOTARIZE;
      case SUBSCRIPTION.STATUS.CHAIN_WAITING_INVITE:
        return ROUTES.PATH.OUTPUT_WAITING;
      case SUBSCRIPTION.STATUS.CHAIN_FAILED_INVITE:
        return ROUTES.PATH.OUTPUT_ERROR;
      case SUBSCRIPTION.STATUS.NONE:
        return ROUTES.PATH.OUTPUT_WAITING;
      default:
        return ROUTES.PATH.NOT_FOUND;
    }
  },
};
