const imageHandler = {
  /**
   *
   * @param {File} file
   * @returns {Promise<string>}
   */
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject('Error converting image to base64');
      };
      reader.readAsDataURL(file);
    });
  },
};

export default imageHandler;
