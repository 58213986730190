import { tokensHandler } from '@/utils';

const settingsHandler = {
  convertSurchargeFromBase(surcharge, decimals) {
    for (const key in surcharge) {
      const amount = parseFloat(surcharge[key], 10);
      const converted = tokensHandler.convertFromBase(amount, decimals);
      surcharge[key] = converted.toFixed(2);
    }
    return surcharge;
  },
  convertSurchargeToBase(surcharge, decimals) {
    for (const key in surcharge) {
      const amount = parseFloat(surcharge[key], 10);
      const converted = tokensHandler.convertToBase(amount, decimals);
      surcharge[key] = converted.toString();
    }
    return surcharge;
  },
};

export default settingsHandler;
