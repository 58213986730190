import { AUTH, ROUTES, SUBSCRIPTION } from '@/constants';
import Vue from 'vue';
import VueRouter from 'vue-router';

import navigationGuard from './navigationGuard';
import { agreementsRoute } from './routes/agreements-route';
import { alertsRoute } from './routes/alerts-route';
import { contactsRoute } from './routes/contacts-route';
import { dossiersRoute } from './routes/dossiers-route';
import { earchiveRoute } from './routes/earchive-route';
import { earnRoute } from './routes/earn-route';
import { edeliveryRoute } from './routes/edelivery-route';
import { eidWalletRoute } from './routes/eid-wallet-route';
import { giftRoute } from './routes/gift-route';
import { mintRoute } from './routes/mint-route';
import { nftRoute } from './routes/nft-route';
import { introRoute } from './routes/intro-route';
import { notarizeRoute } from './routes/notarize-route';
import { purchaseRoute } from './routes/purchase-route';
import { quickDeliveryRoute } from './routes/quick-delivery-route';
import { settingsRoute } from './routes/settings-route';
import { stakeRoute } from './routes/stake-route';
import { swapRoute } from './routes/swap-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES.PATH.ROOT,
    redirect: () => ROUTES.PATH.QUICK_DELIVERY,
    meta: {
      auth: AUTH.USER_ROLE.AUTHORIZED,
    },
  },
];

routes.push(
  agreementsRoute,
  contactsRoute,
  dossiersRoute,
  earnRoute,
  earchiveRoute,
  edeliveryRoute,
  eidWalletRoute,
  giftRoute,
  introRoute,
  mintRoute,
  nftRoute,
  notarizeRoute,
  purchaseRoute,
  quickDeliveryRoute,
  settingsRoute,
  stakeRoute,
  swapRoute,
  ...alertsRoute,
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === AUTH.USER_ROLE.ANY) {
    if (to.name === ROUTES.NAME.INTRO) {
      await navigationGuard.setupApp();
      navigationGuard.redirectToLandingPage(next);
      return;
    } else {
      next();
      return;
    }
  }

  const userRole = await navigationGuard.setupAuthorization();
  if (userRole === AUTH.USER_ROLE.NOT_AUTHORIZED) {
    await navigationGuard.setupApp();
    navigationGuard.redirectToLandingPage(next, ROUTES.PATH.INTRO);
    return;
  }

  await Promise.all([
    navigationGuard.setupAccount(),
    navigationGuard.setupApp(),
  ]);

  const hasContractsToSubscribe = navigationGuard.getHasContractsToSubscribe();
  if (hasContractsToSubscribe) {
    //TODO: set the right route
    next(ROUTES.PATH.SUBSCRIBE_AGREEMENTS);
    return;
  }
  const status = navigationGuard.getSubscriptionStatus();
  if (to.meta.tags && to.meta.tags.indexOf(status) > -1) {
    next();
    return;
  } else {
    const route = navigationGuard.setupDefaultRoute(status);
    next(route);
    return;
  }
});

export default router;

export { AUTH, ROUTES, SUBSCRIPTION };

export function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
