import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setHasFunds(state, payload) {
    state.hasFunds = payload;
  },
  setBasicData(state, payload) {
    const basicData = { ...state.basicData, ...payload };
    state.basicData = basicData;
  },
  setMetadata(state, payload) {
    const metadata = { ...state.metadata, ...payload };
    state.metadata = metadata;
  },
  setAttribute(state, payload) {
    const index = state.attributes.findIndex(
      (attribute) => attribute.id === payload.id,
    );
    if (index > -1) {
      state.attributes = state.attributes.map((attribute) =>
        attribute.id === payload.id ? payload : attribute,
      );
    } else {
      state.attributes.push(payload);
    }
  },
  deleteAttribute(state, payload) {
    state.attributes = state.attributes.filter(
      (attribute) => attribute.id !== payload,
    );
  },
  setContract(state, payload) {
    state.contract = payload;
    if (payload.attributes) {
      state.attributes = structuredClone(payload.attributes);
    }
  },
};
