export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  hasFunds: (state) => state.hasFunds,
  attributes: (state) => state.attributes,
  basicData: (state) => state.basicData,
  contract: (state) => state.contract,
  metadata: (state) => state.metadata,
  contractType: (state) => state.contract?.type || '',
};
