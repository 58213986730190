export const msgs = {
  acceptAdditional: 'Dichiaro di aver letto e accetto specificamente i Termini Addizionali.',
  acceptError: 'Per proseguire devi accettare il contratto',
  acceptNodeAdditional: 'Dichiaro di aver letto  e accetto specificatamente i termini Addizionali ACCORDO NODO DISTRIBUTORE',
  acceptNodeTos: 'Dichiaro di aver letto l’ACCORDO NODO DISTRIBUTORE  e accetto i termini',
  acceptTos: 'Dichiaro di aver letto l\'Accordo di acquisto di beni e servizi tecnologici ("Accordo”) Termini e condizioni generali e accetto i termini.',
  addressError: 'L\'indirizzo non è corretto',
  alphaNumField: 'Questo campo accetta solo caratteri alfanumerici',
  amountError: 'Questo importo non è valido',
  arrayLengthError: 'Almeno un valore',
  askToSaveData: 'Desiderate salvare questi dati?',
  attestDocumentNotarized: 'Questo attesta che il documento è stato autenticato nella Commercio Blockchain, dimostrando così che il documento esisteva nella sua forma attuale al momento dell\'autenticazione.<br>Con la transazione e l\'hash corrispondenti è possibile utilizzare questo documento come prova di certificazione che:',
  cashBackBonus: 'cash back in crediti',
  codeLengthError: 'Min 5 e max 16 caratteri',
  configuringData: 'Attendi, stiamo configurando i tuoi dati',
  configuringError: 'Si è verificato un errore durante la configurazione, contatta l\'amministratore',
  confirmDossierRemove: 'Confermi la rimozione dal fascicolo?',
  confirmReceipt: 'Confermi la ricevuta?',
  contactConfirmDelete: 'Confermi la cancellazione del contatto?',
  contactDuplicatedAlert: 'Questo wallet è già presente in rubrica',
  contactSave: 'Salvare il nuovo contatto?',
  contactsEmptyList: 'Nessun contatto trovato',
  copied: 'Copiato',
  credentialWithoutDetail: 'Questa credenziale non ha dettaglio',
  credentialsEmptyList: 'Nessuna credenziale trovata',
  docsLoseAssociation: 'Numero di documenti che resteranno senza associazione',
  documentExisted: 'Questo documento esisteva nella sua forma attuale (impronta Hash) in data {0}',
  documentNotAltered: 'Questo documento non è stato modificato in alcun modo.',
  documentStored: 'Questo documento è stato archiviato in un caveau sicuro e sarà disponibile fino a {0} o più se il servizio di archiviazione verrà rinnovato.',
  dossierConfirmDelete: 'Confermi la cancellazione del fascicolo?',
  dossiersEmptyList: 'Nessun dossier trovato',
  dropYourFile: 'Trascina qui il tuo file, o fai clic in basso a destra per selezionarlo (max {0} MB).\nPer una migliore esperienza utente, si prega di fornire il nome del file con l\'estensione corretta\n(p. es. ".pdf", ".doc", ".txt").',
  dropYourLogo: 'Trascina qui il tuo logo, o fai clic in basso a destra per selezionarlo (max {0} MB).\nFormati disponibili: jpg, png, svg, webp',
  eArchivesEmptyList: 'Nessun eArchive trovato',
  earnShare: 'Condividi questo link solo con aziende che conosci per dare loro 50 € di crediti gratuiti e guadagnare tokens ABR per ogni abbonamento che acquisteranno',
  edeliverDocAvailable: 'Ora è possibile fare un edelivery del documento',
  emailError: 'L\'email non è corretta',
  emailField: 'Questo campo accetta solo indirizzi e-mail validi',
  enableBoxSurchargeVisibility: 'Visualizza process cost su box intestazioni (True(default)/False)',
  enablePurchaseSurchargeVisibility: 'Acquisto membership Visualizza dettagli descrizione (True(default)/False)',
  errorOccurred: 'Siamo spiacenti, si è verificato un errore',
  fileSizeTooLarge: 'La dimensione del file è troppo grande ({0})',
  fileTypeNotSupported: 'Il tipo di file non è supportato',
  fiscalCodeError: 'Il codice fiscale non è corretto',
  goldFeature: 'Questa funzione è attiva solo con membership Gold',
  insufficientFunds: 'Non hai abbastanza fondi per completare questa operazione',
  inviteContact: 'Inserisci l\'indirizzo email di un tuo contatto per invitarlo ad accedere all\'applicazione.',
  inviteSent: 'Messaggio di invito inviato correttamente',
  loading: 'Caricamento...',
  logoMaxSize: 'La dimensione del logo deve essere inferiore a ',
  mandatoryField: 'Questo campo è obbligatorio',
  modalQrWalletDesc: 'Questo è il tuo Wallet, condividilo con chiunque tu voglia essere pagato',
  nftsEmptyList: 'Nessun NFT trovato',
  nftCollectionsEmptyList: 'Nessuna collezione NFT trovata',
  noCode: 'Nessun codice',
  noDocsLinked: 'Nessun documento associato a questo fascicolo',
  noMessage: 'Nessun messaggio',
  notAvailable: 'Non disponibile',
  notEnoughCCC: 'Non hai abbastanza CCC per completare questa operazione',
  notFound: 'Pagina non trovata! Vai all\'inizio',
  onlyCharsAndDash: 'Solo caratteri e \'-\' sono ammessi',
  operationDeleted: 'Operazione cancellata',
  paidFeature: 'Questa funzione è attiva solo con membership Bronze o superiore',
  paymentCheck: 'Attendi, stiamo verificando il pagamento e configurando i tuoi dati',
  paymentDeleted: 'Il pagamento è stato interrotto',
  paymentError: 'Processo fallito, contatta l\'amministratore',
  processesEmptyList: 'Nessun processo trovato',
  purchaseMembership: 'Usala, invita i tuoi contatti e guadagna un Reddito mensile',
  recipientAlreadyPresentAlert: 'Destinatario già presente',
  sdiLengthError: 'Il codice SDI è una sequenza alfanumerica di 7 cifre',
  searchAddressNotice: 'Per iniziare la ricerca è necessario digitare almeno 3 caratteri di un\'email.{0}La ricerca non tiene conto di maiuscole e minuscole.',
  searchRequirementsNotice: 'Per iniziare la ricerca è necessario digitare almeno 3 caratteri.{0}La ricerca non tiene conto di maiuscole e minuscole.',
  sendingTokens: 'Attendi, stiamo inviando i CCC acquistati al tuo wallet',
  sendingTokensCompleted: 'Invio di CCC completato con successo',
  sendingTokensFailed: 'Invio di CCC non riuscito, stampa il sommario e contatta l\'amministratore',
  serviceTemporarilyUnavailable: "Servizio momentaneamente non disponibile",
  setupSurcharge: 'Impostano il sovraprezzo per ogni singola membership',
  sharedocsEmptyList: 'Nessuna Sharedoc trovata',
  startTypingEmail: 'Inizia a digitare un\'email per cercare',
  startTypingToFilterCountries: 'Inizia a digitare per filtrare le nazioni',
  startTypingToSearch: 'Inizia a digitare per cercare',
  symbolLength: 'Il simbolo deve essere compreso tra 3 e 12 caratteri',
  textLengthError: 'Min 3 e max 256 caratteri',
  tokensSendFooter: 'Attenzione: tutte le transazioni di Crediti/token sono definitive e non possono essere annullate',
  tooLongField: 'Il valore inserito è troppo lungo',
  triggeredAnError: 'È successo qualcosa nell\'impostazione della richiesta che ha provocato un errore',
  txModalFooter: 'Attenzione: tutte le transazioni sono definitive e non possono essere annullate',
  unansweredRequest: 'La richiesta è stata inviata ma non è stata ricevuta alcuna risposta',
  uploadLogoIssue: 'L\'immagine è troppo grande oppure è in un formato non supportato',
  userAlreadyRegistered: 'Utente già registrato',
  vatError: 'La partita IVA non è corretta',
  vatLengthError: 'Min 5 e max 15 caratteri',
  waitOperationCompleted: 'Si prega di attendere la conclusione dell\'operazione di protezione del documento',
  waitPrepareWizard: 'Prego attendere, stiamo preparando la procedura guidata',
};
