export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  info: (state) => state.info,
  config: (state) => state.config,
  hasSettings: (state) => state.config !== null && state.info !== null,
  surcharge: (state) => state.config?.surcharge,
  theme: (state) => state.config?.theme,
  vault: (state) => state.config?.vault.address,
};
