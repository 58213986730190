import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setFetching(state, payload) {
    state.isFetching = payload;
  },
  setTransferring(state, payload) {
    state.isTransferring = payload;
  },
  setDetail(state, payload) {
    state.detail = payload;
  },
  setProcess(state, payload) {
    state.process = payload;
  },
};
