import { FEATURES } from '@/constants';

export const features = {
  [FEATURES.ID.API]: {
    [FEATURES.KEY.TITLE]: 'Api',
    [FEATURES.KEY.MESSAGE]: 'Accesso all’interfaccia di programmazione per un anno per integrare i nostri protocolli disponibili con il tuo software',
  },
  [FEATURES.ID.CREDITS]: {
    [FEATURES.KEY.TITLE]: 'Comprare crediti',
    [FEATURES.KEY.MESSAGE]: 'Dopo il KYC e l’AML da utilizzare per i servizi del network',
  },
  [FEATURES.ID.EDELIVERY]: {
    [FEATURES.KEY.TITLE]: 'Commercio DOCS',
    [FEATURES.KEY.MESSAGE]: 'Servizio API per la consegna dei documenti (eDelivery) con tutti i membri del Network per un anno',
  },
  [FEATURES.ID.EID]: {
    [FEATURES.KEY.TITLE]: 'Commercio ID',
    [FEATURES.KEY.MESSAGE]: 'Servizio API per la identità elettronica sovrana (eID) con tutti i membri del Network per un anno',
  },
  [FEATURES.ID.ESIGNATURE]: {
    [FEATURES.KEY.TITLE]: 'Commercio SIGN',
    [FEATURES.KEY.MESSAGE]: 'Servizio API per la firma elettronica (eSignature) con tutti i membri del Network per un anno',
  },
  [FEATURES.ID.PROGRAMS]: {
    [FEATURES.KEY.TITLE]: 'Programma affiliati',
    [FEATURES.KEY.MESSAGE]: 'Le tre tipologie di members permettono alle aziende di ricevere ABR Token invitando altri members',
  },
}