const receiptPrinter = {
  buildPurchaseTokenHtml(data) {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Receipt ${data.process_id}</title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,300&display=swap"
        rel="stylesheet"
      />
      <style>
        * {
          box-sizing: border-box;
        }
        body {
          font-family: 'Roboto', sans-serif;
          margin: 0;
        }
        #heading {
          text-align: center;
          font-style: italic;
        }
        #heading h1 {
          padding: 16px 0;
        }
        #metadata {
          margin: 32px 8px;
        }
        #metadata ul {
          list-style-type: none;
        }
        #metadata ul li {
          padding: 4px 0;
          font-size: 0.9rem;
          overflow-wrap: break-word;
        }
        .font-bold {
          font-weight: 700;
        }
      </style>
    </head>
    <body>
      <div id="heading">
        <h1>CCCs purchase summary</h1>
      </div>
      <div id="metadata">
        <ul>
          <li>
            <span class="font-bold">Date</span>
            <span>:</span>
            <span>${data.metadata.timestamp}</span>
          </li>
          <li>
            <span class="font-bold">Paid amount</span>
            <span>:</span>
            <span>${data.metadata.paidAmount}</span>
          </li>
          <li>
            <span class="font-bold">Product amount</span>
            <span>:</span>
            <span>${data.metadata.productAmount}</span>
          </li>
          <li>
            <span class="font-bold">Payment id</span>
            <span>:</span>
            <span>${data.metadata.paymentId}</span>
          </li>
          <li>
            <span class="font-bold">Process id</span>
            <span>:</span>
            <span>${data.metadata.processId}</span>
          </li>
          <li>
            <span class="font-bold">Process error</span>
            <span>:</span>
            <span>${data.metadata.processError}</span>
          </li>
        </ul>
      </div>
    </body>
  </html>`;
  },
};

export default receiptPrinter;
