import { mintApi } from '@/apis';
import { v4 as uuidv4 } from 'uuid';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} id
   */
  async initMintNft({ commit, dispatch }, id) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchContractDetail', id)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchContractDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    try {
      const response = await mintApi.requestDetail(token, id);
      commit('setContract', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} attribute
   */
  addAttribute({ commit }, attribute) {
    if (!attribute.id) {
      attribute.id = uuidv4();
    }
    commit('setAttribute', attribute);
  },
  /**
   *
   * @param {Function} commit
   * @param {String} id
   */
  removeAttribute({ commit }, id) {
    commit('deleteAttribute', id);
  },
};
