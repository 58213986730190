<template>
  <v-card outlined :disabled="isLoading">
    <v-card-text>
      <v-list flat>
        <v-list-item-group>
          <v-list-item :inactive="!to" :to="to">
            <v-list-item-icon>
              <slot name="icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="py-1 text-h6">
                <slot name="title" />
              </v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <slot name="subtitle" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-divider class="mx-1" />
    <v-card-actions>
      <v-spacer />
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'BaseGridCard',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      note: 'The view loading state',
    },
    to: {
      type: Object,
      default: () => null,
      note: 'Object representing the route',
    },
  },
};
</script>
