<template>
  <the-error-message v-if="error" :error="error" data-test="error" />
  <v-row v-else data-test="content">
    <v-col cols="12" sm="6" lg="3">
      <the-balance-box
        :coins="coins"
        :token="COIN.STABLE.DENOM"
        :loading="isLoading"
      />
    </v-col>
    <v-col cols="12" sm="6" lg="3">
      <base-box title="CCC Price">
        <template #content>
          <span class="text-h3 font-weight-bold" v-text="'€1'" />
        </template>
      </base-box>
    </v-col>
    <v-col cols="12" sm="6" lg="3">
      <base-box :title="surchargeTitle">
        <template #content>
          <div v-if="isSurchargeVisible" class="d-flex flex-row align-baseline">
            <span class="text-h3 font-weight-bold" v-text="unitProcessCost" />
            <span
              class="text-unit-cost-division font-weight-bold"
              v-text="COIN.STABLE.SYMBOL"
            />
          </div>
          <div v-else>
            <div
              class="text-center text-h5 font-weight-bold"
              v-text="membership"
            />
            <div
              class="info--text text-unit-cost-division font-weight-bold"
              v-text="expirationDate"
            />
          </div>
        </template>
      </base-box>
    </v-col>
    <v-col cols="12" sm="6" lg="3">
      <base-box title="Total Transactions" :loading="isLoading">
        <template #content>
          <span class="text-h3 font-weight-bold" v-text="totalTxs" />
        </template>
      </base-box>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { COIN } from '@/constants';
import { tokensHandler } from '@/utils';

export default {
  name: 'TheDashboardHeaderBoxes',
  data() {
    return {
      COIN,
    };
  },
  computed: {
    ...mapGetters('account', ['subscription', 'wallet']),
    ...mapGetters('application', ['surcharge']),
    ...mapGetters('header', ['error', 'isLoading', 'processSummary']),
    coins() {
      return this.wallet && this.wallet.coins ? this.wallet.coins : [];
    },
    expirationDate() {
      if (!this.subscription) {
        return '';
      }
      const date = new Date(this.subscription.active_until * 1000);
      const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
      return `${this.$t('labels.expire')}: ${date.toLocaleDateString(
        this.$i18n.locale,
        options,
      )}`;
    },
    isSurchargeVisible() {
      return this.surcharge && this.surcharge.visibility.box;
    },
    membership() {
      return this.subscription ? this.subscription.membership.title : '';
    },
    surchargeTitle() {
      return this.isSurchargeVisible ? 'Unit process cost' : 'Membership';
    },
    totalTxs() {
      return !this.processSummary
        ? '-'
        : this.processSummary.total > 999999
        ? `${this.processSummary.total / 1000} M`
        : this.processSummary.total;
    },
    unitProcessCost() {
      if (this.subscription && this.surcharge) {
        const membership = this.subscription.membership.type;
        const fee = this.surcharge.totals[membership];
        return tokensHandler.convertFromBase(fee, COIN.STABLE.EXPONENT);
      }
      return '-.--';
    },
  },
};
</script>

<style lang="scss" scoped>
.text-unit-cost-division {
  font-size: 0.75rem;
}
</style>
