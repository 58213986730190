import { APIS } from '@/constants';
import axios from 'axios';

const eidentity = {
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestInvite(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/invite`,
      method: 'post',
      baseURL: APIS.EIDENTITY,
      headers,
      data,
    });
  },
};

export default eidentity;
