const tokensHandler = {
  convertFromBase(amount, decimals) {
    if (isNaN(parseInt(amount)) || isNaN(parseInt(decimals))) {
      throw new Error('Invalid amount or decimals');
    }
    return parseInt(amount) / Math.pow(10, decimals);
  },
  convertToBase(amount, decimals) {
    if (isNaN(parseFloat(amount)) || isNaN(parseInt(decimals))) {
      throw new Error('Invalid amount or decimals');
    }
    return Math.round(parseFloat(amount) * Math.pow(10, decimals));
  },
  convertBaseAndFormat(balance, decimals) {
    const amount = this.convertFromBase(balance, decimals);
    return formatAmount(amount, decimals);
  },
  formatBalance(balance, decimals) {
    if (isNaN(parseFloat(balance)) || isNaN(parseFloat(decimals))) {
      return '';
    }
    return formatAmount(balance, decimals);
  },
};

export default tokensHandler;

const formatAmount = (amount, decimals = 0) =>
  new Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(amount);
