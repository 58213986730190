import { COIN, SUBSCRIPTION } from '@/constants';

const featureActivator = {
  /**
   *
   * @param {object} identity
   * @param {object} surcharge
   * @param {number} txsAmount
   * @returns {boolean}
   */
  hasFunds({ identity, surcharge, txsAmount = 1 } = {}) {
    const coins = identity.wallet.coins;
    const coin = coins.find((coin) => coin.denom === COIN.STABLE.DENOM);
    if (!coin) {
      return false;
    }
    const membership = identity.subscription.membership.type;
    const fee = parseInt(surcharge.totals[membership]);
    const cost = fee * txsAmount;
    return cost <= parseInt(coin.amount);
  },
  /**
   *
   * @param {string} membershipType
   * @returns {boolean}
   */
  hasGreenMembership(membershipType) {
    return membershipType === SUBSCRIPTION.MEMBERSHIP_TYPE.GREEN;
  },
  /**
   *
   * @param {string} membershipType
   * @returns {boolean}
   */
  hasGoldMembership(membershipType) {
    return membershipType === SUBSCRIPTION.MEMBERSHIP_TYPE.GOLD;
  },
  /**
   *
   * @param {object} identity
   * @param {object} config
   * @returns  {boolean}
   */
  isAdmin(identity, config) {
    if (!identity || !config || !config.referral_eidentity_id) {
      return false;
    }
    return identity.user_id === config.referral_eidentity_id;
  },
};

export default featureActivator;
