import { AUTH, ROUTES, SETTINGS } from '@/constants';

export const giftRoute = {
  path: ROUTES.PATH.GIFT,
  name: ROUTES.NAME.GIFT,
  meta: {
    auth: AUTH.USER_ROLE.ANY,
  },
  beforeEnter: (to) => {
    const referral = to.params.referral;
    const location = `${AUTH.KEYCLOAK.URL}realms/${AUTH.KEYCLOAK.REALM}/protocol/openid-connect/registrations?client_id=${AUTH.KEYCLOAK.CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${SETTINGS.HOST}&referral=${referral}`;
    window.location = location;
  },
};
