<template>
  <v-row>
    <v-col cols="12">
      <v-alert border="left" outlined prominent type="info">
        <template>
          <span v-text="$t('msgs.paidFeature')" />
        </template>
      </v-alert>
    </v-col>
    <v-col cols="12" md="4" offset-md="4">
      <v-btn
        block
        color="primary"
        depressed
        :to="{ name: ROUTES.NAME.PURCHASE }"
      >
        <span v-text="$t('labels.upgradeYourMembership')" />
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { ROUTES } from '@/constants';

export default {
  name: 'ThePaidFeatureAlert',
  data() {
    return {
      ROUTES,
    };
  },
};
</script>
