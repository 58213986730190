const ID = Object.freeze({
  EID: 'eid',
  ESIGNATURE: 'esignature',
  EDELIVERY: 'edelivery',
  API: 'api',
  CREDITS: 'credits',
  PROGRAMS: 'programs',
});

const KEY = Object.freeze({
  MESSAGE: 'message',
  TITLE: 'title',
});

export default Object.freeze({
  ID,
  KEY,
});
