<template>
  <v-row>
    <v-col cols="12">
      <v-alert border="left" outlined prominent type="info">
        <template>
          <span v-text="$t('msgs.goldFeature')" />
        </template>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheGoldMembershipFeatureAlert',
};
</script>
