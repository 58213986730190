import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setAttribute(state, payload) {
    state.attributes.push(payload);
  },
  deleteAttribute(state, payload) {
    state.attributes = state.attributes.filter(
      (attribute) => attribute.id !== payload,
    );
  },
  setContract(state, payload) {
    state.contract = payload;
  },
};
