import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  addCredentials(state, payload) {
    payload.forEach((credential) => state.credentials.push(credential));
  },
  setCredentials(state, payload) {
    state.credentials = payload;
  },
  setInvitation(state, payload) {
    state.invitation = payload;
  },
  setModal(state, payload) {
    state.modal = payload;
  },
};
