import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const ContactsViewDashboard = loadView('contacts/ContactsViewDashboard');
const ContactsViewMangement = loadView('contacts/ContactsViewMangement');

export const contactsRoute = {
  path: ROUTES.PATH.CONTACTS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.CONTACTS,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.CONTACTS,
      component: ContactsViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.CONTACTS_DASHBOARD,
      name: ROUTES.NAME.CONTACTS_DASHBOARD,
      component: ContactsViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.CONTACTS_DASHBOARD,
      },
    },
    {
      path: ROUTES.PATH.CONTACTS_MANAGEMENT,
      name: ROUTES.NAME.CONTACTS_MANAGEMENT,
      component: ContactsViewMangement,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.CONTACTS_MANAGEMENT,
      },
    },
  ],
};
