export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isAdding: (state) => state.isAdding,
  isFetching: (state) => state.isFetching,
  isHandling: (state) => state.isHandling,
  detail: (state) => state.detail,
  summary: (state) => state.summary,
  messages: (state) => state.messages,
  paging: (state) => state.paging,
  cursor: (state) =>
    state.paging && state.paging.next_cursor ? state.paging.next_cursor : null,
};
