import { stakeApi } from '@/apis';
import { STAKE } from '@/constants';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} order
   */
  async initStakeDashboard({ commit, dispatch }, order) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchMessagesList', { order }),
      dispatch('fetchSummary'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchSummary({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    commit('setFetching', true);
    try {
      const response = await stakeApi.requestSummary(token);
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setFetching', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} msgType
   * @param {String} order
   */
  async refreshMessages({ commit, dispatch }, { msgType, order } = {}) {
    commit('setLoading', true);
    commit('setMessages', []);
    await dispatch('fetchMessagesList', { msgType, order });
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} order_by
   * @param {String} query
   */
  async addMessages({ commit, dispatch }, { cursor, msgType, order }) {
    commit('setAdding', true);
    await dispatch('fetchMessagesList', { cursor, msgType, order });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   * @param {Number} limit
   * @param {String} msgType
   * @param {String} order
   */
  async fetchMessagesList(
    { commit, rootGetters },
    { cursor, limit, msgType, order },
  ) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stakeApi.requestList({
        token,
        cursor,
        limit,
        msg_type: msgType,
        order,
      });
      commit('addMessages', response.data.messages);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} id
   * @param {String} msgType
   */
  async initStakeDetail({ commit, dispatch }, { id, msgType }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [];
    switch (msgType) {
      case STAKE.MSG_TYPE.DELEGATE:
        requests.push(dispatch('fetchDelegateDetail', id));
        break;
      case STAKE.MSG_TYPE.REWARDS:
        requests.push(dispatch('fetchRewardsDetail', id));
        break;
      case STAKE.MSG_TYPE.UNDELEGATE:
        requests.push(dispatch('fetchUndelegateDetail', id));
        break;
      default:
        break;
    }
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchDelegateDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stakeApi.requestReadDelegate(token, id);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchRewardsDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stakeApi.requestReadRewards(token, id);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchUndelegateDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stakeApi.requestReadUndelegate(token, id);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async claimRewards({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    commit('setHandling', true);
    try {
      const response = await stakeApi.requestCreateRewards(token);
      return response.data;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setHandling', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async delegate({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setHandling', true);
    try {
      const response = await stakeApi.requestCreateDelegate(token, data);
      return response.data;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setHandling', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async undelegate({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setHandling', true);
    try {
      const response = await stakeApi.requestCreateUndelegate(token, data);
      return response.data;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setHandling', false);
    }
  },
};
