const MSG_TYPE = Object.freeze({
  DELEGATE: 'MsgDelegate',
  REWARDS: 'MsgUndelegate',
  UNDELEGATE: 'MsgWithdrawDelegatorReward',
});

const API_PARAM = Object.freeze({
  [MSG_TYPE.DELEGATE]: 'cosmos.staking.v1beta1.MsgDelegate',
  [MSG_TYPE.REWARDS]: 'cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
  [MSG_TYPE.UNDELEGATE]: 'cosmos.staking.v1beta1.MsgUndelegate',
});

const ROUTE_PARAM = Object.freeze({
  DELEGATE: 'delegate',
  REWARDS: 'rewards',
  UNDELEGATE: 'undelegate',
});

export default Object.freeze({
  API_PARAM,
  MSG_TYPE,
  ROUTE_PARAM,
});
