<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="d-flex align-center">
        <span class="font-weight-bold" v-text="label" />
        <span>:</span>
        <router-link :to="to">
          <span class="pl-1 word-break" v-text="content" />
        </router-link>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'BaseListRouterLink',
  props: {
    label: {
      type: String,
      required: true,
      note: 'The item label',
    },
    content: {
      type: String,
      required: true,
      note: 'The item content',
    },
    to: {
      type: Object,
      required: true,
      note: 'The linked route',
    },
  },
};
</script>

<style scoped>
.word-break {
  white-space: normal;
  word-break: break-all;
}
</style>
