import { AUTH, SETTINGS } from '@/constants';
import Keycloak from 'keycloak-js';

const initOptions = {
  url: AUTH.KEYCLOAK.URL,
  realm: AUTH.KEYCLOAK.REALM,
  clientId: AUTH.KEYCLOAK.CLIENT_ID,
};

const keycloak = new Keycloak(initOptions);
let refreshIntervalId;

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initAuth({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('authenticate')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async authenticate({ commit, dispatch }) {
    await dispatch('setupClient');
    if (keycloak.authenticated) {
      const token = keycloak.token;
      commit('setToken', token);
      const requests = [dispatch('fetchProfile')];
      await Promise.all(requests);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async setupClient({ commit, dispatch }) {
    try {
      await keycloak.init({
        onLoad: AUTH.KEYCLOAK.ON_LOAD,
        checkLoginIframe: false,
      });
      refreshIntervalId = setInterval(async () => {
        dispatch('refreshToken');
      }, AUTH.KEYCLOAK.REFRESH_TOKEN_TIME * 1000);
    } catch (error) {
      commit('setToken', '');
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async refreshToken({ commit }) {
    try {
      const response = await keycloak.updateToken(70);
      if (response) commit('setToken', keycloak.token);
    } catch (error) {
      commit('setToken', '');
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchProfile({ commit }) {
    try {
      const profile = await keycloak.loadUserProfile();
      commit('setProfile', profile);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async logOut({ commit }) {
    clearInterval(refreshIntervalId);
    try {
      await keycloak.logout({
        redirectUri: SETTINGS.HOST,
      });
      commit('reset');
    } catch (error) {
      commit('setError', error);
    }
  },
};
