import { mintApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initMintDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchNftCollectionsList'),
      dispatch('fetchSummary'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchSummary({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await mintApi.requestSummary(token);
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} query
   */
  async addCollections({ commit, dispatch }, { cursor, query }) {
    commit('setAdding', true);
    await dispatch('fetchNftCollectionsList', { cursor, query });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} query
   */
  async refreshCollections({ commit, dispatch }, query) {
    commit('setList', []);
    commit('setLoading', true);
    await dispatch('fetchNftCollectionsList', { query });
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   * @param {Number} limit
   * @param {String} query
   */
  async fetchNftCollectionsList(
    { commit, rootGetters },
    { cursor, limit, query } = {},
  ) {
    const token = rootGetters['auth/token'];
    try {
      const response = await mintApi.requestList({
        token,
        cursor,
        limit,
        query,
      });
      commit('addToList', response.data.collections);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
};
