import { QUICK_DELIVERY } from '@/constants';

const quickDeliveryHelper = {
  /**
   *
   * @param {File} file
   * @returns {Object}
   */
  buildNewVault(file) {
    return {
      document: file,
      name: file.name,
      content_url: QUICK_DELIVERY.METADATA.CONTENT_URI,
      schema_uri: QUICK_DELIVERY.METADATA.SCHEMA_URI,
      schema_version: QUICK_DELIVERY.METADATA.SCHEMA_VERSION,
    };
  },
  /**
   *
   * @param {String} fileName
   * @param {String} recipient
   * @returns {Object}
   */
  buildShareVault(fileName, recipient) {
    return {
      name: fileName,
      content_url: QUICK_DELIVERY.METADATA.CONTENT_URI,
      schema_uri: QUICK_DELIVERY.METADATA.SCHEMA_URI,
      schema_version: QUICK_DELIVERY.METADATA.SCHEMA_VERSION,
      recipients: [recipient],
    };
  },
};

export default quickDeliveryHelper;
