import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const QuickDeliveryViewWizard = loadView(
  'quick-delivery/QuickDeliveryViewWizard',
);

export const quickDeliveryRoute = {
  path: ROUTES.PATH.QUICK_DELIVERY,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.QUICK_DELIVERY,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.QUICK_DELIVERY,
      component: QuickDeliveryViewWizard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.QUICK_DELIVERY_WIZARD,
      name: ROUTES.NAME.QUICK_DELIVERY_WIZARD,
      component: QuickDeliveryViewWizard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.QUICK_DELIVERY_WIZARD,
      },
    },
  ],
};
