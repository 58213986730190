export default {
  bindValuedParams(params) {
    if (!params) return {};
    const obj = {};
    Object.keys(params).forEach((k) => {
      if (params[k]) obj[`${k}`] = params[k];
    });
    return obj;
  },
};
