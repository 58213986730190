import { LISTINGS, NFT, STAKE } from '@/constants';

export const listings = {
  [NFT.ATTRIBUTE_TYPES.BOOST_NUMBER]: 'Boost number',
  [NFT.ATTRIBUTE_TYPES.BOOST_PERCENTAGE]: 'Boost percentage',
  [NFT.ATTRIBUTE_TYPES.DATE]: 'Date',
  [NFT.ATTRIBUTE_TYPES.NUMBER]: 'Number',
  [NFT.TYPES.BASE]: 'Base',
  [NFT.TYPES.METADATA_ON_CHAIN]: 'Metadata on chain',
  [LISTINGS.POSITIONINGS.GRID]: 'Grid',
  [LISTINGS.POSITIONINGS.LIST]: 'List',
  [LISTINGS.SORTINGS.ASC]: 'Ascending',
  [LISTINGS.SORTINGS.DESC]: 'Descending',
  [LISTINGS.SORTINGS.ALPHABET_ASC]: 'Alphabet asc',
  [LISTINGS.SORTINGS.ALPHABET_DESC]: 'Alphabet desc',
  [LISTINGS.SORTINGS.DATE_ASC]: 'Date asc',
  [LISTINGS.SORTINGS.DATE_DESC]: 'Date desc',
  [LISTINGS.SORTINGS.CONTACTS_ALPHABET_ASC]: 'Alphabet asc',
  [LISTINGS.SORTINGS.CONTACTS_ALPHABET_DESC]: 'Alphabet desc',
  [LISTINGS.SORTINGS.CONTACTS_DATE_ASC]: 'Date asc',
  [LISTINGS.SORTINGS.CONTACTS_DATE_DESC]: 'Date desc',
  [LISTINGS.SORTINGS.DOSSIERS_ALPHABET_ASC]: 'Alphabet asc',
  [LISTINGS.SORTINGS.DOSSIERS_ALPHABET_DESC]: 'Alphabet desc',
  [LISTINGS.SORTINGS.DOSSIERS_DATE_ASC]: 'Date asc',
  [LISTINGS.SORTINGS.DOSSIERS_DATE_DESC]: 'Date desc',
  [LISTINGS.TX.TYPE.RECEIVED]: 'Inbound',
  [LISTINGS.TX.TYPE.SENT]: 'Outbound',
  [STAKE.MSG_TYPE.DELEGATE]: 'Delegate',
  [STAKE.MSG_TYPE.REWARDS]: 'Rewards',
  [STAKE.MSG_TYPE.UNDELEGATE]: 'Undelegate',
};