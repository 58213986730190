import { eidentityApi, ekycApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initEidWallet({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchCredentials'),
      dispatch('account/fetchIdentity', null, { root: true }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchCredentials({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    const identity = rootGetters['account/identity'];
    try {
      const response = await ekycApi.requestCredentialsByAddress({
        token,
        address: identity.wallet.address,
      });
      commit('addCredentials', response.data.credentials);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  initInviteForm({ commit }) {
    commit('reset');
    commit('contacts/reset', null, { root: true });
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} emailAddress
   */
  async inviteContact({ commit, rootGetters }, emailAddress) {
    const token = rootGetters['auth/token'];
    const data = {
      email_address: emailAddress,
      workflow_completed_redirect_uri: window.location.origin,
    };
    commit('setLoading', true);
    try {
      const response = await eidentityApi.requestInvite(token, data);
      const invitation = {
        data: response.data,
        email: emailAddress,
        status: response.status,
      };
      commit('setInvitation', invitation);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  handleModal({ commit }, modal) {
    commit('setModal', modal);
  },
};
