export const msgs = {
  acceptAdditional: 'I have read and specifically accept the Additional Terms.',
  acceptError: 'To continue you must accept the contract',
  acceptNodeAdditional: 'I acknowledge that I have read and specifically accept the Additional Terms of the Distributor Node Agreement',
  acceptNodeTos: 'I acknowledge that I have read and accept the terms of the Distributor Node Agreement',
  acceptTos: 'I acknowledge that I have read the Agreement for the purchase of technology goods and services ("Agreement") General Terms and Conditions and accept the terms.',
  addressError: 'Incorrect address',
  alphaNumField: 'This field accepts only alphanumeric characters',
  amountError: 'This is not a valid amount',
  arrayLengthError: 'At least one value',
  askToSaveData: 'Would you like to save this data?',
  attestDocumentNotarized: 'This attest that the document has been notarized in the Commercio Blockchain thus proving that the document existed in its current form at the time of notarization.<br>With the corrisponding transaction and hash you can use this document as proof of certify that:',
  cashBackBonus: 'cash back in credits',
  codeLengthError: 'Min 5 and max 16 characters',
  configuringData: 'Please wait, we are configuring your data',
  configuringError: 'An error occurred during configuration,, contact the administrator',
  confirmDossierRemove: 'Confirm remove from dossier?',
  confirmReceipt: 'Do you confirm the receipt?',
  contactConfirmDelete: 'Confirm contact deletion?',
  contactDuplicatedAlert: 'This wallet is already in the address book',
  contactSave: 'Save the new contact?',
  contactsEmptyList: 'No contact found',
  copied: 'Copied',
  credentialWithoutDetail: 'This credential has no detail',
  credentialsEmptyList: 'No credential found',
  docsLoseAssociation: 'Number of documents that will remain without association',
  documentExisted: 'This document existed in its current form (Hash footprint) on date {0}',
  documentNotAltered: 'This document has not been altered in any way',
  documentStored: 'This document has been stored in a secure vault and will be available till {0} or more if storage service will be renewed',
  dossierConfirmDelete: 'Confirm dossier deletion?',
  dossiersEmptyList: 'No dossier found',
  dropYourFile: 'Drop your file here, or click in the lower right to select it (max {0} MB).\nFor a better user experience please provide the file name with the correct extension\n(e.g., ".pdf", ".doc", ".txt").',
  dropYourLogo: 'Drop your logo here, or click in the lower right to select it (max {0} MB).\nAvailable formats: jpg, png, svg, webp").',
  eArchivesEmptyList: 'No eArchive found',
  earnShare: 'Share this link only with companies you know to give them €50 Free credits and earn ABR Tokens for each membership they purchase',
  edeliverDocAvailable: 'It is now possible to edeliver the document',
  emailError: 'Incorrect email',
  emailField: 'This field accepts only valid email addresses',
  enableBoxSurchargeVisibility: 'Display process cost on header box (True(default)/False)',
  enablePurchaseSurchargeVisibility: 'Purchase membership View description details (True(default)/False)',
  errorOccurred: 'Sorry, an error occurred',
  fileSizeTooLarge: 'The file size is too large ({0})',
  fileTypeNotSupported: 'The file type is not supported',
  fiscalCodeError: 'Incorrect fiscal code',
  goldFeature: 'This feature is active only with Gold membership',
  insufficientFunds: 'You do not have enough funds to complete this operation',
  inviteContact: 'Enter the email address of one of your contacts to invite them to access the app.',
  inviteSent: 'Invitation message sent successfully',
  loading: 'Loading...',
  logoMaxSize: 'Logo size should be less than ',
  mandatoryField: 'This field is mandatory',
  modalQrWalletDesc: 'This is your Wallet , share it with anyone you want to get paid',
  nftsEmptyList: 'No NFT found',
  nftCollectionsEmptyList: 'No NFT collection found',
  noCode: 'No code',
  noDocsLinked: 'No documents linked to this dossier',
  noMessage: 'No message',
  notAvailable: 'Not available',
  notEnoughCCC: 'You do not have enough CCCs to complete this operation',
  notFound: 'Page not found! Go to the beginning',
  onlyCharsAndDash: 'Only characters and \'-\' are allowed',
  operationDeleted: 'Operation deleted',
  paidFeature: 'This feature is active only with Bronze membership or higher',
  paymentCheck: 'Please wait, we are verifying the payment and configuring your data',
  paymentDeleted: 'Payment has been stopped',
  paymentError: 'Process failed, contact administrator',
  processesEmptyList: 'No process found',
  purchaseMembership: 'Use it, invite your contacts and earn a Monthly Income',
  recipientAlreadyPresentAlert: 'Recipient already present',
  sdiLengthError: 'The SDI code is a 7-digit alphanumeric sequence',
  searchAddressNotice: 'To start searching you must type at least 3 characters of an email.{0}The search is case-insensitive.',
  searchRequirementsNotice: 'To start searching you must type at least 3 characters.{0}The search is case-insensitive.',
  sendingTokens: 'Please wait, we are sending the purchased CCCs to your wallet',
  sendingTokensCompleted: 'Sending of CCC completed successfully',
  sendingTokensFailed: 'Sending of CCC failed, please print the summary and contact administrator',
  serviceTemporarilyUnavailable: "Service temporarily unavailable",
  setupSurcharge: 'Set the surcharge for each individual membership',
  sharedocsEmptyList: 'No Sharedoc found',
  startTypingEmail: 'Start typing email to search',
  startTypingToFilterCountries: 'Start typing to filter countries',
  startTypingToSearch: 'Start typing to search',
  symbolLength: 'The symbol must be between 3 and 12 characters long',
  textLengthError: 'Min 3 and max 256 characters',
  tokensSendFooter: 'Warning: all Credits/token transaction are final and cannot be reversed',
  tooLongField: 'The value entered is too long',
  triggeredAnError: 'Something happened in setting up the request that triggered an error',
  txModalFooter: 'Warning: all transaction are final and cannot be reversed',
  unansweredRequest: 'The request was made but no response was received',
  uploadLogoIssue: 'The image is too large or is in an unsupported format',
  userAlreadyRegistered: 'Already registered user',
  vatError: 'Incorrect VAT number',
  vatLengthError: 'Min 5 and max 15 characters',
  waitOperationCompleted: 'Please wait for the conclusion of the document protection operation',
  waitPrepareWizard: 'Please wait, we are preparing the wizard',
};
