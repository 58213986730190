import { COIN, LISTINGS } from '@/constants';

const MSG_STATUS = Object.values(LISTINGS.TX.MESSAGE_STATUS);
const getRandomly = (items) => items[Math.floor(Math.random() * items.length)];

const SWAP = {
  FROM_1_TO_2: 1.2,
  FROM_2_TO_1: 0.8,
};

const mockSwap = (amount, tokenId) => {
  if (tokenId === COIN.TOKEN.DENOM) {
    return parseFloat(amount) * SWAP.FROM_1_TO_2;
  }
  return parseFloat(amount) * SWAP.FROM_2_TO_1;
};

const mockSwapConfig = () => {
  const item = {
    contract_address:
      'did:com:14hj2tavq8fpesdwxxcu44rty3hh90vhujrvcmstl4zr3txmfvw9sh7yll8',
    token_from: {
      id: 'ucommercio',
      tag: '$COM',
    },
    token_to: {
      id: 'uccc',
      tag: '$CCC',
    },
  };
  return item;
};

const mockSwapSummary = ({
  processId = '87df0ab9-58b2-4e41-9b81-ebb41b54c768',
  msgStatus,
} = {}) => {
  const status = msgStatus ? msgStatus : getRandomly(MSG_STATUS);
  const item = {
    back_url: 'http://example.com/callback',
    chain_id: 'commercio-testnet10k2',
    contract:
      'did:com:14hj2tavq8fpesdwxxcu44rty3hh90vhujrvcmstl4zr3txmfvw9sh7yll8',
    created_at: 'string',
    error: 'signature failed',
    from: {
      amount: '1',
      label: '$COM',
    },
    process_id: processId,
    sender: 'did:com:1yq5z4236gmcn9g3l8z4mq5f8nzyt8s9k5z2u6f',
    status,
    to: {
      amount: '1',
      label: '$COM',
    },
    tx_hash: 'FA81C0F03B49FEA2D3E671DC1504004DEDE7F1642C341DC0CCB03E9CE3BC3966',
    tx_timestamp: '2017-07-21T17:32:28Z',
    tx_type: 'cosmos.bank.v1beta1.MsgSend',
  };
  return item;
};

export { mockSwap, mockSwapConfig, mockSwapSummary };
