const ROUTES = Object.freeze({
  ACCOUNT: 'account',
  TXS_DETAIL: 'transactions/detail',
  TXS_SHAREDOC: 'transactions/sharedoc',
});

export default Object.freeze({
  URL: process.env.VUE_APP_EXPLORER,
  ROUTES,
});
