import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const EarnView = loadView('earn/EarnView');

export const earnRoute = {
  path: ROUTES.PATH.EARN,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.EARN,
  },
  beforeEnter: (_to, _from, next) => {
    next(ROUTES.PATH.ROOT);
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.EARN,
      component: EarnView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
  ],
};
