import { APIS } from '@/constants';
import axios from 'axios';

const swap = {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestConfig(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/config',
      method: 'get',
      baseURL: APIS.SWAP,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestSwap(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'post',
      baseURL: APIS.SWAP,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} processId
   * @returns {AxiosPromise}
   */
  requestSummary({ token, processId }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${processId}`,
      method: 'get',
      baseURL: APIS.SWAP,
      headers,
    });
  },
};

export default swap;
