import { contactApi } from '@/apis';
import { SETTINGS } from '@/constants';
import { messageManager } from '@/utils';

export default {
  /**
   *
   * @param {Function} dispatch
   * @param {String} order_by
   */
  initContactsDashboard({ dispatch }, order_by) {
    dispatch('fetchContactsDashboardData', { order_by });
    dispatch('header/initHeader', null, {
      root: true,
    });
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} order_by
   * @param {String} query
   */
  async fetchContactsDashboardData({ commit, dispatch }, { order_by, query }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchContactsList', {
        limit: SETTINGS.INFINITE_SCROLL.LIMIT_FIRST,
        order_by,
        query,
      }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} order_by
   * @param {String} query
   */
  async addContacts({ commit, dispatch }, { cursor, order_by, query }) {
    commit('setAdding', true);
    await dispatch('fetchContactsList', {
      limit: SETTINGS.INFINITE_SCROLL.LIMIT_NEXT,
      cursor,
      order_by,
      query,
    });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} order_by
   * @param {String} query
   */
  async refreshContacts({ commit, dispatch }, order_by) {
    commit('reset');
    commit('setLoading', true);
    await dispatch('fetchContactsList', {
      limit: SETTINGS.INFINITE_SCROLL.LIMIT_FIRST,
      order_by,
    });
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   * @param {Number} limit
   * @param {String} order_by
   * @param {String} query
   */
  async fetchContactsList(
    { commit, rootGetters },
    { cursor, limit, order_by, query },
  ) {
    const token = rootGetters['auth/token'];
    try {
      const response = await contactApi.requestList({
        token,
        cursor,
        limit,
        order_by,
        query,
      });
      commit('addContacts', response.data.contacts);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchContactDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await contactApi.requestDetail(token, id);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   * @returns {Promise}
   */
  async createContact({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setWarning', null);
    commit('setLoading', true);
    try {
      const response = await contactApi.requestCreate(token, data);
      return response;
    } catch (error) {
      if (error.response.status === 400 && error.response.data.code) {
        commit(
          'setWarning',
          messageManager.composeError(
            error.response.data.code,
            error.response.data.message,
          ),
        );
      } else {
        commit('setError', error);
      }
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   * @param {Object} data
   * @returns {Promise}
   */
  async updateContact({ commit, rootGetters }, { id, data }) {
    const token = rootGetters['auth/token'];
    commit('setWarning', null);
    commit('setLoading', true);
    try {
      const response = await contactApi.requestUpdate({ token, id, data });
      return response;
    } catch (error) {
      if (error.response.status === 400 && error.response.data.code) {
        commit(
          'setWarning',
          messageManager.composeError(
            error.response.data.code,
            error.response.data.message,
          ),
        );
      } else {
        commit('setError', error);
      }
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   * @returns {Promise}
   */
  async deleteContact({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await contactApi.requestRemove(token, id);
      return response;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} address
   * @returns {Promise}
   */
  async checkAlreadySavedWallet({ commit, rootGetters }, address) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await contactApi.requestList({
        token,
        query: address,
      });
      return response.data.contacts.length > 0;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
};
