import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setAdding(state, payload) {
    state.isAdding = payload;
  },
  setSending(state, payload) {
    state.isSending = payload;
  },
  setDetail(state, payload) {
    state.detail = payload;
  },
  setPaging(state, payload) {
    state.paging = payload;
  },
  setProcesses(state, payload) {
    state.processes = payload;
  },
  addProcesses(state, payload) {
    state.processes.push(...payload);
  },
};
