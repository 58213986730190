import { dossierApi, sharedocApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} order_by
   */
  async initDossiersDashboard({ dispatch }, order_by) {
    const requests = [
      dispatch('fetchDossiersDashboardData', { order_by }),
      dispatch('header/initHeader', null, { root: true }),
    ];
    await Promise.all(requests);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} order_by
   * @param {String} query
   */
  async fetchDossiersDashboardData(
    { commit, dispatch },
    { order_by, query } = {},
  ) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchDossiersList', { order_by, query })];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} order_by
   * @param {String} query
   */
  async addDossiers({ commit, dispatch }, { cursor, order_by, query }) {
    commit('setAdding', true);
    await dispatch('fetchDossiersList', { cursor, order_by, query });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   * @param {Number} limit
   * @param {String} order_by
   * @param {String} query
   */
  async fetchDossiersList(
    { commit, rootGetters },
    { cursor, limit, order_by, query },
  ) {
    const token = rootGetters['auth/token'];
    try {
      const response = await dossierApi.requestList({
        token,
        cursor,
        limit,
        order_by,
        query,
      });
      commit('addDossiers', response.data.dossiers);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchDossierDetails({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    commit('reset');
    commit('setLoading', true);
    try {
      const response = await dossierApi.requestDetail(token, id);
      commit('setDetails', response.data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async newDossier({ commit, dispatch, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      await dossierApi.requestCreate(token, data);
      await dispatch('drawer/refreshLabels', null, { root: true });
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   * @param {String} id
   * @param {Object} data
   */
  async editDossier({ commit, dispatch, rootGetters }, { id, data }) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      await dossierApi.requestUpdate({ token, id, data });
      await dispatch('drawer/refreshLabels', null, { root: true });
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   * @param {String} id
   */
  async deleteDossier({ commit, dispatch, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    commit('setHandling', true);
    try {
      await dossierApi.requestDelete(token, id);
      await dispatch('drawer/refreshLabels', null, { root: true });
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setHandling', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async countLinkedDocs({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    commit('setHandling', true);
    try {
      const response = await sharedocApi.requestList({
        token: token,
        dossierId: id,
        limit: 1,
      });
      return response.data.paging.total_count;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setHandling', false);
    }
  },
};
