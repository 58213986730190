import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setWarning(state, error) {
    state.warning = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setAdding(state, payload) {
    state.isAdding = payload;
  },
  setPaging(state, payload) {
    state.paging = payload;
  },
  setList(state, payload) {
    state.list = payload;
  },
  addContacts(state, payload) {
    state.list.push(...payload);
  },
  setDetail(state, payload) {
    state.detail = payload;
  },
};
