import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';
import store from '@/store';

const EdeliveryViewConfirm = loadView('edelivery/EdeliveryViewConfirm');
const EdeliveryViewHow = loadView('edelivery/EdeliveryViewHow');
const EdeliveryViewWhat = loadView('edelivery/EdeliveryViewWhat');
const EdeliveryViewWho = loadView('edelivery/EdeliveryViewWho');
const EdeliveryViewWizard = loadView('edelivery/EdeliveryViewWizard');

export const edeliveryRoute = {
  path: ROUTES.PATH.EDELIVERY,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.EDELIVERY,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.EDELIVERY,
      component: EdeliveryViewWizard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.EDELIVERY_CONFIRM,
      name: ROUTES.NAME.EDELIVERY_CONFIRM,
      component: EdeliveryViewConfirm,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EDELIVERY_CONFIRM,
      },
      beforeEnter: (_to, _from, next) => {
        const isStarted = store.getters['edelivery/isStarted'];
        if (isStarted) {
          next();
        } else {
          next({ name: ROUTES.NAME.EDELIVERY });
        }
      },
    },
    {
      path: ROUTES.PATH.EDELIVERY_HOW,
      name: ROUTES.NAME.EDELIVERY_HOW,
      component: EdeliveryViewHow,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EDELIVERY_HOW,
      },
      beforeEnter: (_to, _from, next) => {
        const isStarted = store.getters['edelivery/isStarted'];
        if (isStarted) {
          next();
        } else {
          next({ name: ROUTES.NAME.EDELIVERY });
        }
      },
    },
    {
      path: ROUTES.PATH.EDELIVERY_WHAT,
      name: ROUTES.NAME.EDELIVERY_WHAT,
      component: EdeliveryViewWhat,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EDELIVERY_WHAT,
      },
      beforeEnter: (_to, _from, next) => {
        const isStarted = store.getters['edelivery/isStarted'];
        if (isStarted) {
          next();
        } else {
          next({ name: ROUTES.NAME.EDELIVERY });
        }
      },
    },
    {
      path: ROUTES.PATH.EDELIVERY_WHO,
      name: ROUTES.NAME.EDELIVERY_WHO,
      component: EdeliveryViewWho,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EDELIVERY_WHO,
      },
      beforeEnter: (_to, _from, next) => {
        const isStarted = store.getters['edelivery/isStarted'];
        if (isStarted) {
          next();
        } else {
          next({ name: ROUTES.NAME.EDELIVERY });
        }
      },
    },
    {
      path: ROUTES.PATH.EDELIVERY_WIZARD,
      name: ROUTES.NAME.EDELIVERY_WIZARD,
      component: EdeliveryViewWizard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EDELIVERY_WIZARD,
      },
    },
  ],
};
