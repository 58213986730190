export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isAdding: (state) => state.isAdding,
  isSending: (state) => state.isSending,
  detail: (state) => state.detail,
  paging: (state) => state.paging,
  processes: (state) => state.processes,
  cursor: (state) =>
    state.paging?.next_cursor ? state.paging.next_cursor : null,
};
