import { APIS } from '@/constants';
import axios from 'axios';

const customization = {
  /**
   *
   * @returns {AxiosPromise}
   */
  requestCustomization() {
    return axios({
      url: APIS.CUSTOMIZATION,
      method: 'get',
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestUpdate(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'put',
      baseURL: APIS.CUSTOMIZATION,
      headers,
      data,
    });
  },
};

export default customization;
