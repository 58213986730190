import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';
import store from '@/store';

const EarchiveViewDashboard = loadView('earchive/EarchiveViewDashboard');
const EarchiveViewDetail = loadView('earchive/EarchiveViewDetail');
const EarchiveWizardRouter = loadView('earchive/wizard/EarchiveWizardRouter');
const EarchiveWizardViewConfirm = loadView(
  'earchive/wizard/EarchiveWizardViewConfirm',
);
const EarchiveWizardViewHow = loadView('earchive/wizard/EarchiveWizardViewHow');
const EarchiveWizardViewWhat = loadView(
  'earchive/wizard/EarchiveWizardViewWhat',
);
const EarchiveWizardViewIntro = loadView(
  'earchive/wizard/EarchiveWizardViewIntro',
);

export const earchiveRoute = {
  path: ROUTES.PATH.EARCHIVE,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.EARCHIVE,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.EARCHIVE,
      component: EarchiveViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.EARCHIVE_DASHBOARD,
      name: ROUTES.NAME.EARCHIVE_DASHBOARD,
      component: EarchiveViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EARCHIVE_DASHBOARD,
      },
    },
    {
      path: ROUTES.PATH.EARCHIVE_DETAIL,
      name: ROUTES.NAME.EARCHIVE_DETAIL,
      component: EarchiveViewDetail,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EARCHIVE_DETAIL,
      },
    },
    {
      path: ROUTES.PATH.EARCHIVE_WIZARD,
      component: EarchiveWizardRouter,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EARCHIVE_WIZARD,
      },
      children: [
        {
          path: '',
          name: ROUTES.NAME.EARCHIVE_WIZARD,
          component: EarchiveWizardViewIntro,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
          },
        },
        {
          path: ROUTES.PATH.EARCHIVE_WIZARD_CONFIRM,
          name: ROUTES.NAME.EARCHIVE_WIZARD_CONFIRM,
          component: EarchiveWizardViewConfirm,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.EARCHIVE_WIZARD_CONFIRM,
          },
          beforeEnter: (_to, _from, next) => {
            const isStarted = store.getters['earchive/isStarted'];
            if (isStarted) {
              next();
            } else {
              next({ name: ROUTES.NAME.EARCHIVE_WIZARD });
            }
          },
        },
        {
          path: ROUTES.PATH.EARCHIVE_WIZARD_HOW,
          name: ROUTES.NAME.EARCHIVE_WIZARD_HOW,
          component: EarchiveWizardViewHow,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.EARCHIVE_WIZARD_HOW,
          },
          beforeEnter: (_to, _from, next) => {
            const isStarted = store.getters['earchive/isStarted'];
            if (isStarted) {
              next();
            } else {
              next({ name: ROUTES.NAME.EARCHIVE_WIZARD });
            }
          },
        },
        {
          path: ROUTES.PATH.EARCHIVE_WIZARD_INTRO,
          name: ROUTES.NAME.EARCHIVE_WIZARD_INTRO,
          component: EarchiveWizardViewIntro,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.EARCHIVE_WIZARD_INTRO,
          },
        },
        {
          path: ROUTES.PATH.EARCHIVE_WIZARD_WHAT,
          name: ROUTES.NAME.EARCHIVE_WIZARD_WHAT,
          component: EarchiveWizardViewWhat,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.EARCHIVE_WIZARD_WHAT,
          },
          beforeEnter: (_to, _from, next) => {
            const isStarted = store.getters['earchive/isStarted'];
            if (isStarted) {
              next();
            } else {
              next({ name: ROUTES.NAME.EARCHIVE_WIZARD });
            }
          },
        },
      ],
    },
  ],
};
