import { eidApi } from '@/apis';
import { xLocale } from '@/locale';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initAccount({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    // TODO: add "dispatch('fetchAgreements')"
    const requests = [dispatch('fetchIdentity')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchAgreements({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      // TODO: fetch agreements
      console.log('fetchAgreements', token);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchIdentity({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await eidApi.requestDetail(xLocale, token);
      commit('setIdentity', response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        commit('setIdentity', null);
      } else {
        commit('setError', error);
      }
    }
  },
};
