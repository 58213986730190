export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isHandling: (state) => state.isHandling,
  settings: (state) => state.settings,
  areValidSettings: (state) => state.areValidSettings,
  theme: (state) => state.settings.theme,
  parameters: (state) => state.settings.surcharge.parameters,
  visibility: (state) => state.settings.surcharge.visibility,
};
