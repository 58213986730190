import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const NftViewDashboard = loadView('nft/NftViewDashboard');
const NftViewDetail = loadView('nft/NftViewDetail');
const NftViewTransferOutcome = loadView('nft/NftViewTransferOutcome');
const NftViewTransferSubmit = loadView('nft/NftViewTransferSubmit');

export const nftRoute = {
  path: ROUTES.PATH.NFT,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.NFT,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.NFT,
      component: NftViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.NFT_DASHBOARD,
      name: ROUTES.NAME.NFT_DASHBOARD,
      component: NftViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.NFT_DASHBOARD,
      },
    },
    {
      path: ROUTES.PATH.NFT_DETAIL,
      name: ROUTES.NAME.NFT_DETAIL,
      component: NftViewDetail,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.NFT_DETAIL,
      },
    },
    {
      path: ROUTES.PATH.NFT_TRANSFER_OUTCOME,
      name: ROUTES.NAME.NFT_TRANSFER_OUTCOME,
      component: NftViewTransferOutcome,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.NFT_TRANSFER_OUTCOME,
      },
    },
    {
      path: ROUTES.PATH.NFT_TRANSFER_SUBMIT,
      name: ROUTES.NAME.NFT_TRANSFER_SUBMIT,
      component: NftViewTransferSubmit,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.NFT_TRANSFER_SUBMIT,
      },
    },
  ],
};
