const ATTRIBUTE_TYPES = Object.freeze({
  BOOST_NUMBER: 'boost_number',
  BOOST_PERCENTAGE: 'boost_percentage',
  DATE: 'date',
  NUMBER: 'number',
});

const TYPES = Object.freeze({
  BASE: 'base',
  METADATA_ON_CHAIN: 'metadata_on_chain',
});

export default Object.freeze({
  ATTRIBUTE_TYPES,
  TYPES,
});
