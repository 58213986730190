<template>
  <v-card outlined :loading="loading">
    <v-card-title
      class="d-flex justify-center text-truncate text-overline font-weight-bold"
    >
      <span v-html="title" />
    </v-card-title>
    <v-card-subtitle class="d-flex justify-center text-overline primary--text">
      <span v-html="info" />
    </v-card-subtitle>
    <v-spacer />
    <v-card-text class="d-flex justify-center card-text">
      <slot name="content" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseBox',
  props: {
    title: {
      type: String,
      default: '',
      note: 'Main title',
    },
    info: {
      type: String,
      default: '',
      note: 'Additional infos',
    },
    loading: {
      type: Boolean,
      default: false,
      note: 'The loading state',
    },
  },
};
</script>

<style scoped>
.card-text {
  overflow-y: auto;
  height: 110px;
}
</style>
