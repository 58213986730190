import { faker } from '@faker-js/faker';

import { NFT } from '@/constants';

const attributeTypes = Object.values(NFT.ATTRIBUTE_TYPES);
const types = Object.values(NFT.TYPES);

const mockMintItem = (type) => {
  const nftType = type || faker.helpers.arrayElement(types);
  const attributes =
    nftType === NFT.TYPES.METADATA_ON_CHAIN
      ? getAttributes(faker.number.int({ min: 1, max: 9 }))
      : [];
  const item = {
    attributes,
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    id: faker.string.uuid(),
    name: faker.lorem.word(),
    symbol: faker.string.alphanumeric({
      length: { min: 3, max: 5 },
      casing: 'upper',
    }),
    type: nftType,
  };
  return item;
};

const mockMintItems = (items = 10) => {
  return faker.helpers.multiple(mockMintItem, { count: items });
};

const mockMintProcess = () => {
  const item = {
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    process_id: faker.string.uuid(),
  };
  return item;
};

const mockMintSummary = () => {
  return {
    total: faker.number.int({ max: 10, min: 1 }),
  };
};

export { mockMintItem, mockMintItems, mockMintProcess, mockMintSummary };

const getAttributes = (items = 5) => {
  const attributes = new Array(items).fill(null).map(() => {
    return {
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      display: faker.helpers.arrayElement(attributeTypes),
      id: faker.string.uuid(),
      trait: faker.lorem.word(),
    };
  });
  return attributes;
};
