const TYPE = Object.freeze({
  ERROR: 'error',
  SUCCESS: 'success',
});

const RESPONSE_CODE = Object.freeze({
  E_DEFAULT: 'e_default',
  E_INVALID_BECH32_ADDRESS: 'invalid_bech32_address',
  E_WALLET_NOT_EXISTS: 'wallet_account_does_not_exists',
  OK_DEFAULT: 'ok_default',
});

export default Object.freeze({
  RESPONSE_CODE,
  TYPE,
});
