<template>
  <div class="ma-1">
    <the-alert-notice kind="error" :message="$t('msgs.errorOccurred')" />
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span v-text="$t('titles.details')" />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list dense>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="pb-1">
                  <span v-text="$t('titles.message')" />
                </v-list-item-title>
                <v-list-item-subtitle class="pb-1 text-wrap">
                  <span v-text="message" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="pb-1">
                  <span v-text="$t('titles.code')" />
                </v-list-item-title>
                <v-list-item-subtitle class="pb-1 text-wrap">
                  <span v-text="code" />
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <slot name="actions" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import errorHandler from './helpers/errorHandler';

export default {
  name: 'TheErrorMessage',
  props: {
    error: {
      type: Error,
      required: true,
      note: 'The error to display',
    },
  },
  computed: {
    message() {
      return this.processedError &&
        this.processedError.data &&
        this.processedError.data.message
        ? this.processedError.data.message
        : this.$t('msgs.noMessage');
    },
    code() {
      return this.processedError && this.processedError.status
        ? this.processedError.status
        : this.$t('msgs.noCode');
    },
    processedError() {
      return this.error
        ? errorHandler.process(this.$t, this, this.error)
        : null;
    },
  },
};
</script>
