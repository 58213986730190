import { nftApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initNftDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchNftList'), dispatch('fetchSummary')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchSummary({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await nftApi.requestSummary(token);
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} query
   */
  async addNfts({ commit, dispatch }, { cursor, query }) {
    commit('setAdding', true);
    await dispatch('fetchNftList', { cursor, query });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} query
   */
  async refreshNfts({ commit, dispatch }, query) {
    commit('setList', []);
    commit('setLoading', true);
    await dispatch('fetchNftList', { query });
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   * @param {Number} limit
   * @param {String} query
   */
  async fetchNftList({ commit, rootGetters }, { cursor, limit, query } = {}) {
    const token = rootGetters['auth/token'];
    try {
      const response = await nftApi.requestList({
        token,
        cursor,
        limit,
        query,
      });
      commit('addToList', response.data.tokens);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
};
