import { LOCALES } from '@/constants';
import distributorTermsEnConstant from './distributor-terms-en-constant';
import distributorTermsItConstant from './distributor-terms-it-constant';

const DISTRIBUTOR_TERMS = (lang) => {
  switch (lang) {
    case LOCALES.IT:
      return distributorTermsItConstant;
    default:
      return distributorTermsEnConstant;
  }
};

export default DISTRIBUTOR_TERMS;
