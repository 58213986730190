<template>
  <v-card flat :disabled="isLoading">
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="1"
          class="d-flex justify-center justify-md-start align-md-center"
        >
          <v-btn icon :disabled="!to" :to="to">
            <slot name="icon" />
          </v-btn>
        </v-col>
        <v-col cols="12" md="8">
          <div class="text-body-1 text-capitalize font-weight-bold">
            <slot name="title" />
          </div>
          <div class="text-caption">
            <slot name="subtitle" />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center justify-md-end align-md-center"
        >
          <slot name="actions" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mx-1" />
  </v-card>
</template>

<script>
export default {
  name: 'BaseListCard',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      note: 'The view loading state',
    },
    to: {
      type: Object,
      default: () => null,
      note: 'Object representing the route',
    },
  },
};
</script>
