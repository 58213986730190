import { LISTINGS } from '@/constants';
const statuses = Object.values(LISTINGS.TX.MESSAGE_STATUS);

const mockPayment = () => {
  const apiStatus = statuses[Math.floor(Math.random() * statuses.length)];
  const item = {
    amount_paid: '1022',
    api_services_process_error:
      apiStatus === LISTINGS.TX.MESSAGE_STATUS.FAILURE
        ? 'bastion could not sign the messages'
        : undefined,
    api_services_process_status: apiStatus,
    created_at: '2024-01-31T11:34:38+01:00',
    paid_at: '2024-01-31T11:34:38+01:00',
    paid_currency: 'EUR',
    payment_id: '3ea519ba-91c9-446e-b731-cc8aacac0a08',
    process_id: '0a470607-6d6b-4c69-af6b-14404ed072ef',
    product_quantity: 10,
    status: 'paid',
  };
  return item;
};

export { mockPayment };
