import { nftApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} id
   */
  async initNftDetail({ commit, dispatch }, id) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchNftDetail', id)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchNftDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    try {
      const response = await nftApi.requestDetail({
        bearer: token,
        tokenId: id,
      });
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
