import { LISTINGS, STAKE } from '@/constants';

const MSG_STATUS = Object.values(LISTINGS.TX.MESSAGE_STATUS);
const MSG_TYPES = Object.values(STAKE.MSG_TYPE);
const getRandomly = (items) => items[Math.floor(Math.random() * items.length)];

const mockStakeSummary = () => {
  const item = {
    details: {
      availables: 8000000,
      delegations: 15000000,
      rewards: 2000000,
      unbondings: 5000000,
      last_completion_time: '2024-03-06T08:08:00Z',
    },
    total: 30000000,
  };
  return item;
};

const mockStakeItem = ({
  msgType,
  processId = '87df0ab9-58b2-4e41-9b81-ebb41b54c768',
  msgStatus,
} = {}) => {
  const status = msgStatus ? msgStatus : getRandomly(MSG_STATUS);
  const type = msgType ? msgType : getRandomly(MSG_TYPES);
  const item = {
    amount: [
      {
        amount: '1000000',
        denom: 'ucommercio',
      },
    ],
    completion_time:
      type === STAKE.MSG_TYPE.UNDELEGATE ? '2024-03-06T08:08:00Z' : undefined,
    delegator_address: 'did:com:1svkc84z3dxzrdtddwt58ycme69jtlm5yyvemgw',
    process_id: processId,
    status,
    tx_hash:
      status === LISTINGS.TX.MESSAGE_STATUS.SUCCESS
        ? '94B41BC9CE70729D859C0CB5573ADEF02D027FC1B89281E62C50628BAEBE2ECB'
        : undefined,
    tx_timestamp: '2024-02-14T08:08:00Z',
    tx_type: type,
    validator_address: 'did:com:valoper12hzcdgapfh9eyamd092mz0gp6gvl2reg0n2f0n',
  };
  return item;
};

const mockStakeList = ({ msgType, items = 10 } = {}) => {
  const list = new Array(items)
    .fill(null)
    .map(() => mockStakeItem({ msgType }));
  return list;
};

const mockStakeDelegate = ({
  msgType,
  processId = '87df0ab9-58b2-4e41-9b81-ebb41b54c768',
  msgStatus,
} = {}) => {
  const status = msgStatus ? msgStatus : getRandomly(MSG_STATUS);
  const type = msgType ? msgType : getRandomly(MSG_TYPES);
  const item = {
    amount: {
      amount: '1000000',
      denom: 'ucommercio',
    },
    back_url: 'http://example.com/callback',
    chain_id: 'commercio-net',
    created_at: '2024-02-14T08:08:00Z',
    delegator_address: 'did:com:1svkc84z3dxzrdtddwt58ycme69jtlm5yyvemgw',
    process_id: processId,
    status,
    tx_hash:
      status === LISTINGS.TX.MESSAGE_STATUS.SUCCESS
        ? '94B41BC9CE70729D859C0CB5573ADEF02D027FC1B89281E62C50628BAEBE2ECB'
        : undefined,
    tx_timestamp: '2024-02-14T08:08:00Z',
    tx_type: type,
    validator_address: 'did:com:valoper12hzcdgapfh9eyamd092mz0gp6gvl2reg0n2f0n',
  };
  return item;
};

const mockStakeRewards = ({
  msgType,
  processId = '87df0ab9-58b2-4e41-9b81-ebb41b54c768',
  msgStatus,
} = {}) => {
  const status = msgStatus ? msgStatus : getRandomly(MSG_STATUS);
  const type = msgType ? msgType : getRandomly(MSG_TYPES);
  const item = {
    amount: [
      {
        amount: '1000000',
        denom: 'ucommercio',
      },
    ],
    back_url: 'http://example.com/callback',
    chain_id: 'commercio-net',
    created_at: '2024-02-14T08:08:00Z',
    delegator_address: 'did:com:1svkc84z3dxzrdtddwt58ycme69jtlm5yyvemgw',
    process_id: processId,
    status,
    tx_hash:
      status === LISTINGS.TX.MESSAGE_STATUS.SUCCESS
        ? '94B41BC9CE70729D859C0CB5573ADEF02D027FC1B89281E62C50628BAEBE2ECB'
        : undefined,
    tx_timestamp: '2024-02-14T08:08:00Z',
    tx_type: type,
    validator_address: 'did:com:valoper12hzcdgapfh9eyamd092mz0gp6gvl2reg0n2f0n',
  };
  return item;
};

const mockStakeUndelegate = ({
  msgType,
  processId = '87df0ab9-58b2-4e41-9b81-ebb41b54c768',
  msgStatus,
} = {}) => {
  const status = msgStatus ? msgStatus : getRandomly(MSG_STATUS);
  const type = msgType ? msgType : getRandomly(MSG_TYPES);
  const item = {
    amount: {
      amount: '1000000',
      denom: 'ucommercio',
    },

    back_url: 'http://example.com/callback',
    chain_id: 'commercio-net',
    completion_time:
      type === STAKE.MSG_TYPE.UNDELEGATE ? '2024-03-06T08:08:00Z' : undefined,
    created_at: '2024-02-14T08:08:00Z',
    delegator_address: 'did:com:1svkc84z3dxzrdtddwt58ycme69jtlm5yyvemgw',
    process_id: processId,
    status,
    tx_hash:
      status === LISTINGS.TX.MESSAGE_STATUS.SUCCESS
        ? '94B41BC9CE70729D859C0CB5573ADEF02D027FC1B89281E62C50628BAEBE2ECB'
        : undefined,
    tx_timestamp: '2024-02-14T08:08:00Z',
    tx_type: type,
    validator_address: 'did:com:valoper12hzcdgapfh9eyamd092mz0gp6gvl2reg0n2f0n',
  };
  return item;
};

export {
  mockStakeDelegate,
  mockStakeItem,
  mockStakeList,
  mockStakeRewards,
  mockStakeSummary,
  mockStakeUndelegate,
};
