import { APIS } from '@/constants';
import axios from 'axios';
import apisHelper from './helpers/apisHelper';

const sharedoc = {
  /**
   *
   * @param {String} token
   * @param {Number} limit
   * @param {String} cursor
   * @param {String} order
   * @param {String} dossierId
   * @returns {AxiosPromise}
   */
  requestList({ token, limit, cursor, order, dossierId }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      limit,
      cursor,
      order,
      dossier_id: dossierId,
    });
    return axios({
      url: APIS.SHAREDOC,
      method: 'get',
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} id
   * @returns {AxiosPromise}
   */
  requestDetail(token, id) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${id}`,
      method: 'get',
      baseURL: APIS.SHAREDOC,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestCreate(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: APIS.SHAREDOC,
      method: 'post',
      headers,
      data: data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Number} limit
   * @param {String} cursor
   * @param {String} order
   * @returns {AxiosPromise}
   */
  requestReceivedList({ token, limit, cursor, order }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      limit,
      cursor,
      order,
    });
    return axios({
      url: '/received',
      method: 'get',
      baseURL: APIS.SHAREDOC,
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestReceivedCacheStatus({ token }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/received/cache',
      method: 'get',
      baseURL: APIS.SHAREDOC,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestReceivedCacheRefresh({ token }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/received/cache/refresh',
      method: 'post',
      baseURL: APIS.SHAREDOC,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} documentId
   * @returns {AxiosPromise}
   */
  requestReceivedDetail(token, documentId) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/received/${documentId}`,
      method: 'get',
      baseURL: APIS.SHAREDOC,
      headers,
    });
  },
};

export default sharedoc;
