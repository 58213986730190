import { swapApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initSwapWizard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchConfig'),
      dispatch('account/fetchIdentity', null, { root: true }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchConfig({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await swapApi.requestConfig(token);
      commit('setConfig', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Number} amount
   * @param {String} fromTokenId
   * @returns {Promise}
   */
  async getExchangePreview({ commit, rootGetters }, { amount, fromTokenId }) {
    const token = rootGetters['auth/token'];
    const data = {
      amount,
      token_from_id: fromTokenId,
      is_preview: true,
    };
    commit('setFetching', true);
    try {
      const response = await swapApi.requestSwap(token, data);
      return response.data.amount;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setFetching', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Number} amount
   * @param {String} fromTokenId
   */
  async swapTokens({ commit, rootGetters }, { amount, fromTokenId }) {
    const token = rootGetters['auth/token'];
    const data = {
      amount,
      token_from_id: fromTokenId,
      is_preview: false,
    };
    commit('setHandling', true);
    try {
      const response = await swapApi.requestSwap(token, data);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setHandling', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} processId
   */
  async initSwapSummary({ commit, dispatch }, processId) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchSummary', processId)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} processId
   */
  async fetchSummary({ commit, rootGetters }, processId) {
    const token = rootGetters['auth/token'];
    try {
      const response = await swapApi.requestSummary({ token, processId });
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
