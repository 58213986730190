import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setFetching(state, payload) {
    state.isFetching = payload;
  },
  setHandling(state, payload) {
    state.isHandling = payload;
  },
  setConfig(state, payload) {
    state.config = payload;
  },
  setDetail(state, payload) {
    state.detail = payload;
  },
  setSummary(state, payload) {
    state.summary = payload;
  },
};
