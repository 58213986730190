export const titles = {
  addRecipient: 'Add recipient',
  addRecipients: 'Add recipients',
  agreementAdditionalNodeTerms: 'DISTRIBUTOR NODE AGREEMENT Additional Terms',
  agreementAdditionalTerms: 'Additional Terms',
  agreementBuyService: 'Agreement for the purchase of technology goods and services ("Agreement") General terms and conditions',
  agreementNodeDistributor: 'DISTRIBUTOR NODE AGREEMENT (hereinafter "Agreement") General terms and conditions Version nr. 1 / publication date 15 April 2024',
  agreements: 'Agreements',
  allCredentials: 'All credentials',
  allYourBackup: 'All your eArchives',
  archive: 'Archive',
  archiveDocument: 'Secure archiving of your document',
  attributes: 'Attributes',
  availables: 'Availables',
  basicData: 'Basic data',
  bin: 'Bin',
  buyCcc: 'Buy CCC',
  certificateBlockchain: 'Certificate of Blockchain notarization and eArchive',
  code: 'Code',
  confirmRequest: 'Confirm request',
  contacts: 'Contacts',
  copyright: 'Copyright',
  credentialDetail: 'Credential detail',
  credentials: 'Credentials',
  defaultAttributes: 'Default attributes',
  delegate: 'Delegate #{0}',
  delegations: 'Delegations',
  deliverDocument: 'Electronic deliver your document',
  delivery: 'eDelivery',
  details: 'Details',
  documents: 'Documents',
  dossiers: 'Dossiers',
  dossiersList: 'Dossiers list',
  eArchive: 'eArchive',
  eArchiveDetail: 'eArchive #{0}',
  eDeliveries: 'eDeliveries',
  eDelivery: 'eDelivery',
  eDeliveryDetail: 'eDelivery #{0}',
  eIdWallet: 'eID Wallet',
  eSign: 'eSign',
  earn: 'Earn',
  editContact: 'Edit contact',
  editDossier: 'Edit dossier',
  file: 'File',
  folders: 'Folders',
  invite: 'Invite',
  inviteContact: 'Invite a contact',
  invoicingData: 'Invoicing data',
  joinTheFirst: 'Join the first',
  linkDossier: 'Link dossier',
  logo: 'Logo',
  manageFolders: 'Manage folders',
  manageGroups: 'Manage groups',
  manageTags: 'Manage tags',
  me: 'Me',
  memberDashboard: 'Member dashboard',
  message: 'Message',
  metadata: 'Metadata',
  mint: 'Mint',
  mintNft: 'Mint NFT',
  myContacts: 'My contacts',
  myNft: 'My NFT',
  newArchive: 'New archive',
  newContact: 'New contact',
  newDossier: 'New dossier',
  newEArchive: 'New eArchive',
  newEDelivery: 'New eDelivery',
  newNftCollection: 'New NFT collection',
  newSharedoc: 'New sharedoc',
  nft: 'NFT',
  nftAttribute: 'NFT attribute',
  nftCollection: 'NFT collection #{0}',
  nftCollections: 'NFT collections',
  nftDetail: 'NFT #{0}',
  notarize: 'Notarize',
  parameters: 'Parameters',
  preview: 'Preview',
  primaryColor: 'Primary color',
  proof: 'Proof',
  protected: 'Protected',
  purchase: 'Purchase',
  purchaseMembership: 'Purchase annual membership',
  recap: 'Recap',
  receipt: 'Receipt',
  received: 'Received',
  receivedDetail: 'Received #{0}',
  recipient: 'Recipient',
  reward: 'Reward #{0}',
  rewards: 'Rewards',
  selectDocument: 'Select a document',
  selectType: 'Select the type',
  sendCredits: 'Send credits',
  sent: 'Sent',
  settings: 'Settings',
  sharedoc: 'Sharedoc',
  sharedocSendNow:'Send a Sharedoc',
  sharedocs:'Sharedocs',
  signed: 'Signed',
  specifications: 'Specifications',
  stake: 'Stake',
  stakeDetail: 'Stake #{0}',
  summary: 'Summary',
  surcharge: 'Surcharge',
  swap: 'Swap',
  swapSummary: 'Swap summary',
  tags: 'Tags',
  theme: 'Theme',
  timestampFile: 'Timestamp file',
  toSigned: 'To sign',
  tokens: 'Tokens',
  tokensReceived: 'Tokens received',
  tokensSent: 'Tokens sent',
  transferNft: 'Transfer NFT',
  unbondings: 'Unbondings',
  undelegate: 'Undelegate #{0}',
  upgrade: 'Upgrade',
  visibility: 'Visibility',
  whatCanDo: 'What can i do with commercio.network membership?',
  yourInviteCode: 'Your Invite Code',
  yourNft: 'Your NFT',
  yourWallet: 'Your Wallet',
};
