const PATH = Object.freeze({
  ROOT: '/',
  NOT_FOUND: '/:notFound(.*)',
  AGREEMENTS: '/agreements',
  AGREEMENTS_DASHBOARD: 'dasboard',
  AGREEMENTS_DETAIL: 'detail/:id',
  CONTACTS: '/contacts',
  CONTACTS_DASHBOARD: 'dashboard',
  CONTACTS_MANAGEMENT: 'management/:id?',
  DOSSIERS: '/dossiers',
  DOSSIERS_DASHBOARD: 'dashboard',
  DOSSIERS_MANAGEMENT: 'management/:id?',
  EARCHIVE: '/earchive',
  EARCHIVE_DASHBOARD: 'dashboard',
  EARCHIVE_DETAIL: 'detail/:id',
  EARCHIVE_WIZARD: 'wizard',
  EARCHIVE_WIZARD_CONFIRM: 'confirm',
  EARCHIVE_WIZARD_HOW: 'how',
  EARCHIVE_WIZARD_INTRO: 'intro',
  EARCHIVE_WIZARD_WHAT: 'what',
  EARN: '/earn',
  EDELIVERY: '/edelivery',
  EDELIVERY_CONFIRM: 'confirm',
  EDELIVERY_HOW: 'how',
  EDELIVERY_WHAT: 'what',
  EDELIVERY_WHO: 'who',
  EDELIVERY_WIZARD: 'wizard',
  EIDWALLET: '/eid-wallet',
  EIDWALLET_CREDENTIALS_DETAIL: 'credentials-detail/:id',
  EIDWALLET_CREDENTIALS_LIST: 'credentials-list',
  EIDWALLET_GROUPS: 'groups',
  EIDWALLET_INVITE: 'invite',
  EIDWALLET_TOKENS: 'tokens',
  EIDWALLET_TOKENS_BUY: 'buy',
  EIDWALLET_TOKENS_BUY_CHOICE: 'choice',
  EIDWALLET_TOKENS_BUY_STATUS: 'status',
  EIDWALLET_TOKENS_DASHBOARD: 'dashboard',
  EIDWALLET_TOKENS_SEND: 'send',
  GIFT: '/gift/:referral',
  INTRO: '/intro',
  MINT: '/mint',
  MINT_DASHBOARD: 'dashboard',
  MINT_NEW: 'new',
  MINT_NFT: 'nft/:id',
  MINT_WIZARD: 'wizard/:id',
  MINT_WIZARD_ATTRIBUTES: 'attributes',
  MINT_WIZARD_BASIC_DATA: 'basic-data',
  MINT_WIZARD_METADATA: 'metadata',
  MINT_WIZARD_RECAP: 'recap',
  NFT: '/nft',
  NFT_DASHBOARD: 'dashboard',
  NFT_DETAIL: 'detail/:id',
  NFT_TRANSFER_OUTCOME: 'transfer-outcome/:id',
  NFT_TRANSFER_SUBMIT: 'transfer-submit/:id',
  NOTARIZE: '/notarize',
  NOTARIZE_ASSIGNMENT: 'assignment/:id',
  NOTARIZE_RECEIVED: 'received',
  NOTARIZE_RECEIVED_DASHBOARD: 'dashboard',
  NOTARIZE_RECEIVED_DETAIL: 'detail/:id',
  NOTARIZE_SENT: 'sent',
  NOTARIZE_SENT_DASHBOARD: 'dashboard/:dossier?',
  NOTARIZE_SENT_DETAIL: 'detail/:id',
  INVITE: '/invite',
  OUTPUT_CANCEL: '/output-cancel',
  OUTPUT_ERROR: '/output-error',
  OUTPUT_WAITING: '/output-waiting',
  PURCHASE: '/purchase',
  QUICK_DELIVERY: '/quick-delivery',
  QUICK_DELIVERY_WIZARD: 'wizard',
  SETTINGS: '/settings',
  SETTINGS_THEME: 'theme',
  SETTINGS_SURCHARGE: 'surcharge',
  STAKE: '/stake',
  STAKE_DASHBOARD: 'dashboard',
  STAKE_DELEGATE_DETAIL: 'delegate/:id',
  STAKE_REWARDS_DETAIL: 'rewards/:id',
  STAKE_UNDELEGATE_DETAIL: 'undelegate/:id',
  STAKE_WIZARD: 'wizard/:id',
  SWAP: '/swap',
  SWAP_SUMMARY: 'summary/:id',
  SWAP_WIZARD: 'wizard',
});

const NAME = Object.freeze({
  AGREEMENTS: 'agreements',
  AGREEMENTS_DASHBOARD: 'agreementsDasboard',
  AGREEMENTS_DETAIL: 'agreementsDetail',
  CONTACTS: 'contacts',
  CONTACTS_DASHBOARD: 'contactsDashboard',
  CONTACTS_MANAGEMENT: 'contactsManagement',
  DOSSIERS: 'dossiers',
  DOSSIERS_DASHBOARD: 'dossiersDashboard',
  EARCHIVE: 'earchive',
  EARCHIVE_DASHBOARD: 'earchiveDashboard',
  EARCHIVE_DETAIL: 'earchiveDetail',
  EARCHIVE_WIZARD: 'earchiveWizard',
  EARCHIVE_WIZARD_CONFIRM: 'earchiveWizardConfirm',
  EARCHIVE_WIZARD_HOW: 'earchiveWizardHow',
  EARCHIVE_WIZARD_INTRO: 'earchiveWizardIntro',
  EARCHIVE_WIZARD_WHAT: 'earchiveWizardWhat',
  DOSSIERS_MANAGEMENT: 'dossiersManagement',
  EARN: 'earn',
  EDELIVERY: 'edelivery',
  EDELIVERY_CONFIRM: 'edeliveryConfirm',
  EDELIVERY_HOW: 'edeliveryHow',
  EDELIVERY_WHAT: 'edeliveryWhat',
  EDELIVERY_WHO: 'edeliveryWho',
  EDELIVERY_WIZARD: 'edeliveryWizard',
  EIDWALLET: 'eidWallet',
  EIDWALLET_CREDENTIALS_DETAIL: 'eidWalletCredentialsDetail',
  EIDWALLET_CREDENTIALS_LIST: 'eidWalletCredentialsList',
  EIDWALLET_GROUPS: 'eidWalletGroups',
  EIDWALLET_INVITE: 'eidWalletInvite',
  EIDWALLET_TOKENS: 'eidWalletTokens',
  EIDWALLET_TOKENS_BUY: 'eidWalletTokensBuy',
  EIDWALLET_TOKENS_BUY_CHOICE: 'eidWalletTokensBuyChoice',
  EIDWALLET_TOKENS_BUY_STATUS: 'eidWalletTokensBuyStatus',
  EIDWALLET_TOKENS_DASHBOARD: 'eidWalletTokensDashboard',
  EIDWALLET_TOKENS_SEND: 'eidWalletTokensSend',
  GIFT: 'gift',
  INTRO: 'intro',
  MINT: 'mint',
  MINT_DASHBOARD: 'mintDashboard',
  MINT_NEW: 'mintNew',
  MINT_NFT: 'mintNft',
  MINT_WIZARD: 'mintWizard',
  MINT_WIZARD_ATTRIBUTES: 'mintWizardAttributes',
  MINT_WIZARD_BASIC_DATA: 'mintWizardBasicData',
  MINT_WIZARD_METADATA: 'mintWizardMetadata',
  MINT_WIZARD_RECAP: 'mintWizardRecap',
  NFT: 'nft',
  NFT_DASHBOARD: 'nftDashboard',
  NFT_DETAIL: 'nftDetail',
  NFT_TRANSFER_OUTCOME: 'nftTransferOutcome',
  NFT_TRANSFER_SUBMIT: 'nftTransferSubmit',
  NOTARIZE: 'notarize',
  NOTARIZE_ASSIGNMENT: 'notarizeAssignment',
  NOTARIZE_RECEIVED: 'notarizeReceived',
  NOTARIZE_RECEIVED_DASHBOARD: 'notarizeReceivedDashboard',
  NOTARIZE_RECEIVED_DETAIL: 'notarizeReceivedDetail',
  NOTARIZE_SENT: 'notarizeSent',
  NOTARIZE_SENT_DASHBOARD: 'notarizeSentDashboard',
  NOTARIZE_SENT_DETAIL: 'notarizeSentDetail',
  NOT_FOUND: 'notFound',
  OUTPUT_CANCEL: 'outputCancel',
  OUTPUT_ERROR: 'outputError',
  OUTPUT_WAITING: 'outputWaiting',
  PURCHASE: 'purchase',
  QUICK_DELIVERY: 'quickDelivery',
  QUICK_DELIVERY_WIZARD: 'quickDeliveryWizard',
  SETTINGS: 'settings',
  SETTINGS_THEME: 'settingsTheme',
  SETTINGS_SURCHARGE: 'settingsSurcharge',
  STAKE: 'stake',
  STAKE_DASHBOARD: 'stakeDashboard',
  STAKE_DELEGATE_DETAIL: 'stakeDelegateDetail',
  STAKE_REWARDS_DETAIL: 'stakeRewardsDetail',
  STAKE_UNDELEGATE_DETAIL: 'stakeUndelegateDetail',
  STAKE_WIZARD: 'stakeWizard',
  SWAP: 'swap',
  SWAP_SUMMARY: 'swapSummary',
  SWAP_WIZARD: 'swapWizard',
});

const TITLE = Object.freeze({
  AGREEMENTS: 'Agreements',
  AGREEMENTS_DASHBOARD: 'Agreements Dashboard',
  AGREEMENTS_DETAIL: 'Agreement Detail',
  CONTACTS: 'Contacts',
  CONTACTS_DASHBOARD: 'Contacts Dashboard',
  CONTACTS_MANAGEMENT: 'Contacts Management',
  DOSSIERS: 'Dossiers',
  DOSSIERS_DASHBOARD: 'Dossiers Dashboard',
  DOSSIERS_MANAGEMENT: 'Dossiers Management',
  EARCHIVE: 'Earchive',
  EARCHIVE_DASHBOARD: 'Earchive Dashboard',
  EARCHIVE_DETAIL: 'Earchive Detail',
  EARCHIVE_WIZARD: 'Earchive Wizard',
  EARCHIVE_WIZARD_CONFIRM: 'Earchive Wizard Confirm',
  EARCHIVE_WIZARD_HOW: 'Earchive Wizard How',
  EARCHIVE_WIZARD_INTRO: 'Earchive Wizard Intro',
  EARCHIVE_WIZARD_WHAT: 'Earchive Wizard What',
  EARN: 'Earn',
  EDELIVERY: 'Edelivery',
  EDELIVERY_CONFIRM: 'Edelivery Confirm',
  EDELIVERY_HOW: 'Edelivery How',
  EDELIVERY_WHAT: 'Edelivery What',
  EDELIVERY_WHO: 'Edelivery Who',
  EDELIVERY_WIZARD: 'Edelivery Wizard',
  EIDWALLET: 'EidWallet',
  EIDWALLET_CREDENTIALS_DETAIL: 'EidWallet Credentials Detail',
  EIDWALLET_CREDENTIALS_LIST: 'EidWallet Credentials List',
  EIDWALLET_GROUPS: 'EidWallet Groups',
  EIDWALLET_INVITE: 'EidWallet Invite',
  EIDWALLET_TOKENS: 'EidWallet Tokens',
  EIDWALLET_TOKENS_BUY: 'EidWallet Tokens Buy',
  EIDWALLET_TOKENS_BUY_CHOICE: 'EidWallet Tokens Buy Choice',
  EIDWALLET_TOKENS_BUY_STATUS: 'EidWallet Tokens Buy Status',
  EIDWALLET_TOKENS_DASHBOARD: 'EidWallet Tokens Dashboard',
  EIDWALLET_TOKENS_SEND: 'EidWallet Tokens Send',
  INTRO: 'Introduction',
  MINT: 'Mint',
  MINT_DASHBOARD: 'Mint Dashboard',
  MINT_NEW: 'Mint New',
  MINT_NFT: 'Mint Nft',
  MINT_WIZARD: 'Mint Wizard',
  MINT_WIZARD_ATTRIBUTES: 'Mint Wizard Attributes',
  MINT_WIZARD_BASIC_DATA: 'Mint Wizard Basic Data',
  MINT_WIZARD_METADATA: 'Mint Wizard Metadata',
  MINT_WIZARD_RECAP: 'Mint Wizard Recap',
  NFT: 'Nft',
  NFT_DASHBOARD: 'Nft Dashboard',
  NFT_DETAIL: 'Nft Detail',
  NFT_TRANSFER_OUTCOME: 'Nft Transfer Outcome',
  NFT_TRANSFER_SUBMIT: 'Nft Transfer Submit',
  NOTARIZE: 'Notarize',
  NOTARIZE_ASSIGNMENT: 'Notarize Assignment',
  NOTARIZE_RECEIVED: 'Notarize Received',
  NOTARIZE_RECEIVED_DASHBOARD: 'Notarize Received Dashboard',
  NOTARIZE_RECEIVED_DETAIL: 'Notarize Received Detail',
  NOTARIZE_SENT: 'Notarize Sent',
  NOTARIZE_SENT_DASHBOARD: 'Notarize Sent Dashboard',
  NOTARIZE_SENT_DETAIL: 'Notarize Sent Detail',
  NOT_FOUND: 'Not Found',
  OUTPUT_CANCEL: 'Cancel',
  OUTPUT_ERROR: 'Error',
  OUTPUT_WAITING: 'Waiting',
  PURCHASE: 'Purchase',
  QUICK_DELIVERY: 'Quick Delivery',
  QUICK_DELIVERY_WIZARD: 'Quick Delivery Wizard',
  SETTINGS: 'Settings',
  SETTINGS_THEME: 'Settings Theme',
  SETTINGS_SURCHARGE: 'Settings Surcharge',
  STAKE: 'Stake',
  STAKE_DASHBOARD: 'Stake Dashboard',
  STAKE_DELEGATE_DETAIL: 'Stake Delegate Detail',
  STAKE_REWARDS_DETAIL: 'Stake Rewards Detail',
  STAKE_UNDELEGATE_DETAIL: 'Stake Undelegate Detail',
  STAKE_WIZARD: 'Stake Wizard',
  SWAP: 'Swap',
  SWAP_SUMMARY: 'Swap Summary',
  SWAP_WIZARD: 'Swap Wizard',
});

export default Object.freeze({
  NAME,
  PATH,
  TITLE,
});
