import { productsApi, stripeApi } from '@/apis';
import { PRODUCTS, SETTINGS, ROUTES } from '@/constants';
import { xLocale } from '@/locale';
import { loadStripe } from '@stripe/stripe-js';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initTokensBuy({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchTokens')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchTokens({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await productsApi.requestList({
        token,
        locale: xLocale,
        category: PRODUCTS.CATEGORY.PLATFORM_CREDITS,
      });
      commit('setTokens', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Number} quantity
   * @param {String} product_id
   */
  async buyTokens({ commit, dispatch }, { quantity, product_id }) {
    const basePath = `${SETTINGS.HOST}${ROUTES.PATH.EIDWALLET}/${ROUTES.PATH.EIDWALLET_TOKENS}/${ROUTES.PATH.EIDWALLET_TOKENS_BUY}`;
    const data = {
      cancel_url: `${basePath}/${ROUTES.PATH.EIDWALLET_TOKENS_BUY_CHOICE}`,
      product_id,
      quantity,
      success_url: `${basePath}/${ROUTES.PATH.EIDWALLET_TOKENS_BUY_STATUS}`,
    };
    commit('setSending', true);
    const sessionId = await dispatch('getStripeSessionId', data);
    commit('setSending', false);
    await dispatch('redirectToCheckout', sessionId);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async getStripeSessionId({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stripeApi.requestCreateSession({
        locale: xLocale,
        token,
        data,
      });
      return response.data.session_id;
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {String} sessionId
   */
  async redirectToCheckout({ commit }, sessionId) {
    if (!process.env.VUE_APP_STRIPE_PUBLIC_KEY) return;
    try {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId: sessionId });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} sessionId
   */
  async fetchPayment({ commit, dispatch }, sessionId) {
    commit('setLoading', true);
    await dispatch('getPayment', sessionId);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} sessionId
   */
  async getPayment({ commit, rootGetters }, sessionId) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stripeApi.requestPaymentStatus({
        token,
        sessionId,
      });
      commit('setPayment', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
