import { creditsApi } from '@/apis';
import { LISTINGS } from '@/constants';

export default {
  /**
   *
   * @param {Function} dispatch
   * @param {String} filter
   */
  async initTokensDashboard({ dispatch }, filter) {
    const requests = [
      dispatch('fetchTokensDashboardData', filter),
      dispatch('header/initHeader', null, { root: true }),
    ];
    await Promise.all(requests);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} filter
   */
  async fetchTokensDashboardData({ commit, dispatch }, filter) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchAllContacts'),
      dispatch('getCredits', { filter }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   */
  async fetchAllContacts({ commit, dispatch, rootGetters }) {
    commit('contacts/reset', null, { root: true });
    do {
      await dispatch(
        'contacts/fetchContactsList',
        { cursor: rootGetters['contacts/cursor'] },
        { root: true },
      );
    } while (rootGetters['contacts/cursor']);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   * @param {String} filter
   * @param {String} order
   */
  async getCredits(
    { commit, rootGetters },
    { cursor, filter, order = LISTINGS.SORTINGS.DESC } = {},
  ) {
    const token = rootGetters['auth/token'];
    const request = setRequest(filter);
    try {
      const response = await creditsApi[request]({
        token,
        cursor,
        order,
      });
      commit('addProcesses', response.data.processes);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} filter
   */
  async addCredits({ commit, dispatch }, { cursor, filter }) {
    commit('setAdding', true);
    await dispatch('getCredits', { cursor, filter });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} query
   */
  async fetchContacts({ commit, dispatch }, query = '') {
    commit('contacts/reset', null, { root: true });
    commit('setLoading', true);
    await dispatch('contacts/fetchContactsList', { query }, { root: true });
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} query
   */
  async addContacts({ commit, dispatch }, { cursor = '', query = '' } = {}) {
    commit('setLoading', true);
    await dispatch(
      'contacts/fetchContactsList',
      { cursor, query },
      { root: true },
    );
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   * @returns {Promise<String>}
   */
  async sendCredits({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setSending', true);
    try {
      const response = await creditsApi.requestSend(token, data);
      return response.data.send_credits_id;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setSending', false);
    }
  },
};

const setRequest = (filter) => {
  switch (filter) {
    case LISTINGS.TX.TYPE.RECEIVED:
      return 'requestReceivedList';
    case LISTINGS.TX.TYPE.SENT:
      return 'requestSentList';
    default:
      return 'requestList';
  }
};
