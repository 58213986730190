import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const StakeViewDashboard = loadView('stake/StakeViewDashboard');
const StakeViewDelegateDetail = loadView('stake/StakeViewDelegateDetail');
const StakeViewRewardsDetail = loadView('stake/StakeViewRewardsDetail');
const StakeViewUndelegateDetail = loadView('stake/StakeViewUndelegateDetail');
const StakeViewWizard = loadView('stake/StakeViewWizard');

export const stakeRoute = {
  path: ROUTES.PATH.STAKE,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.STAKE,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.STAKE,
      component: StakeViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.STAKE_DASHBOARD,
      name: ROUTES.NAME.STAKE_DASHBOARD,
      component: StakeViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.STAKE_DASHBOARD,
      },
    },
    {
      path: ROUTES.PATH.STAKE_DELEGATE_DETAIL,
      name: ROUTES.NAME.STAKE_DELEGATE_DETAIL,
      component: StakeViewDelegateDetail,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.STAKE_DELEGATE_DETAIL,
      },
    },
    {
      path: ROUTES.PATH.STAKE_REWARDS_DETAIL,
      name: ROUTES.NAME.STAKE_REWARDS_DETAIL,
      component: StakeViewRewardsDetail,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.STAKE_REWARDS_DETAIL,
      },
    },
    {
      path: ROUTES.PATH.STAKE_UNDELEGATE_DETAIL,
      name: ROUTES.NAME.STAKE_UNDELEGATE_DETAIL,
      component: StakeViewUndelegateDetail,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.STAKE_UNDELEGATE_DETAIL,
      },
    },
    {
      path: ROUTES.PATH.STAKE_WIZARD,
      name: ROUTES.NAME.STAKE_WIZARD,
      component: StakeViewWizard,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.STAKE_WIZARD,
      },
    },
  ],
};

export default stakeRoute;
