export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isAlreadyPresent: (state) => state.isAlreadyPresent,
  isStarted: (state) => state.isStarted,
  hasFunds: (state) => state.hasFunds,
  file: (state) => state.file,
  metadata: (state) => state.metadata,
  recipients: (state) => state.recipients,
  vault: (state) => state.vault,
  where: (state) => state.where,
};
