import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const DossiersViewDashboard = loadView('dossiers/DossiersViewDashboard');
const DossiersViewManagement = loadView('dossiers/DossiersViewManagement');

export const dossiersRoute = {
  path: ROUTES.PATH.DOSSIERS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.DOSSIERS,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.DOSSIERS,
      component: DossiersViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.DOSSIERS_DASHBOARD,
      name: ROUTES.NAME.DOSSIERS_DASHBOARD,
      component: DossiersViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.DOSSIERS_DASHBOARD,
      },
    },
    {
      path: ROUTES.PATH.DOSSIERS_MANAGEMENT,
      name: ROUTES.NAME.DOSSIERS_MANAGEMENT,
      component: DossiersViewManagement,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.DOSSIERS_MANAGEMENT,
      },
    },
  ],
};
