<template>
  <v-btn
    :color="color"
    :depressed="hasLabel"
    :icon="!hasLabel"
    @click.stop="onClick"
  >
    <span v-if="hasLabel" class="text-caption" v-text="$t('labels.share')" />
    <v-icon :right="hasLabel">{{ mdiShareVariantOutline }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiShareVariantOutline } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';
import { ROUTES } from '@/constants';
import { featureActivator } from '@/utils';

export default {
  name: 'BaseBtnShareFromVault',
  props: {
    vault: {
      type: Object,
      required: true,
      note: 'The item to share',
    },
    color: {
      type: String,
      default: 'primary',
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    txs: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      mdiShareVariantOutline,
    };
  },
  computed: {
    ...mapGetters('account', ['identity']),
    ...mapGetters('application', ['surcharge']),
  },
  methods: {
    ...mapActions('edelivery', ['shareDocument']),
    onClick() {
      const hasFunds = featureActivator.hasFunds({
        identity: this.identity,
        surcharge: this.surcharge,
        txsAmount: this.txs,
      });
      this.shareDocument({ document: this.vault, hasFunds });
      this.$router.push({
        name: ROUTES.NAME.EDELIVERY_WHO,
      });
    },
  },
};
</script>
