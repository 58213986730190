import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const EidWalletRouterTokens = loadView('eid-wallet/EidWalletRouterTokens');
const EidWalletRouterTokensBuy = loadView(
  'eid-wallet/EidWalletRouterTokensBuy',
);
const EidWalletViewCredential = loadView('eid-wallet/EidWalletViewCredential');
const EidWalletViewCredentials = loadView(
  'eid-wallet/EidWalletViewCredentials',
);
const EidWalletViewInvite = loadView('eid-wallet/EidWalletViewInvite');
const EidWalletViewTokensBuyChoice = loadView(
  'eid-wallet/EidWalletViewTokensBuyChoice',
);
const EidWalletViewTokensBuyStatus = loadView(
  'eid-wallet/EidWalletViewTokensBuyStatus',
);
const EidWalletViewTokensDashboard = loadView(
  'eid-wallet/EidWalletViewTokensDashboard',
);
const EidWalletViewTokensSend = loadView('eid-wallet/EidWalletViewTokensSend');

export const eidWalletRoute = {
  path: ROUTES.PATH.EIDWALLET,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.EIDWALLET,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.EIDWALLET,
      component: EidWalletViewInvite,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.EIDWALLET_CREDENTIALS_DETAIL,
      name: ROUTES.NAME.EIDWALLET_CREDENTIALS_DETAIL,
      component: EidWalletViewCredential,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EIDWALLET_CREDENTIALS_DETAIL,
      },
    },
    {
      path: ROUTES.PATH.EIDWALLET_CREDENTIALS_LIST,
      name: ROUTES.NAME.EIDWALLET_CREDENTIALS_LIST,
      component: EidWalletViewCredentials,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EIDWALLET_CREDENTIALS_LIST,
      },
    },
    {
      path: ROUTES.PATH.EIDWALLET_INVITE,
      name: ROUTES.NAME.EIDWALLET_INVITE,
      component: EidWalletViewInvite,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EIDWALLET_INVITE,
      },
    },
    {
      path: ROUTES.PATH.EIDWALLET_TOKENS,
      component: EidWalletRouterTokens,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.EIDWALLET_TOKENS,
      },
      children: [
        {
          path: '',
          name: ROUTES.NAME.EIDWALLET_TOKENS,
          component: EidWalletViewTokensDashboard,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
          },
        },
        {
          path: ROUTES.PATH.EIDWALLET_TOKENS_BUY,
          component: EidWalletRouterTokensBuy,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.EIDWALLET_TOKENS_BUY,
          },
          children: [
            {
              path: '',
              name: ROUTES.NAME.EIDWALLET_TOKENS_BUY,
              component: EidWalletViewTokensBuyChoice,
              meta: {
                auth: AUTH.USER_ROLE.AUTHORIZED,
                tags: [SUBSCRIPTION.STATUS.ACTIVE],
              },
            },
            {
              path: ROUTES.PATH.EIDWALLET_TOKENS_BUY_CHOICE,
              name: ROUTES.NAME.EIDWALLET_TOKENS_BUY_CHOICE,
              component: EidWalletViewTokensBuyChoice,
              meta: {
                auth: AUTH.USER_ROLE.AUTHORIZED,
                tags: [SUBSCRIPTION.STATUS.ACTIVE],
                title: ROUTES.TITLE.EIDWALLET_TOKENS_BUY_CHOICE,
              },
            },
            {
              path: ROUTES.PATH.EIDWALLET_TOKENS_BUY_STATUS,
              name: ROUTES.NAME.EIDWALLET_TOKENS_BUY_STATUS,
              component: EidWalletViewTokensBuyStatus,
              props: (route) => ({ sessionId: route.query.session_id }),
              meta: {
                auth: AUTH.USER_ROLE.AUTHORIZED,
                tags: [SUBSCRIPTION.STATUS.ACTIVE],
                title: ROUTES.TITLE.EIDWALLET_TOKENS_BUY_STATUS,
              },
            },
          ],
        },
        {
          path: ROUTES.PATH.EIDWALLET_TOKENS_DASHBOARD,
          name: ROUTES.NAME.EIDWALLET_TOKENS_DASHBOARD,
          component: EidWalletViewTokensDashboard,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.EIDWALLET_TOKENS_DASHBOARD,
          },
        },
        {
          path: ROUTES.PATH.EIDWALLET_TOKENS_SEND,
          name: ROUTES.NAME.EIDWALLET_TOKENS_SEND,
          component: EidWalletViewTokensSend,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.EIDWALLET_TOKENS_SEND,
          },
        },
      ],
    },
  ],
};
