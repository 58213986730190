export const labels = {
  add: 'Aggiungi',
  addRecipient: 'Aggiungi destinatario',
  address: 'Indirizzo',
  all: 'Tutti',
  amount: 'Importo',
  animationUrl: 'Animation URL',
  audit: 'Verifica',
  auditor: 'Revisore',
  available: 'Disponibile',
  back: 'Indietro',
  backgroundColor: 'Background color',
  buyCredits: 'Acquista crediti',
  buyNow: 'Acquista adesso',
  cancel: 'Annulla',
  claimRewards: 'Ritira ricompense',
  clear: 'Pulisci',
  close: 'Chiudi',
  collection: 'Collezione',
  color: 'Colore',
  companyName: 'Nome azienda',
  companyVat: 'Partita iva azienda',
  completionTime: 'Tempo di completamento',
  confirm: 'Conferma',
  contactEmail: 'Indirizzo email contatto',
  contactsList: 'Lista contatti',
  contentUri: 'Content URI',
  contract: 'Contratto',
  copy: 'Copia',
  copyright: 'Copyright',
  country: 'Nazione',
  createNfts: 'Crea NFT',
  date: 'Data',
  delegate: 'Delega',
  delete: 'Cancella',
  depositCredits: 'Deposita crediti',
  description: 'Descrizione',
  details: 'Dettagli',
  did: 'Did',
  didCom: 'did:com:',
  displayType: 'Tipo di visualizzazione',
  documentHash: 'Hash documento',
  documentId: 'ID documento',
  dossierLinkTo: 'Collega a fascicolo',
  dossierRemove: 'Rimuovi fascicolo',
  dossiersList: 'Lista fascicoli',
  download: 'Download',
  eArchivesList: 'Lista eArchives',
  eDeliverDocument: 'eDeliver documento',
  edit: 'Modifica',
  email: 'Email',
  expire: 'Scade',
  externalUrl: 'External URL',
  fee: 'Commissione',
  fileDescription: 'Descrizione del file',
  fileName: 'Nome del file',
  fileUpload: 'Carica file',
  from: 'Da',
  goToShare: 'Vai a condividere',
  hash: 'Hash',
  hashAlg: 'Algoritmo hash',
  how: 'Come',
  image: 'Immagine',
  imageData: 'Dati immagine',
  invite: 'Invita',
  inviteContacts: 'Invita contatti',
  invoicingEmail: 'Email fatturazione',
  label: 'Etichetta',
  lastname: 'Cognome',
  latestRefresh: 'Ultimo aggiornamento',
  legalRepresentativeCode: 'Codice fiscale legale rappresentante',
  legalRepresentativeEmail: 'Email legale rappresentante',
  legalRepresentativeFirstName: 'Nome legale rappresentante',
  legalRepresentativeLastName: 'Cognome legale rappresentante',
  legalRepresentativeMobile: 'Cellulare legale rappresentante',
  login: 'Accedi',
  manageEArchive: 'Gestisci eArchive',
  max: 'Max',
  me: 'Me',
  messageUuid: 'UUID messaggio',
  metadata: 'Metadata',
  min: 'Min',
  mint: 'Mint',
  minter: 'Minter',
  more: 'Di più',
  name: 'Nome',
  new:'Nuovo',
  newCollection: 'Nuova collezione',
  newContact: 'Nuovo contatto',
  newEArchive: 'Nuovo eArchive',
  next: 'Prossimo',
  nftCollections: 'Collezioni NFT',
  notarize: 'Notarizza',
  notarizeDelivery: 'Notarizza consegna',
  object: 'Oggetto',
  of: 'di',
  options: 'Opzioni',
  owner: 'Proprietario',
  paidAmount: 'Importo pagato',
  paidAt: 'Pagato il',
  paymentId: 'ID pagamento',
  pickAnImage: 'Scegli un\'immagine',
  prev: 'Precedente',
  processError: 'Errore di processo',
  processId: 'Process Id',
  productQuantity: 'Quantità prodotto',
  proof: 'Proof',
  protected: 'Protetto',
  quantity: 'Quantità',
  receipt: 'Ricevuta',
  receipts: 'Ricevute',
  recipient: 'Recipient',
  recipients: 'Destinatari',
  register: 'Registra',
  reloadApp: 'Ricarica l\'app',
  restart: 'Riavvia',
  save: 'Salva',
  scanTheAddress: 'Scansiona l\'indirizzo',
  schemaUri: 'Schema URI',
  schemaVersion: 'Versione schema',
  sdiCode: 'Codice SDI',
  search: 'Cerca',
  securedOn: 'In sicurezza da',
  select: 'Seleziona',
  send: 'Invia',
  sendCredits: 'Invia crediti',
  sendReceipt: 'Invia ricevuta',
  sender: 'Mittente',
  share: 'Condividi',
  shareDocument: 'Condividi un documento',
  signOut: 'Esci',
  start: 'Inizio',
  status: 'Status',
  swapNow: 'Scambia ora',
  symbol: 'Simbolo',
  tapToShare: 'Tap to share',
  timestampFile: 'Notarizza file',
  to: 'A',
  tokenCost: 'Costo CCC (Commercio Cash Credit)',
  tokenId: 'Token ID',
  tokenUri: 'Token URI',
  total: 'Totale',
  traitType: 'Tipo di caratteristica',
  transactionHash: 'Hash transazione',
  transfer: 'Trasferisci',
  transferFee: 'Commissione di trasferimento',
  tryAgain: 'Riprova',
  type: 'Tipo',
  undefined: 'Non definito',
  undelegate: 'Sblocca delega',
  unitProcessCost: 'Unit process cost',
  unknown: 'Sconosciuto',
  unprotected: 'Non protetto',
  upgradeYourMembership: 'Upgrade della membership',
  value: 'Valore',
  vat: 'IVA',
  verifiedOn: 'Verificato il',
  version: 'Versione',
  viewCertificate: 'Vedi certificato',
  what: 'Cosa',
  where: 'Dove',
  who: 'Chi',
  yes: 'Si',
  youtubeUrl: 'YouTube URL',
};
