import { FEATURES } from '@/constants';

export const features = {
  [FEATURES.ID.API]: {
    [FEATURES.KEY.TITLE]: 'Api',
    [FEATURES.KEY.MESSAGE]: 'Access to the programming interface for one year to integrate our available protocols with your software',
  },
  [FEATURES.ID.CREDITS]: {
    [FEATURES.KEY.TITLE]: 'Buy credits',
    [FEATURES.KEY.MESSAGE]: 'After KYC and AML to be used for network services',
  },
  [FEATURES.ID.EDELIVERY]: {
    [FEATURES.KEY.TITLE]: 'Commercio DOCS',
    [FEATURES.KEY.MESSAGE]: 'API service for document delivery (eDelivery) with all network members for one year',
  },
  [FEATURES.ID.EID]: {
    [FEATURES.KEY.TITLE]: 'Commercio ID',
    [FEATURES.KEY.MESSAGE]: 'API service for sovereign electronic identity (eID) with all Network members for one year',
  },
  [FEATURES.ID.ESIGNATURE]: {
    [FEATURES.KEY.TITLE]: 'Commercio SIGN',
    [FEATURES.KEY.MESSAGE]: 'API service for electronic signature (eSignature) with all network members for one year',
  },
  [FEATURES.ID.PROGRAMS]: {
    [FEATURES.KEY.TITLE]: 'Affiliates programme',
    [FEATURES.KEY.MESSAGE]: 'The three types of members allow companies to receive ABR Tokens by inviting other members',
  },
}