import { LISTINGS } from '@/constants';

const txHandler = {
  getStatusColor(status) {
    switch (status) {
      case LISTINGS.TX.MESSAGE_STATUS.ENQUEUED:
        return '#FB8C00';
      case LISTINGS.TX.MESSAGE_STATUS.FAILURE:
        return '#C62828';
      case LISTINGS.TX.MESSAGE_STATUS.PROCESSING:
        return '#FFA726';
      case LISTINGS.TX.MESSAGE_STATUS.SUCCESS:
        return '#43A047';
      default:
        return '';
    }
  },
  getTypeColor(type) {
    switch (type) {
      case LISTINGS.TX.TYPE.RECEIVED:
        return '#43A047';
      case LISTINGS.TX.TYPE.SENT:
        return '#C62828';
      default:
        return '';
    }
  },
};

export default txHandler;
