export const intro = {
  buttonRegister: 'Registrati Adesso',
  closeText: 'Naturalmente, è possibile passare da un piano gratuito ad un piano aziendale in qualsiasi momento',
  closeTitle: 'Registrati Gratis',
  faq01Answer: 'Le aziende possono aumentare la fiducia dei consumatori registrando eventi lungo l\'intero ciclo di vita dei prodotti, dalla produzione fino al riciclo o smaltimento. Questo consente ai consumatori di avere una visione trasparente sull\'autenticità e lo stato del prodotto che stanno acquistando, aumentando così la fiducia degli acquirenti e massimizzando la fidelizzazione.',
  faq01Question: 'Come possono le aziende aumentare la fiducia dei consumatori nei loro prodotti?',
  faq02Answer: 'I passaporti digitali possono verificare la sostenibilità di un prodotto convalidando e proteggendo su una rete blockchain immutabile gli attributi di sostenibilità e le affermazioni fatte dalle aziende. Questo permette alle imprese di ottimizzare la loro strategia circolare e diventare leader nella sostenibilità, rispondendo alle crescenti richieste dei clienti per pratiche più ecologiche.',
  faq02Question: 'In che modo i passaporti digitali possono verificare la sostenibilità di un prodotto?',
  faq03Answer: 'Le aziende possono proteggere i consumatori garantendo che le materie prime e i processi di produzione rispettino standard predefiniti. Questo protegge i clienti da materiali e prodotti finali illegittimi, pericolosi e di scarsa qualità, salvaguardando anche il marchio da problemi di pubbliche relazioni negativi come richiami di prodotto e incidenti causati da difetti.',
  faq03Question: 'Come possono le aziende proteggere i consumatori da materiali e prodotti di bassa qualità?',
  faq04Answer: 'I passaporti digitali aumentano la fiducia degli acquirenti consentendo loro di fare scelte d\'acquisto più informate riguardo alla performance di sostenibilità dei marchi. Poiché i consumatori iniziano a preferire sempre più i marchi che supportano le loro dichiarazioni ecologiche, i passaporti digitali sono uno strumento ideale.',
  faq04Question: 'In che modo i passaporti digitali aumentano la fiducia degli acquirenti?',
  faq05Answer: 'I passaporti digitali possono aiutare i consumatori a riconoscere l\'impronta di CO2 dei prodotti fornendo dati accurati sul consumo energetico e l\'impatto ambientale associati ai prodotti. Questo è particolarmente utile per i consumatori che cercano di migliorare le proprie performance sostenibili e ridurre la propria impronta di carbonio.',
  faq05Question: 'Come possono i passaporti digitali aiutare i consumatori a riconoscere l\'impronta CO2 dei prodotti?',
  faqTitle: 'Domande Frequenti',
  featureSlim01Description: 'Valuta accuratamente la sostenibilità e la riciclabilità dei prodotti insieme al loro impatto ambientale, condividendo affermazioni verificabili con clienti e regolatori.',
  featureSlim01Title: 'Sostenibilità Dimostrabile',
  featureSlim02Description: 'Registra eventi chiave lungo il ciclo di vita del prodotto, inclusi riparazioni, manutenzione e aggiornamenti, costruendo una storia del prodotto completamente trasparente accessibile da più stakeholder.',
  featureSlim02Title: 'Tracciamento del Ciclo di Vita',
  featureSlim03Description: 'Verifica la conformità alla normativa europea Ecodesign for Sustainable Products Regulation (ESPR) e dimostra che la tua organizzazione sta raggiungendo gli obiettivi di circolarità.',
  featureSlim03Title: 'Conformità Regolamentare',
  featureSlim04Description: 'Permetti ai clienti di tracciare l\'origine e la fonte delle materie prime e delle risorse utilizzate nel processo di produzione, migliorando la fiducia del consumatore.',
  featureSlim04Title: 'Tracciare Provenienza',
  featureSlim05Description: 'I certificati di garanzia NFT legati un prodotto fisico consentono ai clienti di verificare l\'autenticità del loro prodotto attraverso il DPP associato, combattendo i falsi illegali.',
  featureSlim05Title: 'Combattere i Falsi',
  featureSlim06Description: 'Offri estensioni della garanzia in modo completaemente automatizzato e pro-attivo , migliorando  sensibilmente la gestione delle garanzie e aumentando i ricavi post vendita.',
  featureSlim06Title: 'Ricavi Post Vendita',
  featureWide01Description: ' I passaporti digitali facilitano il miglioramento della riciclabilità dei prodotti e dei processi di fine vita.<br>Dimostra la conformità alle linee guida di sostenibilità dell\'UE e alla nuova legislazione ESPR.<br>Cattura e registra i dati sulla sostenibilità del prodotto per verificare l\'impatto ambientale.<br>Monitora e registra eventi significativi lungo tutto il ciclo di vita per costruire una storia accurata del prodotto.',
  featureWide01Subtitle: 'Riciclabilità dei prodotti e dei processi di fine vita.',
  featureWide01Title: 'Sostenibilità e Circolarità dei Prodotti',
  featureWide02Description: 'Migliora l\'accuratezza dei dati e aumenta la trasparenza lungo tutta la catena di approvvigionamento.<br>Traccia l\'origine delle materie prime e delle risorse per illustrare accuratamente la prova di origine.<br>Verifica facilmente l\'autenticità del prodotto e combatti efficacemente il mercato dei falsi.',
  featureWide02Subtitle: 'Supply chain più efficiente, accurata e trasparente.',
  featureWide02Title: 'Tracciabilità della Catena di Approvvigionamento',
  heroDescription: 'Il Passaporto Digitale di Prodotto, grazie alla blockchain e all\'emissione di un certificato NFT, consente alle aziende di rispettare gli standard dell\'UE sulla sostenibilità dei prodotti, migliorare la tracciabilità della catena di fornitura e migliorare i ricavi post vendita con l\'estensione della garanzia.',
  heroTitle: 'Crea il Passaporto Digitale del tuo Prodotto',
  witness01City: 'Padova',
  witness01Name: 'Giovanni',
  witness01Text: 'Il Passaporto Digitale ci ha aumentato  la trasparenza e la tracciabilità della supply chain.',
  witness02City: 'Roma',
  witness02Name: 'Paola',
  witness02Text: 'Utilizzare i Passaporti Digitali dei Prodotti ha rivoluzionato il nostro approccio alla sostenibilità.',
  witness03City: 'Trieste',
  witness03Name: 'Marco',
  witness03Text: 'I Passaporti Digitali dei Prodotti hanno trasformato la nostra capacità di coinvolgere i clienti.',
  witnessTitle: 'Testimonianze utenti',
}