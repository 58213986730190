import { APIS } from '@/constants';
import axios from 'axios';
import apisHelper from './helpers/apisHelper';

const credits = {
  /**
   *
   * @param {String} token
   * @param {Number} limit
   * @param {String} cursor
   * @param {String} order
   * @returns {AxiosPromise}
   */
  requestList({ token, limit, cursor, order }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      limit,
      cursor,
      order,
    });
    return axios({
      method: 'get',
      baseURL: APIS.CREDITS,
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestSend(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'post',
      baseURL: APIS.CREDITS,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} cursor
   * @param {Number} limit
   * @param {String} order
   * @returns {AxiosPromise}
   */
  requestReceivedList({ token, cursor, limit, order }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      limit,
      cursor,
      order,
    });
    return axios({
      url: '/received',
      method: 'get',
      baseURL: APIS.CREDITS,
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} cursor
   * @param {Number} limit
   * @param {String} order
   * @returns {AxiosPromise}
   */
  requestSentList({ token, cursor, limit, order }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      limit,
      cursor,
      order,
    });
    return axios({
      url: '/sent',
      method: 'get',
      baseURL: APIS.CREDITS,
      headers,
      params,
    });
  },
};

export default credits;
