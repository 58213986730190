import { APIS } from '@/constants';
import axios from 'axios';
import apisHelper from './helpers/apisHelper';

const contactApi = {
  /**
   *
   * @param {String} token
   * @param {Number} limit
   * @param {String} cursor
   * @param {String} order_by
   * @param {String} query
   * @returns {AxiosPromise}
   */
  requestList({ token, limit, cursor, order_by, query }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      limit,
      cursor,
      order_by,
      query,
    });
    return axios({
      method: 'get',
      baseURL: APIS.CONTACT,
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestCreate(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'post',
      baseURL: APIS.CONTACT,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} id
   * @returns {AxiosPromise}
   */
  requestDetail(token, id) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${id}`,
      method: 'get',
      baseURL: APIS.CONTACT,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} id
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestUpdate({ token, id, data }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${id}`,
      method: 'patch',
      baseURL: APIS.CONTACT,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} id
   * @returns {AxiosPromise}
   */
  requestRemove(token, id) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${id}`,
      method: 'delete',
      baseURL: APIS.CONTACT,
      headers,
    });
  },
};

export default contactApi;
