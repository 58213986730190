import { vaultApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Number} name
   * @param {String} order
   */
  async initEarchiveDashboard({ commit, dispatch }, { name, order } = {}) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchUserinfo'),
      dispatch('fetchVaultsList', { name, order }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Number} name
   * @param {String} cursor
   * @param {String} order
   */
  async addVaults({ commit, dispatch }, { name, cursor, order } = {}) {
    commit('setAdding', true);
    await dispatch('fetchVaultsList', { name, cursor, order });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} name
   * @param {Number} limit
   * @param {String} cursor
   * @param {String} order
   */
  async fetchVaultsList(
    { commit, rootGetters },
    { name, limit, cursor, order } = {},
  ) {
    const token = rootGetters['auth/token'];
    try {
      const response = await vaultApi.requestList({
        token,
        name,
        limit,
        cursor,
        order,
      });
      commit('addVaults', response.data.vault_infos);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchUserinfo({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await vaultApi.requestUserinfo(token);
      commit('setInfo', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} vaultDocumentUUID
   */
  async initEarchiveDetail({ commit, dispatch }, vaultDocumentUUID) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchVaultDetail', vaultDocumentUUID)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} vaultDocumentUUID
   */
  async fetchVaultDetail({ commit, rootGetters }, vaultDocumentUUID) {
    const token = rootGetters['auth/token'];
    try {
      const response = await vaultApi.requestDetail(token, vaultDocumentUUID);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  initEarchiveWizard({ commit }) {
    commit('reset');
    commit('setIsStarted', true);
    return true;
  },
  /**
   *
   * @param {Function} commit
   * @param {File} file
   */
  setFile({ commit }, file) {
    commit('setMetadata', null);
    commit('setFile', file);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} metadata
   */
  setMetadata({ commit }, metadata) {
    commit('addMetadata', metadata);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} getters
   * @param {Object} rootGetters
   * @returns {Promise}
   */
  async deliverNewVault({ commit, getters, rootGetters }) {
    const file = getters['file'];
    const metadata = getters['metadata'];
    const data = {
      document: file,
      name: metadata.name,
      description: metadata.description || undefined,
      content_url: metadata.contentUri,
      schema_uri: metadata.schemaUri,
      schema_version: metadata.schemaVersion,
    };
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await vaultApi.requestCreate(token, data);
      return response.data.document_id;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
};
