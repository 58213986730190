<template>
  <v-row class="py-3 align-end">
    <v-col cols="12" :lg="boxesLgCols">
      <slot name="boxes" />
    </v-col>
    <v-col cols="12" :lg="buttonsLgCols">
      <v-row class="px-2">
        <v-col cols="12" class="d-flex flex-column">
          <slot name="buttons" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheDashboardHeader',
  props: {
    boxesLgCols: {
      type: String,
      default: '9',
      note: 'Number of columns assigned to boxes when breakpoint is lg',
    },
    buttonsLgCols: {
      type: String,
      default: '3',
      note: 'Number of columns assigned to buttons when breakpoint is lg',
    },
  },
};
</script>
