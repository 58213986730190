import { APIS } from '@/constants';
import axios from 'axios';

const info = {
  /**
   *
   * @returns {AxiosPromise}
   */
  requestInfo() {
    return axios({
      url: APIS.INFO,
      method: 'get',
    });
  },
};

export default info;
