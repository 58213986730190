<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="d-flex align-center">
        <span class="font-weight-bold" v-text="label" />
        <span>:</span>
        <a
          class="pl-1 word-break"
          :style="look"
          :href="link"
          target="_blank"
          v-text="content"
        />
        <span class="pl-1">
          <slot />
        </span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'BaseListLink',
  props: {
    label: {
      type: String,
      required: true,
      note: 'The item label',
    },
    link: {
      type: String,
      required: true,
      note: 'The link value',
    },
    content: {
      required: true,
      note: 'The item content',
    },
    look: {
      type: String,
      default: '',
      note: 'The datum custom style',
    },
  },
};
</script>

<style scoped>
.word-break {
  white-space: normal;
  word-break: break-all;
}
</style>
