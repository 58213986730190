import Vue from 'vue';
import App from './App.vue';
import i18n from './locale';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseBox from '@/components/BaseBox.vue';
import BaseBtnDownloadFromVault from '@/components/BaseBtnDownloadFromVault.vue';
import BaseBtnShareFromVault from '@/components/BaseBtnShareFromVault.vue';
import BaseColorPicker from '@/components/BaseColorPicker.vue';
import BaseCopyToClipboard from '@/components/BaseCopyToClipboard.vue';
import BaseDragArea from '@/components/BaseDragArea.vue';
import BaseGridCard from '@/components/BaseGridCard.vue';
import BaseListCard from '@/components/BaseListCard.vue';
import BaseListItem from '@/components/BaseListItem.vue';
import BaseListLink from '@/components/BaseListLink.vue';
import BaseListRouterLink from '@/components/BaseListRouterLink.vue';
import BaseListRow from '@/components/BaseListRow.vue';
import BaseLoadingCircular from '@/components/BaseLoadingCircular.vue';
import BaseLoadingLinear from '@/components/BaseLoadingLinear.vue';
import BaseModalConfirmAction from '@/components/modals/BaseModalConfirmAction.vue';
import BaseModalQRCode from '@/components/modals/BaseModalQRCode.vue';
import BaseSmallWindow from '@/components/BaseSmallWindow.vue';
import BaseSnackbar from '@/components/BaseSnackbar.vue';
import TheAlertNotice from '@/components/TheAlertNotice.vue';
import TheBalanceBox from '@/components/TheBalanceBox.vue';
import TheDashboardHeader from '@/components/TheDashboardHeader.vue';
import TheDashboardHeaderBoxes from '@/components/TheDashboardHeaderBoxes.vue';
import TheErrorMessage from '@/components/TheErrorMessage.vue';
import TheFormAmount from '@/components/form-components/TheFormAmount';
import TheFormContentUri from '@/components/form-components/TheFormContentUri';
import TheFormDescription from '@/components/form-components/TheFormDescription';
import TheFormDidAddress from '@/components/form-components/TheFormDidAddress';
import TheFormEmail from '@/components/form-components/TheFormEmail';
import TheFormLastname from '@/components/form-components/TheFormLastname';
import TheFormName from '@/components/form-components/TheFormName';
import TheFormSchemaUri from '@/components/form-components/TheFormSchemaUri';
import TheFormSchemaVersion from '@/components/form-components/TheFormSchemaVersion';
import TheFormSelect from '@/components/form-components/TheFormSelect';
import TheFormUpload from '@/components/form-components/TheFormUpload';
import TheGoldMembershipFeatureAlert from '@/components/TheGoldMembershipFeatureAlert.vue';
import TheMissingCCCSnackbar from '@/components/TheMissingCCCSnackbar.vue';
import ThePaidFeatureAlert from '@/components/ThePaidFeatureAlert.vue';
import TheSearchBar from '@/components/search-bar/TheSearchBar.vue';

Vue.component('base-box', BaseBox);
Vue.component('base-btn-download-from-vault', BaseBtnDownloadFromVault);
Vue.component('base-btn-share-from-vault', BaseBtnShareFromVault);
Vue.component('base-color-picker', BaseColorPicker);
Vue.component('base-copy-to-clipboard', BaseCopyToClipboard);
Vue.component('base-drag-area', BaseDragArea);
Vue.component('base-grid-card', BaseGridCard);
Vue.component('base-list-card', BaseListCard);
Vue.component('base-list-item', BaseListItem);
Vue.component('base-list-link', BaseListLink);
Vue.component('base-list-router-link', BaseListRouterLink);
Vue.component('base-list-row', BaseListRow);
Vue.component('base-loading-circular', BaseLoadingCircular);
Vue.component('base-loading-linear', BaseLoadingLinear);
Vue.component('base-modal-confirm-action', BaseModalConfirmAction);
Vue.component('base-modal-qr-code', BaseModalQRCode);
Vue.component('base-small-window', BaseSmallWindow);
Vue.component('base-snackbar', BaseSnackbar);
Vue.component('the-alert-notice', TheAlertNotice);
Vue.component('the-balance-box', TheBalanceBox);
Vue.component('the-dashboard-header', TheDashboardHeader);
Vue.component('the-dashboard-header-boxes', TheDashboardHeaderBoxes);
Vue.component('the-error-message', TheErrorMessage);
Vue.component('the-form-amount', TheFormAmount);
Vue.component('the-form-content-uri', TheFormContentUri);
Vue.component('the-form-description', TheFormDescription);
Vue.component('the-form-did-address', TheFormDidAddress);
Vue.component('the-form-email', TheFormEmail);
Vue.component('the-form-lastname', TheFormLastname);
Vue.component('the-form-name', TheFormName);
Vue.component('the-form-schema-uri', TheFormSchemaUri);
Vue.component('the-form-schema-version', TheFormSchemaVersion);
Vue.component('the-form-select', TheFormSelect);
Vue.component('the-form-upload', TheFormUpload);
Vue.component(
  'the-gold-membership-feature-alert',
  TheGoldMembershipFeatureAlert,
);
Vue.component('the-missing-ccc-snackbar', TheMissingCCCSnackbar);
Vue.component('the-paid-feature-alert', ThePaidFeatureAlert);
Vue.component('the-search-bar', TheSearchBar);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
