const CHECKOUT_DATA = Object.freeze([
  'company',
  'country',
  'email',
  'fiscalCode',
  'lastname',
  'membership',
  'name',
  'phone',
  'sdi',
  'vat',
]);

const CONTACT = Object.freeze([
  'uuid',
  'wallet_address',
  'email',
  'name',
  'lastname',
  'created_at',
  'updated_at',
]);

const DOSSIER = Object.freeze([
  'color',
  'created_at',
  'description',
  'id',
  'name',
  'updated_at',
]);

const E_IDENTITY = Object.freeze([
  'user_id',
  'type',
  'wallet',
  'subscription_status',
]);

const FEATURE = Object.freeze(['id', 'title', 'description', 'comic', 'style']);

const INFO = Object.freeze([
  'version',
  'lcd_endpoint',
  'ws_endpoint',
  'rpc_endpoint',
]);

const MEMBERSHIP = Object.freeze([
  'billing_interval',
  'cashback_currency',
  'cashback_unit',
  'category',
  'created_at',
  'currency',
  'display_order',
  'membership_fee',
  'membership_type',
  'product_id',
  'scope',
  'specifications',
  'stripe_price_id',
  'stripe_product_id',
  'title',
  'unit_amount',
  'updated_at',
]);

const VAULT = Object.freeze([
  'created_at',
  'description',
  'document_uuid',
  'file_name',
  'name',
]);

export default Object.freeze({
  CHECKOUT_DATA,
  CONTACT,
  DOSSIER,
  E_IDENTITY,
  FEATURE,
  INFO,
  MEMBERSHIP,
  VAULT,
});
