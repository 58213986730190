import { customizationApi } from '@/apis';
import { COIN } from '@/constants';
import { settingsHandler } from '@/utils';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  initSettings({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    dispatch('storeSettings');
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  validateSettings({ commit }, areValid) {
    commit('setValidSettings', areValid);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   */
  storeSettings({ commit, dispatch, rootGetters }) {
    const config = rootGetters['application/config'];
    commit('setSettings', config);
    dispatch('validateSettings', true);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} payload
   * @param {Boolean} areValidSettings
   */
  updateTheme({ commit, dispatch }, { payload, areValidSettings = true } = {}) {
    commit('setTheme', payload);
    dispatch('validateSettings', areValidSettings);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} payload
   * @param {Boolean} areValidSettings
   */
  updateParameters(
    { commit, dispatch },
    { payload, areValidSettings = true } = {},
  ) {
    commit('setParameters', payload);
    dispatch('validateSettings', areValidSettings);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} payload
   * @param {Boolean} areValidSettings
   */
  updateVisibility(
    { commit, dispatch },
    { payload, areValidSettings = true } = {},
  ) {
    commit('setVisibility', payload);
    dispatch('validateSettings', areValidSettings);
  },
  /**
   *
   * @param {Function} dispatch
   */
  async refreshSettings({ dispatch }) {
    await dispatch('application/fetchConfig', null, { root: true });
    dispatch('initSettings');
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} getters
   * @param {Object} rootGetters
   */
  async saveSettings({ commit, dispatch, getters, rootGetters }) {
    const token = rootGetters['auth/token'];
    const params = structuredClone(getters['parameters']);
    const parameters = settingsHandler.convertSurchargeToBase(
      params,
      COIN.STABLE.EXPONENT,
    );
    const data = structuredClone(getters['settings']);
    data.surcharge.parameters = parameters;
    commit('setHandling', true);
    try {
      await customizationApi.requestUpdate(token, data);
      await dispatch('refreshSettings');
    } catch (error) {
      commit('setError', error);
    }
    commit('setHandling', false);
  },
};
