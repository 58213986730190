<template>
  <v-textarea
    v-model="description"
    dense
    outlined
    no-resize
    rows="3"
    :disabled="!v"
    :error-messages="errorMessages"
    :label="$t('labels.fileDescription')"
    @blur="v.$touch()"
    @input="v.$touch()"
  />
</template>

<script>
export default {
  name: 'TheFormDescription',
  props: {
    value: {
      type: String,
      default: '',
    },
    v: {
      type: Object,
    },
  },
  emits: ['input'],
  computed: {
    description: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    errorMessages() {
      const errors = [];
      if (!this.v || !this.v.$dirty) return errors;
      (!this.v.minLength || !this.v.maxLength) &&
        errors.push(this.$t('msgs.textLengthError'));
      return errors;
    },
  },
};
</script>
