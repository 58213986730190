import { LoremIpsum } from 'lorem-ipsum';

const lorem = new LoremIpsum(
  {
    sentencesPerParagraph: {
      max: 5,
      min: 4,
    },
    wordsPerSentence: {
      max: 24,
      min: 12,
    },
  },
  'html',
);

const mockContract = (version = true, accepted = true) => {
  const item = {
    contract_id: 'c39eb8e1-9c52-4ad3-87a3-80223d781c00',
    title: lorem.generateWords(5),
    version: version ? '01.2021' : undefined,
    content: lorem.generateParagraphs(5),
    created_at: 1618492722,
    locale: 'it_IT',
    accepted: accepted,
    accepted_at: accepted ? 1620114924 : undefined,
  };
  return item;
};

const mockContracts = (contracts = 3) => {
  const list = new Array(contracts).fill(null).map(() => mockContract());
  return list;
};

export { mockContract, mockContracts };
