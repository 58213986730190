import { APIS } from '@/constants';
import axios from 'axios';
import apisHelper from './helpers/apisHelper';

const nft = {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestSummary(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/summary',
      method: 'get',
      baseURL: APIS.NFT,
      headers,
    });
  },
  /**
   *
   * @param {Number} limit
   * @param {String} cursor
   * @param {String} query
   * @returns {AxiosPromise}
   */
  requestList({ token, limit, cursor, query }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      limit,
      cursor,
      query,
    });
    return axios({
      method: 'get',
      baseURL: APIS.NFT,
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} bearer
   * @param {String} tokenId
   * @returns {AxiosPromise}
   */
  requestDetail({ bearer, tokenId }) {
    const headers = {
      Authorization: `Bearer ${bearer}`,
    };
    return axios({
      url: `/${tokenId}`,
      method: 'get',
      baseURL: APIS.NFT,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestTransfer(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/transfers/process',
      method: 'post',
      baseURL: APIS.NFT,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} processId
   * @returns {AxiosPromise}
   */
  requestProcess({ token, processId }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/transfers/process/${processId}`,
      method: 'get',
      baseURL: APIS.NFT,
      headers,
    });
  },
};

export default nft;
