<template>
  <v-snackbar v-model="snackbar">
    <slot />
    <template v-slot:action="{ attrs }">
      <v-btn
        color="lime accent-2"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        <span v-text="$t('labels.close')" />
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'BaseSnackbar',
  props: {
    value: {
      type: Boolean,
    },
  },
  emits: ['input'],
  computed: {
    snackbar: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
