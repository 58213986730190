const API_VERSION = process.env.VUE_APP_API_VERSION;
const BACKEND = process.env.VUE_APP_BACKEND;
const BASE_PATH = `${BACKEND}/${API_VERSION}`;

export default Object.freeze({
  CONTACT: `${BASE_PATH}/contacts`,
  CONTRACT: `${BASE_PATH}/contract`,
  CREDITS: `${BASE_PATH}/chain/credits`,
  CUSTOMIZATION: `${BASE_PATH}/client_customizations`,
  DOSSIER: `${BASE_PATH}/dossier`,
  EID: `${BASE_PATH}/eid`,
  EIDENTITY: `${BASE_PATH}/eidentity`,
  EKYC: `${BASE_PATH}/eKYC`,
  INFO: `${BASE_PATH}/info`,
  MEMBERSHIP: `${BASE_PATH}/membership`,
  NFT: `${BASE_PATH}/chain/nft`,
  PROCESS: `${BASE_PATH}/process`,
  PRODUCTS: `${BASE_PATH}/products`,
  RECEIPTS: `${BASE_PATH}/chain/receipts`,
  SETTINGS: `${BASE_PATH}/settings`,
  SHAREDOC: `${BASE_PATH}/chain/sharedoc`,
  STAKE: `${BASE_PATH}/chain/stake`,
  SWAP: `${BASE_PATH}/chain/swap`,
  STRIPE: `${BASE_PATH}/stripe`,
  VAULTS: `${BASE_PATH}/vaults`,
});
