import { APIS } from '@/constants';
import axios from 'axios';
import apisHelper from './helpers/apisHelper';

const vault = {
  /**
   *
   * @param {String} token
   * @param {String} name
   * @param {Number} limit
   * @param {String} cursor
   * @param {String} order
   * @returns {AxiosPromise}
   */
  requestList({ token, name, limit, cursor, order }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      name,
      limit,
      cursor,
      order,
    });
    return axios({
      method: 'get',
      baseURL: APIS.VAULTS,
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} vaultDocumentUUID
   * @returns {AxiosPromise}
   */
  requestDetail(token, vaultDocumentUUID) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${vaultDocumentUUID}`,
      method: 'get',
      baseURL: APIS.VAULTS,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestCreate(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    return axios({
      method: 'post',
      baseURL: APIS.VAULTS,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} vaultId
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestShare({ token, vaultId, data }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${vaultId}/sharedoc`,
      method: 'post',
      baseURL: APIS.VAULTS,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @returns {Promise}
   */
  requestUserinfo(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/userinfo',
      method: 'get',
      baseURL: APIS.VAULTS,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} documentUUID
   * @returns {Promise}
   */
  requestDocument(token, documentUUID) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/document/${documentUUID}`,
      method: 'get',
      baseURL: APIS.VAULTS,
      headers,
      responseType: 'blob',
    });
  },
};

export default vault;
