import { receiptsApi, sharedocApi } from '@/apis';
import { SETTINGS } from '@/constants';

export default {
  /**
   *
   * @param {Function} dispatch
   */
  initNotarizeReceivedDashboard({ dispatch }) {
    dispatch('fetchNotarizeReceivedDashboardData');
    dispatch('header/initHeader', null, { root: true });
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async fetchNotarizeReceivedDashboardData({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchContacts'),
      dispatch('requestReceivedCacheStatus'),
      dispatch('fetchReceivedSharedocs', {
        order: SETTINGS.INFINITE_SCROLL.ORDER_DESC,
      }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async addReceivedSharedocs({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchReceivedSharedocs', {
      order: SETTINGS.INFINITE_SCROLL.ORDER_DESC,
    });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} getters
   * @param {Object} rootGetters
   * @param {Number} limit
   * @param {String} order
   */
  async fetchReceivedSharedocs(
    { commit, getters, rootGetters },
    { limit, order },
  ) {
    const cursor = getters['cursor'];
    const token = rootGetters['auth/token'];
    try {
      const response = await sharedocApi.requestReceivedList({
        token,
        limit,
        cursor,
        order,
      });
      commit('addSharedocs', response.data.processes);
      commit('setPaging', response.data.paging);
      commit('setLatestRefresh', response.data.latest_refresh);
    } catch (error) {
      commit('setError', error);
    }
  },
  async requestReceivedCacheStatus({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await sharedocApi.requestReceivedCacheStatus({ token });
      commit('setRefreshStatus', response.data.status);
      commit('setRefreshProgress', response.data.progress);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async refreshReceivedSharedocs({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    commit('setRefreshProgress', 0);
    try {
      const response = await sharedocApi.requestReceivedCacheRefresh({ token });
      commit('setRefreshStatus', response.data.status);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} documentId
   */
  async initNotarizeReceivedDetail({ commit, dispatch }, documentId) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchContacts'),
      dispatch('fetchReceivedSharedocDetail', documentId),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} documentId
   */
  async fetchReceivedSharedocDetail({ commit, rootGetters }, documentId) {
    const token = rootGetters['auth/token'];
    try {
      const response = await sharedocApi.requestReceivedDetail(
        token,
        documentId,
      );
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   */
  async fetchContacts({ commit, dispatch, rootGetters }) {
    commit('contacts/reset', null, { root: true });
    do {
      await dispatch(
        'contacts/fetchContactsList',
        { cursor: rootGetters['contacts/cursor'] },
        { root: true },
      );
    } while (rootGetters['contacts/cursor']);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   * @param {String} documentId
   */
  async sendReceipt({ commit, dispatch, rootGetters }, documentId) {
    const token = rootGetters['auth/token'];
    const data = { document_uuid: documentId };
    commit('setSending', true);
    try {
      const response = await receiptsApi.requestCreate(token, data);
      if (response.data) {
        await dispatch('fetchReceivedSharedocDetail', documentId);
      }
    } catch (error) {
      commit('setError', error);
    }
    commit('setSending', false);
  },
};
