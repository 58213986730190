<template>
  <v-card
    id="drag-area"
    class="drag-area"
    :class="{ 'grey lighten-1': isDragover }"
    flat
    @drop.prevent="onDrop"
    @dragover.prevent="isDragover = true"
    @dragenter.prevent="isDragover = true"
    @dragleave.prevent="isDragover = false"
  >
    <v-card-text>
      <v-row
        class="text-center drag-area_info d-flex flex-column justify-center align-center"
        dense
      >
        <v-icon class="mt-5" size="60">
          {{ mdiCloudUpload }}
        </v-icon>
        <i18n :path="info" tag="div">
          <span class="font-weight-bold" v-text="maxSize" />
        </i18n>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div>
        <v-file-input
          hide-input
          :prepend-icon="mdiUpload"
          v-model="file"
        ></v-file-input>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiCloudUpload, mdiUpload } from '@mdi/js';
import { SETTINGS } from '@/constants';

export default {
  name: 'BaseDragArea',
  props: {
    value: {
      type: File,
      note: 'The file to be uploaded.',
    },
    info: {
      type: String,
      required: true,
      note: 'The info message to be displayed in the drag area.',
    },
    maxSize: {
      type: Number,
      default: SETTINGS.MAX_FILE_SIZE,
      note: 'The maximum file size allowed in megabytes.',
    },
    rules: {
      type: Array,
      default: () => [],
      note: 'The file types allowed to be uploaded.',
    },
  },
  data() {
    return {
      mdiCloudUpload,
      mdiUpload,
      isDragover: false,
    };
  },
  emits: ['input', 'upload-issue'],
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(value) {
        if (
          !this.validateSize(value.size, this.maxSize) ||
          !this.validateType(value.type, this.rules)
        ) {
          this.$emit('upload-issue');
          return;
        }
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onDrop(event) {
      if (event.dataTransfer.files.length > 1) {
        this.isDragover = false;
        return;
      }
      this.file = event.dataTransfer.files[0];
      this.isDragover = false;
    },
    validateSize(size, maxSize) {
      return size <= maxSize * 1024 * 1024;
    },
    validateType(type, rules) {
      if (!rules.length) {
        return true;
      }
      return rules.includes(type);
    },
  },
};
</script>

<style scoped>
.drag-area {
  border: 5px dashed;
}
.drag-area_info {
  white-space: pre-line;
}
</style>
