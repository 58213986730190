import Vue from 'vue';
import Vuex from 'vuex';

import account from './account';
import agreements from './agreements';
import application from './application';
import auth from './auth';
import contacts from './contacts';
import dossiers from './dossiers';
import download from './download';
import drawer from './drawer';
import earchive from './earchive';
import edelivery from './edelivery';
import eidWallet from './eid-wallet';
import header from './header';
import mintDashboard from './mint/dashboard';
import mintNew from './mint/new';
import mintNft from './mint/nft';
import mintWizard from './mint/wizard';
import nftDashboard from './nft/dashboard';
import nftDetail from './nft/detail';
import nftTransfer from './nft/transfer';
import notarizeAssignment from './notarize/assignment';
import notarizeReceived from './notarize/received';
import notarizeSent from './notarize/sent';
import purchase from './purchase';
import quickDelivery from './quick-delivery';
import settings from './settings';
import stake from './stake';
import swap from './swap';
import tokens from './tokens';
import tokensBuy from './tokens-buy';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    agreements,
    application,
    auth,
    contacts,
    dossiers,
    download,
    drawer,
    earchive,
    edelivery,
    eidWallet,
    header,
    mintDashboard,
    mintNew,
    mintNft,
    mintWizard,
    nftDashboard,
    nftDetail,
    nftTransfer,
    notarizeAssignment,
    notarizeReceived,
    notarizeSent,
    purchase,
    quickDelivery,
    settings,
    stake,
    swap,
    tokens,
    tokensBuy,
  },
});
