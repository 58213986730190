import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const NotarizeViewAssignment = loadView('notarize/NotarizeViewAssignment');
const NotarizeReceivedRouter = loadView(
  'notarize/received/NotarizeReceivedRouter',
);
const NotarizeViewReceivedDashboard = loadView(
  'notarize/received/NotarizeViewReceivedDashboard',
);
const NotarizeViewReceivedDetail = loadView(
  'notarize/received/NotarizeViewReceivedDetail',
);
const NotarizeSentRouter = loadView('notarize/sent/NotarizeSentRouter');
const NotarizeViewSentDashboard = loadView(
  'notarize/sent/NotarizeViewSentDashboard',
);
const NotarizeViewSentDetail = loadView('notarize/sent/NotarizeViewSentDetail');

export const notarizeRoute = {
  path: ROUTES.PATH.NOTARIZE,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.NOTARIZE,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.NOTARIZE,
      component: NotarizeViewReceivedDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.NOTARIZE_ASSIGNMENT,
      name: ROUTES.NAME.NOTARIZE_ASSIGNMENT,
      component: NotarizeViewAssignment,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.NOTARIZE_ASSIGNMENT,
      },
    },
    {
      path: ROUTES.PATH.NOTARIZE_RECEIVED,
      component: NotarizeReceivedRouter,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.NOTARIZE_RECEIVED,
      },
      children: [
        {
          path: '',
          name: ROUTES.NAME.NOTARIZE_RECEIVED,
          component: NotarizeViewReceivedDashboard,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
          },
        },
        {
          path: ROUTES.PATH.NOTARIZE_RECEIVED_DASHBOARD,
          name: ROUTES.NAME.NOTARIZE_RECEIVED_DASHBOARD,
          component: NotarizeViewReceivedDashboard,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.NOTARIZE_RECEIVED_DASHBOARD,
          },
        },
        {
          path: ROUTES.PATH.NOTARIZE_RECEIVED_DETAIL,
          name: ROUTES.NAME.NOTARIZE_RECEIVED_DETAIL,
          component: NotarizeViewReceivedDetail,
          props: true,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.NOTARIZE_RECEIVED_DETAIL,
          },
        },
      ],
    },
    {
      path: ROUTES.PATH.NOTARIZE_SENT,
      component: NotarizeSentRouter,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.NOTARIZE_SENT,
      },
      children: [
        {
          path: '',
          name: ROUTES.NAME.NOTARIZE_SENT,
          component: NotarizeViewSentDashboard,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
          },
        },
        {
          path: ROUTES.PATH.NOTARIZE_SENT_DASHBOARD,
          name: ROUTES.NAME.NOTARIZE_SENT_DASHBOARD,
          component: NotarizeViewSentDashboard,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.NOTARIZE_SENT_DASHBOARD,
          },
        },
        {
          path: ROUTES.PATH.NOTARIZE_SENT_DETAIL,
          name: ROUTES.NAME.NOTARIZE_SENT_DETAIL,
          component: NotarizeViewSentDetail,
          props: true,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.NOTARIZE_SENT_DETAIL,
          },
        },
      ],
    },
  ],
};
