<template>
  <v-list-item>
    <v-list-item-content>
      <v-row>
        <v-col
          cols="12"
          md="1"
          class="d-flex justify-center justify-md-start align-md-center"
        >
          <slot name="icon" />
        </v-col>
        <v-col cols="12" md="8">
          <slot name="left" />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center justify-md-end align-md-center"
        >
          <slot name="right" />
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'BaseListRow',
};
</script>
