import Vuetify from '@/plugins/vuetify';
import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setInfo(state, payload) {
    state.info = payload;
  },
  setConfig(state, payload) {
    state.config = payload;
    if (payload) {
      Vuetify.framework.theme.themes.light.primary = payload.theme.color;
    }
  },
};
