import { CREDENTIAL} from "@/constants";

export const credentials = {
  title:{
    [CREDENTIAL.ID.ADDRESSPROOF]: 'addressproof',
    [CREDENTIAL.ID.AML]: 'aml',
    [CREDENTIAL.ID.CREDIT_CARD]: 'credit card',
    [CREDENTIAL.ID.EMAIL]: 'email',
    [CREDENTIAL.ID.FACECHECK]: 'facecheck',
    [CREDENTIAL.ID.IBAN]: 'iban sepa',
    [CREDENTIAL.ID.MIFID_II]: 'mifid-ii',
    [CREDENTIAL.ID.MOBILE_NUMBER]: 'mobile number',
    [CREDENTIAL.ID.PUBLIC_DIGITAL_IDENTITY_SYSTEM]: 'public digital identity system',
  },
  description:{
    [CREDENTIAL.ID.ADDRESSPROOF]: 'Submission of an utility bill',
    [CREDENTIAL.ID.AML]: 'Submission DUE DILIGENCE declaration signed',
    [CREDENTIAL.ID.CREDIT_CARD]: 'Stripe Inc.',
    [CREDENTIAL.ID.EMAIL]: 'Email message for Double opt-ins verification procedure',
    [CREDENTIAL.ID.FACECHECK]: 'Verification through identity document upload plus image capture',
    [CREDENTIAL.ID.IBAN]: 'Payment with Stripe o Yaply',
    [CREDENTIAL.ID.MIFID_II]: 'Submission MIFID declaration signed',
    [CREDENTIAL.ID.MOBILE_NUMBER]: 'Sms message for Double opt-ins verification procedure',
    [CREDENTIAL.ID.PUBLIC_DIGITAL_IDENTITY_SYSTEM]: 'Authentication with Spid (IT),German eID - BSI (DE),CNIE electronic ID(FR),ecc',
  },
}