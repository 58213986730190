import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';
import store from '@/store';
import { featureActivator } from '@/utils';

const SettingsView = loadView('settings/SettingsView');
const SettingsViewSurcharge = loadView('settings/SettingsViewSurcharge');
const SettingsViewTheme = loadView('settings/SettingsViewTheme');

export const settingsRoute = {
  path: ROUTES.PATH.SETTINGS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.SETTINGS,
  },
  beforeEnter: (_to, _from, next) => {
    const identity = store.getters['account/identity'];
    const config = store.getters['application/config'];
    if (featureActivator.isAdmin(identity, config)) {
      next();
    } else {
      next(ROUTES.PATH.ROOT);
    }
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.SETTINGS,
      component: SettingsView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
      children: [
        {
          path: '',
          name: ROUTES.NAME.SETTINGS,
          component: SettingsViewTheme,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
          },
        },
        {
          path: ROUTES.PATH.SETTINGS_SURCHARGE,
          name: ROUTES.NAME.SETTINGS_SURCHARGE,
          component: SettingsViewSurcharge,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.SETTINGS_SURCHARGE,
          },
        },
        {
          path: ROUTES.PATH.SETTINGS_THEME,
          name: ROUTES.NAME.SETTINGS_THEME,
          component: SettingsViewTheme,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.SETTINGS_THEME,
          },
        },
      ],
    },
  ],
};
