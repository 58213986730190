import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setDetail(state, payload) {
    state.detail = payload;
  },
  setFile(state, payload) {
    state.file = payload;
  },
  setInfo(state, payload) {
    state.info = payload;
  },
  setAdding(state, payload) {
    state.isAdding = payload;
  },
  setIsStarted(state, payload) {
    state.isStarted = payload;
  },
  addMetadata(state, payload) {
    const metadata = { ...state.metadata, ...payload };
    state.metadata = metadata;
  },
  setMetadata(state, payload) {
    state.metadata = payload;
  },
  setPaging(state, payload) {
    state.paging = payload;
  },
  addVaults(state, payload) {
    state.vaults.push(...payload);
  },
  setVaults(state, payload) {
    state.vaults = payload;
  },
};
