export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isAdding: (state) => state.isAdding,
  isHandling: (state) => state.isHandling,
  dossiers: (state) => state.dossiers,
  paging: (state) => state.paging,
  cursor: (state) =>
    state.paging && state.paging.next_cursor ? state.paging.next_cursor : null,
  details: (state) => state.details,
};
