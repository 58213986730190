import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const SwapViewSummary = loadView('swap/SwapViewSummary');
const SwapViewWizard = loadView('swap/SwapViewWizard');

export const swapRoute = {
  path: ROUTES.PATH.SWAP,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.SWAP,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.SWAP,
      component: SwapViewWizard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.SWAP_SUMMARY,
      name: ROUTES.NAME.SWAP_SUMMARY,
      component: SwapViewSummary,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.SWAP_SUMMARY,
      },
    },
    {
      path: ROUTES.PATH.SWAP_WIZARD,
      name: ROUTES.NAME.SWAP_WIZARD,
      component: SwapViewWizard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.SWAP_WIZARD,
      },
    },
  ],
};
