import edeliveryHelper from './helpers/edeliveryHelper';
import { sharedocApi, vaultApi } from '@/apis';
import { EDELIVERY } from '@/constants';

export default {
  /**
   *
   * @param {Function} commit
   */
  initEdelivery({ commit }) {
    commit('reset');
    commit('setIsStarted', true);
    return true;
  },
  /**
   *
   * @param {Function} commit
   * @param {File || Object} document
   * @param {String} where
   */
  setDocument({ commit }, { document, where }) {
    commit('setMetadata', null);
    commit('setFile', document);
    commit('setWhere', where);
  },
  /**
   *
   * @param {Function} commit
   * @param {boolean} hasFunds
   */
  saveHasFunds({ commit }, hasFunds) {
    commit('setHasFunds', hasFunds);
  },
  /**
   *
   * @param {Function} commit
   * @param { Object} document
   * @param {boolean} hasFunds
   */
  shareDocument({ commit }, { document, hasFunds }) {
    commit('reset');
    commit('setIsStarted', true);
    commit('setFile', document);
    commit('setHasFunds', hasFunds);
    commit('setWhere', EDELIVERY.WHERE.SHARE_VAULT);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} name
   */
  async fetchVaults({ commit, dispatch }, name = '') {
    commit('earchive/reset', null, { root: true });
    commit('setLoading', true);
    await dispatch('earchive/fetchVaultsList', { name }, { root: true });
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} name
   */
  async addVaults({ commit, dispatch }, { cursor = '', name = '' } = {}) {
    commit('setLoading', true);
    await dispatch(
      'earchive/fetchVaultsList',
      { cursor, name },
      { root: true },
    );
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} getters
   * @param {Object} recipient
   */
  setRecipient({ commit, getters }, recipient) {
    commit('setIsAlreadyPresent', false);
    const recipients = getters['recipients'].map(
      (recipient) => recipient.wallet_address,
    );
    if (recipients.indexOf(recipient.wallet_address) > -1) {
      commit('setIsAlreadyPresent', true);
      return;
    }
    commit('addRecipient', recipient);
  },
  /**
   *
   * @param {Function} commit
   * @param {Number} index
   */
  deleteRecipient({ commit }, index) {
    commit('removeRecipient', index);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} query
   */
  async fetchContacts({ commit, dispatch }, query = '') {
    commit('contacts/reset', null, { root: true });
    commit('setLoading', true);
    await dispatch('contacts/fetchContactsList', { query }, { root: true });
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} query
   */
  async addContacts({ commit, dispatch }, { cursor = '', query = '' } = {}) {
    commit('setLoading', true);
    await dispatch(
      'contacts/fetchContactsList',
      { cursor, query },
      { root: true },
    );
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} data
   */
  setMetadata({ commit }, data) {
    commit('addMetadata', data);
  },
  /**
   *
   * @param {Function} dispatch
   * @param {Object} getters
   */
  async deliver({ dispatch, getters }) {
    const delivery = getters['where'];
    const file = getters['file'];
    const data = getters['metadata'];
    const recipients = getters['recipients'].map(
      (recipient) => recipient.wallet_address,
    );
    switch (delivery) {
      case EDELIVERY.WHERE.NEW_SHAREDOC:
        return await dispatch('deliverNewSharedoc', { file, data, recipients });
      case EDELIVERY.WHERE.NEW_VAULT:
        return await dispatch('deliverNewVault', { file, data });
      case EDELIVERY.WHERE.SHARE_VAULT:
        return await dispatch('deliverShareVault', {
          vaultId: file.document_uuid,
          data,
          recipients,
        });
      default:
        return 500;
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {File} file
   * @param {Object} data
   * @param {Array.<String>} recipients
   * @returns {Promise}
   */
  async deliverNewSharedoc(
    { commit, rootGetters },
    { file, data, recipients },
  ) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const body = await edeliveryHelper.buildSharedoc({
        file,
        data,
        recipients,
      });
      const response = await sharedocApi.requestCreate(token, body);
      return response.data.process_id;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {File} file
   * @param {Object} data
   * @returns {Promise}
   */
  async deliverNewVault({ commit, rootGetters }, { file, data }) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const body = edeliveryHelper.buildNewVault(file, data);
      const response = await vaultApi.requestCreate(token, body);
      commit('setVault', response.data);
      return response.data.process_id;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} vaultId
   * @param {Object} data
   * @param {Array.<String>} recipients
   * @returns {Promise}
   */
  async deliverShareVault(
    { commit, rootGetters },
    { vaultId, data, recipients },
  ) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const body = edeliveryHelper.buildShareVault(data, recipients);
      const response = await vaultApi.requestShare({
        token,
        vaultId,
        data: body,
      });
      return response.data.process_id;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} vaultDocumentUUID
   * @returns {Promise}
   */
  async fetchVault({ commit, rootGetters }, vaultDocumentUUID) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await vaultApi.requestDetail(token, vaultDocumentUUID);
      commit('setVault', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
};
