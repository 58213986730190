export const labels = {
  add: 'Add',
  addRecipient: 'Add recipient',
  address: 'Address',
  all: 'All',
  amount: 'Amount',
  animationUrl: 'Animation URL',
  audit: 'Audit',
  auditor: 'Auditor',
  available: 'Available',
  back: 'Back',
  backgroundColor: 'Background color',
  buyCredits: 'Buy credits',
  buyNow: 'Buy now',
  cancel: 'Cancel',
  claimRewards: 'Claim rewards',
  clear: 'Clear',
  close: 'Close',
  collection: 'Collection',
  color: 'Color',
  companyName: 'Company name',
  companyVat: 'Company vat',
  completionTime: 'Completion time',
  confirm: 'Confirm',
  contactEmail: 'Contact email address',
  contactsList: 'Contacts list',
  contentUri: 'Content URI',
  contract: 'Contract',
  copy: 'Copy',
  copyright: 'Copyright',
  country: 'Country',
  createNfts: 'Create NFTs',
  date: 'Date',
  delegate: 'Delegate',
  delete: 'Delete',
  depositCredits: 'Deposit credits',
  description: 'Description',
  details: 'Details',
  did: 'Did',
  didCom: 'did:com:',
  displayType: 'Display type',
  documentHash: 'Document hash',
  documentId: 'Document ID',
  dossierLinkTo: 'Link to dossier',
  dossierRemove: 'Remove dossier',
  dossiersList: 'Dossiers list',
  download: 'Download',
  eArchivesList: 'eArchives list',
  eDeliverDocument: 'eDeliver document',
  edit: 'Edit',
  email: 'Email',
  expire: 'Expire',
  externalUrl: 'External URL',
  fee: 'Fee',
  fileDescription: 'File description',
  fileName: 'File name',
  fileUpload: 'File upload',
  from: 'From',
  goToShare: 'Go to share',
  hash: 'Hash',
  hashAlg: 'Hash algorithm',
  how: 'How',
  image: 'Image',
  imageData: 'Image data',
  invite: 'Invite',
  inviteContacts: 'Invite contacts',
  invoicingEmail: 'Invoicing email',
  label: 'Label',
  lastname: 'Last name',
  latestRefresh: 'Latest refresh',
  legalRepresentativeCode: 'Legal representative fiscal code',
  legalRepresentativeEmail: 'Legal representative email',
  legalRepresentativeFirstName: 'Legal representative first name',
  legalRepresentativeLastName: 'Legal representative last name',
  legalRepresentativeMobile: 'Legal representative mobile',
  login: 'Login',
  manageEArchive: 'Manage eArchive',
  max: 'Max',
  me: 'Me',
  messageUuid: 'Message UUID',
  metadata: 'Metadata',
  min: 'Min',
  mint: 'Mint',
  minter: 'Minter',
  more: 'More',
  name: 'Name',
  new: 'New',
  newCollection: 'New collection',
  newContact: 'New contact',
  newEArchive: 'New eArchive',
  next: 'Next',
  nftCollections: 'NFT collections',
  notarize: 'Notarize',
  notarizeDelivery: 'Notarize delivery',
  object: 'Object',
  of: 'of',
  options: 'Options',
  owner: 'Owner',
  paidAmount: 'Paid amount',
  paidAt: 'Paid at',
  paymentId: 'Payment ID',
  pickAnImage: 'Pick an image',
  prev: 'Previous',
  processError: 'Process error',
  processId: 'Process Id',
  productQuantity: 'Product quantity',
  proof: 'Proof',
  protected: 'Protected',
  quantity: 'Quantity',
  receipt: 'Receipt',
  receipts: 'Receipts',
  recipient: 'Recipient',
  recipients: 'Recipients',
  register: 'Register',
  reloadApp: 'Reload app',
  restart: 'Restart',
  save: 'Save',
  scanTheAddress: 'Scan the address',
  schemaUri: 'Schema URI',
  schemaVersion: 'Schema version',
  sdiCode: 'SDI code',
  search: 'Search',
  securedOn: 'Secured on',
  select: 'Select',
  send: 'Send',
  sendCredits: 'Send credits',
  sendReceipt: 'Send receipt',
  sender: 'Sender',
  share: 'Share',
  shareDocument: 'Share a document',
  signOut: 'Sign out',
  start: 'Start',
  status: 'Status',
  swapNow: 'Swap now',
  symbol: 'Symbol',
  tapToShare: 'Tap to share',
  timestampFile: 'Timestamp file',
  to: 'To',
  tokenCost: 'CCC (Commercio Cash Credit) cost',
  tokenId: 'Token ID',
  tokenUri: 'Token URI',
  total: 'Total',
  traitType: 'Trait type',
  transactionHash: 'Transaction hash',
  transfer: 'Transfer',
  transferFee: 'Transfer fee',
  tryAgain: 'Try again',
  type: 'Type',
  undefined: 'Undefined',
  undelegate: 'Undelegate',
  unitProcessCost: 'Unit process cost',
  unknown: 'Unknown',
  unprotected: 'Unprotected',
  upgradeYourMembership: 'Upgrade your membership',
  value: 'Value',
  vat: 'Vat',
  verifiedOn: 'Verified on',
  version: 'Version',
  viewCertificate: 'View certificate',
  what: 'What',
  where: 'Where',
  who: 'Who',
  yes: 'Yes',
  youtubeUrl: 'YouTube URL',
};
