import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';
import store from '@/store';
import { featureActivator } from '@/utils';

const PurchaseView = loadView('purchase/PurchaseView');

export const purchaseRoute = {
  path: ROUTES.PATH.PURCHASE,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.PURCHASE,
  },
  beforeEnter: (_to, _from, next) => {
    const identity = store.getters['account/identity'];
    const membershipType = identity.subscription.membership.type;
    if (featureActivator.hasGreenMembership(membershipType)) {
      next();
    } else {
      next(ROUTES.PATH.ROOT);
    }
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.PURCHASE,
      component: PurchaseView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
  ],
};
