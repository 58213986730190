const PROGRESS = Object.freeze({
  CONFIRM: 'confirm',
  HOW: 'how',
  WHAT: 'what',
  WHO: 'who',
  WIZARD: 'wizard',
});

const WHERE = Object.freeze({
  NEW_SHAREDOC: 'new-sharedoc',
  NEW_VAULT: 'new-vault',
  SHARE_VAULT: 'share-vault',
});

const EDELIVERY = Object.freeze({
  PROGRESS,
  WHERE,
});

export default EDELIVERY;
