import { APIS } from '@/constants';
import axios from 'axios';

const contractApi = {
  /**
   *
   * @param {String} locale
   * @returns {AxiosPromise}
   */
  requestList(locale) {
    const headers = {
      'X-Locale': locale,
    };
    return axios({
      url: APIS.CONTRACT,
      method: 'get',
      headers,
    });
  },
  /**
   *
   * @param {String} locale
   * @param {String} id
   * @returns {AxiosPromise}
   */
  requestDetail({ locale, id }) {
    const headers = {
      'X-Locale': locale,
    };
    return axios({
      url: `${APIS.CONTRACT}/${id}`,
      method: 'get',
      headers,
    });
  },
};

export default contractApi;
