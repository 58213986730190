const edeliveryHelper = {
  /**
   *
   * @param {File} file
   * @param {Object} data
   * @returns {Object}
   */
  buildNewVault(file, data) {
    return {
      document: file,
      name: data.vaultName,
      description: data.vaultDescription || undefined,
      content_url: data.contentUri,
      schema_uri: data.schemaUri,
      schema_version: data.schemaVersion,
    };
  },
  /**
   *
   * @param {Object} data
   * @param {Array<String>} recipients
   * @returns {Object}
   */
  buildShareVault(data, recipients) {
    return {
      name: data.sharedocName,
      description: data.sharedocDescription || undefined,
      content_url: data.contentUri,
      schema_uri: data.schemaUri,
      schema_version: data.schemaVersion,
      recipients: recipients,
    };
  },
  /**
   *
   * @param {File} file
   * @param {Object} data
   * @param {Array.<String>} recipients
   * @returns {Promise}
   */
  async buildSharedoc({ file, data, recipients }) {
    let hash;
    try {
      const content = await this.getContent(file);
      const hashBuffer = await this.getHashBuffer(content);
      hash = this.getHash(hashBuffer);
    } catch (error) {
      throw {
        response: {
          data: {
            message: `Build Sharedoc error: ${error}`,
          },
        },
      };
    }
    return {
      name: data.sharedocName,
      description: data.sharedocDescription || undefined,
      content_uri: data.contentUri,
      hash,
      hash_algorithm: 'sha-256',
      metadata: {
        content_uri: data.contentUri,
        schema: {
          uri: data.schemaUri,
          version: data.schemaVersion,
        },
      },
      recipients,
    };
  },
  /**
   *
   * @param {File} file
   * @returns {Promise}
   */
  getContent(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject('Error reading the file content');
      };
      reader.readAsArrayBuffer(file);
    });
  },
  /**
   *
   * @param {ArrayBuffer || String} content
   * @returns {Promise}
   */
  getHashBuffer(content) {
    return new Promise((resolve, reject) => {
      crypto.subtle
        .digest('SHA-256', content)
        .then((buffer) => resolve(buffer))
        .catch(() => reject('Error getting hash buffer'));
    });
  },
  /**
   *
   * @param {ArrayBuffer} hashBuffer
   * @returns {String}
   */
  getHash(hashBuffer) {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  },
};

export default edeliveryHelper;
