import { APIS } from '@/constants';
import axios from 'axios';

const ekyc = {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestCredentials(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'get',
      baseURL: APIS.EKYC,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestCredentialsByAddress({ token, address }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${address}`,
      method: 'get',
      baseURL: APIS.EKYC,
      headers,
    });
  },
};

export default ekyc;
