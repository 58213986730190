const STABLE = Object.freeze({
  NAME: 'commercio cash credits',
  SYMBOL: 'CCC',
  DENOM: 'uccc',
  EXPONENT: 6,
  VALUE: {
    AMOUNT: '1.00',
    SYMBOL: '€',
  },
});

const TOKEN = Object.freeze({
  NAME: 'commercio',
  SYMBOL: 'COM',
  DENOM: 'ucommercio',
  EXPONENT: 6,
  STAKEABLE: true,
});

const ASSETS = Object.freeze([
  {
    text: STABLE.SYMBOL,
    value: STABLE.DENOM,
    exponent: STABLE.EXPONENT,
  },
  {
    text: TOKEN.SYMBOL,
    value: TOKEN.DENOM,
    exponent: TOKEN.EXPONENT,
  },
]);

export default Object.freeze({
  ASSETS,
  STABLE,
  TOKEN,
});
