import { dossierApi } from '@/apis';
import { LISTINGS } from '@/constants';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initDrawer({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchLabels')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchLabels({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    const order_by = LISTINGS.SORTINGS.DOSSIERS_ALPHABET_ASC;
    let cursor = '';
    try {
      do {
        const response = await dossierApi.requestList({
          token,
          cursor,
          order_by,
        });
        commit('addLabels', response.data.dossiers);
        cursor = response.data.paging.next_cursor || '';
      } while (cursor);
    } catch (error) {
      commit('setError', error);
    }
  },
  async refreshLabels({ commit, dispatch }) {
    commit('setLabels', []);
    await dispatch('fetchLabels');
  },
};
