const LANDING_PAGE_TEMPLATES = Object.freeze([
  {
    id: 'TEMPLATE_DEFAULT',
    name: 'IntroDefault',
  },
  {
    id: 'TEMPLATE_SCAFFOLD_ONLY',
    name: 'IntroTemplateScaffoldOnly',
  },
]);

export default Object.freeze({
  LANDING_PAGE_TEMPLATES,
});
