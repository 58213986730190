import { dossierApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async addSharedocToDossier({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      await dossierApi.requestAdd(token, data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} order
   * @param {String} query
   */
  async searchDossiers({ commit, rootGetters }, { order, query }) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await dossierApi.requestList({
        token,
        order,
        query,
      });
      commit('setDossiers', response.data.dossiers);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
};
