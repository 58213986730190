<template>
  <v-snackbar top v-model="snackbar">
    <span v-text="$t('msgs.notEnoughCCC')" />
    <template v-slot:action="{ attrs }">
      <v-btn
        color="lime accent-2"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        <span v-text="$t('labels.close')" />
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';
import { featureActivator } from '@/utils';

export default {
  name: 'TheMissingCCCSnackbar',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    txs: {
      type: Number,
      default: 1,
    },
  },
  emits: ['input'],
  computed: {
    ...mapGetters('account', ['identity']),
    ...mapGetters('application', ['surcharge']),
    snackbar: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    const hasFunds = featureActivator.hasFunds({
      identity: this.identity,
      surcharge: this.surcharge,
      txsAmount: this.txs,
    });
    this.snackbar = !hasFunds;
  },
};
</script>
