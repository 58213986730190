export default Object.freeze({
  TITLE: `
  <h2>DISTRIBUTOR NODE AGREEMENT (hereinafter referred to as the "Agreement")</h2>
  <p>
    General Terms and Conditions<br />
    Version No. 1 / Publication Date: April 15, 2024
  </p>
  `,
  P1: `
  <h3>1. Scope of Application</h3>
  <p>
    The following general conditions govern the relationship between
    COMMERCIO.NETWORK SpA (hereinafter referred to as "COMMERCIO"), with its
    registered office at 36015 Schio (VI), Via Luigi dalla Via No. 3/b, tax
    code/VAT number 04136930247, represented by its legal representative pro
    tempore, on the one hand, and the individual adhering to these conditions
    (hereinafter referred to as the "DISTRIBUTOR"), on the other hand,
    concerning the promotion of the sale of COMMERCIO's products and services
    as defined below. The terms, conditions and provisions contained in the
    hyperlinks in the following general terms and conditions and in the
    version in effect at the time each activity of the DISTRIBUTOR is
    performed also form an integral part of the Agreement. This Agreement
    assumes that DISTRIBUTOR has signed the agreement as USER and the
    Membership agreement on https://Commercio.app and is the holder of the
    relevant Account (as defined below). This Agreement constitutes the entire
    agreement between the Parties with the same subject matter and supersedes
    any prior agreement with the same subject matter and also supersedes any
    general terms and conditions of the DISTRIBUTOR.
  </p>
  `,
  P2: `
  <h3>2. Definitions</h3>
  <p>The terms below have the meaning specified herein:</p>
  <ul>
    <li>
      Parties: are COMMERCIO and the DISTRIBUTOR who has entered into this
      Agreement;
    </li>
    <li>
      Distributor: is the entity that has entered into this Agreement and
      performs the promotion activity regulated hereunder. It may be a natural
      person acting for purposes unrelated to any entrepreneurial, commercial,
      handicraft or professional activity carried out pursuant to Article 3,
      paragraph 1, lett. a of Legislative Decree no. 206 of 2005 (respectively
      ‘Consumer’ and ‘Consumer Code’) or a subject (natural person or legal
      entity) with VAT registration number, acting in the exercise of its
      professional, entrepreneurial, commercial or handicraft activity and
      different from the subjects indicated in Article 3, paragraph 1, lett.
      a-b of the Consumer Code;
    </li>
    <li>
      Customers: these are the parties to whom the DISTRIBUTOR directs its
      promotional activities. They may be Consumers or persons, natural or
      legal), with a VAT number and acting in the exercise of their
      professional, entrepreneurial, commercial or craft activity and other
      than the persons indicated in Article 3, paragraph 1, letter a-b, of the
      Consumer Code;
    </li>
    <li>
      Commercio.network is a blockchain ecosystem compliant with the European
      eIDAS regulation developed by COMMERCIO that enables its USERS and
      MEMBERS to create an electronic identity and to sign, notarise, store
      and exchange legally effective digital documents with each other;
    </li>
    <li>
      Utility token: a type of crypto-activity intended solely to provide
      access to a good or service provided by its issuer (Art. 3(1)(9)
      European Community Regulation No. 2023/1114/EU of 31/05/2023 known as
      MICAR).
    </li>
    <li>
      Commercio token (symbol COM): are Utility tokens with the purpose of
      securing the network through the staking mechanism, which are generated
      by Commercio.network in a predetermined number of 60 million and with a
      value determined by the market, the purchase of which is taxable at the
      ordinary VAT rate pursuant to Presidential Decree No. 633/1972;
    </li>
    <li>
      Commercio Cash Credit (symbol CCC): are utility tokens with the purpose
      of executing transactions on the network, which are generated by
      Commercio.network in a non-predetermined number and with a fixed value
      of EUR 1.00 (one), plus ordinary VAT rate pursuant to Presidential
      Decree No. 633/1972;
    </li>
    <li>
      Account: the set of identification data of the MEMBER acquired through
      the authentication factors of the COMMERC.IO APP in order to allow the
      Member to access the enabling APP, the Wallet and the other services or
      products of COMMERCIO;
    </li>
    <li>
      COMMERC.IO APP: COMMERCIO's IT application for using the services of the
      Commercio.network blockchain;
    </li>
    <li>
      DISTRIBUTOR Node: this is the website created by COMMERCIO, uniquely
      linked to the individual DISTRIBUTOR by means of a code and UNIVOCAL URL
      address assigned at the time of its registration as a DISTRIBUTOR, which
      the DISTRIBUTOR must use for promotion activities (example: https://
      nomedistributore.commerc.io);
    </li>
    <li>
      Membership: refers to the annual subscription to the Commercio.network
      blockchain, which is purchased upon signing the relevant agreement
      through an IT procedure, which is accessed from the UPGRADE link within
      the COMMERC.IO APP;
    </li>
    <li>Member: the subjects who sign the relevant Membership agreement,</li>
    <li>
      User: the person (natural or legal) who has completed the registration
      procedure on the COMMERC.IO APP, signing the relevant agreement;
    </li>
    <li>
      REWARD NODE: the fee in Euros that the DISTRIBUTOR receives for the
      promotional activity carried out directly with Customers, in accordance
      with the terms and conditions of this Agreement;
    </li>
    <li>
      Remuneration: is the reward in Euro that the DISTRIBUTOR receives for
      direct promotion activity, towards customers, and for indirect promotion
      activity, through other Distributors;
    </li>
    <li>
      Project: is the development project of the Commercio.network blockchain
      and is available at the link
      <a href="https://commercio.network/it/progetto" target="_blank"
        >https://commercio.network/it/progetto</a
      >;
    </li>
    <li>
      VBR (Validation Block Rewards): is the fee in COM that the DISTRIBUTOR
      receives, under certain conditions, for the activity of COM Stake
      through delegation to the Validator Node constituted by COMMERCIO;
    </li>
    <li>
      Validator Nodes: are the servers connected to each other in order to
      create a distributed and decentralised infrastructure to perform the
      validation activity of transactions taking place on the
      Commercio.network blockchain;
    </li>
    <li>
      Staking: is the action of blocking a certain amount of COMs for a
      minimum period of 21 days in order to participate, through delegation to
      the COMMERCIO Validator Node, in the distributed consensus process of
      transactions on the Commercio.network blockchain according to the Proof
      of Stake protocol, receiving in reward a certain amount of COMs;
    </li>
    <li>
      Software: is the software developed by COMMERCIO that is required to use
      the Commercio.network blockchain;
    </li>
    <li>
      Wallet: is the hosted wallet owned by the MEMBER, which contains the
      COMs and CCCs;
    </li>
    <li>Logo: means as set out in Article 13;</li>
    <li>Website: means as set out in Article 13.</li>
  </ul>
  `,
  P3: `
  <h3>3. Subject of the Agreement</h3>
  <h4>3.1.</h4>
  <p>
    This Agreement governs the terms and conditions under which the
    DISTRIBUTOR may promote the sale of the products and services of COMMERCIO
    set out in this Agreement to Customers in the European Economic Area (EEA)
    in return for the COMPENSATION set out in Section 7. The Agreement also
    governs the terms and conditions of the free licence to use the Logo
    referred to in article 13 in favour of the DISTRIBUTOR for the best
    execution of this assignment, as well as the terms of use of the
    COMMERC.IO website and APP.
  </p>
  <h4>3.2.</h4>
  <p>
    Promotional activity as a DISTRIBUTOR presupposes that the person already
    holds a valid Membership and has adhered to this Agreement. All Membership
    types include the option to carry out the promotion activity and adhere to
    this Agreement, with the exception of the Green Membership
  </p>
  <h4>3.3.</h4>
  <p>
    The DISTRIBUTOR shall not pay COMMERCIO any price or cost for entering
    into this Agreement, subject to the annual membership fee.
  </p>
  <h4>3.4.</h4>
  <p>
    The promotional activity concerns the purchase of so-called Bundles
    (Membership and CCC packages) and the other products and services listed
    on https://commerc.io. At the same time as this Agreement is signed, a
    website (DISTRIBUTOR Node) uniquely linked to the individual DISTRIBUTOR
    will be created by COMMERCIO.
  </p>
  <h4>3.5.</h4>
  <p>
    The DISTRIBUTOR may carry out their promotional activity exclusively
    through the DISTRIBUTOR NODE, otherwise, no COMPENSATION will be
    recognized.
  </p>
  <h4>3.6.</h4>
  <p>
    CLIENTS always purchase products and services solely from COMMERCIO and
    not from the DISTRIBUTOR. The methods for purchasing products and services
    are as follows: A) by accessing the unique website of the DISTRIBUTOR and
    completing the registration and Membership purchase procedure through that
    website; or B) by following an email received from the DISTRIBUTOR,
    adhering to the "invitation" link contained in that email, and completing
    the registration and Membership purchase procedure associated with that
    invitation.
  </p>
  <h4>3.7.</h4>
  <p>
    The DISTRIBUTOR will have no authority to represent COMMERCIO to third
    parties and thus cannot assume any obligations or rights on behalf of or
    for the benefit of this company.
  </p>
  <h4>3.8.</h4>
  <p>
    The DISTRIBUTOR will not be obligated to COMMERCIO to carry out the
    promotional activity, which will be performed independently, without any
    obligation to collaborate, and without exclusivity. The DISTRIBUTOR will
    not be entitled to be refunded by COMMERCIO for expenses incurred, nor
    will COMMERCIO provide the DISTRIBUTOR with any materials of any kind
    (e.g., brochures, gadgets, etc.). All expenses, costs, risks, losses,
    etc., related to the promotional activity covered by the Agreement will be
    borne by the DISTRIBUTOR.
  </p>
  <h4>3.9.</h4>
  <p>
    The DISTRIBUTOR may use third parties to carry out the activity provided
    in the Agreement and will remain solely responsible to COMMERCIO for any
    failure to comply. The engagement of third parties by the DISTRIBUTOR will
    not result in any direct or indirect relationship between the third
    parties and COMMERCIO.
  </p>
  `,
  P4: `
  <h3>4. How to Become a DISTRIBUTOR</h3>
  <h4>4.1.</h4>
  <p>The procedure to become a DISTRIBUTOR is as follows:</p>
  <ul>
    <li>Register as a User by adhering to the relevant agreement;</li>
    <li>Then register as a Member by adhering to the relevant agreement;</li>
    <li>
      Create a reserved Account, consisting of an email and password, as
      outlined in the Membership purchase contract. The purchased Membership
      must be one that allows for the upgrade to become a DISTRIBUTOR
      (so-called Upgrade);
    </li>
    <li>
      Complete the Upgrade to become a DISTRIBUTOR by accessing the “Upgrade”
      link and adhere to this Agreement through the online procedure
      (so-called Point & Click).
    </li>
  </ul>
  <h4>4.2.</h4>
  <p>
    The individual intending to adhere to this Agreement is aware and accepts
    that each registration phase to become a DISTRIBUTOR is managed by the
    COMMERC.IO APP, as well as all stages of execution and termination of the
    Agreement. Furthermore, the DISTRIBUTOR is aware that, to carry out their
    activity, they will need to acquire a sufficient quantity of CCC to cover
    the invitation costs referred to in Art. 6 and any other transactions on
    the Commercio.network blockchain.
  </p>
  <h4>4.3.</h4>
  <p>
    Registration on the COMMERC.IO APP and adherence to this Agreement entail
    the explicit declaration of the following:
  </p>
  <ol type="a">
    <li>
      To have carefully read the terms and conditions of the Agreement before
      adherence and completion of the related online procedure and to accept
      its terms and conditions;
    </li>
    <li>
      To be aware that adherence through Point & Click is equivalent to
      approval and signing of the Agreement;
    </li>
    <li>
      To guarantee and declare that the provided data is truthful and that the
      signing of the Agreement does not violate any law, regulation, or public
      authority authorization, and also to be aware that any violation of
      these rules and/or authorizations will allow COMMERCIO to immediately
      terminate the Agreement pursuant to Art. 17;
    </li>
    <li>
      To comply with the individual conditions regarding the receipt of COM
      and CCC and to accept them;
    </li>
    <li>
      if requested, to undertake to complete the identification and anti-money
      laundering procedure (so-called Anti-Money-Laundering and
      Know-your-client) completely and truthfully, providing the data required
      by law and a valid identity document;
    </li>
    <li>
      To be aware that only upon purchase of a Membership through the
      COMMERC.IO APP and as a result of adherence to this Agreement will the
      DISTRIBUTOR NODE be allocated for use in promotional activities, also
      for the purpose of payment of the COMPENSATION;
    </li>
    <li>
      To be aware and agree that all information and procedures carried out as
      a Member and as a DISTRIBUTOR will be recorded in their COMMERC.IO APP
      Account (for example, the purchase of Membership, joining the Agreement,
      reporting, settlement of the COMPENSATION, termination and/or withdrawal
      from the Agreement will be recorded). On a monthly basis, COMMERCIO will
      send the DISTRIBUTOR an invitation to review the information and
      transactions recorded in their Account.
    </li>
  </ol>
  <h4>4.4.</h4>
  <p>
    In particular, by accessing their Account, each DISTRIBUTOR may receive a
    monthly email summarising their activity, for example: 1) the
    CUSTOMER-MEMBERS acquired by COMMERCIO as a result of the promotional
    activity carried out by the DISTRIBUTOR in accordance with the terms and
    conditions of this Agreement; 2) the AMOUNTS earned.
  </p>
  <h4>4.5.</h4>
  <p>
    The DISTRIBUTOR understands and agrees that only one Account is permitted
    per legal entity and only one Membership per term. Therefore, should
    DISTRIBUTOR purchase a new Membership while a previous Membership is still
    valid, such new purchase shall be considered as a waiver of the previous
    Membership and the only Membership recognised by DISTRIBUTOR shall be the
    one last purchased.
  </p>
  <h4>4.6.</h4>
  <p>
    The DISTRIBUTOR is aware that the rules governing Utility tokens are
    constantly evolving, both from a technological and regulatory point of
    view, and therefore COMMERCIO reserves the right to request from the
    DISTRIBUTOR, at any time and if it deems it appropriate, further
    information and/or further statements in order to allow COMMERCIO to
    transfer the CCCs or COMs or to pay the Fee and/or to allow the provision
    of the services. The request for information or statements will be sent to
    the DISTRIBUTOR's email with a specific indication of the deadline and the
    manner in which he/she must provide it.
  </p>
  `,
  P5: `
  <h3>5. Duration of the Agreement and Right of Withdrawal</h3>
  <h4>5.1.</h4>
  <p>
    The Agreement becomes effective when COMMERCIO accepts the Membership
    request made by the Member who wishes to become a DISTRIBUTOR, submitted
    through the electronic platform (by clicking the "Upgrade" link). The
    DISTRIBUTOR is informed that electronic transactions related to COM and/or
    CCC (including VBR) are processed on the Commercio.network blockchain with
    immediate execution, which occurs during the fourteen-day withdrawal
    period from the conclusion of this Agreement provided to Consumers by Art.
    52 of the Consumer Code for distance contracts (contracts concluded
    without the physical and simultaneous presence of the Parties, as is the
    case with this Agreement). Therefore, pursuant to Art. 59 of the Consumer
    Code, the DISTRIBUTOR agrees to waive the right of withdrawal provided for
    in the regulations governing contracts concluded with Consumers.
  </p>
  <h4>5.2.</h4>
  <p>
    The Agreement has a duration of one year, with automatic renewal for an
    equal period unless terminated at least 60 (sixty) calendar days before
    the expiration of the Agreement. COMMERCIO will send the DISTRIBUTOR a
    notification of the automatic renewal of the Agreement, with at least 90
    calendar days' notice, to the email address provided at the time of
    registration.
  </p>
  <h4>5.3.</h4>
  <p>
    In order to exclude tacit renewal and terminate the Agreement, the
    DISTRIBUTOR shall carry out the cancellation procedure on the Account page
    with access to the ‘CANCEL MEMBERSHIP’ link. The DISTRIBUTOR is aware and
    accepts that the cancellation and non-renewal procedure of this Agreement
    is also equivalent to the cancellation of the Membership contract and
    therefore, with the cancellation of this Agreement, the cancellation of
    the contract as Member will also automatically take place.
  </p>
  <h4>5.4.</h4>
  <p>
    Following the cancellation of the Membership by the DISTRIBUTOR, COMMERCIO
    will deactivate the corresponding DISTRIBUTOR NODE without further notice.
  </p>
  <h4>5.5.</h4>
  <p>
    The DISTRIBUTOR is aware and accepts that the termination of this
    Agreement and the automatic withdrawal from the Membership contract also
    result in the closure of the Wallet as provided in the Membership
    contract.
  </p> 
  `,
  P6: `
  <h3>6. Customer Purchases and REWARD NODE Allocation</h3>
  <h4>6.1.</h4>
  <p>
    For the recognition of Reward Nodes to the DISTRIBUTOR, two different
    procedures for the purchase of goods or services by customers are
    possible:
  </p>
  <h5>6.1.1.</h5>
  <p>
    Invitation purchase procedure: The DISTRIBUTOR can send an invitation
    (hereinafter "Invitation") using the "invitations" function in the
    COMMERC.IO APP, entering the email address of the potential Customer to
    whom they intend to send the Invitation. The computer system will send an
    email to that email address containing a link, which will grant access to
    the Membership purchase page, or CCC, or goods and services on the
    COMMERC.IO APP. In order to make the purchase, the potential CUSTOMER must
    proceed with prior registration on the COMMERC.IO APP and adhere to the
    relevant agreement as a User. Each purchase made by the Customer through
    access to the Invitation link will be linked to the DISTRIBUTOR who sent
    such Invitation with the recognition of the respective Rewards.
  </p>
  <h5>6.1.2.</h5>
  <p>
    Purchase Procedure via Distributor's Website: Potential CUSTOMERS can
    purchase COMMERCIO products or services (including Membership and CCC) by
    accessing the unique website of the DISTRIBUTOR present on the COMMERC.IO
    APP. In order to make the purchase through the DISTRIBUTOR's website, it
    will be necessary for the potential CUSTOMER to register on the COMMERC.IO
    APP as a User, completing the relevant procedure and adhering to the
    relevant agreement. The computer system will record each access of
    potential Customers to the DISTRIBUTOR's website and each purchase made by
    the Customer through access to such website on the COMMERC.IO APP will be
    linked to the respective DISTRIBUTOR with recognition of the relevant
    Reward Nodes.
  </p>
  <h4>6.2.</h4>
  <p>
    Any sale of products or services takes place directly between COMMERCIO
    and the CUSTOMER and not between the DISTRIBUTOR and the CUSTOMER. The
    relevant sale will be indicated in the Account of the DISTRIBUTOR who sent
    the Invitation or whose unique web page the CUSTOMER has accessed. This
    Agreement does not govern the transfer of MERCHANDISE goods and services
    by DISTRIBUTOR to CUSTOMERS or third parties.
  </p>
  <h4>6.3.</h4>
  <p>
    The CUSTOMER with a type of Membership that allows the Upgrade to become a
    Distributor and who decides to subscribe to this Agreement to carry out
    promotional activities will in turn become a DISTRIBUTOR and will be
    assigned a new distributor code linked to the DISTRIBUTOR who invited them
    or from whose unique web page they made purchases. Each DISTRIBUTOR will
    then be part of a promotion chain with its own level.
  </p>
  <h4>6.4.</h4>
  <p>
    The linking mechanism between DISTRIBUTORS will operate up to the limit of
    the fourth distributor following the first DISTRIBUTOR (e.g. the code of
    the fifth distributor will be linked to the codes of the fourth, third,
    second and first distributor, but will have no link to the Distributor
    Code of the fifth distributor in the promotion chain).
  </p>
  <h4>6.5.</h4>
  <p>
    DISTRIBUTORS whose Distributor Codes are linked to each other may receive
    the Fees set forth in Article 7. In the event that a linked DISTRIBUTOR
    does not renew its membership in this Agreement and no longer qualifies as
    a DISTRIBUTOR, the other linked DISTRIBUTORS shall retain their level in
    the chain of links for the purposes of the allocation and calculation of
    their respective Fees.
  </p>  
  `,
  P7: `
  <h3>7. COMPENSATION, charges and payment terms</h3>
  <h4>7.1.</h4>
  <p>
    For each purchase made by a CUSTOMER who received the Invitation or who
    accessed the unique website of the Distributor, the DISTRIBUTOR will be
    entitled to the COMPENSATION as described below, to be calculated based on
    the tables available on the HTTPS://COMMERC.IO website and in force at the
    time of purchase of COMMERCIO products and services by Customers promoted
    by the DISTRIBUTOR. In the same indicated table, the percentages of
    Compensation attributed to the different levels of DISTRIBUTOR regarding
    the chain of connections will also be reported, as described in the
    previous article.
  </p>
  <h4>7.2.</h4>
  <p>The regulation of NODE REWARDS is as follows:</p>
  <ol type="a">
    <li>
      Compensation in EURO to be calculated based on the aforementioned table,
      for each type of Membership purchased by CUSTOMERS as a result of the
      promotional activity and according to the type of purchase procedure
      between the two described in the previous article.
    </li>
    <li>
      Each Membership entitles to different COMMERCIO products or services, as
      provided in the Membership contract, and each type of Membership is
      assigned a differentiated and increasing Compensation, starting from
      Green Membership up to Platinum Membership.
    </li>
    <li>
      Each DISTRIBUTOR can receive the NODE REWARDS only if the Membership
      purchased by the CUSTOMER is of a level equal to or lower than the
      Membership purchased by the DISTRIBUTOR and valid at the time of
      purchase of the CUSTOMER's own Membership (e.g., the DISTRIBUTOR holding
      a Silver Membership can receive the Node Rewards only if the Customer,
      recipient of the Invitation, purchases a Bronze, Red, Blue Membership
      from COMMERCIO, while they will not receive any Node Rewards if the
      CUSTOMER purchases a Gold or Platinum Membership).
    </li>
    <li>
      If the CUSTOMER purchases a Membership of a higher level than that of
      the DISTRIBUTOR, COMMERCIO will send the DISTRIBUTOR an email
      notification informing them of the purchase and advising them that they
      will have 30 calendar days to purchase a new Membership of equal or
      higher level to that purchased by the CUSTOMER in order to be recognized
      the Node Rewards for that purchase. The cost of the new Membership will
      be calculated as follows: for the purchase of a new Membership of a
      higher level made at least 6 months before the expiration of the lower
      level Membership currently valid, it will be equal to 50% of the price
      difference between the two Memberships of different levels; if instead
      the purchase of a new Membership of a higher level is made less than six
      months before the expiration of the lower level Membership currently
      valid, it will be equal to the entire amount of the Membership of higher
      level.
    </li>
    <li>
      If the DISTRIBUTOR does not proceed with the purchase of the new higher
      level Membership within the 30 calendar days, they will lose any right
      to be recognized the Node Rewards related to the purchase of the higher
      Membership by the CUSTOMER.
    </li>
  </ol>
  <h4>7.3.</h4>
  <p>
    The DISTRIBUTOR acknowledges and agrees to pay COMMERCIO, through the
    Commercio.network blockchain, a variable commission ranging from (0.01 CCC
    to 0.25 CCC), depending on the type of Membership of each DISTRIBUTOR, for
    each single Invitation sent to a CUSTOMER via the DISTRIBUTOR NODE within
    the scope of the promotional activity subject to the Agreement. The
    commission fee will be automatically deducted from the system for each
    Invitation.
  </p>
  <h4>7.4.</h4>
  <p>
    COMMERCIO, through a monthly email (Monthly Summary Email), will report to
    the DISTRIBUTOR every purchase made by a CUSTOMER as a result of the
    latter's promotion during the successful month (i.e., sales for which
    COMMERCIO receives the payment of the price). The DISTRIBUTOR is
    responsible for contesting the data reported in the Monthly Summary Email
    within 48 hours of receipt, failing which they will forfeit their right to
    contest. DISTRIBUTORS with VAT numbers must issue an invoice payable to
    Commercio.network SPA bearing the unique code.
  </p>
  <h4>7.5.</h4>
  <p>
    In the event of termination of the Agreement, the DISTRIBUTOR will be
    entitled to Compensation for successful sales until the day of the
    Agreement's expiration.
  </p>  
  `,
  P8: `
  <h3>
  8. Course, Logos, Distributor Node Customisation and Activity Restrictions
  </h3>
  <h4>8.1.</h4>
  <p>
    The DISTRIBUTOR shall, for the duration of this Agreement, have access to
    a course dedicated to the sale of the Membership. The course is free of
    charge and is made up of videos created by COMMERCIO and usable via the
    COMMERC.IO APP (hereinafter also referred to as ‘Digital Content’) owned
    by and/or exclusively available to COMMERCIO, for which the latter holds
    all rights of use and economic exploitation. The Digital Content may only
    be used by accessing the COMMERCIO APP in accordance with the procedures
    set out in the User Agreement and may not be downloaded, in accordance
    with the terms and conditions of use of the COMMERCIO APP set out in the
    User Agreement.
  </p>
  <h4>8.2.</h4>
  <p>
    Each DISTRIBUTOR can personalize their own DISTRIBUTOR NODE by inserting
    their logo and color or by adding digital content (e.g., videos or texts).
    For this purpose, COMMERCIO will make available a settings menu to
    customize their website.
  </p>
  <h4>8.3.</h4>
  <p>
    By inserting such logos, the DISTRIBUTOR declares to be the rightful owner
    of them or, in any case, to be authorized to use them, and releases and
    exonerates COMMERCIO from any liability towards the DISTRIBUTOR or third
    parties arising from the use of such logos, fronts, videos, texts, or any
    other form and type of content inserted by the DISTRIBUTOR into their own
    DISTRIBUTOR NODE. The DISTRIBUTOR will also be responsible for any damage
    suffered by COMMERCIO due to the use of such logos or types of content of
    any form on their own DISTRIBUTOR NODE.
  </p>
  <h4>8.4.</h4>
  <p>
    Subject to immediate termination of the Agreement pursuant to Article 17,
    in carrying out their promotional activity, the DISTRIBUTOR MUST NOT
    engage in any of the following activities:
  </p>
  <ol type="a">
    <li>
      Direct their action towards subjects with characteristics or
      qualifications different from those provided for by the Agreement for
      CUSTOMERS (e.g., private individuals).
    </li>
    <li>
      Damage the image, reputation, or credibility of the company COMMERCIO
      (including its employees and its organs and representatives), partners,
      and clients of COMMERCIO, or towards other DISTRIBUTORS, nor make false,
      misleading, or denigratory statements in relation to Commercio.network
      and its related services and products.
    </li>
    <li>
      Make statements under any form regarding the future value of TOKENS.
    </li>
    <li>
      Use, in any form or manner, the future price of COM as a promotional
      argument.
    </li>
    <li>
      Create websites or advertisements that copy or imitate Commercio.network
      and/or its services and products in a pedantic manner or in a way that
      generates confusion in the CUSTOMERS' market or creates confusion about
      the nature and services of Commercio.network.
    </li>
    <li>
      Copy, in whole or in part, the COMMERCIO website, or use the name and
      Logo of COMMERCIO for illicit purposes or, in any case, for purposes
      other than the execution of the Agreement or in violation thereof.
    </li>
    <li>
      Use automatic filling techniques of the Registration Form by CUSTOMERS
      and/or automate in any form their promotional activity.
    </li>
    <li>
      Make offers, or use the Logo or trademarks or the name of COMMERCIO or
      Commercio.network, or use incorrect keywords for the so-called
      pay-per-click practice on internet search engines.
    </li>
    <li>
      Illegally use the Logo or trademarks or the name of COMMERCIO or
      Commercio.network in domain names, or use keywords.
    </li>
    <li>
      Use marketing material or advertising on any website that promotes
      violence, discrimination based on race, sex, religion, nationality,
      disability, sexual orientation, or age, or any illegal activity, or that
      violates intellectual property or other rights of third parties.
    </li>
    <li>
      Send emails in violation of European Regulation No. EU/2016/679 GDPR and
      without obtaining the consent of the data subject.
    </li>
    <li>
      Send marketing emails to promote the products and services of COMMERCIO
      that are misleading or create uncertainty about the identity of the
      sender.
    </li>
    <li>
      Use malware or spyware to market or promote the services and products of
      COMMERCIO.
    </li>
  </ol>
  <h4>8.5.</h4>
  <p>
    Furthermore, also subject to the immediate termination of the Agreement
    pursuant to Article 17, the DISTRIBUTOR declares and warrants:
  </p>
  <ol type="a">
    <li>
      To ensure that the DISTRIBUTOR NODE functions correctly before sending
      the invitation traffic.
    </li>
    <li>
      To clearly disclose to CUSTOMERS that they are the sender of all
      promotional communications regarding the products and services of
      COMMERCIO, and not COMMERCIO.
    </li>
  </ol>
  `,
  P9: `
  <h3>9. SWAP - Staking and Staking Rewards</h3>
  <h4>9.1.</h4>
  <p>
    Each DISTRIBUTOR will have the option to proceed with the exchange of CCC
    for COM using the SWAP function available in the Wallet. The exchange of
    CCC for COM will take place at a fixed exchange rate of 1:1 (one to one).
  </p>
  <h4>9.2.</h4>
  <p>
    Each DISTRIBUTOR will have the option to proceed with the Staking of their
    own COM at the COMMERCIO Validator Node, using the "STAKE" function
    available in the COMMERC.IO APP, possibly also after exchanging CCC for
    COM using the above SWAP function.
  </p>
  <h4>9.3.</h4>
  <p>
    This Staking function is solely intended to secure the commercio.network
    network through the Proof of Stake mechanism, which allows a certain
    amount of tokens to be locked on a validator node that performs the
    validation activity of all blockchain transactions and must not be used
    for speculative purposes by the DISTRIBUTOR.
  </p>
  <h4>9.4.</h4>
  <p>
    The DISTRIBUTOR is aware and accepts that the VBR will be paid by
    COMMERCIO in COM, according to the Validation Block Rewards (VBR)
    accumulation mechanism which is detailed in the Project and for which
    reference is made to the link docs.commercio.network. In particular, the
    DISTRIBUTOR is aware and accepts that: A) a 10% commission will be applied
    to the quantity of VBR accrued as a result of the Staking in favor of the
    Validator Node delegated by the DISTRIBUTOR for the validation activity
    carried out; furthermore B) the quantity of COM delegated to the Validator
    Node for Staking will be subject to a reduction ranging from 1% to 5% if
    the delegated Validator Node performs a double validation signature of a
    blockchain transaction or fails to keep the server active for a period
    exceeding 15 hours.
  </p>
  <h4>9.5.</h4>
  <p>
    The DISTRIBUTOR is aware and accepts that the Project may be varied,
    modified, and updated without this entailing any right for the DISTRIBUTOR
    to compensation or refund or compensation for the possible difference
    between the amount of VBR that could be hypothetically and calculably
    expected at the time of purchase of the COM and/or their Staking and that
    resulting at the time of the actual calculation of the accrued COM and
    their credit to the DISTRIBUTOR.
  </p> 
  `,
  P10: `
  <h3>10. Warranty Limitations</h3>
  <h4>10.1. Warranty Limitation Regarding the COMMERC.IO APP</h4>
  <p>
    The DISTRIBUTOR acknowledges and accepts that the COMMERC.IO APP used for
    promotional activities through the DISTRIBUTOR NODE may be subject to
    system errors or malfunctions that in no case will give rise to
    COMMERCIO's liability.
  </p>
  <h4>10.2. Warranty Limitation Concerning COM and CCC</h4>
  <h5>10.2.1.</h5>
  <p>
    The DISTRIBUTOR acknowledges, understands, and expressly accepts that the
    COM and/or CCC are transferred and can be used "AS IS" and "AS AVAILABLE"
    without any promise or warranty from COMMERCIO of qualities,
    characteristics, or functionalities other than those described below. The
    DISTRIBUTOR declares to have been adequately informed about:
  </p>
  <ul>
    <li>the functionalities and characteristics of the COM and CCC;</li>
    <li>
      the fact that the COM and CCC will be transferred to the DISTRIBUTOR in
      accordance with the Project. Subsequently, the COM may be purchased from
      exchanges that list them or, alternatively, through direct exchange with
      other parties that hold them.
    </li>
  </ul>
  <h5>10.2.2.</h5>
  <p>
    The DISTRIBUTOR acknowledges that COMMERCIO does not provide any express
    or implied warranty of merchantability or exchangeability of the COM into
    cryptocurrencies or legal tender, nor suitability of the COM for purposes
    and uses other than Staking.
  </p>
  <h5>10.2.3.</h5>
  <p>The DISTRIBUTOR is aware and accepts that:</p>
  <ol type="a">
    <li>
      receiving CCC or COM may have tax implications for him and is solely
      responsible for fulfilling any tax obligations related to the receipt
      and/or possession of CCC or COM;
    </li>
    <li>
      CCC and COM can only be transferred to third parties who have wallets
      that meet the NATIVE COSMOS standards;
    </li>
    <li>
      CCC and COM are not a financial product or service and do not constitute
      an investment, nor a form of financing. This Agreement and all other
      documents referred to herein do not constitute prospectuses, quotations,
      investment offers, securities offers, or exchange offers to receive
      securities or goods;
    </li>
    <li>
      CCCs and COMs are neither a cryptocurrency, nor an asset-linked
      cryptocurrency token (i.e. a type of crypto-asset that is not an e-money
      token and that seeks to maintain a stable value by reference to the
      value of an official currency within the meaning of Art. 3(1) no. 7
      MICAR), nor an asset-linked token (i.e. a type of crypto-asset which is
      not an e-money token and which seeks to maintain a stable value by
      reference to another value or right or a combination of the two,
      including one or more official currencies within the meaning of Article
      3(1)(6) MICAR)
    </li>
    <li>
      CCCs and COMs do not constitute corporate participations, nor do they
      confer any equity and/or administrative rights relating to COMMECIO. By
      way of example and not limitation, they do not confer the right to
      dividends, or to assets, or to income from COMMERCIO, nor do they
      entitle the holder to reimbursements and liquidations, or the right to
      participate in meetings, or to vote, or other financial or legal rights;
    </li>
    <li>
      CCCs and COMs are not currently convertible into official currency or
      cryptocurrency and may not be in the future. In particular, it is warned
      that CCCs or COMs may never be listed on a Centralised Exchange (CEX) or
      Decentralised Exchange (DEX) and, therefore, no liquid exchange market
      for CCCs or COMs may be established;
    </li>
    <li>
      CCCs and COMs may only be listed on a Centralised Exchange (CEX) or
      Decentralised Exchange (DEX) if other Users or Members, or other CCC or
      COM holders, establish a liquidity pool with the CCCs or COMs and other
      crypto assets in the future. Even in the event that CCCs or COMs are
      listed in a CEX or DEX in the future, they may be delisted at any time
      without any liability to COMMERCIO, including for the CCCs or COMs not
      being able to be traded, or for their loss of value. DISTRIBUTOR
      confirms, understands and agrees that if the CCCs or COMs were to be
      listed in CEX or DEX, their value would be susceptible to the rules of
      the market and could vary significantly, such that the CCCs or COMs may
      not even have any value. The DISTRIBUTOR understands and accepts that
      COMMERCIO shall not be held responsible for their market value,
      convertibility and/or liquidity.
    </li>
  </ol>
  <h5>10.2.4.</h5>
  <p>
    By agreeing to this Agreement, the DISTRIBUTOR declares to have received
    sufficient information regarding the functionalities and characteristics
    of CCC and COM.
  </p>
  <h4>
    10.3. Warranty Limitation Regarding the Commercio.network Blockchain
  </h4>
  <h5>10.3.1.</h5>
  <p>
    Similarly, the DISTRIBUTOR acknowledges, understands, and accepts that the
    Commercio.network blockchain, to which it will have access, is equipped
    with the functionalities "AS IS" and "AS AVAILABLE". The DISTRIBUTOR is
    aware and accepts that:
  </p>
  <ol type="a">
    <li>
      there is no warranty from COMMERCIO of the complete realization and/or
      development of Commercio.network with respect to the Project;
    </li>
    <li>
      Commercio.network is still in development and may undergo significant
      changes over time compared to the Project. Although at the moment
      COMMERCIO intends to develop this blockchain so that it has the
      characteristics and specifications established in the Project, it is
      understood that COMMERCIO may subsequently make changes to these
      characteristics and specifications at its sole discretion, even if this
      might not meet the DISTRIBUTOR's expectations for technical,
      technological, legislative or regulatory, economic, or market reasons;
    </li>
    <li>
      the development of Commercio.network may eventually be abandoned by
      COMMERCIO for a number of objective reasons, including, but not limited
      to: lack of public interest, lack of funding, technical or technological
      impediments, or legislative or regulatory impediments;
    </li>
    <li>
      in many jurisdictions, the definition and regulation at the legislative
      or regulatory level of cryptographic tokens, digital resources, and
      blockchain technology is not defined and is still evolving. It is
      difficult to predict how or if government authorities will regulate such
      technologies. Similarly, it is difficult to predict how or if a
      government authority may amend existing laws, regulations, and/or rules
      that will affect cryptographic tokens, digital resources, blockchain
      technology, and its applications. Such changes could have a negative
      impact on Commercio tokens and/or Commercio Cash Credit in various ways,
      including, for example, determining that Commercio tokens and/or
      Commercio Cash Credit are regulated financial instruments and therefore
      require relevant authorizations or registrations. COMMERCIO may
      therefore, at its discretion or at the order of the authority, cease the
      sale of Commercio tokens, and/or Commercio Cash Credit and/or cease the
      development of the Commercio.network blockchain if government actions
      make it illegal or commercially undesirable to continue doing so.
      Consequently, the DISTRIBUTOR declares to be aware and accepts that:
      <ul>
        <li>
          the Commercio.network blockchain has been developed, completed and
          put into operation with version 5 and it is not known whether
          further versions will be developed and/or put into operation;
        </li>
        <li>
          any versions after version 5 of Commercio.network could be developed
          or realised with different timeframes than those envisaged in the
          Project;
        </li>
        <li>
          Commercio.network could be developed and realised with different
          modalities, functionalities and possibilities of use than those
          indicated in the Project;
        </li>
        <li>
          the exchange and/or signature activities in encrypted and
          pseudo-anonymous form of documents between companies participating
          in Commercio.network might not be possible and/or might be
          executable with modalities, functionalities and/or costs different
          from those indicated in the Project.
        </li>
      </ul>
    </li>
  </ol>  
  `,
  P11: `
  <h3>11. Indemnification Limits</h3>
  <p>
    As a result, COMMERCIO shall not be obligated to compensate or indemnify
    the DISTRIBUTOR, nor any of its affiliates, in the event that:
  </p>
  <ol type="a">
    <li>
      Commercio tokens and Commercio Cash Credit cannot be converted into
      cryptocurrencies and/or legal tender, or are convertible at exchange
      rates different from those hoped for, hypothesized, or conceivable at
      the time of their purchase.
    </li>
    <li>
      Commercio.network is not developed in all phases of the Project, or is
      developed with delays or with functionalities and utilities different
      from those envisaged in the Project.
    </li>
    <li>
      Notarization and archiving activities among participating companies in
      the blockchain are temporarily not feasible, and/or cannot be carried
      out with modalities, functionalities, and/or costs different from those
      indicated in the Project.
    </li>
  </ol>
  `,
  P12: `
  <h3>12. Intellectual Property</h3>
  <p>
    COMMERCIO shall retain all its intellectual and industrial property
    rights, including, without limitation, inventions, ideas, know-how, trade
    secrets, concepts, codes, discoveries, processes, patents, trademarks,
    methods, software, compositions, formulas, techniques, information, and
    data, whether or not patentable or protected by copyright. The DISTRIBUTOR
    may not use any of COMMERCIO's intellectual or industrial property rights
    without the prior written consent of COMMERCIO.
  </p>
  `,
  P13: `
  <h3>13. Logo, APP COMMERC.IO, and COMMERCIO Website</h3>
  <h4>13.1. Logo</h4>
  <h5>13.1.1.</h5>
  <p>
    COMMERCIO owns its own logo, brand, slogan, symbols, trademarks, including
    those related to the Commercio.network blockchain, and under this
    Agreement, intends to grant a license to use said logo, slogan, brand,
    symbols, trademarks (collectively the "Logo") to the DISTRIBUTOR free of
    charge and non-exclusively.
  </p>
  <h5>13.1.2.</h5>
  <p>
    As a result of this license grant, once the Agreement is in effect, the
    DISTRIBUTOR may indicate its affiliation with Commercio.network on its
    letterhead, website, and in its printed or digital documentation, using
    the Logo, slogan, brand, symbols, trademarks, including those of the
    blockchain itself.
  </p>
  <h5>13.1.3.</h5>
  <p>
    For the duration of the Agreement, the DISTRIBUTOR has the right to use
    the Logo, subject to all property and non-property rights therein
    belonging to COMMERCIO.
  </p>
  <h5>13.1.4.</h5>
  <p>
    The DISTRIBUTOR is not authorized to register any domain name or website
    that contains - in whole or in part - the name, Logo, brands, slogans, or
    symbols, etc. of Commercio.network or COMMERCIO.
  </p>
  <h5>13.1.5.</h5>
  <p>
    Upon termination of the Agreement, for any reason, the right to use the
    Logo, brands, slogans, symbols, etc. of the blockchain in any way will
    cease.
  </p>
  <h5>13.1.6.</h5>
  <p>
    In the event that the DISTRIBUTOR uses the Logo or any reference to
    Commercio.network in any way after the termination of the Agreement, they
    shall be liable to COMMERCIO for all financial and non-financial damages
    incurred.
  </p>
  <h4>13.2. APP COMMERC.IO and Website</h4>
  <h5>13.2.1.</h5>
  <p>
    The COMMERCIO APP and Website are the exclusive property of COMMERCIO and
    may only be used by the DISTRIBUTOR in execution of the Agreement, for
    which they shall be liable for any financial and non-financial damages
    resulting from its violation.
  </p>
  <h5>13.2.2.</h5>
  <p>
    Upon termination of the Agreement, for any reason, the DISTRIBUTOR must
    refrain from using the COMMERCIO APP and Website in any way.
  </p>
  <h5>13.2.3.</h5>
  <p>
    In the event that the DISTRIBUTOR uses the COMMERCIO APP and Website in
    any way after the termination of the Agreement, they shall be liable to
    COMMERCIO for all financial and non-financial damages incurred.
  </p>  
  `,
  P14: `
  <h3>14. Confidentiality agreement</h3>
  <h4>14.1.</h4>
  <p>
    The Parties expressly agree that all information, data, results, or
    documents related to this Agreement shall be kept confidential. For the
    purposes of this Agreement, confidential information (hereinafter referred
    to as 'Confidential Information') means any information, including but not
    limited to scientific information, comprising any document disclosed or
    delivered by COMMERCIO for the benefit of the DISTRIBUTOR
  </p>
  <h4>14.2.</h4>
  <p>
    In particular, the Parties agree that all Confidential Information
    communicated in any form—verbal, written, electronic, or any other
    intelligible form—by COMMERCIO to the DISTRIBUTOR, or otherwise acquired
    by the latter in the course of the relationships between the Parties,
    including, but not limited to, any information covered by industrial or
    intellectual property rights (patents and related registration
    applications, unregistered inventions, processes, formulas, trade or
    industrial secrets, know-how, trademarks and copyright and related rights,
    registered and unregistered, registered designs or their registration
    applications, software), information and models related to business
    processes, financial and managerial information and models, names of
    customers, suppliers, business partners, commercial transactions,
    proposals, reports, market projections, promotional material, data, or any
    other confidential information, as well as analyses, working documents,
    data collections, comparative tables, studies, and any document prepared
    by COMMERCIO (or by its client, collaborator, consultant, or supplier)
    within the scope of the activity related to this Agreement, containing,
    reflecting, or based on the aforementioned information, shall be deemed
    confidential.
  </p>
  <h4>14.3.</h4>
  <p>
    Confidential Information is deemed to be confidential regardless of
    whether COMMERCIO has marked it as ‘confidential’ or similar.
  </p>
  <h4>14.4.</h4>
  <p>
    The obligation of confidentiality shall also extend to all drawings,
    designs, software and any documents or data etc. produced by COMMERCIO in
    execution of this Agreement, it being understood that, from the outset of
    its preparation, everything produced by COMMERCIO in execution of this
    Agreement shall constitute Confidential Information within the meaning and
    effect of this Agreement.
  </p>
  <h4>14.5.</h4>
  <p>
    The obligations described above shall not apply to confidential
    information:
  </p>
  <ol type="a">
    <li>
      of which the receiving party can prove that it had knowledge prior to
      its receipt from the other party under this Agreement; or
    </li>
    <li>
      Which is in the public domain at the time the disclosure is made for the
      benefit of the receiving party or which is in the public domain as a
      result of an event not involving the breach of this Agreement; or
    </li>
    <li>
      which have been lawfully acquired by the recipient from an independent
      source having the right of disclosure thereof.
    </li>
  </ol>
  <h4>14.6.</h4>
  <p>
    The Parties agree to treat as confidential any and all information and
    commit to:
  </p>
  <ol type="i">
    <li>
      Use the Confidential Information exclusively for the purpose of
      executing this Agreement and not to use the Confidential Information,
      either directly or indirectly, for purposes other than those provided
      for in this Agreement;
    </li>
    <li>
      Not copy or reproduce the Confidential Information in any way, nor allow
      third parties or employees or collaborators to do so;
    </li>
    <li>
      Not disclose the same to third parties, nor make it public or accessible
      by any other means, unless expressly permitted by written consent issued
      by COMMERCIO in favor of the DISTRIBUTOR;
    </li>
    <li>
      Limit the communication of Confidential Information to their own
      employees, consultants, or collaborators to the extent strictly
      necessary for the execution of this Agreement. It is understood that in
      any case, the DISTRIBUTOR shall remain responsible and liable towards
      COMMERCIO jointly with the individuals materially responsible for any
      and all violations of this Agreement.
    </li>
  </ol>
  <h4>14.7.</h4>
  <p>
    In the event of a request by law, regulation, court ruling or legitimate
    request by the authority for knowledge of the Confidential Information,
    the DISTRIBUTOR must notify COMMERCIO without delay.
  </p>
  <h4>14.8.</h4>
  <p>
    This confidentiality obligation will last until the Confidential
    Information has become public knowledge, provided that this does not occur
    in violation of this Agreement. It is understood that if one or more
    elements constituting Confidential Information become public knowledge,
    the obligation of confidentiality and secrecy will remain in force for all
    elements that have not become public knowledge.
  </p>
  <h4>14.9.</h4>
  <p>
    Upon the expiration of the Agreement for any reason, the DISTRIBUTOR shall
    immediately return to COMMERCIO, without retaining any copies, all
    documents, drawings, plans, notes, elaborations, prototypes, or other
    materials containing Confidential Information, including any attached
    documents.
  </p>  
  `,
  P15: `
  <h3>15. Changes to the Agreement</h3>
  <h4>15.1.</h4>
  <p>
    Unilateral Amendments. COMMERCIO reserves the right to amend this
    Agreement by notifying the DISTRIBUTOR via email, sent to the address
    provided upon Account creation, only if such amendments are necessary to
    adapt the terms of the Agreement to legislation and/or to the evolution of
    blockchain and token technology. The amendments will be deemed accepted
    and will become definitive 30 (thirty) days after the communication of the
    amendments has been sent. The DISTRIBUTOR who does not intend to accept
    the amendments may only withdraw from this Agreement by notifying
    COMMERCIO of their intention to withdraw within 30 (thirty) days from the
    communication mentioned above. The DISTRIBUTOR shall have the right to
    withdraw without any costs or charges if they oppose the proposed
    amendment within the specified period and manner. The consequences of
    withdrawal are governed by Article 5 (Agreement Duration).
  </p>
  <h4>15.2.</h4>
  <p>
    Other Amendments. Any modification of this Agreement other than the above
    shall be agreed to in writing by the Parties on penalty of nullity.
  </p>
  `,
  P16: `
  <h3>16. Limitations of liability of COMMERCIO</h3>
  <h4>16.1.</h4>
  <p>COMMERCIO does not guarantee any profit.</p>
  <h4>16.2.</h4>
  <p>
    The DISTRIBUTOR declares to be aware that any promotional activity will be
    carried out independently, at their own risk, and without any
    reimbursement of expenses incurred by COMMERCIO, nor will any materials of
    any kind (e.g., brochures, gadgets, etc.) be provided.
  </p>
  <h4>16.3.</h4>
  <p>
    COMMERCIO shall not be held liable for any damages, pecuniary or
    non-pecuniary, suffered by the DISTRIBUTOR in the performance of the
    promotional activities referred to in this Agreement or, in any case,
    directly or indirectly connected thereto.
  </p>
  <h4>16.4.</h4>
  <p>
    The DISTRIBUTOR shall be solely responsible for its business with third
    parties and shall be liable for any claims, actions, appeals or demands
    made by third parties in connection with its business.
  </p>
  <h4>16.5.</h4>
  <p>
    The DISTRIBUTOR agrees to indemnify and hold COMMERCIO, its employees,
    contractors, suppliers and third parties harmless from and against all
    damages, pecuniary and non-pecuniary, loss of earnings, penalties,
    expenses and costs (including the costs of legal assistance or expert
    witnesses) that may arise in relation to the promotional activity or, in
    any case, directly or indirectly connected to it.
  </p>  
  `,
  P17: `
  <h3>17. Express termination clause</h3>
  <h4>17.1.</h4>
  <p>
    The Parties agree that this Agreement, except in cases provided by law,
    may be lawfully terminated by COMMERCIO pursuant to and for the purposes
    of Article 1456 of the Civil Code, by notification via registered mail
    with return receipt, or PEC, or any other means providing legal proof of
    receipt, in the event that the DISTRIBUTOR is in default or breaches any
    of the obligations provided in the following articles: breach of the rules
    on promotional activities (Art. 8.4), breach of COMMERCIO's industrial and
    intellectual property rights (Art. 12), breach of the rules on COMMERCIO's
    Logo (Art. 13), breach of confidentiality obligations as per Art. 14,
    breach of the prohibition on assigning this Agreement.
  </p>
  <h4>17.2.</h4>
  <p>
    In such cases, termination shall occur as of right, when COMMERCIO
    declares to the DISTRIBUTOR that it intends to make use of this clause.
    This shall be without prejudice to COMMERCIO's right to compensation for
    damages.
  </p>  
  `,
  P18: `
  <h3>18. Pre-contractual Information and General Provisions</h3>
  <h4>18.1.</h4>
  <p>
    Pre-contractual Information. For contracts concluded without the physical
    and simultaneous presence of the Parties (so-called distance contracts),
    as is the case with adherence to this Agreement, the law requires that the
    Consumer, before being contractually bound, receives a series of
    information about the other contracting Professional, the main contractual
    rights and obligations of the Consumer, and the characteristics of the
    contractual services (Art. 49, paragraph 1, and Art. 51 of the Consumer
    Code). Consumo).
  </p>
  <h4>18.2.</h4>
  <p>
    This information is already formulated in the various articles of the
    Agreement and for the sake of clarity and simplicity of exposition is
    summarised below:
  </p>
  <ul>
    <li>
      The main characteristics of COM and CCC (as indicated in the preamble
      and in articles 10 and 11) of the service provided by the DISTRIBUTOR
      and their compensation (Article 7 Compensation and Article 8 methods of
      carrying out promotional activities).
    </li>
    <li>
      The identity of COMMERCIO, the geographical address where it is
      established, and the electronic address [possibly also the telephone
      number] that allows the DISTRIBUTOR to quickly contact and communicate
      effectively with COMMERCIO are indicated in the heading of Article 18.3.
    </li>
    <li>
      The purchase price and payment methods for goods and services provided
      by COMMERCIO, including COM and, if applicable, CCC, are indicated in
      the Membership Agreement, while the exchange conditions (SWAP) between
      CCC and COM are indicated in Article 9.
    </li>
    <li>
      The methods and timing of transactions of Node Rewards are indicated in
      Article 7 "Compensation, fees, and payment terms".
    </li>
    <li>
      The information that the DISTRIBUTOR will not benefit from the right of
      withdrawal within 14 days from the conclusion as per article 52 of the
      Consumer Code is provided in Article 5 (Duration of the Agreement) under
      the sub-paragraph "Immediate Execution and Waiver of Withdrawal".
    </li>
    <li>
      Duration of the Agreement: see Article 5 (Duration of the Agreement).
    </li>
    <li>
      The functionality of Digital Content and Digital Services, including
      applicable technical protection measures and relevant compatibility and
      interoperability of Digital Content and Digital Services, of which the
      COMPANY is aware or can reasonably be expected to be aware, if
      applicable, are indicated in Article 8 (Course – Logos and
      customization).
    </li>
  </ul>
  <h4>18.3.</h4>
  <p>
    Should one or more of the clauses contained in this Agreement be annulled
    or be declared null and void or ineffective according to law, the validity
    of the remaining clauses shall remain unaffected.
  </p>
  <h4>18.4.</h4>
  <p>
    The Parties are independent contractors and this Agreement does not and
    will not create between the Parties any relationship of partnership, joint
    venture or trust, nor will it create any other form of association that
    would hold one Party liable for the actions or defaults of the other, nor
    will it provide either Party with the right, power or authority (express
    or implied) to create any lien on the other.
  </p>
  <h4>18.5.</h4>
  <p>
    This Agreement is in the Italian language. Any versions in different
    languages constitute courtesy translations. In the event of any
    discrepancy between the text in the Italian language and the text in
    another language, the Italian version shall prevail.
  </p>
  <h4>18.6.</h4>
  <p>
    Communications. Any communication from the DISTRIBUTOR to COMMERCIO may be
    made by email to info@commerc.io or by pec to commerc.io@postecert.it , or
    by registered mail ar to Commercio.network spa Via Luigi dalla Via , 3/B -
    36015 Schio (VI).
  </p>  
  `,
  P19: `
  <h3>19. Applicable Law and Jurisdiction</h3>
  <h4>19.1.</h4>
  <p>This Agreement is governed by Italian law.</p>
  <h4>19.2.</h4>
  <p>
    If the DISTRIBUTOR is a Consumer, pursuant to Article 3, paragraph 1,
    letter a) of the Consumer Code, on any disputes that are not resolved
    through ADR (Alternative Dispute Resolution), the competent judge shall be
    indisputably the judge of the place of residence or elected domicile of
    the DISTRIBUTOR. If the DISTRIBUTOR is not a Consumer, any disputes that
    are not resolved through ADR shall be exclusively decided by the Court of
    Vicenza.
  </p>
  <h4>19.3.</h4>
  <p>
    For ADR involving consumers, the European Commission provides a platform
    for ADR and online dispute resolution, which can be accessed via this
    link: https://ec.europa.eu/consumers/odr/
  </p>  
  `,
  TITLE_ADDITIONALS: `Additional Terms`,
  P_ADDITIONALS: `
  <p>
    Pursuant to Art. 1341 and Art. 1342 of the Italian Civil Code, the
    DISTRIBUTOR declares to have carefully read and approved the following
    provisions of the Agreement: Automatic renewal (Art. 5), Limitation of the
    right to raise objections and forfeiture (Art. 7), Limitation of warranty
    (Art. 10), Limitation of indemnity (Art. 11), Unilateral amendments and
    tacit consent (Art. 15), Express termination clause (Art. 17), Exclusive
    jurisdiction (Art. 19)
  </p>
  `,
});
