export default Object.freeze({
  TITLE: `Accordo di acquisto di beni e servizi tecnologici (“Accordo”)<br />Termini e
  condizioni generali`,
  P1: `
  <h2>1. Ambito di applicazione</h2>
  <p>
    Le seguenti previsioni delle condizioni generali regolano il rapporto tra
    Commercio.network SpA (di seguito “COMMERCIO.NETWORK”), con sede legale in
    36015- Schio (VI), Via Luigi dalla Via n. 3/b, cf/piva 04136930247, in persona
    del suo legale rappresentante pro tempore e il soggetto che aderisce alle
    presenti condizioni generali per la fruizione dei servizi di Membership (di
    seguito “MEMBER”).<br />
    Fanno parte integrante dell’Accordo anche i termini, le condizioni e le
    previsioni contenute nei link ipertestuali riportati nelle seguenti condizioni
    generali nella versione vigente al compimento di ogni singola attività del
    Member.<br />
    Questo Accordo costituisce l’intero accordo tra le Parti avente il medesimo
    oggetto e supera ogni altro precedente avente il medesimo oggetto e supera,
    altresì, eventuali condizioni generali del Member, che saranno inefficaci.
  </p>
  `,
  P2: `
  <h2>2. Definizioni</h2>
  <p>I termini di seguito indicati hanno il significato qui precisato:</p>
  <ul>
    <li>
      Blockchain Commercio.Network o Commercio.Network: è la rete blockchain
      costituita dall’insieme dei Nodi che permette la creazione di identità
      decentralizzate, nonchè la firma e l’invio di documenti.
    </li>
    <li>
      User: é il soggetto, persona fisica o giuridica, che ha completato la
      procedura di registrazione su Commercio.App.
    </li>
    <li>
      Member: è il soggetto persona fisica o giuridica che ha aderito al presente
      Accordo ed ha acquistato una delle diverse tipologie di Membership, ovvero
      GREEN, BRONZE, SILVER, GOLD.
    </li>
    <li>
      Green Member: é il soggetto persona fisica, che agisce per scopi estranei
      all'attività imprenditoriale, commerciale, artigianale o professionale
      eventualmente svolta ai sensi dell'art. 3 comma 1 lett. a) del decreto
      legislativo n. 206/2005 (c.d. codice del consumo) e che ha aderito al
      presente Accordo mediante il completamento della procedura prevista presso
      Commercio.App e che abbia acquistato una Green Membership.
    </li>
    <li>
      Bronze Member, Silver Member e Gold Member: sono rispettivamente le persone
      fisiche o giuridiche, titolare di partita iva, che agiscono nell’esercizio
      della propria attività professionale, imprenditoriale, commerciale o
      artigianale e diverso dai soggetti indicati dall’articolo 3, comma 1, lett.
      a-b, del decreto legislativo n. 206/2005 (c.d. codice del consumo), che
      abbiano acquistato una Bronze Membership, una Silver Membership o una Gold
      Membership.
    </li>
    <li>
      Membership: è il rapporto tra il soggetto interessato (a condizione che sia
      già User) e COMMERCIO.NETWORK, che si instaura con l'adesione al presente
      Accordo e che permette di beneficiare del pacchetto di servizi (Membership
      Benefits) forniti ai Member da COMMERCIO.NETWORK attraverso la Blockchain
      Commercio.Network
    </li>
    <li>
      Membership Benefits: è l’insieme dei servizi forniti ai Member da parte di
      COMMERCIO.NETWORK (attraverso la Blockchain Commercio.Network), ottenibili
      acquistando una Membership.
    </li>
    <li>
      Affiliate Programs: è l’insieme di tutti i presenti e futuri programmi di
      affiliazione a cui possono aderire i Bronze Member, Silver Member o Gold
      Member.
    </li>
    <li>
      Validator: è il soggetto (persona giuridica) con partita iva, che agisce
      nell’esercizio della propria attività professionale, imprenditoriale,
      commerciale o artigianale e diverso dai soggetti indicati dall’articolo 3,
      comma 1, lett. a-b, del decreto legislativo n. 206/2005 (c.d. codice del
      consumo), che svolge l’attività di gestione di un Nodo Validatore di
      Commercio.Network.
    </li>
    <li>
      Validator Program: è il programma di affiliazione che sancisce i diritti e
      di doveri dei Nodi Validatori.
    </li>
    <li>
      Distributor: è il soggetto (persona fisica o giuridica) con partita iva, che
      agisce nell’esercizio della propria attività professionale, imprenditoriale,
      commerciale o artigianale e diverso dai soggetti indicati dall’articolo 3,
      comma 1, lett. a-b, del decreto legislativo n. 206/2005 (c.d. codice del
      consumo), che svolge l’attività di promozione dei servizi di Membership dei
      CCC di COMMERCIO.NETWORK.
    </li>
    <li>
      Distributor Program: è il programma di affiliazione che sancisce i diritti e
      di doveri dei Distributor.
    </li>
    <li>
      Developer: è il soggetto (persona fisica o giuridica) con partita iva, che
      agisce nell’esercizio della propria attività professionale, imprenditoriale,
      commerciale o artigianale e diverso dai soggetti indicati dall’articolo 3,
      comma 1, lett. a-b, del decreto legislativo n. 206/2005 (c.d. codice del
      consumo), che svolge l’attività di sviluppo di App.
    </li>
    <li>
      Developer Program: è il programma di affiliazione che sancisce i diritti e
      di doveri dei Developers.
    </li>
    <li>
      Ambassador: è il soggetto (persona fisica o giuridica) con partita iva, che
      agisce nell’esercizio della propria attività professionale, imprenditoriale,
      commerciale o artigianale e diverso dai soggetti indicati dall’articolo 3,
      comma 1, lett. a-b, del decreto legislativo n. 206/2005 (c.d. codice del
      consumo) che svolge l’attività di promozione dei servizi di Membership di
      COMMERCIO.NETWORK.
    </li>
    <li>
      Ambassador Program: è il programma di affiliazione che sancisce i diritti e
      di doveri degli Ambassadors.
    </li>
    <li>
      Commercio token (simbolo COM): sono utility token generati da
      Commercio.Network in numero predeterminato di 60 milioni e con valore
      determinato dal mercato, il cui acquisto (tramite piattaforma digitale o
      soggetti terzi) è imponibile secondo aliquota IVA ordinaria ai sensi del
      decreto del Presidente della Repubblica n. 633/1972, creati per rendere
      sicura la Blockchain attraverso il meccanismo di validazione tramite Messa
      in Stake di token.
    </li>
    <li>
      Commercio Cash Credit (simbolo CCC): sono utility token generati da
      Commercio.network in numero non predeterminato venduti attualmente a euro
      1,00 (uno) od al diverso prezzo che verrà indicato nella procedura di
      acquisto al momento della registrazione del soggetto interessato, oltre ad
      aliquota IVA ordinaria ai sensi del decreto del Presidente della Repubblica
      n. 633/1972, creati per effettuare le transazioni della Blockchain
      Commercio.Network.
    </li>
    <li>Utility Token: COM e CCC collettivamente intesi.</li>
    <li>
      VBR: è il compenso in COM che i Member ricevono, a determinate condizioni,
      quando effettuano l’attività della Messa in Stake di COM, in proprio (in
      qualità di Nodi) oppure con delega a soggetti terzi che siano Nodi
      Validatori.
    </li>
    <li>
      ABR: è il compenso in COM che i Member ricevono, a determinate condizioni,
      quando promuovono nei confronti dei Clienti, secondo le condizioni e i
      termini dell’Accordo, l’acquisto di Membership GREEN, BRONZE, SILVER, GOLD.
    </li>
    <li>
      Messa in Stake: è l’attività specifica di stake secondo il protocollo
      informatico del sistema di Proof of Stake, ossia la costituzione di un
      vincolo a scopo di garanzia della correttezza dell’attività svolta come Nodo
      Validatore di Commercio.Network secondo le regole del sistema.
    </li>
    <li>
      Nodi Validatori: sono i nodi validatori, ossia server collegati tra loro in
      modo da creare una infrastruttura distribuita e decentralizzata per eseguire
      l’attività di validazione delle transazioni che avvengono sulla Blockchain
      Commercio.Network.
    </li>
    <li>
      Protocollo: è il protocollo di consenso della Blockchain Commercio.Network
      che utilizza il metodo Proof of Stake (o POS) e che si basa sull’algoritmo
      Tendermint.
    </li>
    <li>
      Progetto: è il progetto di sviluppo della Blockchain Commercio.Network ed è
      disponibile al link https://commercio.network/it/progetto.
    </li>
    <li>
      Software: è il software sviluppato da COMMERCIO.NETWORK necessario per
      l’utilizzo della Blockchain Commercio.Network,
    </li>
    <li>
      Validazione: è il meccanismo di validazione della Blockchain
      Commercio.Network che si basa su un sistema di consenso che utilizza il
      metodo ed algoritmo cd. Proof of Stake (POS),
    </li>
    <li>
      eID (o Commercio eID) è un wallet elettronico gestito (Hosted) che permette
      ai Member di creare e gestire una identità sovrana SSI (Self Sovereign
      Identity) e di verificare il saldo del eID wallet in crediti e token
    </li>
    <li>
      Credits Wallet: è un wallet elettronico gestito (Hosted) che permette ai
      Member di acquistare, conservare, ricevere, tracciare, trasferire e gestire
      i saldi di Commercio Cash Credit (simbolo CCC)
    </li>
    <li>
      Token Wallet: è un wallet elettronico gestito (Hosted) che permette ai
      Member di scambiare, conservare, ricevere, tracciare, trasferire e gestire i
      saldi di Token presenti su Commercio.Network (esempio simbolo COM)
    </li>
    <li>
      DID:COM, è l’indirizzo univoco sulla blockchain del wallet e della identità
      decentralizzata eID.
    </li>
    <li>
      CommercioID: è il protocollo informatico che permette di creare un'identità
      decentralizzata.
    </li>
    <li>
      CommercioSign: è il protocollo informatico che permette di creare una firma
      elettronica.
    </li>
    <li>
      CommercioDocs: è il protocollo informatico che permette di scambiare un
      documento, certificandone l'integrità.
    </li>
    <li>
      Commercio.App: è il sistema elettronico dove un utente (User) può
      registrarsi, e successivamente acquistare una Membership che permette di
      accedere a: eID, App, Wallet, Token Wallet e Affiliate Programs.
    </li>
    <li>
      App o APP: è un’applicazione software basata sui protocolli di
      Commercio.Network e creata da un Developer per essere utilizzata dai Member,
      previa registrazione ed eventuale acquisto.
    </li>
    <li>
      Apps: è il sistema elettronico dove un Member può trovare una App e
      registrarsi
    </li>
    <li>
      API: (Application Programming Interface) è l'Interfaccia di programmazione
      fornito da COMMERCIO.NETWORK che permette ai Member di integrare i propri
      software applicativi con i protocolli disponibili di Commercio.Network.
    </li>
  </ul>
  `,
  P3: `
  <h2>3. Oggetto dell’Accordo</h2>
  <h3>3.1. Membership</h3>
  <p>
    COMMERCIO.NETWORK ha lanciato la Blockchain Commercio.Network. Questo Accordo
    disciplina i termini e le condizioni per l’acquisto di una Membership,
    mediante la quale il Member può acquistare i servizi basati su
    Commercio.Network (i Membership Benefits). Per usufruire o acquistare
    qualsiasi prodotto o servizio all’interno di Commercio.Network è necessario
    utilizzare l’Utility token denominato Commercio Cash Credit (CCC).<br />
    Un Member, mediante accesso all’area dedicata di Commercio.App può procedere
    ad una o più tra le seguenti opzioni di acquisto:
  </p>
  <ul>
    <li>
      GREEN per le persone fisiche che agiscono per scopi estranei all'attività
      imprenditoriale, commerciale, artigianale o professionale eventualmente
      svolta ai sensi dell'art. 3 comma 1 lett. a) del decreto legislativo n.
      206/2005 (c.d. codice del consumo),
    </li>
    <li>
      BRONZE, SILVER e GOLD per le persone fisiche o giuridiche, titolare di
      partita iva, che agiscono nell’esercizio della propria attività
      professionale, imprenditoriale, commerciale o artigianale e diverso dai
      soggetti indicati dall’articolo 3, comma 1, lett. a-b, del decreto
      legislativo n. 206/2005 (c.d. codice del consumo)
    </li>
  </ul>
  <p>
    Tutte le Membership (GREEN, BRONZE, SILVER e GOLD) ottengono una eID da
    utilizzare per tutti i rapporti contrattuali con l’intero eco sistema di
    Commercio.Network e l'Interfaccia di programmazione (API).<br />
    La titolarità di una Membership BRONZE, SILVER e GOLD , inoltre, permette al
    Member di acquistare Commercio Cash Credit (CCC).<br />
    Con l'adesione a Commercio.Network ciascun Member si impegna come segue nei
    confronti di COMMERCIO.NETWORK e di ciascun altro Member:
  </p>
  <ol type="a">
    <li>
      ad accettare di essere identificato attraverso il sistema kyc - know your
      client previsto nel presente accordo,
    </li>
    <li>a riconoscere e a non contestare la eID di ciascun Member,</li>
    <li>
      a riconoscere e a non contestare la sottoscrizione eSignature apposta su
      documenti da un altro Member attraverso l’utilizzo di App dedicate e/o da
      COMMERCIO.NETWORK su Commercio.App,
    </li>
    <li>
      a riconoscere e a non contestare la consegna eDelivery di documenti
      effettuata da un altro Member attraverso l’utilizzo di App dedicate e/o da
      COMMERCIO.NETWORK su Commercio.App,
    </li>
    <li>
      ad utilizzare gli Utility Token come unità di conto e di pagamento dei
      Member Benefits utilizzati attraverso l’utilizzo di App dedicate e/o da
      COMMERCIO.NETWORK su Commercio.App.
    </li>
  </ol>
  <h3>3.2. Extra Benefits</h3>
  <p>
    Secondo i termini e le condizioni del presente Accordo, COMMERCIO.NETWORK, nel
    corso della durata della partecipazione alla Membership, potrà offrire,
    gratuitamente o a pagamento, ai Member una serie di ulteriori e distinti Extra
    Benefits. Gli Extra Benefits sono servizi aggiuntivi rispetto ai Membership
    Benefits forniti da COMMERCIO.NETWORK ai propri Member, che di volta in volta
    soddisfino i requisiti previsti ed indicati da COMMERCIO.NETWORK, quali
    criteri di idoneità al singolo e distinto Extra Benefit.<br />
    COMMERCIO.NETWORK si riserva di poter prevedere e di poter modificare i
    criteri di idoneità alla attribuzione di ciascun Extra Benefit, di poter
    attribuire o meno al Member ciascun Extra Benefit, di poter modificare il
    contenuto di ciascun Extra Benefit, tutto ciò in qualsiasi momento e per
    qualsivoglia ragione, senza che ciò comporti l'insorgenza in capo al Member di
    alcun diritto ad indennizzo e/o risarcimento di danni patrimoniali e non
    patrimoniali di alcun genere.<br />
    In questo momento, COMMERCIO.NETWORK offre i Free Commercio Cash Credits.
    L’accesso a questo servizio sperimentale è concesso per un periodo temporaneo
    e può essere rimosso in qualsiasi momento. Il servizio consiste in una dazione
    variabile gratuita una Tantum di Commercio Cash Credits CCC a scopo
    promozionale utilizzabili al solo fine di testare i servizi.<br />
    La lista con l'elenco ed il contenuto degli eventuali e ulteriori Extra
    Benefits è prevista al seguente link
    https://commercio.app/membership/extrabenefits presso Commercio.App e sarà
    aggiornata di volta in volta da parte di COMMERCIO.NETWORK. COMMERCIO.NETWORK
    si riserva, a propria esclusiva discrezione di verificare la sussistenza dei
    requisiti di idoneità di ciascun Member per l'attribuzione del singolo Extra
    Benefit. L'attribuzione del singolo Extra Benefit sarà comunicata da
    COMMERCIO.NETWORK al Member mediante email all'indirizzo di posta elettronica
    comunicato al momento della registrazione a Member e/o mediante notifica che
    apparirà al Member al momento dell'accesso al proprio Account.
  </p>
  <h3>3.3. Acquisto di Utility Tokens di Commercio.Network</h3>
  <p>
    Per usufruire o acquistare qualsiasi prodotto o servizio all’interno di
    Commercio.Network è necessario utilizzare l’Utility token denominato Commercio
    Cash Credit (CCC).
  </p>
  `,
  P4: `
  <h2>4. Base contrattuale</h2>
  <p>
    La sottoscrizione del presente Accordo da parte di un soggetto interessato è
    possibile a condizione che il soggetto si sia già preventivamente registrato
    quale User, aderendo al relativo accordo con COMMERCIO.NETWORK.<br />
    Lo User interessato ad aderire al presente Accordo, dovrà completare
    correttamente la procedura di registrazione quale Member e procedere
    all'acquisto e al pagamento di una delle tipologie di Membership (GREEN,
    BRONZE, SILVER e GOLD). Solo una volta che il soggetto si sia registrato
    presso Commercio.App e abbia successivamente ricevuto conferma della sua
    iscrizione da parte di COMMERCIO.NETWORK, egli diventerà, e sarà considerato a
    tutti gli effetti, un Member di Commercio.Network.
  </p>
  `,
  P5: `
  <h2>5. Tipologie di Membership</h2>
  <p>
    Il Member che abbia acquistato una delle tipologie di Membership, potrà fruire
    dei tipi di Member Benefits previsti per quella specifica tipologia di
    Membership. Qualora un Member desideri fruire di servizi previsti in una
    diversa tipologia di Membership, dovrà acquistare tale diversa tipologia di
    Membership, non essendo previsto dal presente Accordo l'acquisto del singolo
    Member Benefit aggiuntivo.<br />
    COMMERCIO.NETWORK si impegna, per la durata e secondo i termini e le
    condizioni del presente Accordo, nei confronti del singolo Member a fornire i
    singoli Member Benefits di seguito indicati e successivamente meglio precisati
    nell’area Commercio.app/membership/:
  </p>
  <h3>GREEN MEMBERSHIP</h3>
  <p>Questa tipologia dà diritto ai seguenti Member Benefits:</p>
  <ul>
    <li>eID</li>
  </ul>
  <h3>BRONZE MEMBERSHIP (AMBASSADOR)</h3>
  <p>Questa dà diritto ai seguenti Member Benefits:</p>
  <ul>
    <li>eID</li>
    <li>Interfaccia di programmazione (API);</li>
    <li>il Member può acquistare o detenere CCC Commercio Cash Credits;</li>
    <li>
      il Member potrà partecipare ai programmi di affiliazione Ambassador offerti
      da COMMERCIO.NETWORK quando verranno attivati, previa sottoscrizione del
      relativo contratto.
    </li>
  </ul>
  <h3>SILVER MEMBERSHIP (DEVELOPER)</h3>
  <p>Questa dà diritto ai seguenti Member Benefits:</p>
  <ul>
    <li>eID</li>
    <li>Interfaccia di programmazione (API) ;.</li>
    <li>il Member può acquistare o detenere CCC Commercio Cash Credits;</li>
    <li>
      il Member potrà partecipare ai programmi di affiliazione Developer offerti
      da COMMERCIO.NETWORK quando verranno attivati, previa sottoscrizione del
      relativo contratto.
    </li>
  </ul>
  <h3>GOLD MEMBERSHIP (DISTRIBUTOR)</h3>
  <p>Questa dà diritto ai seguenti Member Benefits:</p>
  <ul>
    <li>eID</li>
    <li>Interfaccia di programmazione (API);</li>
    <li>il Member può acquistare o detenere CCC Commercio Cash Credits;</li>
    <li>
      il Member potrà partecipare ai programmi di affiliazione Distributor offerti
      da COMMERCIO.NETWORK quando verranno attivati, previa sottoscrizione del
      relativo contratto.
    </li>
  </ul>
  <p>
    Per le Membership GREEN, BRONZE, SILVER e GOLD COMMERCIO.NETWORK provvederà a
    creare e a rendere disponibile al Member su Commercio.App un wallet con un
    indirizzo univoco pubblico di identificazione non trasferibile (denominato di
    seguito “DID:COM”).
  </p>
  `,
  P6: `
  <h2>6. Procedura di registrazione del Member</h2>
  <p>
    Il contratto tra COMMERCIO.NETWORK ed il potenziale Member avviene unicamente
    previa registrazione quale Member e con l’adempimento delle formalità di
    seguito indicate. La registrazione potrà avvenire nei seguenti modi:
  </p>
  <ol type="a">
    <li>per mezzo di Commercio.App;</li>
    <li>
      per mezzo di un Distributor, con registrazione al link attribuito da
      COMMERCIO.NETWORK al Distributor secondo i termini e le condizioni del
      contratto concluso con quest'ultimo;
    </li>
    <li>
      per mezzo di un Developer, con registrazione al link attribuito da
      COMMERCIO.NETWORK al Developer secondo i termini e le condizioni del
      contratto concluso con quest'ultimo;
    </li>
    <li>
      per mezzo di altro Member che partecipa all’Ambassador Program, con
      registrazione al link attribuito da COMMERCIO.NETWORK all'Ambassador.
    </li>
  </ol>
  <p>
    Una volta completato con successo il processo di registrazione, il soggetto
    interessato potrà acquistare una GREEN Membership o una delle altre tre
    Membership disponibili: BRONZE, SILVER e GOLD. L'adesione alla Membership è
    riservata a chi si sia già registrato con successo come User.<br />
    Ai fini dell’acquisto della Membership il soggetto interessato dovrà
    completare correttamente la fase di identificazione fornendo i seguenti dati
    richiesti, previsti come obbligatori:
  </p>
  <ul>
    <li>
      Persone Fisiche: Nome, Cognome, email, Cellulare, Codice fiscale e Nazione
      di residenza Fiscale e Partita Iva (quando non si tratta di un consumatore
      ai sensi dell'art. 3 comma 1 lett. a) del decreto legislativo n. 206/2005);
    </li>
    <li>
      Persone giuridiche: Nome azienda, Nome e Cognome del Legale rappresentante,
      email Legale rappresentante, Cellulare Legale rappresentante, Codice fiscale
      legale rappresentante, Partita Iva e Nazione di residenza Fiscale
    </li>
  </ul>
  <p>
    Il soggetto interessato si impegna a fornire tutti i dati richiesti al momento
    della sua registrazione. In particolare, il soggetto interessato si impegna a
    fornire informazioni veritiere, accurate e complete durante la registrazione e
    l'utilizzo della Commercio.App ed a garantirne il periodico aggiornamento.<br />
    Il soggetto interessato è consapevole che il mancato conferimento di tutti i
    dati in questione comporterà l’impossibilità per COMMERCIO.NETWORK di
    accettare la sua richiesta di adesione al presente Accordo.<br />
    Completata con successo la procedura di registrazione, il Member diventerà
    titolare di un account personale (l’Account") con indicazione di una email e
    creazione di una password personale.<br />
    L’accesso a Commercio.App avverrà mediante login, indicando email e password.
    COMMERCIO.NETWORK, a seguito dell’acquisto della Membership, permetterà al
    Member di attivare un'app mobile di Strong Customer Authentication (SCA) che
    permetterà all’utente di generare una One Time Password (OTP che costituisce
    il secondo fattore di autenticazione di sicurezza). Il Member si impegna a non
    rivelare alcuna informazione sull'Account a nessun soggetto.<br />
    Per motivi di sicurezza, in nessun caso potranno essere condivisi con soggetti
    terzi i dettagli dell'Account e/o le password, OTP necessarie per accedervi,
    inoltre il Member si impegna ad adottare ogni misura idonea, ragionevole ed
    opportuna a tutela e protezione del proprio Account. Il Member riconosce di
    essere l'unico responsabile del mantenimento della riservatezza e della
    sicurezza dell'Account e di tutte le attività che si svolgono sull'Account o
    attraverso di esso.<br />
    Qualsiasi violazione della sicurezza dell'Account dovrà essere comunicata a
    COMMERCIO.NETWORK immediatamente, e comunque senza ritardo alcuno;
    COMMERCIO.NETWORK non sarà responsabile per danni, costi o spese derivanti o
    conseguenti alla mancata o ritardata comunicazione delle violazioni.<br />
    Il Member è consapevole ed accetta che:
  </p>
  <ol type="I">
    <li>
      prima di comunicare a COMMERCIO.NETWORK i propri dati personali, il soggetto
      interessato dichiara di avere letto e compreso la privacy policy di
      Commercio.App (reperibile al seguente link https://commercio.app/privacy/) e
      che una volta registrato si impegna a prendere visione di ogni successivo
      aggiornamento o modifica;
    </li>
    <li>
      i danni conseguenti alla non esattezza e/o completezza delle informazioni
      fornite non potranno essere imputati a COMMERCIO.NETWORK e si impegna a
      tenere indenne e manlevata COMMERCIO.NETWORK da eventuali responsabilità
      connesse a dichiarazione mendaci colpevolmente fornite; inoltre
    </li>
    <li>
      eventuali danni che siano conseguenza dell'accesso all'Account da parte di
      terzi non autorizzati, a seguito della violazione da parte del Member delle
      misure di sicurezza previste e/o consigliate o consigliabili da attuarsi
      secondo le regole di prudenza, non potranno in nessun caso essere imputati a
      COMMERCIO.NETWORK; inoltre
    </li>
    <li>
      eventuali violazioni delle disposizioni di cui sopra possono comportare la
      sospensione e/o la cancellazione dell'Account ai sensi degli art. 11.5 e
      art. 18.2.
    </li>
  </ol>
  <p>
    COMMERCIO.NETWORK si riserva il diritto di chiedere, in qualsiasi momento e a
    proprio insindacabile giudizio, ulteriori informazioni relative al Member per
    consentire la corretta operatività ed erogazione dei servizi. In caso di
    rifiuto, COMMERCIO.NETWORK potrà sospendere l'operatività dell'Account, ovvero
    procedere alla sua cancellazione.<br />
    Il Member si impegna ad informare tempestivamente COMMERCIO.NETWORK di
    eventuali variazioni dei propri dati personali forniti al momento della
    registrazione (in particolare, indirizzo, indirizzo e-mail, coordinate
    bancarie, numero di telefono, ecc.).<br />
    È consentito un solo Account per ciascun soggetto fisico o giuridico in
    possesso di partita IVA interessato a diventare un Member. Pertanto, lo User
    persona fisica che intende diventare Member potrà attivare al massimo un
    Account quale persona fisica che agisce per scopi estranei all'attività
    imprenditoriale, commerciale, artigianale o professionale eventualmente svolta
    ai sensi dell'art. 3 comma 1 lett. a) del decreto legislativo n. 206/2005
    (c.d. codice del consumo) ed un altro Account quale titolare di partita iva,
    che agiscono nell’esercizio della propria attività professionale,
    imprenditoriale, commerciale o artigianale e diverso dai soggetti indicati
    dall’articolo 3, comma 1, lett. a-b, del decreto legislativo n. 206/2005 (c.d.
    codice del consumo). Resta fermo che in tali casi i singoli Account dovranno
    essere associati a distinti Dispositivi, essendo vietato utilizzare un Account
    su Dispositivo diverso da quello al quale sia stato associato al momento della
    registrazione.<br />
    Nel caso in cui uno User, dovesse effettuare una registrazione multipla,
    COMMERCIO.NETWORK avrà diritto di risolvere di diritto l’Accordo ai sensi
    dell’art. 18.1 o sospenderne il servizio ai sensi dell’art. 11.5. In caso di
    registrazioni multiple, gli Account registrati successivamente al primo
    verranno cancellati, con immediata perdita di tutti i Membership Benefits
    ottenuti esclusivamente sulla base della registrazione multipla.<br />
    L’accettazione dell’Accordo, da parte del soggetto interessato, dovrà
    avvenire, previa compilazione della procedura di registrazione, mediante
    sistema di Point & Click da acquisire preventivamente tramite flag di conferma
    da parte dal soggetto interessato. Il rapporto contrattuale disciplinato dalle
    condizioni generali del presente Accordo si perfeziona quando il soggetto
    interessato abbia ricevuto la conferma via email di accettazione inviata da
    COMMERCIO.NETWORK.<br />
    Il Member dovrà compilare correttamente la fase di identificazione
    (Know-your-client-KYC) fornendo tutti i dati richiesti, previsti come
    obbligatori dalla procedura di registrazione, fornendo anche valido documenti
    di identità.
  </p>
  `,
  P7: `
  <h2>7. Rapporto giuridico</h2>
  <p>
    Tra COMMERCIO.NETWORK e il Member non si costituisce nessun tipo di rapporto
    di lavoro, di servizio o societario (in particolare, nessun rapporto di tipo
    associativo). La partecipazione del Member ai diversi Programs: Validator
    program, Distributor program, Developer program e Ambassador program avviene
    esclusivamente nell’ambito di un’attività autonoma e giuridicamente
    indipendente da COMMERCIO.NETWORK sotto la propria responsabilità.<br />
    Il Member avrà diritto unicamente a quanto previsto e disciplinato nel
    presente Accordo, ossia ai Membership Benefits. Il Member non ha diritto ad
    alcun ulteriore compenso per la sua attività. Il Member non ha diritto a
    nessun rimborso spese. Nel caso in cui il Member inviti altri membri tramite
    il Validator Program, o il Distributor Program, o il Developer Program o
    l’Ambassador Program avrà diritto a quanto stabilito nei singoli accordi.<br />
    Il Member non è autorizzato a rappresentare COMMERCIO.NETWORK, in particolare,
    a fornire o ricevere dichiarazioni nei confronti di altri Member nell’ambito
    di qualisiasi Affiliate Program. Il Member non è autorizzato a ricevere denaro
    contante, né ad incassare somme in nome e per conto di COMMERCIO.NETWORK. Una
    violazione colpevole delle disposizioni di questo articolo dà diritto a
    COMMERCIO.NETWORK di risolvere di diritto l’Accordo ai sensi dell’art.
    18.1.<br />
    Senza previa autorizzazione espressa e per iscritto da parte di
    COMMERCIO.NETWORK o in assenza di uno specifico rapporto contrattuale
    (Validator Program - Distributor Program - Developer Program Ambassador
    Program ) con COMMERCIO.NETWORK, il Member non è autorizzato:
  </p>
  <ol type="a">
    <li>
      ad utilizzare loghi, scritte, marchi, slogan, domini, altri contrassegni e
      materiale simile di COMMERCIO.NETWORK, o di Commercio.Network, o loghi e
      marchi dei Developer ;
    </li>
    <li>
      a creare, in forma scritta o elettronica, diffondere o rendere disponibili
      al pubblico sotto altra forma biglietti da visita, presentazioni, video,
      file audio, screenshot, contenuti web, contenuti multimediali, flyer,
      brochure, siti web, documenti pubblicitari, stampati, mailing, homepage o
      simili riguardo a COMMERCIO.NETWORK, o a Commercio.Network e nominando
      espressamente quest’ultima o il Membership Benefits Program (ad esempio su
      pagine Internet come YouTube o Facebook) per esprimere orientamenti
      politici, ideologici o religiosi o con utilizzo di toni e termini
      oltraggiosi o che comunque travalicano il limite della critica oggettiva e
      civile;
    </li>
    <li>
      ad organizzare manifestazioni come, ad esempio, manifestazioni informative,
      eventi, workshop, seminari, ecc. riguardo COMMERCIO.NETWORK o
      Commercio.Network .
    </li>
    <li>
      a promuovere verso dettaglianti, grossisti ed altri rivenditori, nonché nei
      confronti di altre imprese o servizi destinati a consumatori finali, incluse
      stazioni di servizio, franchisee e grandi magazzini;
    </li>
    <li>
      ad agire in nome e/o per conto di COMMERCIO.NETWORK e ad assumere
      obbligazioni in nome e/o per conto di COMMERCIO.NETWORK.
    </li>
  </ol>
  `,
  P8: `
  <p>
  L'acquisto di una delle Membership è possibile attraverso uno dei canali
  descritti sopra all’art. 6 e previa corretta registrazione.<br />
  Il Member dichiara di aver compreso che Commercio.Network non è un sistema di
  pagamento in Valuta tradizionale FIAT “eMoney”, bensì di una blockchain
  decentralizzata, distribuita ed immutabile dove vengono registrate le
  transazioni di dati e documenti e pagate tramite Utility Tokens fra i membri
  del network. Si tratta di transazioni documentali con validità legale secondo
  i principi eIDAS, che possono essere effettuate esclusivamente tramite
  l’utilizzo di token CCC e COM. Questi Utility Token sono delle unità di conto
  e di valore su base volontaria disponibili solo sulla Blockchain
  Commercio.Network. L'acquisto di una Membership permette l’accesso ad App che
  sono rese disponibili sull’area Apps da parte dei Developers. Il Member potrà
  registrarsi o utilizzare queste App.
  </p> 
  `,
  P9: `
  <h2>9. Membership Benefits derivanti dall’acquisto della Membership</h2>
  <p>
    Le Membership GREEN, BRONZE, SILVER e GOLD danno diritto ad una eID che
    permetterà al Member di ottenere una identità sovrana SSI (Self Sovereign
    Identity). L’accesso vale per 1 anno.<br />
    Solo ed esclusivamente le Membership di tipo BRONZE SILVER GOLD daranno
    inoltre diritto ai seguenti Benefits:
  </p>
  <ul>
    <li>Interfaccia di programmazione (API);</li>
    <li>
      Credits Wallet (accesso per 1 anno). Questo wallet permetterà di acquistare
      Utility Token Token chiamati Commercio Cash Credit (simbolo CCC).
    </li>
  </ul>
  `,
  P10: `
  <h2>10. Creazione di COMMERCIO eID</h2>
  <p>
    Commercio.App dispone di una sezione denominata eID, ossia una identità
    sovrana (Self Sovereign Identity, o SSI), per mezzo della quale il Member
    potrà Dimostrare la paternità dei suoi Documenti. Poichè l'indirizzo del
    Wallet e quello della eID corrispondono, utente potrà anche prendere visione
    del saldo dei propri Commercio Cash Credits e Commercio Tokens, senza tuttavia
    poter effettuare alcuna operazione di acquisto, scambio trasferimento,
    gestione dei medesimi Tokens.<br />
    Nessun altro servizio è dovuto da COMMERCIO.NETWORK e nessun’altra attività
    e/o servizio verrà svolta da COMMERCIO.NETWORK.<br />
    In particolare, il Member dichiara di essere consapevole e di accettare quanto
    segue:
  </p>
  <ol type="a">
    <li>
      Il Wallet che controlla la Commercio eID non costituisce un servizio con cui
      COMMERCIO.NETWORK svolga attività di mediatore finanziario o di custodia e/o
      amministrazione di valute digitali ma può solo contenere dei token
      utilizzabili per i servizi di cui all’art. 9;
    </li>
    <li>
      COMMERCIO.NETWORK non garantisce in alcun modo la funzionalità, sicurezza e
      riservatezza di Commercio eID;
    </li>
    <li>
      l'uso di Commercio eID è sotto la esclusiva responsabilità del Member, a sua
      completa discrezione e nel rispetto di ogni legge applicabile;
    </li>
    <li>
      il Member è l'unico responsabile della custodia e sicurezza dell’Account,
      della password, della SCA-OTP e, in genere, di ogni codice di accesso allo
      Commercio eID (di seguito anche “Credenziali”). In particolare il Member si
      impegna ad adottare ogni misura idonea, ragionevole ed opportuna a tutela
      delle proprie Credenziali; a non comunicare a terzi le proprie Credenziali,
      a non condividere lo schermo di accesso a Commercio.App; a non consentire a
      terzi l'accesso da remoto a Commercio.App. Il Member è consapevole che lo
      smarrimento, la perdita, il furto, l'uso indebito e/o ogni altra perdita di
      controllo delle Credenziali potrebbe comportare la perdita, il furto e/o la
      indisponibilità in futuro dei Token contenuti nel proprio Commercio eID. Il
      Member è consapevole ed accetta che in tali casi COMMERCIO.NETWORK sarà
      esente da qualsivoglia responsabilità per quanto accaduto.
    </li>
    <li>
      nel caso in cui il Member dovesse smarrire, vedersi sottratte e/o perdere in
      qualsivoglia modo il controllo delle proprie Credenziali di accesso al
      proprio Commercio eID, non sarà possibile in alcun modo recuperarlo da parte
      di COMMERCIO.NETWORK e COMMERCIO.NETWORK stessa non potrà essere ritenuta in
      alcun modo responsabile di ogni eventuali perdita, né dei danni conseguenti.
    </li>
    <li>
      il Member è consapevole ed accetta che in caso di sospetto uso indebito e/o
      qualsivoglia ipotesi di violazione della sicurezza delle proprie Credenziali
      e/o qualsivoglia ipotesi di tentativo di frode e/o di accesso indebito e/o
      di violazione della sicurezza delle Credenziali (di seguito “Violazioni”),
      il Member stesso dovrà prontamente e senza ritardo alcuno informare
      COMMERCIO.NETWORK di quanto accaduto inviando al servizio di supporto
      informatico di Commercio.App una email - all'indirizzo
      info@commercio.network - contenente le informazioni accurate, approfondite
      ed aggiornate di quanto accaduto. Nel contempo il Member si impegna ad
      adottare ogni e tutte le misure ragionevoli e utili a ridurre e gestire le
      Violazioni della sicurezza. Nel caso di mancata tempestiva notifica a
      COMMERCIO.NETWORK da parte del Member, COMMERCIO.NETWORK non potrà essere
      ritenuto responsabile nè della adeguatezza, nè della tempestività della sua
      risposta, nè dei danni che ne dovessero derivare al Member
    </li>
  </ol>
  `,
  P11: `
  <h2>11. Interfaccia di programmazione (API)</h2>
  <p>
    COMMERCIO.NETWORK fornirà al Member BRONZE SILVER e GOLD l’Interfaccia di
    programmazione (API) che permette ai Member di integrare i propri software
    applicativi con i protocolli disponibili di Commercio.Network.<br />
    L’utilizzo delle API permette di inviare e archiviare delle transazioni sulla
    Blockchain Commercio.Network. I contenuti delle transazioni inviate, scambiate
    ed archiviate da ciascun Member sulla Blockchain Commercio.Network
    costituiscono i contenuti di quel Member (i “Contenuti”). Poiché la Blockchain
    è un registro immutabile, non esiste la possibilità di cancellare alcun
    dato.<br />
    Il Member è consapevole e accetta ciò che segue:
  </p>
  <h3>11.1. Contenuti.</h3>
  <p>
    Il Member si deve assicurare che i Contenuti e il relativo utilizzo non
    violino nessuna delle disposizione elencate all'Appendice A (“Utilizzi
    Vietati”) o qualsiasi legge applicabile. Il Member è l'unico responsabile
    dello sviluppo, del contenuto, del funzionamento, della manutenzione e
    dell'utilizzo dei propri Contenuti. Il Member si assumerà il pieno rischio di
    perdita e danneggiamento dei propri Contenuti. Il Member riconosce e accetta
    di essere l'unico responsabile di tutti gli atti, le omissioni, l'utilizzo, le
    spese sostenute con riferimento a qualsiasi Contenuto visualizzato, collegato,
    trasmesso o memorizzato tramite le API su Commercio.Network. Il Member è
    l'unico responsabile dell'adozione di misure per (i) prevenire qualsiasi
    perdita o danno ai Contenuti del Member; (ii) mantenere copie di archivio e di
    backup indipendenti dei Contenuti del Member; e (iii) garantire la sicurezza,
    la riservatezza e l'integrità dei Contenuti del Member trasmessi attraverso le
    API. I servizi API non sono destinati a essere utilizzati per il backup o
    l'archiviazione dei dati. COMMERCIO.NETWORK non è responsabile nei confronti
    del Member o di qualsiasi altra persona per la perdita, il danneggiamento o la
    distruzione di qualsiasi Contenuto del Member.
  </p>
  <h3>11.2. Credenziali di accesso e chiavi dell'account API.</h3>
  <p>
    Nella misura in cui COMMERCIO.NETWORK fornirà le credenziali di accesso API e
    l'autenticazione API generata dai servizi, tali credenziali di accesso e
    l'autenticazione API sono esclusivamente ad uso interno del Member e il Member
    non dovrà in nessun caso: vendere, trasferire o dare in sublicenza a qualsiasi
    altra entità o persona, ad eccezione della comunicazione della chiave privata
    da parte del Member ai propri collaboratori sotto la propria responsabilità.
  </p>
  <h3>11.3. Rischi per la sicurezza.</h3>
  <p>
    Il Member è consapevole che l'archiviazione dei propri Contenuti su o
    utilizzando i protocolli di Commercio.Network può comportare dei rischi, tra
    cui, a titolo esemplificativo, la perdita dei Contenuti stessi. Il Member è
    consapevole che COMMERCIO.NETWORK non sarà in alcun modo responsabile dei
    Contenuti del Member memorizzati su Commercio.Network; è inoltre consapevole e
    accetta che COMMERCIO.NETWORK non esercita alcun controllo sui Contenuto e/o
    sull'oggetto delle informazioni che passano attraverso la rete. Il Member
    dovrà valutare preventivamente, e comunque prima del trasferimento dei suoi
    Contenuti nella Blockchain, la legittimità e la liceità della propria
    condotta. L'importanza della valutazione di legittimità e liceità è dovuta in
    primis al fatto che ogni condotta del Member deve essere improntata al
    rispetto della normativa e dei diritti di terzi, nonché al fatto che per le
    caratteristiche della Blockchain Commercio.Network non è possibile la
    cancellazione di alcun dato una volta inserito nel sistema.<br />
    Il Member inviando ed archiviando i propri Contenuti nella blockchain assume
    in proprio la responsabilità di ogni condotta illecita e/o illegittima e/o in
    violazione dei diritti di terzi che abbia commesso con l'utilizzazione della
    blockchain Commercio.Network. Nell'ipotesi di in cui la condotta del Member
    dovesse risultare illecita, illegittima e/o in violazione dei diritti di
    terzi, il Member dovrà manlevare e tenere indenne COMMERCIO.NETWORK di
    qualsiasi costo, spesa (compreso ogni costo e spese per eventuale difesa di
    COMMERCIO.NETWORK in controversie giudiziali e non giudiziali), perdita, danno
    patrimoniale e non patrimoniale (compreso a titolo esemplificativo e non
    esaustivo il danno all'immagine ed i costi necessari ad eliminarlo) che
    COMMERCIO.NETWORK dovesse subire in relazione alla condotta del Member.
  </p>
  <h3>11.4. Privacy dei dati</h3>
  <p>
    COMMERCIO.NETWORK non svolge alcuna attività di trattamento dei dati personali
    presenti nei Contenuti, che si trovano sulla rete decentralizzata di
    Commercio.Network e non presso COMMERCIO.NETWORK. In ogni caso
    COMMERCIO.NETWORK non effettuerà l’accesso o l’utilizzo dei Contenuti.
  </p>
  <h3>11.5. Sospensione temporanea della Membership.</h3>
  <p>
    A determinate condizioni COMMERCIO.NETWORK potrà sospendere il diritto del
    Member di accedere o utilizzare la membership e quindi la totalità delle API.
    La sospensione della Membership avverrà a seguito di comunicazione da parte di
    COMMERCIO.NETWORK, a mezzo pec o raccomandata ar o altro mezzo che dia prova
    di ricezione, con preavviso di 48 ore successivi, ( salvo diversa disposizione
    di autorità giudizioari o altra pubblica autorità o disposizione di legge)
    qualora l'utilizzo delle API da parte del Member (i) rappresenti un rischio
    per la sicurezza di Commercio.App, di Comercio.Network o di terzi, (ii) possa
    avere un impatto negativo sui sistemi informatici o sui servizi di
    COMMERCIO.NETWORK, o sui sistemi informatici o sui Contenuti di qualsiasi
    altro Member , (iii) possa esporre COMMERCIO.NETWORK, i suoi affiliati o terzi
    a responsabilità, o (iv) possa essere fraudolento; (v) sia effettuato in
    violazione delle disposizioni dell’Appendice 1.<br />
    La sospensione permane sino alla completa eliminazione delle cause di
    sospensione sopra elencate e di cui il Member avrà dato comunicazione scritta
    a COMMERCIO.NETWORK, che si riserva la verifica.<br />
    La sospensione non libera il Member dall’obbligo di pagamento dei canoni
    annuali e delle spese sostenute durante il periodo di sospensione, fermo
    restando che il Member non avrà diritto ad alcun credito di servizio per
    qualsiasi periodo di sospensione.
  </p> 
  `,
  P12: `
  <h2>12. Credits wallet</h2>
  <p>
    COMMERCIO.NETWORK fornirà al Member BRONZE SILVER e GOLD un wallet (Wallet)
    abilitato per acquistare, ricevere, contenere, tracciare, trasferire e gestire
    un utility token denominato Commercio Cash Credit. Non è previsto il servizio
    da parte di COMMERCIO.NETWORK di cambio di criptovalute con altre criptovalute
    oppure il servizio di vendita od acquisto di criptovalute.
  </p>
  <h3>12.1. Acquisto e ricezione di CCC.</h3>
  <p>
    Il Member può acquistare e ricevere i Commercio Cash Credits da
    COMMERCIO.NETWORK mediante operazione sul proprio Credits Wallet. Al fine di
    acquistare gli Utility Token CCC, o comunque di effettuare operazioni sul
    proprio Wallet, ciascun Member dovrà seguire le indicazioni presenti su
    Commercio.App. Ciascuna operazione sarà processata dal sistema Commercio.App
    al più tardi entro un giorno lavorativo successivo alla richiesta.<br />
    Il Member, con la disposizione di acquisto, autorizza COMMERCIO.NETWORK ad
    addebitare i costi della operazione richiesta. Una volta ricevuta da
    Commercio.App la richiesta di acquisto, NON è più possibile revocarla, neppure
    nell'ipotesi in cui l'acquisto non sia ancora stato effettuato e/o registrato
    su Commercio.Network.<br />
    Qualora l'acquisto di Utility Token non andasse a buon fine per mancanza di
    fondi, Commercio.App provvederà a segnalare senza ritardo l'esito negativo
    della operazione richiesta dal Member.
  </p>
  <h3>12.2. Transazioni non autorizzate o non corrette.</h3>
  <p>
    Qualora l’acquisto di Utility Token sia effettuato utilizzando le Credenziali
    del Member, COMMERCIO.NETWORK riterrà che si tratti di una transazione
    autorizzata, salvo che COMMERCIO.NETWORK riceva una notifica in senso diverso
    (es. il Member comunica di non aver autorizzato la transazione) prima del
    compimento di tale operazione e/o di registrazione dell'operazione in
    Commercio.Network.<br />
    Se un Member ritiene che una transazione del Wallet sia stata effettuata senza
    autorizzazione, o se un Member ha ragione di ritenere che una transazione del
    Wallet sia stata eseguita in modo non corretto o non completo da parte di
    COMMERCIO.NETWORK, il Member si impegna a contattare immediatamente, e
    comunque senza ritardo, COMMERCIO.NETWORK.<br />
    Ciascun Member si impegna a controllare con regolarità gli esiti di ciascuna
    transazione, in modo da individuare e tracciare ogni transazione non
    autorizzata e/o non corretta e/o non completa e si impegna a segnalare a
    COMMERCIO.NETWORK tale evenienza senza ritardo alcuno e comunque entro 2
    giorni dal momento della scoperta.<br />
    Ciascun Member è consapevole ed accetta che la tardiva segnalazione di
    operazioni non autorizzate e/o non corrette e/o non complete oltre i termini
    di cui sopra comporterà l'impossibilità per COMMERCIO.NETWORK di eliminare il
    problema.<br />
    Nel caso in cui la transazione segnalata nei termini di cui sopra, risultasse
    essere frutto di frode e/ o di errore da parte di COMMERCIO.NETWORK, il Member
    potrà ricevere un rimborso dei Utility Token trafugati e/o persi nei limiti
    del capitale massimo garantito secondo quanto previsto nel WalletShield.
  </p>
  <h3>12.3. Riscatto del contenuto del Wallet.</h3>
  <p>
    Il Member potrà in qualsivoglia momento riscattare il contenuto del proprio
    Wallet, mediante selezione della relativa richiesta in Commercio.App seguendo
    le indicazioni là previste. COMMERCIO.NETWORK provvederà ad accreditare gli
    utility Token contenuti nel Wallet al momento della richiesta presso un altro
    wallet digitale indicato dal Member entro 15 giorni lavorativi dalla richiesta
    e previe le verifiche previste dalla legge. Qualora il Member non fornisca un
    wallet digitale gli utility Token contenuti nel Wallet al momento della
    richiesta non potranno essere recuperati.
  </p>
  <h3>12.4. Esclusioni di responsabilità di COMMERCIO.NETWORK.</h3>
  <p>
    COMMERCIO.NETWORK non può essere ritenuto responsabile per ogni danno o
    mancata esecuzione delle transazioni e/o loro sospensione causata da condotta
    fraudolenta e/o negligente e/o colposa del Member e/o causata dalla violazione
    dei termini e condizioni previsti nel presente Accordo. In particolare, a
    titolo esemplificati e non esaustivo:
  </p>
  <ol type="a">
    <li>
      COMMERCIO.NETWORK non potrà essere in nessun caso ritenuto responsabile per
      qualsivoglia transazione non autorizzata e/o non corretta e/o non completa
      che sia stata comunicata a COMMERCIO.NETWORK oltre i termini e/o con
      modalità diverse da quelli previsti nel presente Accordo. COMMERCIO.NETWORK
      non potrà essere ritenuto responsabile per nessun reclamo, azione,
      richiesta, indennizzo, danni in relazione a transazioni non autorizzate e/o
      non corrette e/o non complete comunicate a COMMERCIO.NETWORK tardivamente;
    </li>
    <li>
      le transazioni eseguite sul Wallet tramite Commercio.App sono eseguite "as
      is" e "come possibile", senza alcuna garanzia da parte di COMMERCIO.NETWORK
      in termini di guadagno, profitto e/o risultato;
    </li>
    <li>
      il Member è consapevole ed accetta che l'accesso ai Membership Benefits
      potrebbe risultare compromesso o non disponibile in periodi di significativa
      volatilità o volume di traffico dati. Ciò potrebbe comportare
      l'impossibilità di acquistare o vendere per periodi di tempo e potrebbe
      anche portare a ritardi nei tempi di risposta di Commercio.App;
    </li>
    <li>
      Il Member è inoltre consapevole ed accetta che, sebbene COMMERCIO.NETWORK si
      sforzi di fornire un servizio eccellente, non garantisce che la propria
      piattaforma Commercio.App o altri servizi, tra cui il sito web, siano
      disponibili senza interruzioni e non garantisce che qualsiasi
      comando/ordine/richiesta del Member venga eseguito, accettato, registrato o
      rimanga aperto. COMMERCIO.NETWORK precisa che i tempi di risposta del suo
      servizio di assistenza clienti possono essere ritardati, anche in periodi di
      notevole volatilità o volume di traffico dati.
    </li>
  </ol>
  <p>
    COMMERCIO.NETWORK non potrà essere ritenuto in questi casi responsabile per
    eventuali perdite, danni, spese e qualsivoglia pregiudizio che dovesse
    derivare da ritardi nelle transazioni.<br />
    COMMERCIO.NETWORK si impegna a fornire informazioni accurate e tempestive sul
    proprio sito web e su Commercio.App. Nonostante ciò, il contenuto di questi
    potrebbe non essere sempre del tutto accurato, completo o aggiornato e
    potrebbe anche includere imprecisioni tecniche o errori tipografici.<br />
    Nel tentativo di continuare a fornire al Member informazioni il più possibile
    complete e accurate, le informazioni possono, nella misura consentita dalla
    legge applicabile, essere modificate o aggiornate di volta in volta senza
    preavviso, incluse, senza limitazione, le informazioni relative alle
    politiche, prodotti e servizi di COMMERCIO.NETWORK. Di conseguenza, il Member
    è consapevole ed accetta di dover verificare tutte le informazioni prima di
    fare affidamento su di esse, e tutte le decisioni basate sulle informazioni
    contenute nel sito o su Commercio.App sono di esclusiva responsabilità del
    Member stesso e COMMERCIO.NETWORK non potrà essere in alcun modo ritenuta
    responsabile per tali decisioni.<br />
    Il Member è consapevole ed accetta che eventuali link a materiali di terze
    parti (inclusi, senza limitazione, i siti web o App) possono essere forniti
    per comodità, ma non sono preventivamente controllati da COMMERCIO.NETWORK.
    Conseguentemente, il Member riconosce e accetta che COMMERCIO.NETWORK non
    potrà essere in alcun modo ritenuta responsabile di alcun aspetto delle
    informazioni, dei contenuti o dei servizi contenuti in tali materiali di terzi
    accessibili o collegati al sito di COMMERCIO.NETWORK o a Commercio.App.
  </p>
  <h3>
    12.5. Sospensione, rescissione e cancellazione di transazioni sul Wallet
  </h3>
  <p>
    Il Member è consapevole ed accetta che COMMERCIO.NETWORK possa (a) rifiutare
    di completare, o bloccare, o annullare una transazione autorizzata dal Member
    (anche dopo che i fondi sono stati addebitati sul Wallet Commercio.App),
    oppure (b) sospendere, limitare o terminare l'accesso a uno o tutti i
    Membership Benefits, e/o (c) disattivare o annullare l'Account del Member con
    effetto immediato, per i seguenti motivi:
  </p>
  <ol>
    <li>
      ritenga ragionevolmente di doverlo fare per proteggere la reputazione di
      COMMERCIO.NETWORK;
    </li>
    <li>
      COMMERCIO.NETWORK sia, secondo il suo ragionevole parere, tenuta a farlo in
      base alla legge, ai regolamenti o a qualsiasi tribunale o altra autorità a
      cui siamo soggetti in qualsiasi giurisdizione;
    </li>
    <li>
      COMMERCIO.NETWORK abbia il ragionevole sospetto che il Member stia agendo in
      violazione del presente Accordo;
    </li>
    <li>
      COMMERCIO.NETWORK abbia il ragionevole sospetto che il Member abbia violato
      le previsioni dell’Appendice 1 (“Utilizzi vietati”);
    </li>
    <li>
      COMMERCIO.NETWORK abbia il ragionevole sospetto che una transazione sia
      errata;
    </li>
    <li>
      COMMERCIO.NETWORK abbia il ragionevole sospetto che sia stata violata la
      sicurezza dell'Account, oppure che i Membership Benefits siano utilizzati in
      modo fraudolento o non autorizzato;
    </li>
    <li>
      COMMERCIO.NETWORK sospetti il riciclaggio di denaro, il finanziamento del
      terrorismo, la frode o qualsiasi altro crimine finanziario;
    </li>
    <li>
      COMMERCIO.NETWORK abbia avuto notizia che l'utilizzo dell'Account sia
      soggetto a qualsiasi controversia, indagine o procedimento governativo in
      corso e/o COMMERCIO.NETWORK abbia notizia di un elevato rischio di non
      conformità legale o normativa associato all'attività dell'Account;
    </li>
    <li>
      COMMERCIO.NETWORK abbia avuto notizia che il Member intraprende qualsiasi
      azione che possa aggirare i suoi controlli, come ad esempio l'apertura di
      più account o l'abuso di promozioni che possa offrire di tanto in tanto.
    </li>
  </ol>
  <p>
    Nel caso in cui COMMERCIO.NETWORK rifiuti di completare una transazione e/o
    sospenda, limiti o chiuda l'Account del Member, e/o interrompa l’utilizzo dei
    Membership Benefits, fornirà (a meno che non sia illegale) una notifica delle
    azioni intraprese e delle ragioni del rifiuto, della sospensione o della
    chiusura e, se del caso, la procedura per la correzione di eventuali errori
    fattuali che hanno portato al rifiuto, alla sospensione o alla chiusura.<br />
    Nel caso in cui COMMERCIO.NETWORK rifiutasse di completare una transazione e/o
    sospendere l'Account del Member, revocherà la sospensione o completerà la
    transazione non appena questo sia ragionevolmente possibile ed una volta che
    le ragioni del rifiuto e/o della sospensione non sussistano più. Tuttavia,
    COMMERCIO.NETWORK non è obbligato a consentire al Member di ripristinare una
    transazione allo stesso prezzo o alle stesse condizioni della transazione
    sospesa, invertita o annullata.<br />
    In nessun caso COMMERCIO.NETWORK potrà essere ritenuto responsabile della
    sospensione, rescissione o annullamento della transazione se abbia
    correttamente applicato quanto previsto nel presente Accordo.
  </p>
  `,
  P13: `
  <h2>13. Durata della Membership</h2>
  <p>
    L’acquisto della Membership ha durata annuale con decorrenza dalla
    dichiarazione di adesione con completamento della procedura e sottoscrizione
    del presente Accordo.<br />
    Al momento della approssimarsi di tale scadenza annuale, almeno 30 gg prima
    della scadenza, COMMERCIO.NETWORK potrà provvedere ad inviare al Member una
    comunicazione circa l’approssimarsi della scadenza con le indicazioni per
    procedere al rinnovo della Membership. Tale avviso potrà essere effettuato sia
    all’indirizzo email indicato alla registrazione, sia tramite notifica presso
    l’Account del Member. Il Member attiva, al momento della sottoscrizione del
    presente Accordo, la funzione di rinnovo automatico a mezzo RID o addebito con
    Carta di Credito. In tal caso la Membership è automaticamente rinnovata alla
    scadenza, mediante addebito del relativo costo secondo la modalità di
    pagamento prescelta. COMMERCIO.NETWORK potrà inviare al Member comunciazione
    circa il buon esito o meno del rinnovo automatico.<br />
    Nel caso in cui il Member provveda al rinnovo con corresponsione del nuovo
    canone annuale, tutti i Membership Benefits saranno nuovamente fruibili da
    parte del Member.<br />
    Nell’ipotesi in cui invece non venga effettuato il rinnovo, la Membership si
    estingue e non sarà più possibile fruire dei Membership Benefits e le
    conseguenze della cessazione del rapporto di Membership saranno quelle
    disciplinate all'articolo 17.1.
  </p>  
  `,
  P14: `
  <h2>14. Malfunzionamento nella prestazione dei servizi dei Developers</h2>
  <p>
    Le prestazioni fornite da COMMERCIO.NETWORK si limitano ai Membership Benefits
    come descritti nel presente Accordo e nelle sue successive modifiche.<br />
    Gli acquisti dei servizi effettuati dal Member all’interno delle App sono
    conclusi unicamente tra il Member e il Developer, senza che COMMERCIO.NETWORK
    ne sia parte. Difatti, COMMERCIO.NETWORK non si assume alcuna responsabilità,
    né concede alcuna garanzia per gli obblighi di prestazioni di prodotti o
    servizi dei Developer, in particolare è da escludersi qualsivoglia forma di
    responsabilità, anche solo indiretta, di COMMERCIO.NETWORK sia in caso di
    inadempimento e responsabilità contrattuale che in caso di responsabilità
    extracontrattuale da parte del Developer.
  </p>
  `,
  P15: `
  <h2>15. Responsabilità</h2>
  <p>
    COMMERCIO.NETWORK è responsabile solo e limitatamente per il proprio
    inadempimento degli obblighi previsti nel presente Accordo. COMMERCIO.NETWORK
    non potrà essere ritenuto responsabile per condotte negligenti e/o
    inadempimenti di terzi, quali a titolo esemplificativo e non esaustivo i
    Developer, i Distributor, i Nodi Validatori o gli internet provider,ecc.<br />
    A titolo esemplificativo e non esaustivo COMMERCIO.NETWORK non è responsabile
    per:
  </p>
  <ol type="a">
    <li>Interruzioni della disponibilità dell’accesso ad internet del Member;</li>
    <li>
      altri guasti tecnici ed elettronici (i) durante una trasmissione di dati
      tramite internet, nonché (ii) durante l’utilizzo di siti o app di
      COMMERCIO.NETWORK, del servizio di sms COMMERCIO.NETWORK e delle
      applicazioni COMMERCIO.NETWORK per terminali mobili, nella misura in cui
      tali guasti non rientrino nell’ambito di responsabilità di
      COMMERCIO.NETWORK;
    </li>
    <li>
      guasti tecnici ed elettronici che impediscono una registrazione degli
      acquisti effettuati nel Membership Benefits di COMMERCIO.NETWORK non
      imputabili a COMMERCIO.NETWORK (in particolare, eventuali anomalie nel
      rintracciamento e conseguenti perdite di dati);
    </li>
    <li>non disponibilità delle reti di telefonia mobile o di terminali;</li>
    <li>malfunzionamento dei terminali mobili del Member;</li>
    <li>
      danni e/o interruzioni del servizio di alcun genere derivanti da virus
      informatici o altri codici dannosi che possano colpire il computer del
      Member o altre apparecchiature dello stesso, compreso qualsivoglia attacco
      phishing, spoofing o di altro genere. Onde evitare simili evenienze, il
      Member è consapevole che COMMERCIO.NETWORK consiglia l'uso di software di
      screening e prevenzione di virus informatici ed altre attività malevole che
      siano adeguatamente aggiornati ed affidabili. Il Member inoltre è reso
      edotto che anche l'attività di invio di sms (es. quella di OTP) potrebbe
      essere oggetto di attacchi di spoofing o di phishing o di altre attività
      malevole, impegnandosi pertanto a prestare idonea ed adeguata attenzione ad
      ogni messaggio, e-mail ed in genere comunicazione, che appaia provenire da
      parte di COMMERCIO.NETWORK.
    </li>
  </ol>
  <p>
    Nella misura in cui la responsabilità di COMMERCIO.NETWORK sia limitata o
    esclusa, le limitazioni o le esclusioni si applicano anche alla responsabilità
    personale dei dipendenti, dei rappresentanti legali e degli ausiliari di
    COMMERCIO.NETWORK.<br />
    Le limitazioni di responsabilità e le esclusioni di responsabilità previste
    qui non modificano l’eventuale responsabilità di COMMERCIO.NETWORK secondo le
    disposizioni di legge inderogabili dalle parti.
  </p>
  `,
  P16: `
  <h2>16. Costi</h2>
  <ul style="list-style-type: none">
    <li>
      16.1. La sottoscrizione delle Membership è a pagamento con costo 
      differenziato a seconda della tipologia di Membership prevista. I costi 
      annuali delle Membership sono disponibili nella pagina https://commerc.io
    </li>
  </ul>
  `,
  P17: `
  <h2>17. Cessazione del rapporto contrattuale da parte del Member</h2>
  <p>
    Il Member ha diritto di recedere in qualsiasi momento dal presente Accordo con
    COMMERCIO.NETWORK mediante pulsante “Cancella Membership” sulla
    Commercio.App.<br />
    Alla cessazione del rapporto contrattuale, il Member ha diritto di percepire
    soltanto i crediti CCC qualora il Member esegua entro 15 gg dalla scadenza
    della propria Membership il trasferimento del contenuto del proprio Wallet su
    un Wallet digitale alternativo valido, in caso contrario tutto il contenuto
    del proprio Wallet andrà automaticamente perso e non sarà recuperabile in
    alcun modo.
  </p>
  <h3>17.1. Conseguenze della cessazione del rapporto di Membership.</h3>
  <p>
    Una volta che il Member abbia optato per lo scioglimento del suo rapporto con
    COMMERCIO.NETWORK oppure nell'ipotesi in cui il Member non abbia proceduto al
    rinnovo della Membership entro la scadenza secondo quanto previsto all'art.
    13, il Member tornerà ad essere qualificato come mero User e COMMERCIO.NETWORK
    potrà procedere alla chiusura dell'Account quale Member secondo le seguenti
    disposizioni:
  </p>
  <ol type="a">
    <li>
      COMMERCIO.NETWORK consentirà allo User (in quanto ex Member) l'accesso al
      proprio Account scaduto nel limite massimo di 15 giorni dalla scadenza ai
      fini del trasferimento delle eventuali Utility Token contenuti nel Wallet
      dello User;
    </li>
    <li>
      lo User è consapevole ed accetta che, in caso di trasferimento degli Utility
      Token dopo la scadenza dell'Account, COMMERCIO.NETWORK potrà legittimamente
      richiedere allo User una nuova identificazione con relativo svolgimento
      della procedura di verificazione KYC prevista;
    </li>
    <li>
      lo User è consapevole ed accetta che dalla data di scadenza del proprio
      Account egli non sarà più autorizzato ad utilizzare in alcun modo
      Commercio.App od i servizi a questa connessi e/o collegati.
    </li>
    <li>
      COMMERCIO.NETWORK non presterà più alcuna attività di gestione del Wallet.
      Il contenuto del Wallet dovrà essere trasferito a spese e cura dello User in
      un altro wallet di cui egli sia titolare e che verrà gestito in completa
      autonomia dallo User. Il trasferimento del contenuto del Wallet dovrà essere
      effettuato da parte dello User entro e non oltre 15 (quindici) giorni
      decorrenti dalla scadenza della Membership. Qualora il Member non esegua
      entro 15 gg dalla scadenza della propria Membership il trasferimento del
      contenuto del proprio Wallet su un Wallet digitale alternativo valido tutto
      il contenuto del proprio Wallet andrà automaticamente perso e non sarà
      recuperabile in alcun modo. Tutto il contenuto del suo Wallet andrà perso.
    </li>
  </ol>
  `,
  P18: `
  <h2>18. Cessazione del rapporto contrattuale da parte di COMMERCIO.NETWORK</h2>
  <ul style="list-style-type: none">
    <li>
      18.1. COMMERCIO.NETWORK ha diritto di risolvere il Contratto di diritto, ai
      sensi dell’art. 1456 c.c., qualora il Member violi una delle disposizioni di
      questo Accordo previste agli articoli: art 6 comma 13; art. 7, commi 3 e 4
      lett. a-d-e-; art. 11.2.; Allegato 1, sub. 1 e 2. L’Accordo si scioglie nel
      momento in cui il Member riceve la comunicazione di COMMERCIO.NETWORK (a
      mezzo pec o raccomandata ar o altro mezzo che dia prova di ricezione) che
      dichiara di avvalersi della clausola risolutiva espressa. E’ fatto salvo il
      risarcimento degli eventuali danni.
    </li>
    <li>
      18.2. Fuori dei casi previsti all’art. 18.1, COMMERCIO.NETWORK ha diritto di
      risolvere questo Accordo per grave inadempimento dell’Accordo da parte del
      Member ai sensi dell'art. 1453 c.c., salva preventiva contestazione
      dell’inadempienza a mezzo pec o raccomandata ar o altro mezzo che dia prova
      di ricezione, a cui il Member non ponga rimedio entro i successivi 15
      giorni. E’ fatto salvo il risarcimento degli eventuali danni agli interessi
      economici o alla reputazione di COMMERCIO.NETWORK o dei Validator,
      Distributor, Developer o Ambassador.
    </li>
    <li>
      18.3. Il Member si impegna a tenere COMMERCIO.NETWORK manlevata e indenne in
      relazione a responsabilità e/o danni connessi alla sua colpevole violazione
      delle disposizioni contrattuali. Ciò vale anche riguardo ai costi di difesa
      da sostenere in caso di rivendicazioni da parte di terzi. Inoltre
      COMMERCIO.NETWORK ha diritto di far valere nei confronti del Member
      qualsiasi danno derivante dalla violazioni degli obblighi del Member,
      comprese le spese giudiziarie.
    </li>
    <li>
      18.4. Alla conclusione del rapporto contrattuale opera quanto previsto al
      precedente art. 17.1.
    </li>
  </ul>
  `,
  P19: `
  <h2>19. Disposizioni generali</h2>
  <h3>19.1. Comunicazioni.</h3>
  <p>
    Il Member espressamente acconsente alla ricezione di tutte le comunicazioni
    (anche di carattere contabile o relative alla cessazione o sospensione del
    rapporto) in formato elettronico, incluso il formato email, salvo che sia
    diversamente disposto per legge o da questo Accordo.<br />
    Ogni comunicazione relativa al presente Accordo sarà trasmessa all'indirizzo
    indicato dal Member durante la fase di registrazione, ovvero al diverso
    indirizzo comunicato dal Member.<br />
    Il Member acconsente a che tutte le comunicazioni vengano effettuate anche
    mediante tecniche di comunicazione a distanza ("TCD"), quali posta
    elettronica, servizi di messaggistica e notifiche via Commercio.App.<br />
    Le comunicazioni periodiche previste dal presente Accordo, oppure che
    COMMERCIO.NETWORK intenda effettuare al Member, saranno indirizzate unicamente
    al Member e si considera che il Member le avrà ricevute il giorno della
    notifica, su Commercio.App, che informi che la comunicazione è a disposizione
    nell'apposita sezione.
  </p>
  <h3>19.2. Modifiche unilaterali</h3>
  <p>
    Le modifiche del presente Accordo, comunicate al Member anche a mezzo TCD, si
    intendono accettate dal Member se il Member non si oppone per iscritto (via
    email) entro 30 giorni dalla ricezione della relativa comunicazione di
    modifica.<br />
    COMMERCIO.NETWORK richiamerà l’attenzione del Member alla decorrenza del
    termine, in particolare, sul fatto che il suo consenso alle modifiche
    comunicate delle condizioni generali del presente Accordo si intenderà
    accettato se non si oppone, per iscritto (via email ad
    info@commercio.network), alla loro validità entro il termine fissato.<br />
    Le modifiche in questione si intendono accettate dal Member soltanto se questo
    avviso è stato effettivamente dato.
  </p>
  <ul style="list-style-type: none">
    <li>
      19.3. Qualora vengano utilizzate all’interno del contratto espressioni
      specifiche con riferimento al sesso, si fa riferimento ugualmente a soggetti
      di sesso maschile e femminile e a soggetti giuridici.
    </li>
    <li>
      19.4. Qualora determinate disposizioni del presente Accordo si rivelassero
      interamente o parzialmente inefficaci o inapplicabili, resteranno efficaci
      le rimanenti disposizioni.
    </li>
    <li>
      19.5. Si applica al rapporto contrattuale il diritto italiano. Nel caso in
      cui il Member (Green) sia considerato quale "consumatore" ai sensi della
      normativa in vigore, le disposizioni inderogabili di legge prevarranno sulle
      disposizioni del presente Accordo.
    </li>
    <li>
      19.6. La registrazione e l’acquisto di Membership sono consentite per le
      persone fisiche a partire dal compimento del 18° anno di età.
    </li>
    <li>
      19.7. Il Member si impegna ad accollarsi tutte le spese, diritti, imposte,
      ecc. derivanti dall’ottenimento delle Membership degli Utility Token e di
      qualsiasi altro prodotto o servizio acquistato.
    </li>
    <li>
      19.8. Protezione dei dati Personali è disponibile all’indirizzo
      COMMERCIO.NETWORK/privacy.
    </li>
  </ul>
  `,
  P20: `
  <h2>20. Controversie</h2>
  <ul style="list-style-type: none">
    <li>
      20.1. Le parti di questo Accordo (il Member e COMMERCIO.NETWORK)
      sottoporranno le controversie derivanti dal presente atto al tentativo di
      conciliazione previsto dal Servizio di conciliazione della Camera Arbitrale
      di Milano istituita presso la CCIAA di Milano, Monza Brianza e Lodi. Nel
      caso in cui il tentativo fallisca, le controversie, anche di natura non
      contrattuale, derivanti dal presente atto, relative o connesse allo stesso,
      saranno risolte mediante arbitrato secondo il Regolamento della Camera
      Arbitrale di Milano, Monza Brianza e Lodi, da un arbitro unico nominato in
      conformità a tale Regolamento.
    </li>
    <li>
      20.2. Il Green Member che sia qualificabile come consumatore ai sensi
      dell'art. 3 lettera a) del D.Lgs. n. 206/2005 ogni controversia che dovesse
      insorgere in relazione alla interpretazione, esecuzione e/o validità del
      presente Accordo sarà decisa dal Giudice competente per legge.
    </li>
  </ul>
  `,
  P_APPENDIX: `
  <h2>Appendice 1: utilizzi vietati</h2>
  <h3>1) Usi Vietati</h3>
  <p>
    Il Member non deve utilizzare il proprio Account e la Membership di
    COMMERCIO.NETWORK per svolgere le seguenti categorie di attività ("Usi
    vietati"). Aprendo un Account con COMMERCIO.NETWORK, confermate che non
    utilizzerete il vostro Account e/o i servizi di COMMERCIO.NETWORK per fare
    quanto segue:
  </p>
  <ul style="list-style-type: none">
    <li>
      Attività illecite: Attività che violino, o contribuiscano a violare,
      qualsiasi legge, statuto, ordinanza, regolamento o programma di sanzioni
      amministrative nei paesi in cui COMMERCIO.NETWORK svolge la propria attività
      o che comportino proventi di attività illegali; pubblicare, distribuire o
      diffondere materiale o informazioni illegali.
    </li>
    <li>
      Attività abusiva: Azioni che impongono un carico irragionevole o
      sproporzionato sulla infrastruttura di COMMERCIO.NETWORK, o che
      interferiscono, intercettano o espropriano qualsiasi sistema, dato o
      informazione; trasmettere o caricare qualsiasi materiale sul Sito che
      contenga virus, cavalli di troia, worm o qualsiasi altro programma dannoso o
      deleterio; tentare di ottenere l'accesso non autorizzato al sito di
      COMMERCIO.NETWORK, ad altri conti COMMERCIO.NETWORK, sistemi informatici o
      reti connesse al sito COMMERCIO.NETWORK, attraverso l'estrazione di password
      o qualsiasi altro mezzo; utilizzare le informazioni dell'Account di un'altra
      parte per accedere o utilizzare il sito COMMERCIO.NETWORK, salvo il caso di
      specifici commercianti e/o applicazioni specificamente autorizzati da un
      utente ad accedere all'Account e alle informazioni di tale utente; oppure
      trasferire l'accesso al proprio account o i diritti sul proprio account a
      terzi, salvo che per effetto di legge o con l'espressa autorizzazione di
      COMMERCIO.NETWORK.
    </li>
    <li>
      Abuso di altri utenti: Interferire con l'accesso o l'utilizzo di un altro
      individuo o entità di qualsiasi servizio di COMMERCIO.NETWORK; diffamare,
      abusare, estorcere, molestare, perseguitare, minacciare o comunque violare o
      infrangere i diritti legali (come, ma non solo, i diritti di privacy,
      pubblicità e proprietà intellettuale) di altri; incitare, minacciare,
      facilitare, promuovere o incoraggiare l'odio, l'intolleranza razziale o atti
      violenti contro altri; raccogliere o comunque raccogliere informazioni dal
      sito di COMMERCIO.NETWORK su altri, inclusi, senza limitazione, gli
      indirizzi e-mail, senza un adeguato consenso.
    </li>
    <li>
      Frode: Attività che opera per frodare COMMERCIO.NETWORK, gli utenti di
      Commercio.Network o qualsiasi altra persona; fornire a COMMERCIO.NETWORK
      informazioni false, inaccurate o fuorvianti.
    </li>
    <li>
      Gioco d'azzardo: utilizzare i servizi di Commercio.Network per svolgere
      lotterie; aste a pagamento per le offerte; previsioni o quotazioni sportive;
      campionati sportivi di fantasia con premi in denaro; giochi su internet;
      concorsi; lotterie; lotterie; giochi d'azzardo.
    </li>
    <li>
      Violazione della proprietà intellettuale: Effettuare transazioni che
      coinvolgono oggetti che violano il diritto d'autore, la tutela del marchio,
      diritto di pubblicità o di privacy o qualsiasi diritto di proprietà ai sensi
      della legge, inclusi, a titolo esemplificativo ma non esaustivo, la vendita,
      la distribuzione o l'accesso a musica, film, software o altri materiali su
      licenza contraffatti senza l'appropriata autorizzazione del titolare dei
      diritti; l'uso della proprietà intellettuale, del nome o del logo di
      COMMERCIO.NETWORK, incluso l'uso di eventuali marchi commerciali o di
      servizio di COMMERCIO.NETWORK, senza il consenso espresso di
      COMMERCIO.NETWORK o in un modo che danneggi COMMERCIO.NETWORK o il marchio
      COMMERCIO.NETWORK; qualsiasi azione che implica un'approvazione non
      veritiera da parte di COMMERCIO.NETWORK o un'affiliazione con
      COMMERCIO.NETWORK.
    </li>
  </ul>
  <h3>2) Aziende vietate</h3>
  <p>
    Acquistando una Membership, il Member si impegna a non utilizzare i servizi
    COMMERCIO.NETWORK in relazione a nessuna delle seguenti attività, pratiche o
    articoli (“Attività vietate”):
  </p>
  <ul style="list-style-type: none">
    <li>
      Servizi di Investimento e Credito: broker di titoli; servizi di consulenza
      ipotecaria o di riduzione del debito; consulenza o riparazione del credito;
      opportunita immobiliari; piani di investimento;
    </li>
    <li>
      Servizi finanziari limitati: incassi di assegni, cauzioni, agenzie di
      recupero crediti;
    </li>
    <li>
      Violazione della proprietà intellettuale o dei diritti di proprietà:
      vendita, distribuzione o accesso a musica, film, software o altri materiali
      su licenza contraffatti senza l'opportuna autorizzazione del titolare dei
      diritti;
    </li>
    <li>
      Prodotti contraffatti o non autorizzati: vendita o rivendita non autorizzata
      di prodotti o servizi di marca o di design; vendita di prodotti o servizi
      importati o esportati illegalmente o rubati;
    </li>
    <li>
      Prodotti e servizi regolamentati: vendita di tabacco, sigarette elettroniche
      e liquidi elettronici; servizi farmaceutici o di prescrizione online; beni o
      servizi soggetti a restrizioni di età; armi e munizioni; polvere da sparo e
      altri esplosivi; fuochi d'artificio e beni correlati; materiali tossici,
      infiammabili e radioattivi;
    </li>
    <li>
      Droga e armamentario per la droga: vendita di stupefacenti, sostanze
      controllate e qualsiasi attrezzatura progettata per la produzione o l'uso di
      droghe, come bong, vaporizzatori e narghilè;
    </li>
    <li>
      Pseudo-Farmaceutici: prodotti farmaceutici e altri prodotti che forniscono
      indicazioni sulla salute che non sono state approvate o verificate dall'ente
      normativo locale e/o nazionale competente;
    </li>
    <li>
      Sostanze progettate per imitare le droghe illegali: vendita di una sostanza
      legale che fornisce lo stesso effetto di una droga illegale
    </li>
    <li>
      Contenuti e servizi per adulti: pornografia e altri materiali osceni
      (inclusi letteratura, immagini e altri media); siti che offrono servizi
      legati al sesso come prostituzione, escort, pay-per-view, servizi di live
      chat per adulti;
    </li>
    <li>
      Marketing multilivello: schemi piramidali, network marketing e programmi di
      marketing di riferimento;
    </li>
    <li>
      Pratiche sleali, predatorie o ingannevoli: opportunità di investimento o
      altri servizi che promettono alte ricompense; vendita o rivendita di un
      servizio senza vantaggi aggiuntivi per l'acquirente; rivendita di offerte
      governative senza autorizzazione o valore aggiunto; siti che determiniamo a
      nostra esclusiva discrezione essere sleali, ingannevoli o predatori nei
      confronti dei consumatori;
    </li>
    <li>
      Attività ad alto rischio: qualsiasi attività che riteniamo comporti un
      elevato rischio finanziario, responsabilità legale o violi le politiche
      della rete di carte di credito o delle banche.
    </li>
    <li>
      Servizi in denaro: trasferimento di denaro, di valuta digitale; cambi di
      valuta o di valuta digitale; carte regalo; carte prepagate; vendita di
      valuta in-game a meno che il commerciante non sia l'operatore del mondo
      virtuale; agire come intermediario o aggregatore di pagamenti;
    </li>
    <li>
      Giochi di abilità: Giochi che non sono definiti come gioco d'azzardo ai
      sensi del presente accordo o per legge, ma che richiedono una quota
      d'iscrizione e l'assegnazione di un premio;
    </li>
    <li>
      Organizzazioni Religiose/Spirituali: Funzionamento di un'organizzazione
      religiosa o spirituale a scopo di lucro.
    </li>
  </ul>
  `,
  TITLE_ADDITIONALS: `Termini Addizionali`,
  P_ADDITIONALS: `
  <p>
  Il Member sottoscrive per specifica accettazione, ai sensi degli articoli 1341
  - 1342 c.c., le seguenti previsioni: limiti di responsabilità di
  COMMERCIO.NETWORK (art. 10 lett. b-d-e-f-, art. 11.1, art. 11.3, art. 12.4,
  art. 14, art. 15 comma 1), facoltà di recesso unilaterale (art.18.1), clausola
  risolutiva espressa (art. 18.1), modifiche unilaterali di COMMERCIO.NETWORK
  (art. 19.2), clausola arbitrale (art. 20.1)
  </p>
  `,
});
