import { mockMintItem, mockMintItems, mockMintSummary } from '@/__mocks__';
import { NFT } from '@/constants';

const mint = {
  requestSummary() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const summary = { data: mockMintSummary() };
        resolve(summary);
      }, 100);
    });
  },
  requestList() {
    return new Promise((resolve) => {
      const collections = 10;
      setTimeout(() => {
        const items = {
          data: {
            collections: mockMintItems(collections),
            paging: {
              next_cursor: 'MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
              next_link:
                'https://localhost/items?cursor=MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
              total_count: collections,
            },
          },
        };
        resolve(items);
      }, 100);
    });
  },
  requestDetail() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const summary = {
          data: mockMintItem(NFT.TYPES.METADATA_ON_CHAIN),
        };
        resolve(summary);
      }, 100);
    });
  },
};

export default mint;
