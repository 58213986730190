import { APIS } from '@/constants';
import axios from 'axios';
import apisHelper from './helpers/apisHelper';

const stake = {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestSummary(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/summary',
      method: 'get',
      baseURL: APIS.STAKE,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} cursor
   * @param {Number} limit
   * @param {String} msg_type
   * @param {String} order
   * @returns {AxiosPromise}
   */
  requestList({ token, cursor, limit, msg_type, order }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = apisHelper.bindValuedParams({
      cursor,
      limit,
      msg_type,
      order,
    });
    return axios({
      url: APIS.STAKE,
      method: 'get',
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestCreateDelegate(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/delegate',
      method: 'post',
      baseURL: APIS.STAKE,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} id
   * @returns {AxiosPromise}
   */
  requestReadDelegate(token, id) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/delegate/${id}`,
      method: 'get',
      baseURL: APIS.STAKE,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestCreateRewards(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/rewards',
      method: 'post',
      baseURL: APIS.STAKE,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} id
   * @returns {AxiosPromise}
   */
  requestReadRewards(token, id) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/rewards/${id}`,
      method: 'get',
      baseURL: APIS.STAKE,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestCreateUndelegate(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/undelegate',
      method: 'post',
      baseURL: APIS.STAKE,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} id
   * @returns {AxiosPromise}
   */
  requestReadUndelegate(token, id) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/undelegate/${id}`,
      method: 'get',
      baseURL: APIS.STAKE,
      headers,
    });
  },
};

export default stake;
