<template>
  <v-row>
    <v-col class="d-flex">
      <v-file-input
        v-model="file"
        dense
        outlined
        required
        :error-messages="errorMessages"
        :label="$t('labels.fileUpload')"
        @blur="v.$touch()"
        @input="v.$touch()"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheFormUpload',
  props: {
    value: File,
    v: {
      type: Object,
    },
  },
  emits: ['input'],
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    errorMessages() {
      const errors = [];
      if (!this.v.$dirty) return errors;
      !this.v.required && errors.push(this.$t('msgs.mandatoryField'));
      return errors;
    },
  },
};
</script>
