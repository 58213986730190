import { MESSAGE } from '@/constants';

const messageManager = {
  composeError(code, data) {
    return {
      code,
      data,
      type: MESSAGE.TYPE.ERROR,
    };
  },
  composeSuccess(code, data) {
    return {
      code,
      data,
      type: MESSAGE.TYPE.SUCCESS,
    };
  },
  getCode(message) {
    let code =
      message.type === MESSAGE.TYPE.ERROR
        ? MESSAGE.RESPONSE_CODE.E_DEFAULT
        : MESSAGE.RESPONSE_CODE.OK_DEFAULT;

    if (Object.values(MESSAGE.RESPONSE_CODE).indexOf(message.code) > -1) {
      const index = Object.values(MESSAGE.RESPONSE_CODE).indexOf(message.code);
      code = Object.keys(MESSAGE.RESPONSE_CODE)[index];
    }

    return code;
  },
  getLocalized(translator, context, message) {
    const $t = translator.bind(context);
    let code = this.getCode(message);
    let translated = $t(`responses.${MESSAGE.RESPONSE_CODE[code]}`);

    if (message.type === MESSAGE.TYPE.ERROR && message.data) {
      translated = `${translated}.<br><em>${message.data}</em>`;
    }

    return translated;
  },
};

export default messageManager;
