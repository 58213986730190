<template>
  <v-select
    dense
    item-text="text"
    item-value="value"
    outlined
    :items="items"
    v-model="select"
  >
    <template #label>
      <span v-text="$t('labels.select')" />
    </template>
    <template #append>
      <v-icon color="primary">{{ mdiChevronDown }}</v-icon>
    </template>
    <template #selection="{ item }">
      <div class="font-weight-bold">
        <span v-text="item.text" />
      </div>
    </template>
  </v-select>
</template>

<script>
import { mdiChevronDown } from '@mdi/js';

export default {
  name: 'TheFormSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],

  data() {
    return {
      mdiChevronDown,
    };
  },
  computed: {
    select: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
