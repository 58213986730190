import { LISTINGS, NFT, STAKE } from '@/constants';

export const listings = {
  [NFT.ATTRIBUTE_TYPES.BOOST_NUMBER]: 'Boost number',
  [NFT.ATTRIBUTE_TYPES.BOOST_PERCENTAGE]: 'Boost percentage',
  [NFT.ATTRIBUTE_TYPES.DATE]: 'Date',
  [NFT.ATTRIBUTE_TYPES.NUMBER]: 'Number',
  [NFT.TYPES.BASE]: 'Base',
  [NFT.TYPES.METADATA_ON_CHAIN]: 'Metadata on chain',
  [LISTINGS.POSITIONINGS.GRID]: 'Griglia',
  [LISTINGS.POSITIONINGS.LIST]: 'Lista',
  [LISTINGS.SORTINGS.ASC]: 'Ascendente',
  [LISTINGS.SORTINGS.DESC]: 'Discendente',
  [LISTINGS.SORTINGS.ALPHABET_ASC]: 'Alfabeto asc',
  [LISTINGS.SORTINGS.ALPHABET_DESC]: 'Alfabeto disc',
  [LISTINGS.SORTINGS.DATE_ASC]: 'Data asc',
  [LISTINGS.SORTINGS.DATE_DESC]: 'Data disc',
  [LISTINGS.SORTINGS.CONTACTS_ALPHABET_ASC]: 'Alfabeto asc',
  [LISTINGS.SORTINGS.CONTACTS_ALPHABET_DESC]: 'Alfabeto disc',
  [LISTINGS.SORTINGS.CONTACTS_DATE_ASC]: 'Data asc',
  [LISTINGS.SORTINGS.CONTACTS_DATE_DESC]: 'Data disc',
  [LISTINGS.SORTINGS.DOSSIERS_ALPHABET_ASC]: 'Alfabeto asc',
  [LISTINGS.SORTINGS.DOSSIERS_ALPHABET_DESC]: 'Alfabeto disc',
  [LISTINGS.SORTINGS.DOSSIERS_DATE_ASC]: 'Data asc',
  [LISTINGS.SORTINGS.DOSSIERS_DATE_DESC]: 'Data disc',
  [LISTINGS.TX.TYPE.RECEIVED]: 'In entrata',
  [LISTINGS.TX.TYPE.SENT]: 'In uscita',
  [STAKE.MSG_TYPE.DELEGATE]: 'Deleghe',
  [STAKE.MSG_TYPE.REWARDS]: 'Ricompense',
  [STAKE.MSG_TYPE.UNDELEGATE]: 'Sblocchi',
};