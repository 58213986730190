const accountsHandler = {
  namingRecipient({
    address,
    eid,
    vault,
    contacts,
    eArchiveLabel,
    meLabel,
    unknownLabel,
    isShortened = false,
  } = {}) {
    const wallet = eid.wallet.address;
    if (address === wallet) {
      return namingAccount({ eid, meLabel, isShortened });
    }
    if (vault && eArchiveLabel && address === vault) {
      return namingEArchive({ address, eArchiveLabel, isShortened });
    }
    const name = getNameFromContacts({
      address,
      contacts,
      unknownLabel,
    });
    return isShortened ? name : buildFullName(name, address);
  },
  namingRecipients({
    recipients,
    eid,
    vault,
    contacts,
    eArchiveLabel,
    meLabel,
    unknownLabel,
    isShortened = false,
  } = {}) {
    const all = [];
    const address = eid.wallet.address;
    const addresses = JSON.parse(JSON.stringify(recipients));
    let index = addresses.indexOf(address);
    if (index > -1) {
      const account = namingAccount({ eid, meLabel, isShortened });
      all.push(account);
      addresses.splice(index, 1);
    }
    if (vault) {
      index = addresses.indexOf(vault);
      if (index > -1 && eArchiveLabel) {
        const eArchive = namingEArchive({
          address: vault,
          eArchiveLabel,
          isShortened,
        });
        all.push(eArchive);
        addresses.splice(index, 1);
      }
    }

    for (const address of addresses) {
      const name = getNameFromContacts({
        address,
        contacts,
        unknownLabel,
      });
      const fullName = isShortened ? name : buildFullName(name, address);
      all.push(fullName);
      if (isShortened && all.length === 2) {
        if (addresses.length > 2) {
          all.push('...');
        }
        break;
      }
    }
    return all.toString();
  },
  namingSender({ sender, eid, contacts, meLabel, unknownLabel }) {
    let name = '';
    const address = eid.wallet.address;
    if (sender === address) {
      name = meLabel;
    } else {
      name = getNameFromContacts({ address: sender, contacts, unknownLabel });
    }
    return buildFullName(name, sender);
  },
  formatRecipient(recipient) {
    let address = `<${this.shortenWallet(recipient.wallet_address)}>`;
    if (recipient.email) {
      const fulladdress = recipient.email + address;
      address = fulladdress;
    }
    return address;
  },
  shortenWallet(address) {
    return `${address.slice(0, 14)}...${address.slice(44, 47)}`;
  },
};

export default accountsHandler;

const namingAccount = ({ eid, meLabel, isShortened }) => {
  if (isShortened) {
    return meLabel;
  }
  const name = meLabel;
  const address = eid.wallet.address;
  return buildFullName(name, address);
};

const namingEArchive = ({ address, eArchiveLabel, isShortened }) => {
  if (isShortened) {
    return eArchiveLabel;
  }
  const name = eArchiveLabel;
  return buildFullName(name, address);
};

const buildFullName = (name, address) =>
  name ? `${name} <${address}>` : `<${address}>`;

const getNameFromContacts = ({ address, contacts, unknownLabel }) => {
  const index = contacts.findIndex(
    (contact) => contact.wallet_address === address,
  );
  if (index < 0) {
    return unknownLabel;
  }
  const contact = contacts[index];
  if (contact.name && contact.lastname) {
    return `${contact.name} ${contact.lastname}`;
  }
  if (!contact.name && contact.lastname) {
    return contact.lastname;
  }
  if (contact.name && !contact.lastname) {
    return contact.name;
  }
  if (!contact.name && !contact.lastname && contact.email) {
    return contact.email;
  }
  if (!contact.name && !contact.lastname && !contact.email) {
    return contact.wallet_address.slice(0, 14);
  }
};
