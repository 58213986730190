import { vaultApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} documentUUID
   * @returns {Promise}
   */
  async fetchFileFromVault({ commit, rootGetters }, documentUUID) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await vaultApi.requestDocument(token, documentUUID);
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = URL.createObjectURL(blob);
      commit('setUrl', url);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
};
