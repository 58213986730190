import { dossierApi, sharedocApi } from '@/apis';
import { SETTINGS } from '@/constants';

export default {
  /**
   *
   * @param {Function} dispatch
   * @param {String} dossierId
   */
  initNotarizeSentDashboard({ dispatch }, dossierId) {
    dispatch('fetchNotarizeSentDashboardData', dossierId);
    dispatch('header/initHeader', null, { root: true });
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} dossierId
   */
  async fetchNotarizeSentDashboardData({ commit, dispatch }, dossierId) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchContacts'),
      dispatch('fetchSentSharedocs', {
        dossierId,
        order: SETTINGS.INFINITE_SCROLL.ORDER_DESC,
      }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} dossierId
   */
  async addSentSharedocs({ commit, dispatch }, { cursor, dossierId }) {
    commit('setAdding', true);
    await dispatch('fetchSentSharedocs', {
      cursor,
      dossierId,
      order: SETTINGS.INFINITE_SCROLL.ORDER_DESC,
    });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   * @param {String} dossierId
   * @param {Number} limit
   * @param {String} order
   */
  async fetchSentSharedocs(
    { commit, rootGetters },
    { cursor, dossierId, limit, order },
  ) {
    const token = rootGetters['auth/token'];
    try {
      const response = await sharedocApi.requestList({
        token,
        dossierId,
        limit,
        cursor,
        order,
      });
      commit('addSharedocs', response.data.processes);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async removeSharedocFromDossier({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      await dossierApi.requestRemove(token, data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} id
   */
  async initNotarizeSentDetail({ commit, dispatch }, id) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchContacts'),
      dispatch('fetchSentSharedocDetail', id),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchSentSharedocDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    try {
      const response = await sharedocApi.requestDetail(token, id);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} rootGetters
   */
  async fetchContacts({ commit, dispatch, rootGetters }) {
    commit('contacts/reset', null, { root: true });
    do {
      await dispatch(
        'contacts/fetchContactsList',
        { cursor: rootGetters['contacts/cursor'] },
        { root: true },
      );
    } while (rootGetters['contacts/cursor']);
  },
};
