import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setIsAlreadyPresent(state, payload) {
    state.isAlreadyPresent = payload;
  },
  setIsStarted(state, payload) {
    state.isStarted = payload;
  },
  setHasFunds(state, payload) {
    state.hasFunds = payload;
  },
  setFile(state, payload) {
    state.file = payload;
  },
  addMetadata(state, payload) {
    const metadata = { ...state.metadata, ...payload };
    state.metadata = metadata;
  },
  setMetadata(state, payload) {
    state.metadata = payload;
  },
  addRecipient(state, payload) {
    state.recipients.push(payload);
  },
  removeRecipient(state, payload) {
    if (state.recipients && state.recipients.length > 0) {
      state.recipients.splice(payload, 1);
    }
  },
  setRecipients(state, payload) {
    state.recipients = payload.slice();
  },
  setVault(state, payload) {
    state.vault = payload;
  },
  setWhere(state, payload) {
    state.where = payload;
  },
};
