import { nftApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} id
   */
  async initNftTransferSubmit({ commit, dispatch }, id) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchContacts'),
      dispatch('fetchNftDetail', id),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} query
   */
  async fetchContacts({ commit, dispatch }, query = '') {
    commit('contacts/reset', null, { root: true });
    commit('setFetching', true);
    await dispatch('contacts/fetchContactsList', { query }, { root: true });
    commit('setFetching', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} query
   */
  async addContacts({ commit, dispatch }, { cursor = '', query = '' } = {}) {
    commit('setFetching', true);
    await dispatch(
      'contacts/fetchContactsList',
      { cursor, query },
      { root: true },
    );
    commit('setFetching', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} id
   */
  async fetchNftDetail({ commit, rootGetters }, id) {
    const token = rootGetters['auth/token'];
    try {
      const response = await nftApi.requestDetail({
        bearer: token,
        tokenId: id,
      });
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   * @returns {Promise.<String>}
   */
  async transferNft({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setTransferring', true);
    try {
      const response = await nftApi.requestTransfer(token, data);
      return response.data.process_id;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setTransferring', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} processId
   */
  async initNftTransferOutcome({ commit, dispatch }, processId) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchProcess', processId)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} processId
   */
  async fetchProcess({ commit, rootGetters }, processId) {
    const token = rootGetters['auth/token'];
    try {
      const response = await nftApi.requestProcess({ token, processId });
      commit('setProcess', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
