import { COIN } from './index';

const CHAIN_COST = 0.02 * Math.pow(10, COIN.STABLE.EXPONENT);

const HOST = window.location.origin;

const INFINITE_SCROLL = Object.freeze({
  LIMIT_FIRST: 50,
  LIMIT_NEXT: 25,
  ORDER_ASC: 'asc',
  ORDER_DESC: 'desc',
  THRESHOLD: [0.5],
});

const MAINTENANCE = process.env.VUE_APP_MAINTENANCE;

// Declare the maximum file size in MB
const MAX_FILE_SIZE = 25;
const MAX_LOGO_SIZE = 1;

const IMAGE_TYPE = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  SVG: 'image/svg+xml',
  WEBP: 'image/webp',
};

const REFERRAL_PATH = 'gift';

const REFRESH_INTERVAL = Object.freeze({
  DELETION_REDIRECT: 5,
  OPERATION_PENDING: 10,
  PAYMENT_PENDING: 3,
  PROGRESS_INDICATOR: 0.5,
});

const VAT = 22;

export default Object.freeze({
  IMAGE_TYPE,
  CHAIN_COST,
  HOST,
  INFINITE_SCROLL,
  MAINTENANCE,
  MAX_FILE_SIZE,
  MAX_LOGO_SIZE,
  REFERRAL_PATH,
  REFRESH_INTERVAL,
  VAT,
});
